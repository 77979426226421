export class SalesHistory {

    
    public Amount: String;
    public PaymentChannelType: String;
    public TransactionRef: String;
    public CustomerName: String;
    public CustomerEmail: String;
    public CustomerPhone: String;
    public AmountTendered: String;
    public CustomerBalance: String;
    public ProductQuantity: String;
    public ChannelStatusCode: String;
    public ChannelStatusMessage: String;

    public CardName: String;
    public CardType: String;
    public Currency: String;
    public Fee: String;
    public SoldByUser: String;
    public Status: String;
    public SettlementAmount: String;
    public Vat: String;
    public Levy: String;
    public GHF: String;
    public CreatedAt: String;

    public ProductsDetails: String;
    
}