<header class="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
    <div class="container-fluid">
        <div class="page-header-content">
            <div class="row align-items-center justify-content-between pt-3">
                <div class="col-auto mb-3">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="user"></i></div>
                        Account - Profile
                    </h1>
                </div>
            </div>
        </div>
    </div>
</header>

<div class="container mt-4">
    <!-- Account page navigation-->
    <nav class="nav nav-borders">
        <a class="nav-link active ml-0" routerLink="/admin/dashboard/myprofile">Profile</a>
        <a class="nav-link" routerLink="/admin/dashboard/security">Security</a>
    </nav>
    <hr class="mt-0 mb-4" />
    <div class="row">
        <div class="col-xl-4">
            <!-- Profile picture card-->
            <div class="card">
                <div class="card-header">Profile Picture</div>
                <div class="card-body text-center">
                    <!-- Profile picture image-->
                    <img class="img-account-profile rounded-circle mb-2" src="{{userProfile.profileUrl}}" alt="" />
                    <!-- Profile picture help block-->
                    <div class="small font-italic text-muted mb-4">JPG or PNG no larger than 1 MB</div>
                    <!-- Profile picture upload button-->
                    <!-- <button class="btn btn-primary" type="button">Upload new image</button> -->
                    <angular-file-uploader 
                        [config]="afuConfig"
                        (ApiResponse)="uploadProfileLogoFile($event)">
                    </angular-file-uploader>
                </div>
            </div>
        </div>
        <div class="col-xl-8">
            <!-- Account details card-->
            <div class="card mb-4">
                <div class="card-header">Account Details</div>
                <div class="card-body">
                    <form [formGroup]="userProfileForm" (ngSubmit)="updateProfile(userProfileForm.value)">
                        <!-- Form Group (username)-->
                        <div class="form-group">
                            <label class="small mb-1" for="inputUsername">Your username</label>
                            <input class="form-control" id="inputUsername" type="text" placeholder="Enter your username" formControlName="username" />
                        </div>
                        <!-- Form Row-->
                        <div class="form-row">
                            <!-- Form Group (first name)-->
                            <div class="form-group col-md-6">
                                <label class="small mb-1" for="inputFirstName">First name</label>
                                <input class="form-control" id="inputFirstName" type="text" placeholder="Enter your first name" formControlName="firstName" />
                                <div *ngIf="f.firstName.invalid && f.firstName.touched">
                                    <small *ngIf="f.firstName.errors?.required" class="small-warning text-danger">*First name is required</small>
                                </div>
                            </div>
                            <!-- Form Group (last name)-->
                            <div class="form-group col-md-6">
                                <label class="small mb-1" for="inputLastName">Last name</label>
                                <input class="form-control" id="inputLastName" type="text" placeholder="Enter your last name" formControlName="lastName" />
                                <div *ngIf="f.lastName.invalid && f.lastName.touched">
                                    <small *ngIf="f.lastName.errors?.required" class="small-warning text-danger">*Last name is required</small>
                                </div>
                            </div>
                        </div>
                        <!-- Form Row        -->
                        <div class="form-row">
                            <!-- Form Group (organization name)-->
                            <div class="form-group col-md-6">
                                <label class="small mb-1" for="inputEmailAddress">Email address</label>
                                <input class="form-control" id="inputEmailAddress" type="email" placeholder="Enter your other personal email" formControlName="emailAddress"  />
                                <div *ngIf="f.emailAddress.invalid && f.emailAddress.touched">
                                    <small *ngIf="f.emailAddress.errors?.required" class="small-warning text-danger">*Personal email address is required</small>
                                    <small *ngIf="f.emailAddress.errors?.email" class="small-warning text-danger">*Only valid email allowed!</small>
                                </div>
                            </div>
                            <!-- Form Group (location)-->
                            <div class="form-group col-md-6">
                                <label class="small mb-1" for="inputPhone">Phone number</label>
                                <input class="form-control" id="inputPhone" type="text" placeholder="Enter phone number" formControlName="phoneNumber" />
                            </div>
                        </div>
                        
                        <!-- Save changes button-->
                        <span class="notification" *ngIf="showProcessing"> <img src="../../../../assets/landing/images/ring1.gif"  class="imgNot float-right"></span>
                        <button class="btn btn-primary" type="submit" [disabled]="!userProfileForm.valid || isUpdating">Save changes</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
