import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  CommonModule,
  LocationStrategy,
  HashLocationStrategy,
} from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFileUploaderModule } from 'angular-file-uploader';

import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { ToastrModule, ToastrService } from 'ngx-toastr';
import { PdfViewerModule } from 'ng2-pdf-viewer';
//import { CoreModule } from './core/core.module';
import { NgxLoadingModule } from 'ngx-loading';

//import * as $ from 'jquery';
declare var $: any;
declare var jQuery: any;

import { AppComponent } from './app.component';
import { StoredashoardComponent } from './storedashoard/storedashoard.component';
import { AuthInterceptor } from './shared/services/auth-interceptor';
import { StorekeepersaleboardComponent } from './storekeeper/storekeepersaleboard/storekeepersaleboard.component';
import { StorekeeperloginComponent } from './storekeeper/storekeeperlogin/storekeeperlogin.component';
import { StorekeeperdashboardComponent } from './storekeeper/storekeeperdashboard/storekeeperdashboard.component';
import { AdmindashboardComponent } from './admindashboard/admindashboard.component';
import { AdminstoresComponent } from './admin/adminstores/adminstores.component';
import { AdminstoresalesComponent } from './admin/adminstoresales/adminstoresales.component';
import { AdminstoresettlementsComponent } from './admin/adminstoresettlements/adminstoresettlements.component';
import { AdmincountriesComponent } from './admin/admincountries/admincountries.component';
import { AdminterminalsComponent } from './admin/adminterminals/adminterminals.component';
import { AdminbillingsComponent } from './admin/adminbillings/adminbillings.component';
import { AdminusersComponent } from './admin/adminusers/adminusers.component';
import { AdminroleandpermissionsComponent } from './admin/adminroleandpermissions/adminroleandpermissions.component';
import { AdminuserprofileComponent } from './admin/adminuserprofile/adminuserprofile.component';
import { AdminsecurityComponent } from './admin/adminsecurity/adminsecurity.component';
import { AdmindirectstoresalesComponent } from './admin/admindirectstoresales/admindirectstoresales.component';
import { AdminsettlementtransactionsComponent } from './admin/adminsettlementtransactions/adminsettlementtransactions.component';

import { NgSelectModule } from '@ng-select/ng-select';
import { StorekycconfigComponent } from './storeadmin/storekycconfig/storekycconfig.component';
import { PolicyComponent } from './main/home/policy/policy.component';

@NgModule({
  declarations: [
    AppComponent,
    StoredashoardComponent,
    StorekeepersaleboardComponent,
    StorekeeperloginComponent,
    StorekeeperdashboardComponent,
    AdmindashboardComponent,
    AdminstoresComponent,
    AdminstoresalesComponent,
    AdminstoresettlementsComponent,
    AdmincountriesComponent,
    AdminterminalsComponent,
    AdminbillingsComponent,
    AdminusersComponent,
    AdminroleandpermissionsComponent,
    AdminuserprofileComponent,
    AdminsecurityComponent,
    AdmindirectstoresalesComponent,
    AdminsettlementtransactionsComponent,
    StorekycconfigComponent,
    PolicyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    PdfViewerModule,
    HttpClientModule,
    RouterModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatRadioModule,
    MatSelectModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    AngularFileUploaderModule,
    ToastrModule.forRoot({
      timeOut: 2500,
      progressBar: true,
      progressAnimation: 'decreasing',
      preventDuplicates: true,
    }),
    NgxLoadingModule.forRoot({}),

    //SweetAlert2Module.forRoot()
  ],
  providers: [
    ToastrService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
