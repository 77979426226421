import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Configuration } from 'src/app/config/configuration';
import { ApiResponseObj } from 'src/app/shared/models/loginApiResponse';
import { ClientAppService } from 'src/app/shared/services/clientapp.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FormBuilder } from '@angular/forms';
import { AlertService } from 'src/app/shared/services/alert.service';
import { FileExportService } from 'src/app/shared/services/file-export.service';
import { ActivatedRoute } from '@angular/router';

import { DomSanitizer } from '@angular/platform-browser';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';

@Component({
  selector: 'app-adminsettlementtransactions',
  templateUrl: './adminsettlementtransactions.component.html',
  styleUrls: ['./adminsettlementtransactions.component.css']
})
export class AdminsettlementtransactionsComponent implements OnInit {

  public options = {type: '', title: '', text: ''};

  private termPaths = new Configuration;
  private reqResponseObject = new ApiResponseObj;
  public showProcessing: boolean;
  public showProcessingFile: boolean;
  public transactions: any[];
  
  public salesTransaction;
  public totalAmount: number = 0;
  public totalQty = 0;
  public salesProducts;

  public activePage = 1;
  public prevPage = 1;
  public totalPages;
  public totalPageList: any[] = [];
  public currentPage = 1;
  public totalCount;

  public perPageCount = 25;
  public totalCurrentPageCount = 25;

  private productSearch = '';
  public showSearch = false;
  public isLoading = false;
  public showLoadData = false;

  public salesDetailsLoader = false;
  public isSalesDetails = false;
  public transactionRef = '';

  public productSalesAnalytics: any  = {};

  public currency = 'GHS'
  private chartData;
  private todaysDate;

  private xLabels = [];
  private xPayLabels = [];
  private ySuccessDataValues = [];
  private yFailureDataValues = [];

  private yPayOptionDataValues = [];

  private ySuccessAmountDataValues = [];
  private yFailureAmountDataValues = [];
  private polarChartBgColors = ["#8e5ea2", "#3cba9f", "#e8c3b9","#c45850", "#3e95cd"];

  private chartScheduler;
  private defaultChart = {
      "startDate": "",
      "type": "DAY",
      "duration": 7
  }

  private countryCode = 'GHS';

  public currencyLoader = {
    "NGN": "&#8358;",
    "GHS": "&#8373;" 
   } 

  public processingSalesAnalytics: boolean;
  public primaryColour = "#ffffff";
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;

  private cycleRef;
  private channelId = '';
  public paymentOptions = [];
  
  constructor(
    private _clientApp: ClientAppService,
    public auth: AuthService,
    private fb: FormBuilder,
    private alert: AlertService,
    private fileExport: FileExportService,
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
    //console.log(this.route.snapshot.params.cycleRef);
    this.cycleRef = this.route.snapshot.params.cycleRef;
    this.loadProductSalesAnalytics();
    this.loadPaymentOption();
    this.loadSettlementTransactions();
    
  }

  loadProductSalesAnalytics(){
    this.options = {type: '', title: '', text: ''};

    let url = this.termPaths._adminBaseUrl+"productsales/analytics";
    
    this.processingSalesAnalytics = true;

    this._clientApp.get(url).subscribe(res =>{
      //console.log(res);
      this.processingSalesAnalytics = false;
      this.reqResponseObject = this.auth.mapHttpResponse(res);
      
      if(this.reqResponseObject.statusCode == "00"){
        this.productSalesAnalytics = this.reqResponseObject.data;
      }
    },  err => {
      
      this.options.type = 'error';
      this.options.text = err.error.errorMessage;
      
      this.processingSalesAnalytics = false;
      this.alert.doToastAlert(this.options, 4);
    });

  }

  loadSettlementTransactions(){
    this.isLoading = !this.isLoading;
    let url = this.termPaths._adminBaseUrl+"settlementcycles/"
    +this.cycleRef+"/transactions?type="
    +this.channelId+"&page="+this.currentPage+"&size="+this.perPageCount;

    this._clientApp.get(url).subscribe(res =>{
      //console.log(res);
      this.isLoading = !this.isLoading;
      this._clientApp.updateStoreUrlScripts();
      this.reqResponseObject = this.auth.mapHttpResponse(res);
      
      if(this.reqResponseObject){
        
        this.totalCount = this.reqResponseObject.data.totalCount;
        this.totalPages = this.reqResponseObject.data.totalPages;
        this.setPaginationList(this.totalPages);
        this.currentPage = this.reqResponseObject.data.page;
        this.transactions = this.reqResponseObject.data.payload;

        if (this.currentPage >= 1) {
          if (this.totalCurrentPageCount > this.totalCount) {
            this.totalCurrentPageCount = this.totalCount;
          }else{
            this.totalCurrentPageCount = this.perPageCount * this.currentPage;
            if (this.totalCurrentPageCount >= this.totalCount) {
              this.totalCurrentPageCount = this.totalCount;
            }
          }
        }
        
      }
    })

  }

  searchSettlementTransactions(txt){

    this.isLoading = !this.isLoading;
    let url = this.termPaths._adminBaseUrl+"settlementcycles/"
    +this.cycleRef+"/transactions?type="
    +this.channelId+"&page="+this.currentPage+"&size="+this.perPageCount;

    this._clientApp.get(url).subscribe(res =>{
      //console.log(res);
      this.isLoading = !this.isLoading;
      this._clientApp.updateStoreUrlScripts();
      this.reqResponseObject = this.auth.mapHttpResponse(res);
      
      if(this.reqResponseObject){
        
        this.totalCount = this.reqResponseObject.data.totalCount;
        this.totalPages = this.reqResponseObject.data.totalPages;
        this.setPaginationList(this.totalPages);
        this.currentPage = this.reqResponseObject.data.page;
        this.transactions = this.reqResponseObject.data.payload;

        if (this.currentPage >= 1) {
          if (this.totalCurrentPageCount > this.totalCount) {
            this.totalCurrentPageCount = this.totalCount;
          }else{
            this.totalCurrentPageCount = this.perPageCount * this.currentPage;
            if (this.totalCurrentPageCount >= this.totalCount) {
              this.totalCurrentPageCount = this.totalCount;
            }
          }
        }
        
      }
    });

  }

  loadPaymentOption(){
  
    let countryCode = this.countryCode;

    let url = this.termPaths._adminBackendBaseUrl+"admin/system/"+"loadcountry/paymentoptions?countryCode="+countryCode;
   
   this._clientApp.get(url).subscribe(res=>{
     //console.log('payment options: ',res);
     this.reqResponseObject = this.auth.mapHttpResponse(res);
     
     if(this.reqResponseObject){
        
      const payOptions = this.reqResponseObject.data;
      for(var i=0; i<payOptions.length; i++){
        //console.log(payOptions[i].allowOnWeb);
        this.paymentOptions.push(payOptions[i]);
      }
    }
   });

  }

 updateSettlemetSearchType($event){
  //console.log($event);
  this.channelId = $event.target.value;
  this.loadSettlementTransactions();
}

  setPaginationList(totalPages){
    //console.log('Total transactions: ', totalPages);
    this.totalPageList =  [];
    if(totalPages < 10){
    
      for (let i = 0; i < totalPages; i++) {
        this.totalPageList[i] = {key: i, value: (i+1).toString()};
      }

    }else{

      let firstCount = 5;
      let lastCount = totalPages-firstCount;
      let elipsCountDown = firstCount+2;
      let elipsCountUp = lastCount-2;

      // if ((lastCount-firstCount) < 4) {
      //   elipsCountDown = firstCount;
      //   elipsCountUp = elipsCountDown+1
      // }

      let index = 0;

      for (let i = 0; i < firstCount; i++) {
        this.totalPageList[index] = {key: i, value: (i+1).toString()};
        index++;
      }

      for (let k = firstCount; k < elipsCountDown; k++) {
        this.totalPageList[index] = {key: k+"d", value: "."};
        //lastCount++;
        index++;
      }
      for (let k = elipsCountUp; k < lastCount; k++) {
        this.totalPageList[index] = {key: k+"u", value: "."};
        //lastCount ++;
        index++;
      }
      for (let k = lastCount; k < totalPages; k++) {
        this.totalPageList[index] = {key: k, value: k};
        //lastCount ++;
        index++;
      }
    }
    //console.log(this.totalPageList);
  }

  paginate(page){
    page = String(page);
    if (page.indexOf('d') !== -1) {
      page = page.substr(0, page.indexOf('d'));
    }

    if (page.indexOf('u') !== -1) {
      page = page.substr(0, page.indexOf('u'));
    }
    this.currentPage = parseInt(page) + 1;

    this.loadSettlementTransactions();
  }

  changePerPage($event){
    let pageCount = $event.value;
    //console.log(pageCount);
    this.perPageCount = parseInt(pageCount);
    this.loadSettlementTransactions();
  }

  paginateBack(){
    //console.log(this.currentPage);
    if(this.currentPage > 1){
      --this.currentPage;
      this.loadSettlementTransactions();
    }
  }

  paginateForward(){
    //console.log(this.currentPage);
    if(this.currentPage < this.totalPages){
      this.currentPage++;
      this.loadSettlementTransactions();
    }
  }

}
