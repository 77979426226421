<header class="page-header page-header-dark bg-gradient-primary-to-secondaryadmin pb-10">
    <div class="container">
        <div class="page-header-content pt-4">
            <div class="row align-items-center justify-content-between">
                <div class="col-auto mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Registered Stores
                    </h1>
                    <div class="page-header-subtitle">Inventory management system admin product registered stores</div>
                </div>
                
            </div>
        </div>
    </div>
</header>

<div class="container mt-n10">

    <div class="row">
        <div class="col-xl-3 col-md-6 mb-4">
            <!-- Dashboard info widget 1-->
            <div class="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-primary h-100">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <div class="small font-weight-bold text-primary mb-1">Today Sales ({{productSalesAnalytics.totalDaySalesCount}})</div>
                            <div class="h5"><span [innerHTML]="currencyLoader[currency]"></span>{{productSalesAnalytics.totalDaySalesAmount | number : '1.2-2'}}</div>
                            <div class="text-xs font-weight-bold text-success d-inline-flex align-items-center">
                                <i class="mr-1" data-feather="trending-up"></i>
                                {{(productSalesAnalytics.averageDaySalesAmount/productSalesAnalytics.averageDaySalesCount)/100 | number : '1.2-2'  }}%
                            </div>
                        </div>
                        <div class="ml-2"><i class="fas fa-tag fa-2x fa-2x text-gray-200"></i></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
            <!-- Dashboard info widget 3-->
            <div class="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-success h-100">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <div class="small font-weight-bold text-success mb-1">Current Week ({{productSalesAnalytics.totalWeekSalesCount}})</div>
                            <div class="h5"><span [innerHTML]="currencyLoader[currency]"></span>{{productSalesAnalytics.totalWeekSalesAmount | number : '1.2-2'}}</div>
                            <div class="text-xs font-weight-bold text-success d-inline-flex align-items-center">
                                <i *ngIf="productSalesAnalytics.weekGrowthType == 'NEGATIVE'" class="mr-1" data-feather="trending-down"></i>
                                <i *ngIf="productSalesAnalytics.weekGrowthType == 'POSITIVE'" class="mr-1" data-feather="trending-up"></i>
                                {{productSalesAnalytics.totalWeekSalesAmount/productSalesAnalytics.prevWeekSalesAmount | number : '1.2-2' }}%
                            </div>
                        </div>
                        <div class="ml-2"><i class="fas fa-mouse-pointer fa-2x text-gray-200"></i></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
            <!-- Dashboard info widget 2-->
            <div class="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-secondary h-100">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <div class="small font-weight-bold text-secondary mb-1">Current Month ({{productSalesAnalytics.totalMonthSalesCount}})</div>
                            <div class="h5"><span [innerHTML]="currencyLoader[currency]"></span>{{productSalesAnalytics.totalMonthSalesAmount | number : '1.2-2'}}</div>
                            <div class="text-xs font-weight-bold text-danger d-inline-flex align-items-center">
                                <i *ngIf="productSalesAnalytics.monthGrowthType == 'NEGATIVE'" class="mr-1" data-feather="trending-down"></i>
                                <i *ngIf="productSalesAnalytics.monthGrowthType == 'POSITIVE'" class="mr-1" data-feather="trending-up"></i>
                                {{productSalesAnalytics.monthGrowthPercentage}}%
                            </div>
                        </div>
                        <div class="ml-2"><i class="fas fa-tag fa-2x text-gray-200"></i></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
            <!-- Dashboard info widget 4-->
            <div class="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-info h-100">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <div class="small font-weight-bold text-info mb-1">Last Month ({{productSalesAnalytics.prevMonthAverageSalesCount}})</div>
                            <div class="h5"><span [innerHTML]="currencyLoader[currency]"></span>{{productSalesAnalytics.prevMonthAverageSalesAmount | number : '1.2-2'}}</div>
                            <div class="text-xs font-weight-bold text-danger d-inline-flex align-items-center">
                                <i *ngIf="productSalesAnalytics.averageMonthSalesAmount > productSalesAnalytics.prevMonthAverageSalesAmount" class="mr-1" data-feather="trending-down"></i>
                                <i *ngIf="productSalesAnalytics.averageMonthSalesAmount < productSalesAnalytics.prevMonthAverageSalesAmount" class="mr-1" data-feather="trending-up"></i>
                                <span *ngIf="productSalesAnalytics.averageMonthSalesAmount < productSalesAnalytics.prevMonthAverageSalesAmount">{{(productSalesAnalytics.prevMonthAverageSalesAmount/productSalesAnalytics.totalMonthSalesAmount)/100 | number : '1.2-2' }}%</span>
                                <span *ngIf="productSalesAnalytics.averageMonthSalesAmount > productSalesAnalytics.prevMonthAverageSalesAmount">{{(productSalesAnalytics.totalMonthSalesAmount/productSalesAnalytics.prevMonthAverageSalesAmount)*100 | number : '1.2-2' }}%</span>

                            </div>
                        </div>
                        <div class="ml-2"><i class="fas fa-percentage fa-2x text-gray-200"></i></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!isStoreDetails" class="card mb-4">
        <div class="card-header">Registered stores</div>
        <div class="card-body">
            <span class="notification float-left" *ngIf="isLoading"> Loading stores... <img src="../../../../assets/landing/images/ring1.gif"class="smLoader"></span>
            <span class="notification float-left" *ngIf="showSearch"> Searching stores... <img src="../../../../assets/landing/images/ring1.gif" class="smLoader"></span>
            <span class="notification float-left" *ngIf="showProcessingFile"> Processing download... <img src="../../../../assets/landing/images/ring1.gif" class="smLoader"></span>
            <div>
                <!-- <button class="btn btn-outline-teal btn-sm btn-icon float-right" style="margin: 2px;" type="button">
                    <i data-feather="download"></i>
                </button> -->
            </div>
            <div class="form-group" class="tp-full-width">
                
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <select class="form-control select2bs4" (change)="updateStoreSearchType($event)" style="width: 100%">
                      <option value="ALL">ALL</option>
                      <option value="ACTIVE">ACTIVE STORES</option>
                      <option value="SUSPENDED">SUSPENDED STORES</option>
                      <option value="NEW_STORE">NEW STORES</option>
                    </select>
                   </div>
                    <input class="form-control" #searchField (keyup)="searchStores(searchField.value)" type="text" placeholder="Search stores..." aria-label="Search">
                </div>
            </div>
            <!-- <div class="input-group input-group-joined mb-4">
                <input class="form-control" #searchField (keyup)="searchStores(searchField.value)" type="text" placeholder="Search sales..." aria-label="Search" />
                <div class="input-group-append">
                    <span class="input-group-text"><i data-feather="search"></i></span>
                </div>
            </div> -->
            <div class="datatable">
                <table class="table table-bordered table-hover" width="100%" cellspacing="0">
                    <thead>
                        <tr>
                            <th>S/N</th>
                            <th>Store Name</th>
                            <th>Owner Info</th>
                            <th>Country</th>
                            <th>Business Name</th>
                            <!-- <th>Owner Name</th> -->
                            <th>Business Email</th>
                            <th>Phone no</th>
                            <th>Status</th>
                            <th>Reg Date</th>
                            <th>Sales</th>
                            <th>Manage</th>
                        </tr>
                    </thead>
                    
                    <tbody>
                        <tr *ngFor='let store of stores; let i=index;'>
                            <td>{{((currentPage-1)*perPageCount)+(i+1)}}</td>
                            <td style="word-break: break-all; max-width: 80px;">{{store.storeName}} </td>
                            <td>
                                <div class="justify-content-start align-items-start">
                                     <div class="d-flex justify-content-between align-items-center"><span>N : </span>
                                        <div class="cursor-pointer btn-link" style="width: 35px; margin-left: 5px; margin-right: 2px;">
                                            <span class="d-inline-block text-truncate" style="max-width: 40px;" *ngIf="(store.storeOwner)">{{store.storeOwner.name}}</span>
                                            <span *ngIf="!(store.storeOwner)">n/a</span>
                                        </div>
                                        <svg width="10" height="10" (click)="auth.handleCopy($event, store.storeOwner.name)" viewBox="0 0 9 10" fill="none" color="#0DB450" class="cursor-pointer">
                                            <path d="M5.293 10h-3.73C.7 10 0 9.3 0 8.437V3.146c0-.862.7-1.563 1.563-1.563h3.73c.862 0 1.562.7 1.562 1.563v5.293c0 .861-.7 1.562-1.562 1.562zm-3.73-7.637c-.431 0-.782.35-.782.782v5.293c0 .43.35.78.781.78h3.731c.43 0 .781-.35.781-.78V3.144c0-.431-.35-.782-.781-.782h-3.73zm6.855 5.098V1.562C8.418.702 7.718 0 6.855 0H2.52a.39.39 0 100 .781h4.335c.431 0 .782.35.782.781v5.899a.39.39 0 10.781 0z" fill="#ccc"></path>
                                        </svg>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center"><span>E : </span>
                                        <div class="cursor-pointer btn-link" style="width: 35px; margin-left: 5px; margin-right: 2px;">
                                            <span class="d-inline-block text-truncate" style="max-width: 40px;" *ngIf="(store.storeOwner.username)">{{store.storeOwner.username}}</span>
                                            <span *ngIf="!(store.storeOwner.username)">n/a</span>
                                        </div>
                                        <svg width="10" height="10" (click)="auth.handleCopy($event, store.storeOwner.username)" viewBox="0 0 9 10" fill="none" color="#0DB450" class="cursor-pointer">
                                            <path d="M5.293 10h-3.73C.7 10 0 9.3 0 8.437V3.146c0-.862.7-1.563 1.563-1.563h3.73c.862 0 1.562.7 1.562 1.563v5.293c0 .861-.7 1.562-1.562 1.562zm-3.73-7.637c-.431 0-.782.35-.782.782v5.293c0 .43.35.78.781.78h3.731c.43 0 .781-.35.781-.78V3.144c0-.431-.35-.782-.781-.782h-3.73zm6.855 5.098V1.562C8.418.702 7.718 0 6.855 0H2.52a.39.39 0 100 .781h4.335c.431 0 .782.35.782.781v5.899a.39.39 0 10.781 0z" fill="#ccc"></path>
                                        </svg>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center"><span>T : </span>
                                        <div class="cursor-pointer btn-link" style="width: 35px; margin-left: 5px; margin-right: 2px;">
                                            <span class="d-inline-block text-truncate" style="max-width: 40px;" *ngIf="(store.storeOwner.phoneNumber)">{{store.storeOwner.phoneNumber}}</span>
                                            <span *ngIf="!(store.storeOwner.phoneNumber)">n/a</span>
                                        </div>
                                        <svg width="10" height="10" (click)="auth.handleCopy($event, store.storeOwner.phoneNumber)" viewBox="0 0 9 10" fill="none" color="#0DB450" class="cursor-pointer">
                                            <path d="M5.293 10h-3.73C.7 10 0 9.3 0 8.437V3.146c0-.862.7-1.563 1.563-1.563h3.73c.862 0 1.562.7 1.562 1.563v5.293c0 .861-.7 1.562-1.562 1.562zm-3.73-7.637c-.431 0-.782.35-.782.782v5.293c0 .43.35.78.781.78h3.731c.43 0 .781-.35.781-.78V3.144c0-.431-.35-.782-.781-.782h-3.73zm6.855 5.098V1.562C8.418.702 7.718 0 6.855 0H2.52a.39.39 0 100 .781h4.335c.431 0 .782.35.782.781v5.899a.39.39 0 10.781 0z" fill="#ccc"></path>
                                        </svg>
                                    </div>
                                </div>
                            </td>
                            <td>{{store.country.name}}</td>
                            <td style="word-break: break-all; max-width: 60px;">
                                {{store.businessName}}
                            </td>
                            <!-- <td>{{store.storeOwner.name}}</td> -->
                            <!-- <td>{{store.businessEmail}}</td> -->
                            <td>
                                <div class="d-flex justify-content-between align-items-center">
                                    <span class="d-inline-block" style="max-width: 90px;" *ngIf="(store.businessEmail)">{{store.businessEmail?.substring(0, 10)}}..</span>
                                    <span *ngIf="!(store.businessEmail)">n/a</span>
                                    <svg width="10" height="10" (click)="auth.handleCopy($event, store.businessEmail)" viewBox="0 0 9 10" fill="none" color="#0DB450" class="cursor-pointer">
                                        <path d="M5.293 10h-3.73C.7 10 0 9.3 0 8.437V3.146c0-.862.7-1.563 1.563-1.563h3.73c.862 0 1.562.7 1.562 1.563v5.293c0 .861-.7 1.562-1.562 1.562zm-3.73-7.637c-.431 0-.782.35-.782.782v5.293c0 .43.35.78.781.78h3.731c.43 0 .781-.35.781-.78V3.144c0-.431-.35-.782-.781-.782h-3.73zm6.855 5.098V1.562C8.418.702 7.718 0 6.855 0H2.52a.39.39 0 100 .781h4.335c.431 0 .782.35.782.781v5.899a.39.39 0 10.781 0z" fill="#ccc"></path>
                                    </svg>
                                </div>
                            </td>
                            <td>
                                {{store.phoneNumber}}
                            </td>
                            
                            <td>
                                <div *ngIf='store.status === "ACTIVE"' class="badge badge-secondary badge-pill">Active</div>
                                <div *ngIf='store.status == "IN_REVIEW"' class="badge badge-warning badge-pill">In review</div>
                                <div *ngIf='store.status === "SUSPENDED"' class="badge badge-secondary badge-pill">Suspended</div>
                                <div *ngIf='store.status == "NEW_STORE"' class="badge badge-warning badge-pill">New store</div>
                            </td>
                            <td>{{store.createdAt | date :'short'}}</td>
                            <td>
                                <a [routerLink]="['/admin/dashboard/adminstoresales', store.storeNumber]"> <i class="text-purple" data-feather="arrow-right-circle"></i></a>
                            </td>
                            <td>
                                <button class="btn btn-outline-purple btn-icon btn-sm" (click)="viewStoreDetails(store)" >
                                    <i data-feather="eye"></i>
                                </button> 
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="stores?.length > 0; else noStores" class="clearfix">
                <div class="hint-text">Showing <b>{{totalCurrentPageCount}}</b> out of <b>{{totalCount}}</b> entries @ </div>
                <div class="pg-text">
                    <mat-select class="pg-width" [(value)]="perPageCount" (selectionChange)="changePerPage($event)" >
                        <mat-option [value]="10">10</mat-option>
                        <mat-option [value]="15">15</mat-option>
                        <mat-option [value]="25">25</mat-option>
                        <mat-option [value]="50">50</mat-option>
                        <mat-option [value]="75">75</mat-option>
                        <mat-option [value]="100">100</mat-option>
                    </mat-select>
                </div>
                <ul class="pagination">
                    <li class="page-item"><a (click)='paginateBack()' class="page-link mover"> << </a></li>
                    <li *ngFor='let pg of totalPageList; let i=index;' class="page-item" [ngClass]="{'active': currentPage == pg.value}">
                        <a (click)='paginate(pg.key)' class="page-link">{{pg.value}}</a>
                    </li>
                    <li class="page-item"><a (click)='paginateForward()' class="page-link mover"> >> </a></li>
                </ul>
            </div>
            <div style="margin-top: 5px;">
                <span class="notification float-left" *ngIf="isLoading"> Loading sales... <img src="../../../../assets/landing/images/ring1.gif"class="smLoader"></span>
            </div>
            <ng-template #noStores>
                <p>No store record found!</p>
            </ng-template>
        </div>
    </div>

    <div *ngIf="isStoreDetails" class="card mb-4">
        <div class="card invoice">
            <div class="card-header p-4 p-md-5 border-bottom-0 bg-gradient-primary-to-secondary text-white-50">
                <div class="row justify-content-between align-items-center">
                    <div class="col-12 col-lg-auto mb-5 mb-lg-0 text-center text-lg-left">
                        <!-- Invoice branding-->
                        <img class="invoice-brand-img rounded-circle mb-4" src="{{storeLogo}}" alt="" />
                        <div class="h2 text-white mb-0">{{storeProfile.storeName}}</div>
                        Business name &#x2192; <span *ngIf="storeProfile.businessName">{{storeProfile.businessName}}</span> <span *ngIf="!storeProfile.businessName">N/A</span>
                    </div>
                    <div class="col-12 col-lg-auto text-center text-lg-right">
                        <!-- Invoice details-->
                        <div class="h3 text-white">Status: {{ storeProfile.status}} </div>
                        <br />
                        {{ storeProfile.createdAt | date }}
                    </div>
                </div>
            </div>
            <div class="card-body p-4 p-md-5">
                <!-- Invoice table-->
                <button class="btn btn-sm btn-secondary float-left" data-toggle="modal" data-target="#exampleModalStatus" type="button">Change store status</button>
                <button class="btn btn-sm btn-light float-right" type="button" (click)="viewStores()"> &#x2190; back</button>
                <div class="table-responsive">
                    <table class="table table-borderless mb-0">
                        <thead class="border-bottom">
                            <tr class="small text-uppercase text-muted">
                                <th scope="col">Tax Configuration</th>
                                <th class="text-right" scope="col">VAT</th>
                                <th class="text-right" scope="col">T Levy</th>
                                <th class="text-right" scope="col">GHIF</th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- Invoice item 1-->
                            <tr class="border-bottom">
                                <td>
                                    <div class="font-weight-bold">{{storeProfile.storeName}}</div>
                                    <div class="small text-muted d-none d-md-block">{{storeProfile.storeAbout}}</div>
                                </td>
                                <td *ngIf="!storeProfile.taxConfig" class="text-right font-weight-bold">12.50%</td>
                                <td *ngIf="!storeProfile.taxConfig" class="text-right font-weight-bold">5.0%</td>
                                <td *ngIf="!storeProfile.taxConfig" class="text-right font-weight-bold">1.0%</td>

                                <td *ngIf="storeProfile.taxConfig" class="text-right font-weight-bold">{{storeProfile.taxConfig.vatFeeConfigPercentage}}%</td>
                                <td *ngIf="storeProfile.taxConfig" class="text-right font-weight-bold">{{storeProfile.taxConfig.levyFeeConfigPercentage}}%</td>
                                <td *ngIf="storeProfile.taxConfig" class="text-right font-weight-bold">{{storeProfile.taxConfig.gtfFeeConfigPercentage}}%</td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <!-- Payment methods card-->
                <div class="card card-header-actions mb-4">
                    <div class="card-header">
                        Global Payment Methods
                    </div>
                    <div class="card-body">
                        <!-- Payment method 1-->
                        <div *ngFor="let pay of storeCountry.paymentOptions; let i=index;">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="d-flex align-items-center">
                                
                                    <i *ngIf="pay.name.includes('Cash') || pay.name.includes('cash')" class="far fa-money-bill-alt fa-2x cc-color-visa"></i>
                                    <i *ngIf="pay.name.includes('Momo') || pay.name.includes('momo') || pay.name.includes('mobile') || pay.name.includes('mobile') || pay.name.includes('Mobile Money') " class="fas fa-wallet fa-2x"></i>
                                    <i *ngIf="pay.name.includes('cards') || pay.name.includes('Cards')" class="fas fa-credit-card fa-2x"></i>
                                    <i *ngIf="pay.name.includes('Pos') || pay.name.includes('pos')" class="fas fa-cash-register fa-2x"></i>
                                    <div class="ml-4">
                                        <div class="small">{{pay.name}}</div>
                                        <div class="text-xs text-muted">{{pay.description}}</div>
                                    </div>
                                </div>
                                <div class="ml-4 small">
                                    <div class="badge badge-light mr-3">Fee (mode: {{pay.paymentOptionFeeMode}}): {{pay.paymentOptionFee}}</div>
                                    <!-- <a href="#!">View full details</a> -->
                                    <button class="btn btn-datatable btn-icon btn-transparent-dark mr-2" ><i data-feather="more-vertical"></i></button>
                                    <button class="btn btn-datatable btn-icon btn-transparent-dark" type="button" data-toggle="modal" data-target="#exampleModalLg" (click)="profileCountryPayOption(pay)" >
                                        <i class="text-purple" data-feather="edit"></i>
                                    </button>
                                </div>
                            </div>
                            <hr />
                        </div>
                        
                    </div>
                </div>

                <!-- Custom Store Payment methods card-->
                <div style="margin-top: 10px;" class="card card-header-actions mb-4">
                    <div class="card-header">
                        Store custom Payment Methods( and fees)
                        <!-- <button class="btn btn-sm btn-primary" type="button">Add Payment Method</button> -->
                    </div>
                    <div class="card-body">
                        <!-- Payment method 1-->
                        <div *ngFor="let pay of storePaymentOptions; let i=index;">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="d-flex align-items-center">
                                
                                    <i *ngIf="pay.name.includes('Cash') || pay.name.includes('cash')" class="far fa-money-bill-alt fa-2x cc-color-visa"></i>
                                    <i *ngIf="pay.name.includes('Momo') || pay.name.includes('momo') || pay.name.includes('mobile') || pay.name.includes('mobile') || pay.name.includes('Mobile Money') " class="fas fa-wallet fa-2x"></i>
                                    <i *ngIf="pay.name.includes('cards') || pay.name.includes('Cards')" class="fas fa-credit-card fa-2x"></i>
                                    <i *ngIf="pay.name.includes('Pos') || pay.name.includes('pos')" class="fas fa-cash-register fa-2x"></i>
                                    <div class="ml-4">
                                        <div class="small">{{pay.name}}</div>
                                        <div class="text-xs text-muted">{{pay.description}}</div>
                                    </div>
                                </div>
                                <div class="ml-4 small">
                                    <div class="badge badge-light mr-3">Fee (mode: {{pay.paymentOptionFeeMode}}): {{pay.paymentOptionFee}}</div>
                                    <!-- <a href="#!">View full details</a> -->
                                    <button class="btn btn-datatable btn-icon btn-transparent-dark mr-2" ><i data-feather="more-vertical"></i></button>
                                    <button class="btn btn-datatable btn-icon btn-transparent-dark" type="button" data-toggle="modal" data-target="#exampleModalLg" (click)="editCountryPayOption(pay)" >
                                        <i class="text-purple" data-feather="edit"></i>
                                    </button>
                                </div>
                            </div>
                            <hr />
                        </div>
                    </div>
                </div>

                <!-- Store branch configuration -->
                <div class="container">
                    <h4 class="mb-0 mt-5">Store Branch configuration</h4>
                    <hr class="mt-2 mb-4" />
                    
                    <!-- Knowledge base main category card 2-->
                    <a class="card card-icon lift lift-sm mb-4">
                        <div class="row no-gutters">
                            <div class="col-auto card-icon-aside bg-secondary"><i class="text-white-50" data-feather="users"></i></div>
                            <div class="col">
                                <div class="card-body py-4">
                                    <h5 class="card-title text-secondary mb-2">Branch Information</h5>
                                    <div *ngIf="!storeProfile.storeBranchConfig">
                                        <p class="card-text mb-1">This store is currently not enabled for multiple store branch implementation. </p>
                                        <button class="btn btn-sm btn-secondary float-right" style="margin-bottom: 7px;" data-toggle="modal" data-target="#exampleModalBranch" (click)="setBranchEditToFalse()" type="button">Configure branch on store</button>
                                    </div>
                                    <div *ngIf="storeProfile.storeBranchConfig">
                                        <p class="card-text mb-1">This store has been enabled for multiple branch store implementation. See branches below. </p>
                                        <button class="btn btn-sm btn-secondary float-right" style="margin-bottom: 7px;" data-toggle="modal" data-target="#exampleModalBranch" (click)="setBranchEditToTrue()" type="button">Change status</button>
                                    </div>
                                    <!-- <div class="small text-muted">3 articles in this category</div> -->
                                </div>
                            </div>
                        </div>
                    </a>
                    
                    <div *ngIf="storeProfile.storeBranchConfig">
                        <h4 class="mb-0 mt-5">Store Branches</h4>
                        <hr class="mt-2 mb-4" />
                        <div class="row">
                            <div *ngIf="!addBranch" class="tab-content" id="dashboardNavContent">
                        
                                <span class="notification float-left" *ngIf="showSearch"> Searching... <img src="../../../../assets/landing/images/ring1.gif"class="smLoader"></span>
                                <div class="input-group input-group-joined mb-4">
                                    <input class="form-control" #searchField (keyup)="searchBranch(searchField.value)" type="text" placeholder="Search for store branches..." aria-label="Search" />
                                    <div class="input-group-append">
                                        <span class="input-group-text"><i data-feather="search"></i></span>
                                    </div>
                                </div>
                                <div class="tab-pane fade show active" id="activities" role="tabpanel" aria-labelledby="activities-pill">
                                    <div class="datatable table-responsive">
                                        <table class="table table-bordered table-hover" width="100%" cellspacing="0">
                                            <thead>
                                                <tr>
                                                    <th>S/N</th>
                                                    <th>Branch Name</th>
                                                    <th>Branch Manager</th>
                                                    <th>Phone no</th>
                                                    <th>Email Address</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor='let branch of branches; let i=index;'>
                                                    <td>{{i+1}}</td>
                                                    <td>
                                                        <i class="mr-2 text-green" data-feather="zap"></i>
                                                        {{branch.branchName}}
                                                    </td>
                                                    <td>
                                                        {{branch.branchManager}}
                                                    </td>
                                                    <td>
                                                        {{branch.branchPhoneNo}}
                                                    </td>
                                                        
                                                    <td>
                                                        {{branch.branchEmailAddress}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div *ngIf="branches?.length > 0; else noBranches" class="clearfix">
                                        <div class="hint-text">Showing <b>{{totalCurrentPageCount}}</b> out of <b>{{totalCount}}</b> entries</div>
                                        <ul class="pagination">
                                            <li class="page-item"><a (click)='paginateBack()' class="page-link mover"> << </a></li>
                                            <li *ngFor='let pg of totalPageList; let i=index;' class="page-item" [ngClass]="{'active': currentPage == pg.value}">
                                                <a (click)='paginate(i)' class="page-link">{{pg.value}}</a>
                                            </li>
                                            <li class="page-item"><a (click)='paginateForward()' class="page-link mover"> >> </a></li>
                                        </ul>
                                    </div>
                                    <ng-template #noBranches>
                                        <p>No store branch record found!</p>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>

                <!-- Store KYC configuration -->
                <div class="container">
                    <h4 class="mb-0 mt-5">Store KYC configuration</h4>
                    <hr class="mt-2 mb-4" />
                    
                    <!-- Knowledge base main category card 2-->
                    <a class="card card-icon lift lift-sm mb-4">
                        <div class="row no-gutters">
                            <div class="col-auto card-icon-aside bg-secondary"><i class="text-white-50" data-feather="users"></i></div>
                            <div class="col">
                                <div class="card-body py-4"> 
                                    <h5 class="card-title text-secondary mb-2">KYC Information</h5>
                                    <div *ngIf="storeKycs?.length == 0;">
                                        <p class="card-text mb-1">This store has not requested for digital service. </p>
                                    </div>
                                    <div *ngIf="storeKycs?.length > 0;">
                                        <p class="card-text mb-1">This store has  requested for digital service. See branches below. </p>
                                    </div>
                                    <!-- <div class="small text-muted">3 articles in this category</div> -->
                                </div>
                            </div>
                        </div>
                    </a>
                    
                    <div *ngIf="storeKycs?.length > 0;">
                        <h4 class="mb-0 mt-5">Store KYC</h4>
                        <hr class="mt-2 mb-4" />
                        <div class="row">
                            <div class="tab-content" id="dashboardNavContent">
                
                                <div class="tab-pane fade show active" id="activities" role="tabpanel" aria-labelledby="activities-pill">
                                    <div class="datatable table-responsive">
                                        <table class="table table-bordered table-hover" width="100%" cellspacing="0">
                                            <thead>
                                                <tr>
                                                    <th>S/N</th>
                                                    <th>Document name</th>
                                                    <th>Document type</th>
                                                    <th>Description</th>
                                                    <th>Status</th>
                                                    <th>Approval Status</th>
                                                    <th>Submission</th>
                                                    <th>Review</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <!-- <form [formGroup]="kycForm" (ngSubmit)="submitKycForm(kycForm.value)">
                                                </form> -->
                                                <tr *ngFor='let kyc of storeKycs; let i=index;'>
                                                    <td>{{(i+1)}}</td>
                                                    <td>
                                                        {{kyc.name}}
                                                    </td>
                                                    <td>
                                                        {{kyc.documentType}}
                                                    </td>
                                                    <td>
                                                        {{kyc.description}}
                                                    </td> 
                                                    <td>
                                                        <span *ngIf="kyc.status != 'PROFILED'"><i class="text-green" data-feather="bell-off"></i></span>
                                                        <span *ngIf="kyc.status === 'PROFILED'"><i class="text-red" data-feather="bell"></i></span>
                                                        {{kyc.status}}
                                                    </td>
                                                    <td>
                                                        <span *ngIf="kyc.approvalStatus === 'APPROVED'"><i class="text-green" data-feather="bell-off"></i></span>
                                                        <span *ngIf="kyc.approvalStatus === 'PROFILED'"><i class="text-blue" data-feather="bell"></i></span>
                                                        <span *ngIf="kyc.approvalStatus === 'REJECTED'"><i class="text-red" data-feather="bell"></i></span>
                                                        <span *ngIf="kyc.approvalStatus === 'REVIEWED'"><i class="text-yellow" data-feather="bell"></i></span>
                                                        {{kyc.approvalStatus}}
                                                    </td>
                                                    <td *ngIf="kyc.documentType == 'TEXT'; else subLink" style="word-wrap: break-word;">
                                                        {{kyc.kycText}}
                                                    </td>
                                                    <ng-template #subLink>
                                                        <td>
                                                            <i *ngIf="kyc.kycDocumentUrl" class="mr-2 text-green" data-feather="zap"></i>
                                                            <a *ngIf="kyc.kycDocumentUrl" href="{{kyc.kycDocumentUrl}}" target="_blank" rel="noopener noreferrer">View</a>
                                                        </td>
                                                    </ng-template>
                                                    <td style="word-wrap: break-word;">
                                                        {{kyc.reviewInfo}}
                                                    </td>
                                                    <td>
                                                        <button class="btn btn-secondary float-right" style="margin-bottom: 7px;" data-toggle="modal" data-target="#exampleModalKYC" (click)="updateStoreKyc(kyc)" type="button">Update</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>


            </div>
            <div class="card-footer p-4 p-lg-5 border-top-0">
                <div class="row">
                    <div class="col-md-6 col-lg-3 mb-4 mb-lg-0">
                        <!-- Invoice - sent to info-->
                        <div class="small text-muted text-uppercase font-weight-700 mb-2">Address</div>
                        <div class="h6 mb-1">Company Address</div>
                        <div *ngIf="!storeProfile.address" class="small">Not available!!</div>
                        <div *ngIf="storeProfile.address" class="small">{{storeProfile.address.street}}</div>
                        <div *ngIf="storeProfile.address" class="small">{{storeProfile.address.city}} {{storeProfile.address.state}}</div>
                    </div>
                    <div class="col-md-6 col-lg-3 mb-4 mb-lg-0">
                        <!-- Invoice - sent from info-->
                        <div class="small text-muted text-uppercase font-weight-700 mb-2">Email</div>
                        <div class="h6 mb-0">Business Email</div>
                        <div class="small">{{storeProfile.businessEmail}}</div>
                        <div class="small">{{storeProfile.phoneNumber}}</div>
                    </div>
                    <div class="col-lg-6">
                        <!-- Invoice - additional notes-->
                        <div class="small text-muted text-uppercase font-weight-700 mb-2">Online details</div>
                        <div class="small mb-0">{{storeProfile.websiteUrl}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<!-- Modal -->
<div class="modal fade" id="exampleModalLg" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"><span *ngIf="!isCustomUpdateOption" style="margin-right: 3px;">Create</span> <span *ngIf="isCustomUpdateOption" style="margin-right: 3px;">Edit</span> Payment Option</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
            
                <form [formGroup]="paymentOptionProfileForm" (ngSubmit)="updatePaymentOptionProfile(paymentOptionProfileForm.value)">
                    <div class="form-row">
                        <!-- Form Group (username)-->
                        <div class="form-group col-md-6">
                            <label class="small mb-1" for="name">Name</label>
                            <input class="form-control" id="name" type="text" formControlName="name" />
                            <div *ngIf="f.name.invalid && f.name.touched">
                                <small *ngIf="f.name.errors?.required" class="small-warning text-danger">*Payment option name is required </small>
                            </div>
                        </div>
                        <!-- Form Group (username)-->
                        <div class="form-group col-md-6">
                            <label class="small mb-1" for="viewName">View Name</label>
                            <input class="form-control" id="viewName" type="text" formControlName="viewName" />
                            <div *ngIf="f.viewName.invalid && f.viewName.touched">
                                <small *ngIf="f.viewName.errors?.required" class="small-warning text-danger">*Payment option view name is required </small>
                            </div>
                        </div>
                    </div>

                    <!-- Form Row    -->
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <div class="custom-control custom-switch mt-n2">
                                <input class="custom-control-input" (change)="setEnableOption($event)" id="enableToggleSwitch" type="checkbox" [checked]="enableOption" />
                                <label class="custom-control-label" for="enableToggleSwitch">Enable/Disable option</label>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <div class="custom-control custom-switch mt-n2">
                                <input class="custom-control-input" (change)="setEnableOptionOnWeb($event)" id="enableWebToggleSwitch" type="checkbox" [checked]="enableWebOption" />
                                <label class="custom-control-label" for="enableWebToggleSwitch"> Enable/Disable option on web</label>
                            </div>
                        </div>
                    </div>
                    <!-- Form Row    -->
                    <div class="form-row">
                        
                        <div class="form-group col-md-6">
                            <div class="custom-control custom-switch mt-n2">
                                <input class="custom-control-input" (change)="setEnableOptionOnApp($event)" id="enableAppToggleSwitch" type="checkbox" [checked]="enableAppOption" />
                                <label class="custom-control-label" for="enableAppToggleSwitch"> Enable/Disable option on App</label>
                            </div>
                        </div>
                    </div>

                    <!-- Form Row-->
                    <div class="form-row">
                        <!-- Form Group (first name)-->
                        <div class="form-group col-md-6">
                            <label class="small mb-1" for="description">Description</label>
                            <input class="form-control" id="description" type="text" formControlName="description" />
                            <div *ngIf="f.description.invalid && f.description.touched">
                                <small *ngIf="f.description.errors?.required" class="small-warning text-danger">*Payment option description is required </small>
                            </div>
                        </div>
                        <!-- Form Group (last name)-->
                        <div class="form-group col-md-6">
                            <label class="small mb-1" for="status">Fee status</label>
                            <select class="form-control" id="status" formControlName="status" >
                                <option value="ACTIVE">ACTIVE</option>
                                <option value="INACTIVE">INACTIVE</option>
                            </select>
                            <div *ngIf="f.status.invalid && f.status.touched">
                                <small *ngIf="f.status.errors?.required" class="small-warning text-danger">*Status is required</small>
                            </div>

                        </div>
                    </div>

                    <!-- Form Row    -->
                    <div class="form-row">
                        <div *ngIf="applyFixCharge" class="form-group col-md-6">
                            <label class="small mb-1" for="fixCharge">Fix charge</label>
                            <input class="form-control" id="fixCharge" type="number" min="0" placeholder="Enter fix charge" formControlName="fixCharge" />
                            <div *ngIf="f.fixCharge.invalid && f.fixCharge.touched">
                                <small *ngIf="f.fixCharge.errors?.required" class="small-warning text-danger">*Fix-charge is required</small>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <div class="custom-control custom-switch mt-n2">
                                <input class="custom-control-input" (change)="setApplyFixCharge($event)" id="prodToggleSwitch" type="checkbox" [checked]="applyFixCharge" />
                                <label class="custom-control-label" for="prodToggleSwitch"> Apply/De-apply fix-charge</label>
                            </div>
                        </div>
                    </div>

                    <!-- Form Row    -->
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <div class="custom-control custom-switch mt-n2">
                                <input class="custom-control-input" (change)="setProcessSettlemet($event)" id="settleToggleSwitch" type="checkbox" [checked]="processSettlement" />
                                <label class="custom-control-label" for="settleToggleSwitch">Enable/Disable settlement</label>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <div class="custom-control custom-switch mt-n2">
                                <input class="custom-control-input" (change)="setTenderedAmount($event)" id="tenderedToggleSwitch" type="checkbox" [checked]="enableTenderedAmount" />
                                <label class="custom-control-label" for="tenderedToggleSwitch"> Allow tendered amount</label>
                            </div>
                        </div>
                    </div>

                    <!-- Form Row    -->
                    <div class="form-row">
                            
                        <div class="form-group col-md-6">
                            <label class="small mb-1" for="paymentOptionFee">Transaction Fee</label>
                            <input class="form-control" id="paymentOptionFee" min="0" type="number" placeholder="Enter pay fee" formControlName="paymentOptionFee"  />
                            <div *ngIf="f.paymentOptionFee.invalid && f.paymentOptionFee.touched">
                                <small *ngIf="f.paymentOptionFee.errors?.required" class="small-warning text-danger">*Transaction fee is required</small>
                            </div>
                        </div>
                        
                        <div class="form-group col-md-6">
                            <label class="small mb-1" for="paymentOptionFeeMode">Transaction Fee Type</label>
                            <select class="form-control" id="paymentOptionFeeMode" formControlName="paymentOptionFeeMode" >
                                <option value="PERCENTAGE">PERCENTAGE</option>
                                <option value="FIXED">FIXED</option>
                                <option value="NONE">NONE</option>
                            </select>
                            <div *ngIf="f.paymentOptionFeeMode.invalid && f.paymentOptionFeeMode.touched">
                                <small *ngIf="f.paymentOptionFeeMode.errors?.required" class="small-warning text-danger">*Transaction fee mode is required</small>
                            </div>
                        </div>
                    </div>

                    <!-- Form Row    -->
                    <div class="form-row">
                            
                        <div class="form-group col-md-6">
                            <label class="small mb-1" for="internationalPaymentOptionFee">International Transaction Fee</label>
                            <input class="form-control" id="internationalPaymentOptionFee" type="number" min="0" placeholder="Enter international fee" formControlName="internationalPaymentOptionFee"  />
                            <div *ngIf="f.internationalPaymentOptionFee.invalid && f.internationalPaymentOptionFee.touched">
                                <small *ngIf="f.internationalPaymentOptionFee.errors?.required" class="small-warning text-danger">*International Transaction fee is required</small>
                            </div>
                        </div>
                        
                        <div class="form-group col-md-6">
                            <label class="small mb-1" for="internationalPaymentOptionMode">International Transaction Fee Type</label>
                            <select class="form-control" id="internationalPaymentOptionMode" formControlName="internationalPaymentOptionMode" >
                                <option value="PERCENTAGE">PERCENTAGE</option>
                                <option value="FIXED">FIXED</option>
                                <option value="NONE">NONE</option>
                            </select>
                        </div>
                    </div>

                    <!-- Form Row sub   -->
                    <div formGroupName="paymentOptionCapStatus">
                        <!-- Form Row-->
                        <div class="form-row">
                            <!-- Form Group (first name)-->
                            <div class="form-group col-md-6">
                                <label class="small mb-1" for="cappedAmount">Capped Fee Amount</label>
                                <input class="form-control" id="cappedAmount" type="number" min="0" placeholder="Enter capped amount" formControlName="cappedAmount" />
                            </div>
                            <!-- Form Group (last name)-->
                            <div class="form-group col-md-6">
                                <label class="small mb-1" for="cappedType">Is fee capped</label>
                                <select class="form-control" id="cappedType" formControlName="cappedType" >
                                    <option value="CAPPED">CAPPED</option>
                                    <option value="UNCAPPED">UNCAPPED</option>
                                </select>
                            </div>
                        </div>
                        
                    </div>

                    <!-- Save changes button-->
                    <span class="notification" *ngIf="showProcessing"> <img src="../../../../assets/landing/images/ring1.gif"  class="imgNot float-right"></span>
                    <button class="btn btn-primary" type="submit" [disabled]="!paymentOptionProfileForm.valid || isUpdating"><span *ngIf="!isCustomUpdateOption" style="margin-right: 3px;">Create</span> <span *ngIf="isCustomUpdateOption" style="margin-right: 3px;">Update</span> Store custom config</button>
                </form>

            </div>
            <div class="modal-footer">
                <button class="btn btn-sm btn-danger closePayOption" type="button" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade"  id="exampleModalStatus" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Store status update</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
               
                <form [formGroup]="storeProfileStatusForm" (ngSubmit)="switchStoreStatus(storeProfileStatusForm.value)">
                    
                    <!-- Form Group (location)-->
                    <div class="form-group">
                        <label class="small mb-1" for="storeStatus">Status</label>
                        <select class="form-control" id="storeStatus" formControlName="storeStatus" required>
                            <option value="ACTIVE">APPROVE STORE</option>
                            <option value="SUSPENDED">SUSPEND STORE</option>
                            <option value="IN_REVIEW">SWITCH STATE TO UNDER REVIEW</option>
                        </select>
                        <div *ngIf="s.storeStatus.invalid && s.storeStatus.touched">
                            <small *ngIf="s.storeStatus.errors?.required" class="small-warning text-danger">*Store status must selected</small>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="custom-control custom-switch mt-n2">
                            <input class="custom-control-input" (change)="allowPosPayment($event)" id="posToggleSwitch" type="checkbox" [checked]="enablePosOption" />
                            <label class="custom-control-label" for="posToggleSwitch"> Enable/Disable Pos payment option</label>
                        </div>
                    </div>
                    
                    <!-- Save changes button-->
                    <span class="notification" *ngIf="showProcessing"> <img src="../../../../assets/landing/images/ring1.gif"  class="imgNot float-right"></span>
                    <button class="btn btn-primary" type="submit" [disabled]="!storeProfileStatusForm.valid || showProcessing">Change status</button>
                </form>

            </div>
            <div class="modal-footer"><button class="btn btn-secondary closeStatus" type="button" data-dismiss="modal">Cancel</button></div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade"  id="exampleModalBranch" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Store Multiple branch config</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
               
                <form [formGroup]="storeProfileBranchForm" (ngSubmit)="configureBranchStoreStatus(storeProfileBranchForm.value)">
                    
                    <!-- Form Group (location)-->
                    <div class="form-group">
                        <label class="small mb-1" for="viewName">Branch identifier length (15-20)</label>
                        <input class="form-control" id="totalLength" type="number" min="15" max="20" formControlName="totalLength" />
                        <div *ngIf="b.totalLength.invalid && b.totalLength.touched">
                            <small *ngIf="b.totalLength.errors?.required" class="small-warning text-danger">*identifier length must be specified. </small>
                        </div>
                    </div>

                    <!-- Form Row    -->
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <div class="custom-control custom-switch mt-n2">
                                <input class="custom-control-input" (change)="setEnableBranch($event)" id="enableBranchSwitch" type="checkbox" [checked]="enableBranch" />
                                <label class="custom-control-label" for="enableBranchSwitch">Enable/Disable store multiple branch</label>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <div class="custom-control custom-switch mt-n2">
                                <input class="custom-control-input" (change)="setEnableBranchOverInternet($event)" id="enableBranchToggleSwitch" type="checkbox" [checked]="enableBranchOverInternet" />
                                <label class="custom-control-label" for="enableBranchToggleSwitch"> Enable/Disable multiple branch over internet</label>
                            </div>
                        </div>
                    </div>
                    
                    <!-- Save changes button-->
                    <span class="notification" *ngIf="showProcessing"> <img src="../../../../assets/landing/images/ring1.gif"  class="imgNot float-right"></span>
                    <button class="btn btn-primary closeStatus" type="submit" [disabled]="!storeProfileBranchForm.valid || showProcessing">Update configuration</button>
                </form>

            </div>
            <div class="modal-footer"><button class="btn btn-secondary closePay" type="button" data-dismiss="modal">Cancel</button></div>
        </div>
    </div>
</div>

<!-- KYC Modal -->
<div class="modal fade"  id="exampleModalKYC" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document" *ngIf="kyc">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Store KYC: {{kyc.name}}</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
               
                <form [formGroup]="storeProfileKYCForm" (ngSubmit)="configureStoreKYCStatus(storeProfileKYCForm.value)">
                
                    <!-- Form Group (last name)-->
                    <div class="form-group">
                        <label class="small mb-1" for="cappedType">Approval Status</label>
                        <select class="form-control" id="cappedType" formControlName="status" >
                            <option value="">Choose</option>
                            <option value="APPROVED">APPROVED</option>
                            <option value="REVIEWED">REVIEWED</option>
                            <option value="REJECTED">REJECTED</option>
                        </select>
                        <div *ngIf="k.status.invalid && k.status.touched">
                            <small *ngIf="k.status.errors?.required" class="small-warning text-danger">*Status of KYC document must be selected </small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="reviewInfo">Document review info</label>
                        <textarea class="form-control" id="reviewInfo" formControlName="reviewInfo" rows="2" maxlength="150"></textarea>
                        <div *ngIf="k.reviewInfo.invalid && f.reviewInfo.touched">
                            <small *ngIf="k.reviewInfo.errors?.maxlength" class="small-warning text-danger">*Review info must be provided.</small>
                        </div>
                    </div>

                    
                    <!-- Save changes button-->
                    <span class="notification" *ngIf="showProcessing"> <img src="../../../../assets/landing/images/ring1.gif"  class="imgNot float-right"></span>
                    <button class="btn btn-primary closeStatus" type="submit" [disabled]="!storeProfileKYCForm.valid || showProcessing">Update kyc</button>
                </form>

            </div>
            <div class="modal-footer"><button class="btn btn-secondary closeKycStatus" type="button" data-dismiss="modal">Cancel</button></div>
        </div>
    </div>
</div>

<ngx-loading [show]="processingSalesAnalytics" 
[config]="{animationType: ngxLoadingAnimationTypes.rectangleBounce, 
    primaryColour: primaryColour, backdropBorderRadius: '3px'}"></ngx-loading>

<ngx-loading [show]="loadingStoreProfile" 
    [config]="{animationType: ngxLoadingAnimationTypes.rectangleBounce, 
        primaryColour: primaryColour, backdropBorderRadius: '3px'}"></ngx-loading>





