import { Injectable, ElementRef } from '@angular/core';
import * as XLSX from 'xlsx';
// import * as jsPDF from 'jspdf';
// import * as jsPDF from 'jspdf-autotable';
import * as autoTable from 'jspdf-autotable';
// import 'jspdf-autotable';
// declare var jsPDF: any;
declare var jsPDF: any;

// declare var jsPDF: any; // Important


@Injectable({
  providedIn: 'root'
})
export class FileExportService {

  constructor() { }

  static toExportFileName(excelFileName: string): string {
    return `${excelFileName}_export_${new Date().getTime()}.xlsx`;
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
    //console.log('Writing excel file..');
    XLSX.writeFile(workbook, FileExportService.toExportFileName(excelFileName));
  }

  public exportAsPDF(tableContent: ElementRef, fileExportName: String) {
    // const pdfDoc = new jsPDF('landscape');
    const pdfDoc = new jsPDF('p', 'pt', 'ledger');

    const specialElementHandler = {
      '#editor': function(element, renderer) {
        return true;
      }
    };

    const margins = {
      top : 80,
      bottom : 10,
      left : 10,
      width : 622
  };

    const content = tableContent.nativeElement;

    // pdfDoc.fromHTML(content.innerHTML, 5, 5, {
    //   'width': 300,
    //   'elementHandlers': specialElementHandler
    // });

    const fileName = fileExportName + '_export_' + (new Date().getTime()) + '.pdf';

    pdfDoc.fromHTML(content, // HTML string or DOM elem ref.
      margins.left, // x coord
      margins.top, { // y coord
          'width' : margins.width, // max width of content on PDF
          'elementHandlers' : specialElementHandler
      },
      function(dispose) {
          // dispose: object with X, Y of the last line add to the PDF
          //          this allow the insertion of new lines after html
          pdfDoc.save(fileName);
      }, margins);

    // pdfDoc.save(fileName);
  }

  public exportDataAsPDF(columns: any, dataRec: any, fileExportName: String, fontSize: number) {
    const pdfDoc = new jsPDF('l', 'pt');
    // console.log(pdfDoc);
    const fileNameDoc = fileExportName + '_export (' + (new Date().getTime()) + ')';
    pdfDoc.autoTable(columns, dataRec, {
        theme: 'striped',
        tableWidth: 'auto',
        styles: {
          fontSize: fontSize,
          overflow: 'linebreak',
          columnWidth: 'auto'
        },
        columnStyles: {
          sn: {fillColor: 255}
        },
        margin: {top: 60},
        addPageContent: function(data) {
          pdfDoc.text(fileNameDoc, 40, 30);
        }
    });
    const fileName = fileExportName + '_export_' + (new Date().getTime()) + '.pdf';
    pdfDoc.save(fileName);
  }

}
