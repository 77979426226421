import { Component, OnInit } from '@angular/core';
import { LoginCred } from 'src/app/main/home/login/login.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Configuration } from 'src/app/config/configuration';
import { ApiResponseObj } from 'src/app/shared/models/loginApiResponse';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';
import { UsernameValidator } from 'src/app/shared/customvalidators/username-validator';
import { Observable, Observer } from 'rxjs';

@Component({
  selector: 'app-storekeeperlogin',
  templateUrl: './storekeeperlogin.component.html',
  styleUrls: ['./storekeeperlogin.component.css']
})
export class StorekeeperloginComponent implements OnInit {
  public emailPattern = '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}';

  public showProcessing: boolean;
  public loginValue: LoginCred[] = [];

  public loginForm: FormGroup;
  public resetPassword: FormGroup;
  public changePassword = false;

  private termPaths = new Configuration;
  private loginResponseObject = new ApiResponseObj;

  public statusMessage = {type: '', text: ''};

  constructor(private fb: FormBuilder, private _api: ApiRequestService,
     private auth: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      username: ['', Validators.compose([Validators.required, Validators.email, 
        Validators.pattern(this.emailPattern), UsernameValidator.cannotContainSpace])],
        password: ['', Validators.required]
    }, {
      validator: []
    });

  }

  loginOld(submittedValue: any) {
    
    let reqBody = JSON.stringify(submittedValue);
    //console.log(reqBody);
    // this.showProcessing = !this.showProcessing;
    // this.auth.userLogin(reqBody, false).subscribe((res)=>{
    //   this.statusMessage = res;
    // });

    this.showProcessing = !this.showProcessing;

  }

  login(submittedValue: any) {
    //this.showProcessing = true;
    let reqBody = JSON.stringify(submittedValue);
   
    this.showProcessing = !this.showProcessing;

    //let statusMessage = {type: '', text: ''};
    let loginResponseObject = new ApiResponseObj;

    this._api.post(this.termPaths._loginUrl, reqBody)
    .subscribe((res) => {
        
        loginResponseObject = this.auth.mapHttpResponse(res);
        
        const statusCode = loginResponseObject.statusCode;
        const statusMsg = loginResponseObject.statusMessage;
        const errorMsg = loginResponseObject.errorMessage;

        let data = loginResponseObject.data;
  
        if(statusCode == '00'){

          this.auth.setUserAccessSession(data);
          this.router.navigate(['storeapp']);

          this.statusMessage.type = 'success';
          this.statusMessage.text = "success";

        }else{

          this.statusMessage.type = 'error';
          this.statusMessage.text = errorMsg;
          this.showProcessing = !this.showProcessing;

        }

    },  err => {
      //console.log(err);
      let errorMsg = "Oops Sorry! Your request cant be processed at the moment!";
      loginResponseObject.status = err.error.statusCode;
      const errorMsgRes = err.error.errorMessage;
      if(errorMsgRes){
        errorMsg = errorMsgRes;
      }
      this.statusMessage.text = errorMsg;
      loginResponseObject.data = err.error.data;
      this.statusMessage.type = 'error';
      //console.log(statusMessage.text);
      this.showProcessing = !this.showProcessing;
    
    });


    

    // this.auth.userLoginAsync(reqBody, true).then((res)=>{
    //   this.statusMessage = res;
    //   console.log(res, "actual res async");
    //   console.log(this.statusMessage, "in res");
    //   console.log(res['text']);
    //   console.log(res['type']);
    //   //this.showProcessing = false;
    //   if (this.statusMessage.type === 'error') {
    //     this.showProcessing = false;
    //   }

    // });
    
  }

  get f(){
    return this.loginForm.controls;
  }

  get username() {
    return this.loginForm.get('username');
  }
  get password() {
    return this.loginForm.get('password');
  }

}
