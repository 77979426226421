<header class="page-header page-header-dark bg-gradient-primary-to-secondaryadmin pb-10">
    <div class="container">
        <div class="page-header-content pt-4">
            <div class="row align-items-center justify-content-between">
                <div class="col-auto mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Country configuration 
                    </h1>
                    <div class="page-header-subtitle">Inventory management system country configuration management</div>
                </div>
                
            </div>
        </div>
    </div>
</header>

<div class="container mt-n10">

    <div class="row">
        <div class="col-xl-3 col-md-6 mb-4">
            <!-- Dashboard info widget 1-->
            <div class="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-primary h-100">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <div class="small font-weight-bold text-primary mb-1">Today Sales ({{productSalesAnalytics.totalDaySalesCount}})</div>
                            <div class="h5"><span [innerHTML]="currencyLoader[currency]"></span>{{productSalesAnalytics.totalDaySalesAmount | number : '1.2-2'}}</div>
                            <div class="text-xs font-weight-bold text-success d-inline-flex align-items-center">
                                <i class="mr-1" data-feather="trending-up"></i>
                                {{(productSalesAnalytics.averageDaySalesAmount/productSalesAnalytics.averageDaySalesCount)/100 | number : '1.2-2'  }}%
                            </div>
                        </div>
                        <div class="ml-2"><i class="fas fa-tag fa-2x fa-2x text-gray-200"></i></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
            <!-- Dashboard info widget 3-->
            <div class="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-success h-100">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <div class="small font-weight-bold text-success mb-1">Current Week ({{productSalesAnalytics.totalWeekSalesCount}})</div>
                            <div class="h5"><span [innerHTML]="currencyLoader[currency]"></span>{{productSalesAnalytics.totalWeekSalesAmount | number : '1.2-2'}}</div>
                            <div class="text-xs font-weight-bold text-success d-inline-flex align-items-center">
                                <i *ngIf="productSalesAnalytics.weekGrowthType == 'NEGATIVE'" class="mr-1" data-feather="trending-down"></i>
                                <i *ngIf="productSalesAnalytics.weekGrowthType == 'POSITIVE'" class="mr-1" data-feather="trending-up"></i>
                                {{productSalesAnalytics.totalWeekSalesAmount/productSalesAnalytics.prevWeekSalesAmount | number : '1.2-2' }}%
                            </div>
                        </div>
                        <div class="ml-2"><i class="fas fa-mouse-pointer fa-2x text-gray-200"></i></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
            <!-- Dashboard info widget 2-->
            <div class="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-secondary h-100">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <div class="small font-weight-bold text-secondary mb-1">Current Month ({{productSalesAnalytics.totalMonthSalesCount}})</div>
                            <div class="h5"><span [innerHTML]="currencyLoader[currency]"></span>{{productSalesAnalytics.totalMonthSalesAmount | number : '1.2-2'}}</div>
                            <div class="text-xs font-weight-bold text-danger d-inline-flex align-items-center">
                                <i *ngIf="productSalesAnalytics.monthGrowthType == 'NEGATIVE'" class="mr-1" data-feather="trending-down"></i>
                                <i *ngIf="productSalesAnalytics.monthGrowthType == 'POSITIVE'" class="mr-1" data-feather="trending-up"></i>
                                {{productSalesAnalytics.monthGrowthPercentage}}%
                            </div>
                        </div>
                        <div class="ml-2"><i class="fas fa-tag fa-2x text-gray-200"></i></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
            <!-- Dashboard info widget 4-->
            <div class="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-info h-100">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <div class="small font-weight-bold text-info mb-1">Last Month ({{productSalesAnalytics.prevMonthAverageSalesCount}})</div>
                            <div class="h5"><span [innerHTML]="currencyLoader[currency]"></span>{{productSalesAnalytics.prevMonthAverageSalesAmount | number : '1.2-2'}}</div>
                            <div class="text-xs font-weight-bold text-danger d-inline-flex align-items-center">
                                <i *ngIf="productSalesAnalytics.averageMonthSalesAmount > productSalesAnalytics.prevMonthAverageSalesAmount" class="mr-1" data-feather="trending-down"></i>
                                <i *ngIf="productSalesAnalytics.averageMonthSalesAmount < productSalesAnalytics.prevMonthAverageSalesAmount" class="mr-1" data-feather="trending-up"></i>
                                <span *ngIf="productSalesAnalytics.averageMonthSalesAmount < productSalesAnalytics.prevMonthAverageSalesAmount">{{(productSalesAnalytics.prevMonthAverageSalesAmount/productSalesAnalytics.totalMonthSalesAmount)/100 | number : '1.2-2' }}%</span>
                                <span *ngIf="productSalesAnalytics.averageMonthSalesAmount > productSalesAnalytics.prevMonthAverageSalesAmount">{{(productSalesAnalytics.totalMonthSalesAmount/productSalesAnalytics.prevMonthAverageSalesAmount)*100 | number : '1.2-2' }}%</span>
                            </div>
                        </div>
                        <div class="ml-2"><i class="fas fa-percentage fa-2x text-gray-200"></i></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <!-- Billing history card-->
    <div *ngIf="!viewCountryConfig" class="card mb-4">
        <div class="card-header">System countries</div>
        <div class="card-body">
            <!-- Billing history table-->
            
            <div class="datatable">
                <table class="table table-bordered table-hover" width="100%" cellspacing="0">
                    <thead>
                        <tr>
                            <th>S/N</th>
                            <th>Name</th>
                            <th>Continent</th>
                            <th>Code</th>
                            <th>Def Currency</th>
                            <th>Def PayOption</th>
                            <th>Vat %</th>
                            <th>GTF %</th>
                            <th>Levy %</th>
                            <th>Status</th>
                            <th>Fee Config</th>
                        </tr>
                    </thead>
                    
                    <tbody> 
                        <tr *ngFor='let st of countries; let i=index;'>
                            <td>{{i+1}}</td>
                            <td>{{st.name}}</td>
                            <td>{{st.continent}}</td>
                            <td>{{st.countryCode}}</td>
                            <td>{{st.defaultCurrency.currency}} ({{st.defaultCurrency.code}})</td>
                            <td>{{st.defaultPaymentMode.code}}</td>
                            <td>
                                <span *ngIf="st.vatFeeConfigPercentage">{{st.vatFeeConfigPercentage}}%</span>
                                <span *ngIf="!st.vatFeeConfigPercentage">12.5%</span>
                            </td>
                            <td>
                                <span *ngIf="st.gtfFeeConfigPercentage">{{st.gtfFeeConfigPercentage}}%</span>
                                <span *ngIf="!st.gtfFeeConfigPercentage">5.0%</span>
                            </td>
                            <td>
                                
                                <span *ngIf="st.levyFeeConfigPercentage">{{st.levyFeeConfigPercentage}} %</span>
                                <span *ngIf="!st.levyFeeConfigPercentage">1.0%</span>
                            </td>
                            <td><span class="badge badge-light">{{st.status}}</span></td>
                            <td>
                                <button class="btn btn-datatable btn-icon btn-transparent-dark mr-2"><i data-feather="more-vertical"></i></button>
                                <button class="btn btn-datatable btn-icon btn-transparent-dark" (click)="viewCountryPaymentOptions(st.paymentOptions)"  data-toggle="modal" data-target="#exampleModalXl">
                                    <i class="text-purple" data-feather="eye"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
        </div>
    </div>
    <!-- Payment methods card-->
    <div *ngIf="viewCountryConfig" class="card card-header-actions mb-4">
        <div class="card-header">
            Payment Methods
            <button class="btn btn-sm btn-primary float-right" type="button" (click)="closePayoption()"> &#x2190; back</button>
        </div>
        <div class="card-body">
            <!-- Payment method 1-->
            <div *ngFor="let pay of paymentOptions; let i=index;">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                    
                        <i *ngIf="pay.name.includes('Cash') || pay.name.includes('cash')" class="far fa-money-bill-alt fa-2x cc-color-visa"></i>
                        <i *ngIf="pay.name.includes('Momo') || pay.name.includes('momo') || pay.name.includes('mobile') || pay.name.includes('mobile') || pay.name.includes('Mobile Money') " class="fas fa-wallet fa-2x"></i>
                        <i *ngIf="pay.name.includes('cards') || pay.name.includes('Cards')" class="fas fa-credit-card fa-2x"></i>
                                    <i *ngIf="pay.name.includes('Pos') || pay.name.includes('pos')" class="fas fa-cash-register fa-2x"></i>
                        <div class="ml-4">
                            <div class="small">{{pay.name}}</div>
                            <div class="text-xs text-muted">{{pay.description}}</div>
                        </div>
                    </div>
                    <div class="ml-4 small">
                        <div class="badge badge-light mr-3">Fee (mode: {{pay.paymentOptionFeeMode}}): {{pay.paymentOptionFee}}</div>
                        <!-- <a href="#!">View full details</a> -->
                        <button class="btn btn-datatable btn-icon btn-transparent-dark mr-2" ><i data-feather="more-vertical"></i></button>
                        <button class="btn btn-datatable btn-icon btn-transparent-dark" type="button" data-toggle="modal" data-target="#exampleModalLg" (click)="editCountryPayOption(pay)" >
                            <i class="text-purple" data-feather="edit"></i>
                        </button>
                    </div>
                </div>
                <hr />
            </div>
            
        </div>
    </div>
    
</div>

<div class="modal fade" id="exampleModalLg" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Edit Payment Option</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
            
                <form [formGroup]="paymentOptionProfileForm" (ngSubmit)="updatePaymentOptionProfile(paymentOptionProfileForm.value)">
                    <div class="form-row">
                        <!-- Form Group (username)-->
                        <div class="form-group col-md-6">
                            <label class="small mb-1" for="name">Name</label>
                            <input class="form-control" id="name" type="text" formControlName="name" />
                            <div *ngIf="f.name.invalid && f.name.touched">
                                <small *ngIf="f.name.errors?.required" class="small-warning text-danger">*Payment option name is required </small>
                            </div>
                        </div>
                        <!-- Form Group (username)-->
                        <div class="form-group col-md-6">
                            <label class="small mb-1" for="viewName">View Name</label>
                            <input class="form-control" id="viewName" type="text" formControlName="viewName" />
                            <div *ngIf="f.viewName.invalid && f.viewName.touched">
                                <small *ngIf="f.viewName.errors?.required" class="small-warning text-danger">*Payment option view name is required </small>
                            </div>
                        </div>
                    </div>

                    <!-- Form Row    -->
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <div class="custom-control custom-switch mt-n2">
                                <input class="custom-control-input" (change)="setEnableOption($event)" id="enableToggleSwitch" type="checkbox" [checked]="enableOption" />
                                <label class="custom-control-label" for="enableToggleSwitch">Enable/Disable option</label>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <div class="custom-control custom-switch mt-n2">
                                <input class="custom-control-input" (change)="setEnableOptionOnWeb($event)" id="enableWebToggleSwitch" type="checkbox" [checked]="enableWebOption" />
                                <label class="custom-control-label" for="enableWebToggleSwitch"> Enable/Disable option on web</label>
                            </div>
                        </div>
                    </div>
                    <!-- Form Row    -->
                    <div class="form-row">
                        
                        <div class="form-group col-md-6">
                            <div class="custom-control custom-switch mt-n2">
                                <input class="custom-control-input" (change)="setEnableOptionOnApp($event)" id="enableAppToggleSwitch" type="checkbox" [checked]="enableAppOption" />
                                <label class="custom-control-label" for="enableAppToggleSwitch"> Enable/Disable option on App</label>
                            </div>
                        </div>
                    </div>

                    <!-- Form Row-->
                    <div class="form-row">
                        <!-- Form Group (first name)-->
                        <div class="form-group col-md-6">
                            <label class="small mb-1" for="description">Description</label>
                            <input class="form-control" id="description" type="text" formControlName="description" />
                            <div *ngIf="f.description.invalid && f.description.touched">
                                <small *ngIf="f.description.errors?.required" class="small-warning text-danger">*Payment option description is required </small>
                            </div>
                        </div>
                        <!-- Form Group (last name)-->
                        <div class="form-group col-md-6">
                            <label class="small mb-1" for="status">Fee status</label>
                            <select class="form-control" id="status" formControlName="status" >
                                <option value="ACTIVE">ACTIVE</option>
                                <option value="INACTIVE">INACTIVE</option>
                            </select>
                            <div *ngIf="f.status.invalid && f.status.touched">
                                <small *ngIf="f.status.errors?.required" class="small-warning text-danger">*Status is required</small>
                            </div>

                        </div>
                    </div>

                    <!-- Form Row    -->
                    <div class="form-row">
                        <div *ngIf="applyFixCharge" class="form-group col-md-6">
                            <label class="small mb-1" for="fixCharge">Fix charge</label>
                            <input class="form-control" id="fixCharge" type="number" min="0" placeholder="Enter fix charge" formControlName="fixCharge" />
                            <div *ngIf="f.fixCharge.invalid && f.fixCharge.touched">
                                <small *ngIf="f.fixCharge.errors?.required" class="small-warning text-danger">*Fix-charge is required</small>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <div class="custom-control custom-switch mt-n2">
                                <input class="custom-control-input" (change)="setApplyFixCharge($event)" id="prodToggleSwitch" type="checkbox" [checked]="applyFixCharge" />
                                <label class="custom-control-label" for="prodToggleSwitch"> Apply/De-apply fix-charge</label>
                            </div>
                        </div>
                    </div>

                    <!-- Form Row    -->
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <div class="custom-control custom-switch mt-n2">
                                <input class="custom-control-input" (change)="setProcessSettlemet($event)" id="settleToggleSwitch" type="checkbox" [checked]="processSettlement" />
                                <label class="custom-control-label" for="settleToggleSwitch">Enable/Disable settlement</label>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <div class="custom-control custom-switch mt-n2">
                                <input class="custom-control-input" (change)="setTenderedAmount($event)" id="tenderedToggleSwitch" type="checkbox" [checked]="enableTenderedAmount" />
                                <label class="custom-control-label" for="tenderedToggleSwitch"> Allow tendered amount</label>
                            </div>
                        </div>
                    </div>

                    <!-- Form Row    -->
                    <div class="form-row">
                            
                        <div class="form-group col-md-6">
                            <label class="small mb-1" for="paymentOptionFee">Transaction Fee</label>
                            <input class="form-control" id="paymentOptionFee" min="0" type="number" placeholder="Enter pay fee" formControlName="paymentOptionFee"  />
                            <div *ngIf="f.paymentOptionFee.invalid && f.paymentOptionFee.touched">
                                <small *ngIf="f.paymentOptionFee.errors?.required" class="small-warning text-danger">*Transaction fee is required</small>
                            </div>
                        </div>
                        
                        <div class="form-group col-md-6">
                            <label class="small mb-1" for="paymentOptionFeeMode">Transaction Fee Type</label>
                            <select class="form-control" id="paymentOptionFeeMode" formControlName="paymentOptionFeeMode" >
                                <option value="PERCENTAGE">PERCENTAGE</option>
                                <option value="FIXED">FIXED</option>
                                <option value="NONE">NONE</option>
                            </select>
                            <div *ngIf="f.paymentOptionFeeMode.invalid && f.paymentOptionFeeMode.touched">
                                <small *ngIf="f.paymentOptionFeeMode.errors?.required" class="small-warning text-danger">*Transaction fee mode is required</small>
                            </div>
                        </div>
                    </div>

                    <!-- Form Row    -->
                    <div class="form-row">
                            
                        <div class="form-group col-md-6">
                            <label class="small mb-1" for="internationalPaymentOptionFee">International Transaction Fee</label>
                            <input class="form-control" id="internationalPaymentOptionFee" type="number" min="0" placeholder="Enter international fee" formControlName="internationalPaymentOptionFee"  />
                            <div *ngIf="f.internationalPaymentOptionFee.invalid && f.internationalPaymentOptionFee.touched">
                                <small *ngIf="f.internationalPaymentOptionFee.errors?.required" class="small-warning text-danger">*International Transaction fee is required</small>
                            </div>
                        </div>
                        
                        <div class="form-group col-md-6">
                            <label class="small mb-1" for="internationalPaymentOptionMode">International Transaction Fee Type</label>
                            <select class="form-control" id="internationalPaymentOptionMode" formControlName="internationalPaymentOptionMode" >
                                <option value="PERCENTAGE">PERCENTAGE</option>
                                <option value="FIXED">FIXED</option>
                                <option value="NONE">NONE</option>
                            </select>
                        </div>
                    </div>

                    <!-- Form Row sub   -->
                    <div formGroupName="paymentOptionCapStatus">
                        <!-- Form Row-->
                        <div class="form-row">
                            <!-- Form Group (first name)-->
                            <div class="form-group col-md-6">
                                <label class="small mb-1" for="cappedAmount">Capped Fee Amount</label>
                                <input class="form-control" id="cappedAmount" type="number" min="0" placeholder="Enter capped amount" formControlName="cappedAmount" />
                            </div>
                            <!-- Form Group (last name)-->
                            <div class="form-group col-md-6">
                                <label class="small mb-1" for="cappedType">Is fee capped</label>
                                <select class="form-control" id="cappedType" formControlName="cappedType" >
                                    <option value="CAPPED">CAPPED</option>
                                    <option value="UNCAPPED">UNCAPPED</option>
                                </select>
                            </div>
                        </div>
                        
                    </div>

                    
                    <!-- Save changes button-->
                    <span class="notification" *ngIf="showProcessing"> <img src="../../../../assets/landing/images/ring1.gif"  class="imgNot float-right"></span>
                    <button class="btn btn-primary" type="submit" [disabled]="!paymentOptionProfileForm.valid || isUpdating">Save changes</button>
                </form>

            </div>
            <div class="modal-footer">
                <button class="btn btn-sm btn-danger closePayOption" type="button" data-dismiss="modal">Close</button>
                <!-- <button class="btn btn-sm btn-primary" type="button">Save changes</button> -->
            </div>
        </div>
    </div>
</div>

<ngx-loading [show]="processingSalesAnalytics" 
    [config]="{animationType: ngxLoadingAnimationTypes.rectangleBounce, 
        primaryColour: primaryColour, backdropBorderRadius: '3px'}"></ngx-loading>







