import { Injectable } from '@angular/core';

@Injectable()
export class Configuration {
  public _fwScript = './assets/stores/js/all.min.js';
  public _featherScript = './assets/stores/js/feather.min.js';
  public _jqScript = './assets/stores/js/jquery-3.5.1.min.js';
  public _bstScript = './assets/stores/js/bootstrap.bundle.min.js';
  public _tsScript = './assets/stores/js/scripts.js';
  public _chartScript = './assets/stores/js/Chart.min.js';
  public _chartDemoScript = './assets/stores/assets/demo/chart-area-demo.js';
  public _chartBarScript = './assets/stores/assets/demo/chart-bar-demo.js';
  public _dataTableScript = './assets/stores/js/jquery.dataTables.min.js';
  public _bDataTableScript = './assets/stores/js/dataTables.bootstrap4.min.js';
  public _dataTableDemoScript =
    './assets/stores/assets/demo/datatables-demo.js';
  public _momentScript = './assets/stores/js/moment.min.js';
  public _datePickerScript = './assets/stores/js/daterangepicker.min.js';
  public _datePickerDemoScript =
    './assets/stores/assets/demo/date-range-picker-demo.js';

    public _backendBaseUrl =
      'https://shopkeeperapi.myshopkeeper.net:8080/api/v1/';
    public _adminBackendBaseUrl =
      'https://shopkeeperapi.myshopkeeper.net:9900/api/v1/';

  // public _backendBaseUrl = "http://3.10.179.52:8080/api/v1/"
  // public _adminBackendBaseUrl = "http://3.10.179.52:9900/api/v1/"

  // public _backendBaseUrl = 'http://localhost:8080/api/v1/';
  // public _adminBackendBaseUrl = 'http://localhost:9090/api/v1/';

  public _systemBaseUrl = this._backendBaseUrl + 'admin/system/';
  public _systemAdminBaseUrl = this._adminBackendBaseUrl + 'admin/system/';
  public _storeBaseUrl = this._backendBaseUrl + 'stores/';

  // Endpoints..
  public _loginUrl = this._backendBaseUrl + 'login';
  public _switchStoreUrl = this._backendBaseUrl + 'switchstore';
  public _registerUrl = this._backendBaseUrl + 'register';
  public _loadCountryUrl = this._systemBaseUrl + 'loadcountry';
  public _productUploadUrl = this._backendBaseUrl + 'upload-product';

  //Admin Endpoints
  public _adminLoginUrl = this._adminBackendBaseUrl + 'manager/user/login';
  public _adminBaseUrl = this._adminBackendBaseUrl + 'admin/';

  
}
