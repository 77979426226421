import { Component, OnInit } from '@angular/core';
import { Configuration } from 'src/app/config/configuration';

import { DomSanitizer } from '@angular/platform-browser';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';
import { ApiResponseObj } from 'src/app/shared/models/loginApiResponse';

import swal from 'sweetalert2';
import { ClientAppService } from 'src/app/shared/services/clientapp.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-storekeeperdashboard',
  templateUrl: './storekeeperdashboard.component.html',
  styleUrls: ['./storekeeperdashboard.component.css']
})
export class StorekeeperdashboardComponent implements OnInit {

  private termPaths = new Configuration;

  public cYear: any;
  public cDate: any;
  public loggedInUser: String;
  public userProfileUrl: String;
  public user: any;

  public userStores: any[] = [];
  public storeNumber;

  public switchProcessing: boolean;
  public primaryColour = "#ffffff";
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;

  constructor(private _clientApp: ClientAppService, 
    public auth: AuthService, 
    private alert: AlertService, private router: Router) { }

  ngOnInit(): void {

    this._clientApp.updateStoreUrlScripts();
    this.cYear = this._clientApp.setCopyDate(); 
    this.cDate = this._clientApp.setCurrentFullDate(); 
    this.loggedInUser = this.auth.getLoggedUserName();
    this.userProfileUrl = this.auth.getUser().profileUrl;

    this.user = this.auth.getUser();
    this.userStores = this.auth.getAllUserStoreList();
    this.storeNumber = this.auth.getUserStoreNumber();

  }

  switchStoreOrWebsite(store:any, $event){
    const user = this.auth.getUser();
    let newStoreNumber = store.storeNumber

    if(newStoreNumber !== this.storeNumber){
      
      $event.preventDefault();

      return swal({
        title: 'Are you sure?',
        text: 'Are sure you want to switch to '+store.storeName+' store?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
  
      }).then((result) => {
  
        if(result.value){
  
          let options = {text: '', title: ''};
          let switchResponseObject = new ApiResponseObj;
          let req = {username: user.username, storeNumber: this.storeNumber}
          this.switchProcessing = true;

          let reqBody = JSON.stringify(req);
          //console.log(reqBody);

          this._clientApp.post(this.termPaths._switchStoreUrl, reqBody)
          .subscribe((res) => {

            switchResponseObject = this.auth.mapHttpResponse(res);
            
            const statusCode = switchResponseObject.statusCode
            const errorMsg = switchResponseObject.errorMessage
            const statusMsg = switchResponseObject.statusMessage
      
            let data = switchResponseObject.data;
      
            this.switchProcessing = false;
      
            if(statusCode == '00'){
      
              options.text = statusMsg;
              options.title = status;
                
              this.alert.doToastAlert(options, 1);
              this.auth.clearLocalStorage();
              this.auth.updateUserAccessSession(data, newStoreNumber);
              location.reload();
              
            }else{
      
              options.text = errorMsg;
              options.title = status;
              this.alert.doToastAlert(options, 3);
            }
      
          },  err => {
            //console.log(err);
            options.text = err.error.errorMessage;
            options.title = status;
            this.alert.doToastAlert(options, 3);

            this.switchProcessing = false;
            
          });
        
  
        }
  
      });
      
    }
  }


  gotoUploadProducts(){
    this.router.navigate(['/mystore/dashboard/products'], { queryParams: { action: "multiuploads" } }); 
  }
  gotoAddProducts(){
    this.router.navigate(['/mystore/dashboard/products'], { queryParams: { action: "add" } }); 
  }
  gotoUpdateProducts(){
    this.router.navigate(['/mystore/dashboard/products'], { queryParams: { action: "editproducts" } }); 
  }


}
