
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Response, Headers, RequestOptions } from '@angular/http';
import {throwError as ObservableThrowError, Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class ApiRequestService {

  private options = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
};

  constructor(private http: HttpClient,
    private _router: Router) { }

    get(url) {
        return this.http.get(url, {observe: 'response',  headers: new HttpHeaders(this.options)})
        .pipe(catchError(this.handleError));
    }

    post(url, body) {
        return this.http.post(url, body, {observe: 'response', headers: new HttpHeaders(this.options)}).pipe(catchError(this.handleError));
    }

    put(url, body) {
        return this.http.put(url, body, {observe: 'response',  headers: new HttpHeaders(this.options) }).pipe(catchError(this.handleError));
    }

    delete(url, authorized) {

        return this.http.delete(url, {observe: 'response',  headers: new HttpHeaders(this.options)}).pipe(catchError(this.handleError));
    }

    changePassword(url, username, Password, activationToken) {
        const header = new Headers({
            'Content-Type': 'application/json'
        });

        const options = new RequestOptions({ headers: header });

        header.append('USERNAME', username);
        header.append('PASSWORD', Password);
        header.append('ACTIVATECODE', activationToken);

        const body = {};
        console.log(url, body, options);
        return this.http.put(url, body, {observe: 'response'}).pipe(catchError(this.handleError));
    }

    resetPassword(url, oldPassword, newPassword, username) {
        const header = new Headers({
            'Content-Type': 'application/json'
        });

        const options = new RequestOptions({ headers: header });
        const user = JSON.parse(localStorage.getItem('currentUser'));

        header.append('X-AUTH-TOKEN', user.accessToken);
        header.append('X-AUTH-OLDPASSWORD', oldPassword);
        header.append('X-AUTH-PASSWORD', newPassword);
        header.append('X-AUTH-USERNAME', username);

        const body = {};
        console.log(url, body, options);
        return this.http.put(url, body, {observe: 'response'}).pipe(catchError(this.handleError));
    }

    fileUpload(url, body) {
        return this.http.post(url, body, {observe: 'response'}).pipe(catchError(this.handleError));

    }

    postGetUser(url, body) {
        const header = new Headers();
        const accessToken = localStorage.getItem('accessToken');
        const token = localStorage.getItem('userToken');
        const options = {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
            'X-Auth-Token': accessToken
        };
        // console.log(accessToken);
        return this.http.post(url, body, {headers: new HttpHeaders(options), observe: 'response'})
        .pipe(catchError(this.handleError));
    }

    postPutUser(url, body) {
        const header = new Headers();
        const accessToken = localStorage.getItem('accessToken');
        const token = localStorage.getItem('userToken');
        const options = {
            'Authorization': 'Bearer ' + token,
            'X-Auth-Token': accessToken
        };
        // console.log(accessToken);
        return this.http.post(url, body, {headers: new HttpHeaders(options), observe: 'response'})
        .pipe(catchError(this.handleError));
    }

    postFetchUserBankAccounts(url, body) {
        const header = new Headers();
        const accessToken = localStorage.getItem('accessToken');
        const token = localStorage.getItem('userToken');
        const options = {
            'Authorization': 'Bearer ' + token,
            'X-Auth-Token': accessToken
        };
        // console.log(accessToken);
        return this.http.post(url, body, {headers: new HttpHeaders(options), observe: 'response'})
        .pipe(catchError(this.handleError));
    }

    getUser(url) {

        // const options = new RequestOptions({ headers: header });
        // { headers: new HttpHeaders({'Authorization': 'Bearer ' + token})
        const accessToken = localStorage.getItem('accessToken');
        const token = localStorage.getItem('userToken');
        // console.log(accessToken);
        // console.log(token);
        const options = {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + token,
            'X-Auth-Token': accessToken
        };
        // header.append('Authorization', 'Bearer ' + token);
        // header.append('X-Auth-Token', accessToken);

        return this.http.get(url, {headers: new HttpHeaders(options), observe: 'response'})
        .pipe(catchError(this.handleError));
    }

    getUserBankAccountList1(url) {

        // const options = new RequestOptions({ headers: header });
        // { headers: new HttpHeaders({'Authorization': 'Bearer ' + token})
        const accessToken = localStorage.getItem('accessToken');
        const token = localStorage.getItem('userToken');
        // console.log(accessToken);
        // console.log(token);
        const options = {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + token,
            'X-Auth-Token': accessToken
        };
        // header.append('Authorization', 'Bearer ' + token);
        // header.append('X-Auth-Token', accessToken);

        return this.http.get(url, {headers: new HttpHeaders(options), observe: 'response'})
        .pipe(catchError(this.handleError));
    }

    getUserPasswordUpdate(url, body) {
        const header = new HttpHeaders();

        // const options = new RequestOptions({ headers: header });
        const Username = localStorage.getItem('pUsername');
        const Password = localStorage.getItem('pPassword');
        const ActivateCode = localStorage.getItem('pActivateCode');
        console.log(Username);

        const options = {
            'Username': Username,
            'Password': Password,
            'ActivateCode': ActivateCode
        };

        // header.append('Username', Username);
        // header.append('Password', Password);
        // header.append('ActivateCode', ActivateCode);

        return this.http.post(url, body, {headers: new HttpHeaders(options), observe: 'response'})
        .pipe(catchError(this.handleError));

    }

    changeUserPassword(url) {
        const header = new HttpHeaders();

        // const options = new RequestOptions({ headers: header });
        const Username = localStorage.getItem('pUsername');
        const OldPassword = localStorage.getItem('pOldPassword');
        const Password = localStorage.getItem('pPassword');

        console.log(Username);

        const options = {
            'Username': Username,
            'Oldpassword': OldPassword,
            'Password': Password,

        };

        // header.append('Username', Username);
        // header.append('Password', Password);
        // header.append('ActivateCode', ActivateCode);

        return this.http.post(url, {}, {headers: new HttpHeaders(options), observe: 'response'})
        .pipe(catchError(this.handleError));

    }

    updateUser(url, body) {
        const header = new HttpHeaders();

        // const options = new RequestOptions({ headers: header });
        const accessToken = localStorage.getItem('accessToken');
        // console.log(accessToken);
        const token = localStorage.getItem('userToken');
        console.log(accessToken);
        const options = {
            'Access-Control-Allow-Origin': '*',
            'authorization': 'Bearer ' + token,
            'X-Auth-Token': accessToken
        };
        // header.append('authorization', 'Bearer ' + token);
        // header.append('X-Auth-Token', accessToken);

        return this.http.post(url, body, {headers: new HttpHeaders(options), observe: 'response'})
        .pipe(catchError(this.handleError));
    }

    private handleError(error: HttpErrorResponse) {
      //console.log(error);
      console.log("Error on handler: "+error.status);
      return ObservableThrowError(error || {});
    }

}

