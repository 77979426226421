import { Injectable, Injector } from '@angular/core';
import {Router} from '@angular/router';
import * as moment from 'moment';
import swal from 'sweetalert2';
import { ApiResponseObj } from '../models/loginApiResponse';
import { StoreProfilesList } from '../models/loginModels/storeProfileList';
import { Configuration } from 'src/app/config/configuration';
import { ApiRequestService } from './api-request.service';
import { Observable, of } from 'rxjs';
import { AlertService } from './alert.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // private cs;
  private termPaths = new Configuration;
  private alertUser;
  constructor(private router: Router, private injector: Injector, 
    private _api: ApiRequestService, private alert: AlertService) {
    // this.cs = this.injector.get(ClientService);
    this.alertUser = this.injector.get(AlertService);
  }

  public userLogin(reqBody: any, isStore: boolean): Observable<any>{

    let statusMessage = {type: '', text: ''};
    let loginResponseObject = new ApiResponseObj;

    this._api.post(this.termPaths._loginUrl, reqBody)
    .subscribe((res) => {
        
        loginResponseObject = this.mapHttpResponse(res);
        
        const statusCode = loginResponseObject.statusCode;
        const statusMsg = loginResponseObject.statusMessage;
        const errorMsg = loginResponseObject.errorMessage;

        let data = loginResponseObject.data;
  
        if(statusCode == '00'){

          this.setUserAccessSession(data);
          
          if (isStore) {
            this.router.navigate(['mystore']);
          }else{
            this.router.navigate(['storekeeper/salesboard']);
          }

          statusMessage.type = 'success';
          statusMessage.text = "success";

        }else{

          statusMessage.type = 'error';
          statusMessage.text = errorMsg;

        }

    },  err => {
      //console.log(err);
      let errorMsg = "Oops Sorry! Your request cant be processed at the moment!";
      loginResponseObject.status = err.error.statusCode;
      const errorMsgRes = err.error.errorMessage;
      if(errorMsgRes){
        errorMsg = errorMsgRes;
      }
      statusMessage.text = errorMsg;
      loginResponseObject.data = err.error.data;
      statusMessage.type = 'error';
      //console.log(statusMessage.text);
    
    });

    return of(statusMessage);

  }

  async userLoginAsync(reqBody: any, isStore: boolean){

    let statusMessage = {type: '', text: ''};
    let loginResponseObject = new ApiResponseObj;

    await this._api.post(this.termPaths._loginUrl, reqBody)
    .subscribe((res) => {
        
        loginResponseObject = this.mapHttpResponse(res);
        
        const statusCode = loginResponseObject.statusCode;
        const statusMsg = loginResponseObject.statusMessage;
        const errorMsg = loginResponseObject.errorMessage;

        let data = loginResponseObject.data;
  
        if(statusCode == '00'){

          this.setUserAccessSession(data);
          
          if (isStore) {
            this.router.navigate(['mystore']);
          }else{
            this.router.navigate(['storekeeper/salesboard']);
          }

          statusMessage.type = 'success';
          statusMessage.text = "success";

        }else{

          statusMessage.type = 'error';
          statusMessage.text = errorMsg;

        }

    },  err => {
      //console.log(err);
      let errorMsg = "Oops Sorry! Your request cant be processed at the moment!";
      loginResponseObject.status = err.error.statusCode;
      const errorMsgRes = err.error.errorMessage;
      if(errorMsgRes){
        errorMsg = errorMsgRes;
      }
      statusMessage.text = errorMsg;
      loginResponseObject.data = err.error.data;
      statusMessage.type = 'error';
      //console.log(statusMessage.text);
    
    });
    
    return statusMessage;

  }

  public isRegistered() {
    return !!localStorage.getItem('idToken');
  }

  public getRegisterationToken() {
    return localStorage.getItem('idToken');
  }

  public setRegSession(authResult) {
    const expiresAt = moment().add(authResult.expiresIn, 'second');
    localStorage.setItem('idToken', authResult.token);
    localStorage.setItem('expiresAt', JSON.stringify(expiresAt.valueOf()));
  }

  public mapHttpResponse(res: any){
    let resResponseObject = new ApiResponseObj;
    resResponseObject.status = res.body['status'];
    resResponseObject.error = res.body['error'];
    resResponseObject.errorMessage = res.body['errorMessage'];
    resResponseObject.statusCode = res.body['statusCode'];
    resResponseObject.statusMessage = res.body['statusMessage'];
    resResponseObject.data = res.body['data'];
    return resResponseObject
  }


  public mapHttpErrorResponse(res: HttpErrorResponse){
    let resResponseObject = new ApiResponseObj;
    resResponseObject.status = res.error['status'];
    resResponseObject.error = res.error['error'];
    resResponseObject.errorMessage = res.error['errorMessage'];
    resResponseObject.statusCode = res.error['statusCode'];
    resResponseObject.statusMessage = res.error['statusMessage'];
    resResponseObject.data = res.error['data'];
    return resResponseObject
  }

  handleCopy(e, props){
    // console.log(e);
    // console.log('Copy properties: ', props);
    if (props) {
      e.preventDefault();
      this.alert.doToastAlert({text: 'Copied successfully', title: 'Copied'}, 1);
      const textField = document.createElement("textarea");
      textField.innerText = props;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();
    }

  };



  public setUserAccessSession(loginResult: any) {
  
    let expires = loginResult.expires;
    //console.log("expires: "+expires);
    let expiresIn = parseInt(expires, 10) * 10;
    //console.log(expiresIn);
    let userStoreProfiles =  {};
    let userStorePerms =  {};
    let userStoreTerminals =  {};


    const expiresAt = moment().add(expiresIn, 'second');
    //console.log(expiresAt);
    let token = loginResult.token;
    //console.log('Data in session token: '+loginResult);
    let userProfile = loginResult.userData['user'];
    let storeList: any[] = loginResult.userData['storeList'];
    let userType = loginResult.userData['userType'];
    let storeProfilesList: StoreProfilesList[] = loginResult.userData['storeProfilesList'];
    let storeProfilesPermissionList = loginResult.userData['userStoreMappedRole'];
    let storeProfilesTerminalList = loginResult.userData['userStoreMappedTerminals'];

    localStorage.setItem('expiresIn', JSON.stringify(expiresIn));
    
    if (userType == 'STORE_USER') {
      localStorage.setItem('isStoreUser', 'true');
      localStorage.setItem('storeList', JSON.stringify(storeList));
      //const storeCount = storeProfilesList.length;

      if (storeProfilesList) {

        Object.entries(storeProfilesList).forEach(([k,v]) =>{
          userStoreProfiles[v.storeNumber] = v;
        });
        
      }

      localStorage.setItem('storeNumber', storeList[0]);
      localStorage.setItem('storeName', storeProfilesList[0].storeName);
      localStorage.setItem('storeProfilesList', JSON.stringify(storeProfilesList));
      localStorage.setItem('storeNumberProfilesList', JSON.stringify(userStoreProfiles));
      localStorage.setItem('storeProfilesTerminalList', JSON.stringify(userStoreProfiles));

      if (storeProfilesPermissionList) {
        Object.entries(storeProfilesPermissionList).forEach(([k,v]) =>{
          // console.log(v)
          // console.log(v['permissions'])
          userStorePerms[k] = v['permissions'];
        });
      }

      if (storeProfilesTerminalList) {
        Object.entries(storeProfilesTerminalList).forEach(([k,v]) =>{
          userStoreTerminals[k] = v;
        });
      }
      
      //console.log("full store permission", JSON.stringify(userStorePerms));
      localStorage.setItem('storeProfilePermissions', JSON.stringify(userStorePerms));
      localStorage.setItem('storeProfileTerminals', JSON.stringify(userStoreTerminals));

    } else {
    
      localStorage.setItem('isAdminUser', 'true');
    }

    localStorage.setItem('accessToken', token);
    localStorage.setItem('userToken', token);
    localStorage.setItem('isStoreUser', 'true');
    localStorage.setItem('user', JSON.stringify(userProfile));
    localStorage.setItem('userName', userProfile.name);
    localStorage.setItem('userData', JSON.stringify(loginResult.userData));
    //localStorage.setItem('roles', roles);
    // console.log('Expires at: ');
    // console.log(JSON.stringify(expiresAt.valueOf()));
    localStorage.setItem('expiresAt', JSON.stringify(expiresAt.valueOf()));

  }

  public updateUserAccessSession(loginResult: any, storeNumber) {
  
    let expires = loginResult.expires;
    //console.log("expires: "+expires);
    let expiresIn = parseInt(expires, 10) * 10;
    //console.log(expiresIn);
    let userStoreProfiles =  {};
    let userStorePerms =  {};
    let userStoreTerminals =  {};
    let activateSingIeSignInSales;


    const expiresAt = moment().add(expiresIn, 'second');
    let token = loginResult.token;
    //console.log('Data in session token: '+loginResult);
    let userProfile = loginResult.userData['user'];
    let storeList: any[] = loginResult.userData['storeList'];
    let userType = loginResult.userData['userType'];
    let storeProfilesList: StoreProfilesList[] = loginResult.userData['storeProfilesList'];
    let storeProfilesPermissionList = loginResult.userData['userStoreMappedRole'];
    let storeProfilesTerminalList = loginResult.userData['userStoreMappedTerminals'];

    localStorage.setItem('expiresIn', JSON.stringify(expiresIn));
    
    if (userType == 'STORE_USER') {
      localStorage.setItem('isStoreUser', 'true');
      localStorage.setItem('storeList', JSON.stringify(storeList));
      //const storeCount = storeProfilesList.length;

      if (storeProfilesList) {

        Object.entries(storeProfilesList).forEach(([k,v]) =>{
          userStoreProfiles[v.storeNumber] = v;
        });
        
      }

      let storeProfileName = "";
      Object.entries(storeProfilesList).forEach(([k,v]) =>{
        if (v.storeNumber == storeNumber) {
          storeProfileName = v.storeName;
          activateSingIeSignInSales = v.activateSingIeSignInSales;
        }
      });

      localStorage.setItem('storeNumber', storeNumber);
      localStorage.setItem('storeName', storeProfileName);
      localStorage.setItem('storeProfilesList', JSON.stringify(storeProfilesList));
      localStorage.setItem('storeNumberProfilesList', JSON.stringify(userStoreProfiles));
      localStorage.setItem('storeProfilesTerminalList', JSON.stringify(userStoreProfiles));
      localStorage.setItem('activateSingIeSignInSales', activateSingIeSignInSales);

      if (storeProfilesPermissionList) {
        Object.entries(storeProfilesPermissionList).forEach(([k,v]) =>{
          userStorePerms[k] = v['permissions'];
        });
      }

      if (storeProfilesTerminalList) {
        Object.entries(storeProfilesTerminalList).forEach(([k,v]) =>{
          userStoreTerminals[k] = v;
        });
      }
      
      //console.log("full store permission", JSON.stringify(userStorePerms));
      localStorage.setItem('storeProfilePermissions', JSON.stringify(userStorePerms));
      localStorage.setItem('storeProfileTerminals', JSON.stringify(userStoreTerminals));

    } else {
    
      localStorage.setItem('isAdminUser', 'true');
    }

    localStorage.setItem('accessToken', token);
    localStorage.setItem('userToken', token);
    localStorage.setItem('isStoreUser', 'true');
    localStorage.setItem('user', JSON.stringify(userProfile));
    localStorage.setItem('userName', userProfile.name);
    localStorage.setItem('userData', JSON.stringify(loginResult.userData));
    //localStorage.setItem('roles', roles);
    localStorage.setItem('expiresAt', JSON.stringify(expiresAt.valueOf()));

  }

  public getAccessToken() {
    return localStorage.getItem('userToken');
  }

  public updateUserAccessDetails(userProfile){
    localStorage.setItem('user', JSON.stringify(userProfile));
    localStorage.setItem('userName', userProfile.firstName + " "+userProfile.lastName);
  }

  public getUserToken() {
    return localStorage.getItem('userToken');
  }

  public getAgentCode() {
    return localStorage.getItem('agentCode');
  }

  public getUserData() {
    return JSON.parse(localStorage.getItem('userData'));
  }

  public getUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  public getLoggedUserName() {
    return localStorage.getItem('userName');
  }

  public getUserRoles() {
    return JSON.stringify(localStorage.getItem('roles'));
  }

  public getUserStorePermissions() {
    return JSON.stringify(localStorage.getItem('storeProfilePermissions'));
  }

  public getUserStoreNumberProfiles() {
    return JSON.stringify(localStorage.getItem('storeNumberProfilesList'));
  }

  public getUserStoreNumber() {
    return localStorage.getItem('storeNumber');
  }

  public getUserStoreName() {
    return localStorage.getItem('storeName');
  }

  public setUserStoreNumber(storeNumber) {
    return localStorage.setItem('storeNumber', storeNumber);
  }

  public getAllUserStoreList() {
    let userStoreProfiles = [];
    let count = 0;
    let storeProfiles = JSON.parse(JSON.stringify(localStorage.getItem('storeNumberProfilesList')));
    var storesObj = JSON.parse(storeProfiles);
    Object.entries(storesObj).forEach(([k,v]) =>{
      userStoreProfiles[count]= v;
      count++;
    });
    //console.log(JSON.stringify(userStoreProfiles));
    return userStoreProfiles;
  }

  public getUserStoreNumberProfilesByNumber(storeNumber) {
    let store = null;
    let storeProfiles = JSON.parse(JSON.stringify(localStorage.getItem('storeNumberProfilesList')));
    var storeObj = JSON.parse(storeProfiles);
    store = storeObj[storeNumber];
    //console.log(store);
    return store;
  }

  public getUserStoreTerminalByNumber(storeNumber) {
    let userTerminals = [];
    let storeTerminals = JSON.parse(JSON.stringify(localStorage.getItem('storeProfileTerminals')));
    var terminalObj = JSON.parse(storeTerminals);
    userTerminals = terminalObj[storeNumber];
    //console.log(userTerminals);
    return userTerminals;
  }

  public getUserStoreDefaultCurrency(storeNumber) {
    let store = null;
    let storeProfiles = JSON.parse(JSON.stringify(localStorage.getItem('storeNumberProfilesList')));
    var storeObj = JSON.parse(storeProfiles);
    store = storeObj[storeNumber];
    //console.log(store);
    return store.storeDefaultCurrency;
  }

  public hasProfilePermission(perms) {
    //console.log('checking permission: '+perms);
    let isPermitted = false;
    let storePermissions = JSON.parse(JSON.stringify(localStorage.getItem('storeProfilePermissions')));
    //console.log('storePerms', storePermissions);
    let storeNumber = localStorage.getItem('storeNumber');
    //console.log(storeNumber);
    storePermissions = JSON.parse(storePermissions);
    //console.log(storePermissions);
    if (storePermissions) {
      Object.entries(storePermissions).forEach(([k,v]) =>{
        // console.log("key", k);
        // console.log("perms", v);
        let permRecs: any = v;
        if(k == storeNumber ){
          //console.log("Permission store: "+ k + " Perms: "+ permRecs);
          if(permRecs.includes(perms)){
            isPermitted = true;
            //console.log("Permission found returning response!");
            return isPermitted;
          }
          
        }
      });
    }
    return isPermitted;
  }

  
  public isStoreUser() {
    const status = localStorage.getItem('isStoreUser');
    //console.log(status);
    if (status) {
      return true;
    }
    return false;
  }
  public isAdminUser() {
    const status = localStorage.getItem('isAdminUser');
    if (status) {
      return true;
    }
    return false;
  }

  public isActiveLogin() {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      return false;
    }
    return true;
  }
  public isLoggedIn() {
    return moment().isBefore(this.getExpiration());
  }
  public validateToken() {
    return moment().isBefore(this.getExpiration());
  }

  public isLoggedOut() {
    return !this.isLoggedIn();
  }



  public logout() {
    
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userToken');
    localStorage.removeItem('user');
    localStorage.removeItem('userName');
    localStorage.removeItem('userData');
    localStorage.removeItem('expiresIn');
    localStorage.removeItem('expiresAt');
    
    const localAdmin = localStorage.getItem('isAdminUser');

    if (localAdmin) {
      //console.log('Logging out admin');
      localStorage.removeItem('isAdminUser');

      //localStorage.setItem('logout', '1');
    
      this.router.navigateByUrl('/admin/login');

      //location.reload();

    } else {
      //console.log('Logging out store user');
      localStorage.removeItem('isStoreUser');
      localStorage.removeItem('isStoreUser');
      localStorage.removeItem('storeList');
      localStorage.removeItem('storeProfilesList');
      localStorage.removeItem('storeProfilePermissions');

      //localStorage.setItem('logout', '1');

      localStorage.clear();
      let options = {text: 'Logged out successfully', title: 'Successfull'};
      this.alertUser.doToastAlert(options, 1);
      this.router.navigate(['/inventory/login']);
    
      //this.router.navigateByUrl('/inventory/login/');
      this.router.navigate(['inventory']);
      //location.reload();
    }
    
    
  }

  public clearLocalStorage() {
    
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userToken');
    localStorage.removeItem('user');
    localStorage.removeItem('userName');
    localStorage.removeItem('userData');
    localStorage.removeItem('expiresIn');
    localStorage.removeItem('expiresAt');
    
    const localAdmin = localStorage.getItem('isAdminUser');

    if (localAdmin) {
      
      localStorage.removeItem('isAdminUser');

      //localStorage.setItem('logout', '1');
    
      this.router.navigateByUrl('/admin/login/');

      //location.reload();

    } else {
      //console.log('Logging out store user');
      localStorage.removeItem('isStoreUser');
      localStorage.removeItem('isStoreUser');
      localStorage.removeItem('storeList');
      localStorage.removeItem('storeProfilesList');
      localStorage.removeItem('storeProfilePermissions');

      localStorage.clear();
    }
    
    
  }


  public logoutApp() {
    //const warningText = 'Logout';
    return swal({
      title: 'Are you sure?',
      text: "Logout",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      //console.log(result);
      if (result.value) {
        this.logout();
      }
    });
  }

  public removeRegToken() {
    localStorage.removeItem('idToken');
    localStorage.removeItem('expiresAt');
  }

  public clearReg() {
    localStorage.clear();
    this.router.navigateByUrl('/home/register');
    location.reload();
  }

  getExpiration() {
    const expiration = localStorage.getItem('expiresAt');
    const expiresAt = JSON.parse(expiration);
    //console.log(expiresAt); 
    return moment(expiresAt);
  }

}
