import { Component, OnInit } from '@angular/core';
import { ClientAppService } from 'src/app/shared/services/clientapp.service';

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.css']
})
export class PagenotfoundComponent implements OnInit {

  public cYear; 
  constructor(private _clientApp: ClientAppService) { }

  ngOnInit(): void {
    this.cYear = this._clientApp.setCopyDate(); 
  }

}
