import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Configuration } from 'src/app/config/configuration';
import { ApiResponseObj } from 'src/app/shared/models/loginApiResponse';
import { ClientAppService } from 'src/app/shared/services/clientapp.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/shared/services/alert.service';
import { FileExportService } from 'src/app/shared/services/file-export.service';

import { DomSanitizer } from '@angular/platform-browser';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';
import { Country } from 'src/app/shared/models/loginModels/country';

@Component({
  selector: 'app-adminstores',
  templateUrl: './adminstores.component.html',
  styleUrls: ['./adminstores.component.css']
})
export class AdminstoresComponent implements OnInit {

  public options = {type: '', title: '', text: ''};

  private termPaths = new Configuration;
  private reqResponseObject = new ApiResponseObj;

  public productSalesAnalytics: any  = {};

  private storeSearch = '';
  private storeSearchType = 'ALL';
  public showSearch = false;
  public isLoading = false;
  public showLoadData = false;
  public showProcessingFile: boolean;
  public isStoreDetails: boolean;

  public totalAmount: number = 0;
  public totalQty = 0;
  public salesProducts;

  public activePage = 1;
  public prevPage = 1;
  public totalPages;
  public totalPageList: any[] = [];
  public currentPage = 1;
  public totalCount;

  public stores: any[];
  public storeProfile: any;
  public storeLogo = "../../../assets/stores/assets/img/demo/demo-logo.svg";

  public perPageCount = 25;
  public totalCurrentPageCount = 25;

  public currency = 'GHS'
  public countryCode = 'GHS'
  public currencyLoader = {
    "NGN": "&#8358;",
    "GHS": "&#8373;" 
   } 

  public processingSalesAnalytics: boolean;
  public primaryColour = "#ffffff";
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public storePaymentOptions: any[] = [];

  public showProcessing: boolean;
  public isUpdating = false;
  public isCustomUpdateOption: boolean = false;

  public countries: Country[];
  public storeCountry: Country;

  public paymentOptionProfileForm: FormGroup;
  public storeProfileStatusForm: FormGroup;
  public storeProfileBranchForm: FormGroup;
  public storeProfileKYCForm: FormGroup;

  public applyFixCharge = true;
  public processSettlement = true;
  public enableTenderedAmount = true;
  public enableOption = true;
  public enableWebOption = true;
  public enableAppOption = true;
  public enableBranchOverInternet: boolean = false;
  public enableBranch: boolean = false;
  public branchEdit: boolean;
  public enablePosOption: boolean = false;

  public loadingStoreProfile: boolean = false;

  public branches = [];
  private branchSearch='';
  public storeKycs = [];
  public kyc: any;
  public searchTimer: any;


  public disablePayFields = false;

  constructor(private _clientApp: ClientAppService, 
    public auth: AuthService,
    private fb: FormBuilder,
    private alert: AlertService,
    private fileExport: FileExportService,
    private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {

    this.disablePayFields = true;
    this.storeProfileStatusForm = this.fb.group({
      storeStatus: ['ACTIVE', Validators.required],
      allowPosPayment: this.enablePosOption
    });

    this.storeProfileBranchForm = this.fb.group({
      active: ['false', Validators.required],
      totalLength: ['15', Validators.required],
      activeOverInternet: ['true', Validators.required]
    });

    this.storeProfileKYCForm = this.fb.group({
      status: ['', Validators.required],
      kycNumber: ['', Validators.required],
      reviewInfo: ['', Validators.maxLength(150)]
    });
    
    this.paymentOptionProfileForm = this.fb.group({
      name: [{ value: '', disabled: this.disablePayFields}, Validators.required],
      viewName: [{ value: '', disabled: this.disablePayFields}, Validators.required],
      countryCode: [this.countryCode],
      status: ['ACTIVE', Validators.required],
      description: [''],
      applyFixCharge: [this.applyFixCharge],
      fixCharge: ['0.00'],
      processSettlement: [this.processSettlement],
      allowOption: ['true'],
      allowOnWeb: ['true'],
      allowOnApp: ['false'],
      allowTenderedAmount: ['true'],
      paymentOptionId: [''],
      paymentOptionNumber: [''],
      paymentOptionFeeMode: ['PERCENTAGE', Validators.required],
      paymentOptionFee: [''],
      internationalPaymentOptionMode: ['PERCENTAGE'],
      internationalPaymentOptionFee: [''],
      paymentOptionCapStatus: this.fb.group({
        cappedAmount: [''],
        cappedType: ['UNCAPPED']
      })
    });

    this.loadProductSalesAnalytics();
    this.loadRegisteredStores();
    //this._clientApp.updateStoreUrlScripts();
    this.loadCountries();

  }

  loadProductSalesAnalytics(){
    this.options = {type: '', title: '', text: ''};

    let url = this.termPaths._adminBaseUrl+"productsales/analytics";
    
    this.processingSalesAnalytics = true;

    this._clientApp.get(url).subscribe(res =>{
      //console.log(res);
      this.processingSalesAnalytics = false;
      this.reqResponseObject = this.auth.mapHttpResponse(res);
      
      if(this.reqResponseObject.statusCode == "00"){
        this.productSalesAnalytics = this.reqResponseObject.data;
      }
    },  err => {
      
      this.options.type = 'error';
      this.options.text = err.error.errorMessage;
      
      this.processingSalesAnalytics = false;
      this.alert.doToastAlert(this.options, 4);
    });

  }

  loadCountries(){
    let url = this.termPaths._adminBaseUrl+"system/loadcountry"
    this._clientApp.get(url)
    .subscribe((res) => {
         const statusCode = res.body['statusCode'];
         if(statusCode == "00"){
           this.countries = res.body["data"];
           this._clientApp.updateStoreUrlScripts();
         }
    });
  }

  loadRegisteredStores(){
    this.isLoading = !this.isLoading;
    
    let url = this.termPaths._adminBaseUrl+"stores?type="+this.storeSearchType+"&search="+this.storeSearch
    +"&page="+this.currentPage+"&size="+this.perPageCount;
    //console.log('store url', url);
    this._clientApp.get(url).subscribe(res =>{
      //console.log(res);
      this.isLoading = !this.isLoading;
      this.stores = res.body['data']['payload'];
      
      this.reqResponseObject = this.auth.mapHttpResponse(res);
      
      if(this.reqResponseObject){
        
        this.totalCount = this.reqResponseObject.data.totalCount;
        this.totalPages = this.reqResponseObject.data.totalPages;
        this.setPaginationList(this.totalPages);
        this.currentPage = this.reqResponseObject.data.page;
        //this.stores = this.reqResponseObject.data.payload;
        //console.log(this.stores);
        
        if (this.currentPage >= 1) {
          if (this.totalCurrentPageCount > this.totalCount) {
            this.totalCurrentPageCount = this.totalCount;
          }else{
            this.totalCurrentPageCount = this.perPageCount * this.currentPage;
            if (this.totalCurrentPageCount >= this.totalCount) {
              this.totalCurrentPageCount = this.totalCount;
            }
          }
        }

        this._clientApp.updateStoreUrlScripts();
        
      }
    })
  }

  searchStores(txt){

    if(txt !== this.storeSearch){

      clearTimeout(this.searchTimer);

      this.searchTimer = setTimeout(() => {

        this.storeSearch = txt;
        this.showSearch = true;
        this.currentPage = 1;

        let url = this.termPaths._adminBaseUrl+"stores?type="+this.storeSearchType+"&search="
        +this.storeSearch+"&page="+this.currentPage+"&size="+this.perPageCount;

        this._clientApp.get(url).subscribe(res=>{

        this.stores = res.body['data']['payload'];
        this.reqResponseObject = this.auth.mapHttpResponse(res);
        this.showSearch = false;
        if(this.reqResponseObject){
            //console.log("products search paginated: ",this.reqResponseObject.data);
            this.totalCount = this.reqResponseObject.data.totalCount;
            this.totalPages = this.reqResponseObject.data.totalPages;
            this.setPaginationList(this.totalPages);
            this.currentPage = this.reqResponseObject.data.page;
            //this.stores = this.reqResponseObject.data.payload;

            if (this.currentPage >= 1) {
              if (this.totalCurrentPageCount > this.totalCount) {
                this.totalCurrentPageCount = this.totalCount;
              }else{
                this.totalCurrentPageCount = this.perPageCount * this.currentPage;
                if (this.totalCurrentPageCount >= this.totalCount) {
                  this.totalCurrentPageCount = this.totalCount;
                }
              }
            }

            this._clientApp.updateStoreUrlScripts();
            this.changeDetector.detectChanges();

          }
        });

        
      }, 1000);
      

    }

  }

  viewStoreDetails(store){

    this.loadingStoreProfile = !this.loadingStoreProfile;

    this.storePaymentOptions = store.storePaymentOptions;
    this.isStoreDetails = true;
    this.storeProfile = store;
    //console.log(this.storeProfile);
    this.storeLogo = this.storeProfile.logo;
    if (!this.storeLogo) {
      this.storeLogo = "../../../assets/stores/assets/img/demo/demo-logo.svg";
    }
    this.currentPage = 1;
    this.perPageCount = 20;

    this.enablePosOption = this.storeProfile.allowPosPayment;
    
    //console.log(this.storeProfile.storeBranchConfig);

    if(this.storeProfile.storeBranchConfig){
      this.enableBranchOverInternet = this.storeProfile.storeBranchConfig.activeOverInternet;
      this.enableBranch = this.storeProfile.storeBranchConfig.active;
      this.storeProfileBranchForm.patchValue({totalLength: this.storeProfile.storeBranchConfig.totalLength });
      this.loadStoreBranches();
    }
    
    Object.entries(this.countries).forEach(([p,j]) =>{
      if (j.countryCode == this.storeProfile.country.countryCode) {
        this.storeCountry = j;
      }
    });
    this.loadStoreKYC();
    this._clientApp.updateStoreUrlScripts();

    this.loadingStoreProfile = !this.loadingStoreProfile;
  }

  loadStoreKYC() {
    this.storeKycs = [];
    this.isLoading = !this.isLoading;
    
    let url = this.termPaths._adminBaseUrl+"store/kyc/"
      +this.storeProfile.storeNumber;
    this._clientApp.get(url).subscribe((res) => {
      this.reqResponseObject = this.auth.mapHttpResponse(res);

      if (this.reqResponseObject) {
        
        let model =  this.reqResponseObject.data;
        
        for (let fi of Object.keys(model)) {
          this.storeKycs.push(model[fi]);
        }

      }
    });
  }

  updateStoreKyc(kyc:any){
    this.kyc = kyc;
    //console.log(this.kyc);
    this.storeProfileKYCForm.patchValue({kycNumber: this.kyc.number });
  }

  configureStoreKYCStatus(submittedValue){

    let kycConfig = submittedValue;
    
    let reqBody = JSON.stringify(kycConfig);
    //console.log(reqBody);
    
    let url = this.termPaths._adminBaseUrl+"store/kyc/"+this.storeProfile.storeNumber +"/updateKyc";

    let type = "post";

    this.showProcessing = !this.showProcessing;

    this._clientApp.processRequestMapped(url, type, reqBody, true)
    .subscribe((res) => {

    this.reqResponseObject = this.auth.mapHttpResponse(res);
    const statusCode = this.reqResponseObject.statusCode;
    const errorMsg = this.reqResponseObject.errorMessage;

    const statusMsg = this.reqResponseObject.statusMessage;
    this.showProcessing = !this.showProcessing;

      if(statusCode == '00'){

        this.options.text = statusMsg;
        this.options.title = 'Successful';
        this.alert.doToastAlert(this.options, 1);
        
        this.storeProfile = this.reqResponseObject.data;

        $(".closeKycStatus").click();

        this.loadStoreKYC();
        this.storeProfileKYCForm.reset();
        
        this._clientApp.updateStoreUrlScripts();
        
      }else{

        this.options.type = 'error';
        this.options.text = errorMsg;
        this.alert.doToastAlert(this.options, 4);
      }

    },  err => {
      
      this.showProcessing = !this.showProcessing;
      this.options.type = 'error';
      this.options.text = err.error.errorMessage;
      
      this.alert.doToastAlert(this.options, 4);
      
      
    });

  }

  viewStores(){

    this.currentPage = 1;
    this.perPageCount = 20;

    this.isStoreDetails = false;
    this.storeLogo = "../../../assets/stores/assets/img/demo/demo-logo.svg";
    this._clientApp.updateStoreUrlScripts();
  }

  loadStoreBranches(){
    this.isLoading = !this.isLoading;
    let url = this.termPaths._adminBaseUrl+"store/"
      +this.storeProfile.storeNumber+"/loadbranches?search="
      +this.branchSearch+"&page="+this.currentPage+"&size="+this.perPageCount;

    this._clientApp.get(url).subscribe(res=>{
      
      this.isLoading = !this.isLoading;
      
      this.reqResponseObject = this.auth.mapHttpResponse(res);
      
      if(this.reqResponseObject){
        //console.log("branches paginated: ",this.reqResponseObject.data);
        this.totalCount = this.reqResponseObject.data.totalCount;
        this.totalPages = this.reqResponseObject.data.totalPages;
        this.setPaginationList(this.totalPages);
        this.currentPage = this.reqResponseObject.data.page;
        this.branches = this.reqResponseObject.data.payload;

        if (this.currentPage >= 1) {
          if (this.totalCurrentPageCount > this.totalCount) {
            this.totalCurrentPageCount = this.totalCount;

          }else{

            this.totalCurrentPageCount = this.perPageCount * this.currentPage;
            if (this.totalCurrentPageCount >= this.totalCount) {
              this.totalCurrentPageCount = this.totalCount;
            }
          }
        }

        this._clientApp.updateStoreUrlScripts();
        this.changeDetector.detectChanges();
      }
    })

  }

  searchBranch(txt){
   
    if(txt !== this.branchSearch){

      this.branchSearch = txt;
      this.showSearch = true;
      this.currentPage = 1;

      let url = this.termPaths._adminBaseUrl+"store/"
      +this.storeProfile.number+"/loadbranches?search="
      +this.branchSearch+"&page="+this.currentPage+"&size="+this.perPageCount;

    this._clientApp.get(url).subscribe(res=>{
      this.reqResponseObject = this.auth.mapHttpResponse(res);
      this.showSearch = false;
      if(this.reqResponseObject){
        //console.log("branch search paginated: ",this.reqResponseObject.data);
        this.totalCount = this.reqResponseObject.data.totalCount;
        this.totalPages = this.reqResponseObject.data.totalPages;
        this.setPaginationList(this.totalPages);
        this.currentPage = this.reqResponseObject.data.page;
        this.branches = this.reqResponseObject.data.payload;

        this._clientApp.updateStoreUrlScripts();
        this.changeDetector.detectChanges();

      }

    })

    }
    
  }

  profileCountryPayOption(payOption){

    // this.applyFixCharge = true;
    // this.processSettlement = true;
    // this.enableTenderedAmount = true;
    // this.enableOption = true;
    // this.enableWebOption = true;
    // this.enableAppOption = false;

    this.countryCode = this.storeProfile.country.countryCode;
    this.isCustomUpdateOption = false;
    this.disablePayFields = true;
    this.paymentOptionProfileForm.patchValue(payOption);
    this.applyFixCharge = payOption.applyFixCharge;
    this.processSettlement = payOption.processSettlement;
    this.enableTenderedAmount = payOption.allowTenderedAmount;
    this.enableOption = payOption.allowOption;
    this.enableWebOption = payOption.allowOnWeb;
    this.paymentOptionProfileForm.patchValue({paymentOptionId: payOption.id});
    this.paymentOptionProfileForm.patchValue({countryCode: this.countryCode});
  }

  editCountryPayOption(payOption){
    //console.log('Edit store', payOption);
    this.isCustomUpdateOption = true;
    this.countryCode = this.storeProfile.country.countryCode;
    this.disablePayFields = true;
    this.paymentOptionProfileForm.patchValue(payOption);
    this.applyFixCharge = payOption.applyFixCharge;
    this.processSettlement = payOption.processSettlement;
    this.enableTenderedAmount = payOption.allowTenderedAmount;
    this.enableOption = payOption.allowOption;
    this.enableWebOption = payOption.allowOnWeb;
    this.enableAppOption = payOption.allowOnApp;
    this.paymentOptionProfileForm.patchValue({paymentOptionId: payOption.channelOptionId});
    this.paymentOptionProfileForm.patchValue({paymentOptionNumber: payOption.channelOptionNumber});
    this.paymentOptionProfileForm.patchValue({countryCode: this.countryCode});
  }

  updatePaymentOptionProfile(submittedValue: any){
    //console.log(submittedValue);
    submittedValue.applyFixCharge = this.applyFixCharge;
    submittedValue.processSettlement = this.processSettlement;
    submittedValue.allowOption = this.enableOption;
    submittedValue.allowOnWeb = this.enableWebOption;
    submittedValue.allowOnApp = this.enableAppOption;

    if (((submittedValue.paymentOptionFeeMode == 'FIXED' || submittedValue.paymentOptionFeeMode == 'PERCENTAGE') && 
      (!submittedValue.paymentOptionFee || submittedValue.paymentOptionFee < 0 || submittedValue.paymentOptionFee == 0) )) {
        this.options.text = "Payment option fee amount must be supplied!";
        this.options.title = 'Warning';
        this.alert.doToastAlert(this.options, 3);
        return;
    }

    if (this.applyFixCharge && (!submittedValue.fixCharge || 
      (submittedValue.fixCharge < 0 ||submittedValue.fixCharge == 0) )) {
        this.options.text = "Fix charge amount must be supplied";
        this.options.title = 'Warning';
        this.alert.doToastAlert(this.options, 3);
        return;
    }

    if ((submittedValue.paymentOptionCapStatus.cappedType == 'CAPPED') && 
      (!submittedValue.paymentOptionCapStatus.cappedAmount || 
        submittedValue.paymentOptionCapStatus.cappedAmount < 0 || 
        submittedValue.paymentOptionCapStatus.cappedAmount == 0) ) {

        this.options.text = "Capped amount must be provided when set fee charge per transaction as capped";
        this.options.title = 'Warning';
        this.alert.doToastAlert(this.options, 3);
        return;
    }

    //console.log(submittedValue);
    let reqBody = JSON.stringify(submittedValue);

    let url = this.termPaths._adminBaseUrl+"addpaymentoption/store/"+this.storeProfile.storeNumber;

    let type = "post";

    if (this.isCustomUpdateOption) {
      url = this.termPaths._adminBaseUrl+"addpaymentoption/store/"+this.storeProfile.storeNumber;
      type = "put";
    }
    this.showProcessing = !this.showProcessing;

    this._clientApp.processRequestMapped(url, type, reqBody, true)
    .subscribe((res) => {

    this.reqResponseObject = this.auth.mapHttpResponse(res);
    const statusCode = this.reqResponseObject.statusCode;
    const errorMsg = this.reqResponseObject.errorMessage;

    const statusMsg = this.reqResponseObject.statusMessage;
    this.showProcessing = !this.showProcessing;

      if(statusCode == '00'){

        this.options.text = statusMsg;
        this.options.title = 'Successful';
        this.alert.doToastAlert(this.options, 1);
        
        this.paymentOptionProfileForm.reset();

        this.applyFixCharge = true;
        this.processSettlement = true;
        this.enableTenderedAmount = true;
        this.enableOption = true;
        this.enableWebOption = true;
        this.enableAppOption = false;
        this.disablePayFields = false;

        $(".closePayOption").click();

        this.loadRegisteredStores();
        
        this._clientApp.updateStoreUrlScripts();
        
      }else{

        this.options.type = 'error';
        this.options.text = errorMsg;
        this.alert.doToastAlert(this.options, 4);
      }

    },  err => {
      
      this.showProcessing = !this.showProcessing;
      this.options.type = 'error';
      this.options.text = err.error.errorMessage;
      
      this.alert.doToastAlert(this.options, 4);
      
      
    });
    
  }

  configureBranchStoreStatus(submittedValue){

    let branchConfig = submittedValue;
    branchConfig.active = this.enableBranch;
    branchConfig.activeOverInternet = this.enableBranchOverInternet;

    if (this.branchEdit) {
      branchConfig.totalLength = this.storeProfile.storeBranchConfig.totalLength;
    }

    let reqBody = JSON.stringify(branchConfig);
    
    let url = this.termPaths._adminBaseUrl+"store/"+this.storeProfile.storeNumber +"/branchconfig";

    let type = "post";

    this.showProcessing = !this.showProcessing;

    this._clientApp.processRequestMapped(url, type, reqBody, true)
    .subscribe((res) => {

    this.reqResponseObject = this.auth.mapHttpResponse(res);
    const statusCode = this.reqResponseObject.statusCode;
    const errorMsg = this.reqResponseObject.errorMessage;

    const statusMsg = this.reqResponseObject.statusMessage;
    this.showProcessing = !this.showProcessing;

      if(statusCode == '00'){

        this.options.text = statusMsg;
        this.options.title = 'Successful';
        this.alert.doToastAlert(this.options, 1);
        
        this.storeProfile = this.reqResponseObject.data;

        $(".closeStatus").click();

        this.loadRegisteredStores();
        
        this._clientApp.updateStoreUrlScripts();
        
      }else{

        this.options.type = 'error';
        this.options.text = errorMsg;
        this.alert.doToastAlert(this.options, 4);
      }

    },  err => {
      
      this.showProcessing = !this.showProcessing;
      this.options.type = 'error';
      this.options.text = err.error.errorMessage;
      
      this.alert.doToastAlert(this.options, 4);
      
      
    });

  }

  switchStoreStatus(submittedValue){

    //console.log(submittedValue);
    let status = submittedValue.storeStatus;
    let reqBody = JSON.stringify(submittedValue);

    let url = this.termPaths._adminBaseUrl+"store/"+this.storeProfile.storeNumber +"/updatestatus/"+status+"?pos="+this.enablePosOption;

    let type = "put";

    this.showProcessing = !this.showProcessing;

    this._clientApp.processRequestMapped(url, type, reqBody, true)
    .subscribe((res) => {

    this.reqResponseObject = this.auth.mapHttpResponse(res);
    const statusCode = this.reqResponseObject.statusCode;
    const errorMsg = this.reqResponseObject.errorMessage;

    const statusMsg = this.reqResponseObject.statusMessage;
    this.showProcessing = !this.showProcessing;

      if(statusCode == '00'){

        this.options.text = statusMsg;
        this.options.title = 'Successful';
        this.alert.doToastAlert(this.options, 1);
        
        this.storeProfile = this.reqResponseObject.data;

        $(".closeStatus").click();

        this.loadRegisteredStores();
        
        this._clientApp.updateStoreUrlScripts();
        
      }else{

        this.options.type = 'error';
        this.options.text = errorMsg;
        this.alert.doToastAlert(this.options, 4);
      }

    },  err => {
      
      this.showProcessing = !this.showProcessing;
      this.options.type = 'error';
      this.options.text = err.error.errorMessage;
      
      this.alert.doToastAlert(this.options, 4);
      
      
    });
  }

  setApplyFixCharge($event){
    let target = $event.target.value;
    //console.log('Apply fix charge value: ', target);
    this.applyFixCharge = !this.applyFixCharge; 
  }

  allowPosPayment($event){
    this.enablePosOption = !this.enablePosOption;
  }

  setEnableBranchOverInternet($event){
    this.enableBranchOverInternet = !this.enableBranchOverInternet;
  }

  setEnableBranch($event){
    this.enableBranch = !this.enableBranch;
  }

  setBranchEditToFalse(){
    this.branchEdit = false;
  }

  setBranchEditToTrue(){
    this.branchEdit = true;
  }

  setProcessSettlemet($event){
    let target = $event.target.value;
    this.processSettlement = !this.processSettlement;
  }

  setTenderedAmount($event){
    let target = $event.target.value;
    //console.log('Apply fix charge value: ', target);
    this.enableTenderedAmount = !this.enableTenderedAmount;
    
  }

  setEnableOption($event){
    let target = $event.target.value;
    //console.log('Apply fix charge value: ', target);
    this.enableOption = !this.enableOption;  
  }

  setEnableOptionOnWeb($event){
    let target = $event.target.value;
    //console.log('Apply fix charge value: ', target);
    this.enableWebOption = !this.enableWebOption;  
  }

  setEnableOptionOnApp($event){
    let target = $event.target.value;
    //console.log('Apply fix charge value: ', target);
    this.enableAppOption = !this.enableAppOption;
  }

  updateStoreSearchType($event){
    //console.log($event);
    this.storeSearchType = $event.target.value;
    this.loadRegisteredStores();
  }


  setPaginationList(totalPages){
    //console.log('Total transactions: ', totalPages);
    this.totalPageList =  [];
    if(totalPages < 10){
    
      for (let i = 0; i < totalPages; i++) {
        this.totalPageList[i] = {key: i, value: (i+1).toString()};
      }

    }else{

      let firstCount = 5;
      let lastCount = totalPages-firstCount;
      let elipsCountDown = firstCount+2;
      let elipsCountUp = lastCount-2;

      // if ((lastCount-firstCount) < 4) {
      //   elipsCountDown = firstCount;
      //   elipsCountUp = elipsCountDown+1
      // }

      let index = 0;

      for (let i = 0; i < firstCount; i++) {
        this.totalPageList[index] = {key: i, value: (i+1).toString()};
        index++;
      }

      for (let k = firstCount; k < elipsCountDown; k++) {
        this.totalPageList[index] = {key: k+"d", value: "."};
        //lastCount++;
        index++;
      }
      for (let k = elipsCountUp; k < lastCount; k++) {
        this.totalPageList[index] = {key: k+"u", value: "."};
        //lastCount ++;
        index++;
      }
      for (let k = lastCount; k < totalPages; k++) {
        this.totalPageList[index] = {key: k, value: k};
        //lastCount ++;
        index++;
      }
    }
    //console.log(this.totalPageList);
  }

  paginate(page){
    page = String(page);
    if (page.indexOf('d') !== -1) {
      page = page.substr(0, page.indexOf('d'));
    }

    if (page.indexOf('u') !== -1) {
      page = page.substr(0, page.indexOf('u'));
    }
    this.currentPage = parseInt(page) + 1;

    this.loadRegisteredStores();
  }

  changePerPage($event){
    let pageCount = $event.value;
    //console.log(pageCount);
    this.perPageCount = parseInt(pageCount);
    this.loadRegisteredStores();
  }

  paginateBack(){
    //console.log(this.currentPage);
    if(this.currentPage > 1){
      --this.currentPage;
      this.loadRegisteredStores();
    }
  }

  
  paginateForward(){
    //console.log(this.currentPage);
    if(this.currentPage < this.totalPages){
      this.currentPage++;
      this.loadRegisteredStores();
    }
  }

  get f(){
    return this.paymentOptionProfileForm.controls;
  }

  get s(){
    return this.storeProfileStatusForm.controls;
  }

  get b(){
    return this.storeProfileBranchForm.controls;
  }

  get k(){
    return this.storeProfileKYCForm.controls;
  }

}
