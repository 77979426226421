import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, 
  HttpHandler, HttpEvent, 
  HttpErrorResponse, HttpResponse } from '../../../../node_modules/@angular/common/http';
import { Observable } from '../../../../node_modules/rxjs';
import { AuthService } from './auth.service';

import { tap, map, filter, scan, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private injector: Injector) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const authService = this.injector.get(AuthService);
    let idToken = this.authService.getUserToken();
    //console.log(idToken);
    if (idToken) {
      const cloneReq = req.clone({
        setHeaders: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + idToken
        }
      });
      
      return next.handle(cloneReq)
      .pipe(
        map((event: HttpEvent<any>) => {
            if (event instanceof HttpErrorResponse) {
                console.log('event--->>>', event);
                console.log('Error status: '+event.status)
              if(event.status == 400){
                console.log('Error response from interceptor');
              }
            }
            return event;
        }));


    } else {
      return next.handle(req);
    }
  }
}
