<div class="content">
    <div class="container">
      <div class="row">
        <div class="col-md-6 order-md-2">
          <img src="../../../../assets/landing/images/undraw_remotely_2j6y.svg" alt="Image" class="img-fluid">
        </div>
        <div class="col-md-6 contents">
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div class="mb-4 text-center">
                <img style="width: 20%; height: 20%;" src="../../../../assets/stores/assets/img/bluepen1.png"/>
                <h3>myShopKeeper</h3> 
              </div>
              <div class="mb-4">
                <h3 class="text-center"> <strong>Sales & Inventory Management System</strong></h3>
                <p class="mb-4">Data Retention and Account Deletion request process</p>
              </div>
              <div class="mb-4">
                <h3 class="text-lef"> <strong>Data Retention</strong></h3>
                <p class="mb-4">We treat your personal information as private and confidential.</p>
                <p class="mb-4">We are dedicated to protecting your privacy and providing you with the highest level of security at any point of interaction with us.</p>
                <p class="mb-4">Unless official requested, all personal information and transaction activities are retained through out the live-span of our business engagement.</p>
              </div>
              <div class="mb-4">
                <h3 class="text-lef"> <strong>Account Deletion Process</strong></h3>
                <p class="mb-4">Should you as a partner decides to delete your account, the process follows as below.</p>
                <p class="mb-4">1. Send an official email to info@bluepenguin.com, with subject Account deletion. </p>
                <p class="mb-4">2. The content of the email above must clearly states; a) Account username b) Contact Phone no c) Reason for deletion and date.</p>
                <p class="mb-4">3. Our business engagement team will reach out to you to validate your request.</p>
                <p class="mb-4">4. After proper validation and approve confirmation from you, the account will be deleted/deactivated i.e. become unaccessible across all channels; web and mobile app.</p>
                <p class="mb-4"><b>NOTE:</b> Upon deletion above, transactional activities/records data will be retained for a period of 5 years.</p>
              </div>
              <div class="mb-4">
                <a routerLink="/inventory/login"><span class="d-block text-left my-4 text-muted"> return to login</span></a>
              </div>
            </div>
          </div>
          
        </div>
        
      </div>
    </div>
</div>


  <script src="../../../../assets/landing/js/jquery-3.3.1.min.js"></script>
  <script src="../../../../assets/landing/js/popper.min.js"></script>
  <script src="../../../../assets/landing/js/bootstrap.min.js"></script>
  <script src="../../../../assets/landing/js/main.js"></script>