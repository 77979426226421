import { Component, OnInit } from '@angular/core';
import { Configuration } from 'src/app/config/configuration';
import { ApiResponseObj } from 'src/app/shared/models/loginApiResponse';
import { FormGroup, Form, FormBuilder, Validators } from '@angular/forms';
import { ClientAppService } from 'src/app/shared/services/clientapp.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-adminuserprofile',
  templateUrl: './adminuserprofile.component.html',
  styleUrls: ['./adminuserprofile.component.css']
})
export class AdminuserprofileComponent implements OnInit {

  private termPaths = new Configuration;
  private reqResponseObject = new ApiResponseObj;
  public showProcessing: boolean;

  public options = {type: '', title: '', text: ''};

  public afuConfig = {};
  public userProfileForm: FormGroup;

  public uploadingFile = false;
  public isUploadDetails = false;
  public isUpdating = false;
  public userProfile;

  constructor(private _clientApp: ClientAppService, public auth: AuthService, 
    private alert: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder) { }

  ngOnInit(): void {

    this.userProfile = this.auth.getUser();
    //console.log(this.userProfile);

    this.userProfileForm = this.fb.group({
      username: [{ value: this.userProfile.username, disabled: true}, Validators.required],
      firstName: [this.userProfile.firstName, Validators.required],
      lastName: [this.userProfile.lastName, Validators.required],
      emailAddress: [this.userProfile.emailAddress, [Validators.email]],
      phoneNumber: [this.userProfile.phoneNumber]
    });

    this.enableProfileImageUpload();
    //this._clientApp.updateStoreUrlScripts();

  }

  updateProfile(submittedValue: any) {
    this.showProcessing = true;
    this.isUpdating = !this.isUpdating;
    
    submittedValue.id = this.userProfile.id;

    let reqBody = JSON.stringify(submittedValue);
    
    let url = this.termPaths._adminBaseUrl+ "system/userprofile/"+this.userProfile.id;

    this._clientApp.put(url, reqBody)
    .subscribe((res) => {

      this.reqResponseObject = this.auth.mapHttpResponse(res);
      const statusCode = this.reqResponseObject.statusCode;
      const errorMsg = this.reqResponseObject.errorMessage;

        const statusMsg = this.reqResponseObject.statusMessage

        this.showProcessing = !this.showProcessing;
        this.isUpdating = !this.isUpdating;

        if(statusCode == '00'){

          this.options.text = statusMsg;
          this.options.title = 'Successful';
          this.auth.updateUserAccessDetails(this.reqResponseObject.data);

          this.alert.doToastAlert(this.options, 1);
          
        }else{

          this.options.type = 'error';
          this.options.text = errorMsg;
          this.alert.doToastAlert(this.options, 4);
        }

    },  err => {
      
      this.options.type = 'error';
      this.options.text = err.error.errorMessage;
      //console.log(this.options.text);
      this.alert.doToastAlert(this.options, 4);
      this.showProcessing = !this.showProcessing;
      this.isUpdating = !this.isUpdating;
      
    });

  }

  enableProfileImageUpload(){
    const user = this.auth.getUser();
    //console.log(user);
    this.userProfile = this.auth.getUser();
    
    let url = this.termPaths._adminBaseUrl+ "system/userprofile/"+user.id +"/updatelogo";
    
    this.afuConfig = {
      multiple: false,
      formatsAllowed: ".jpeg,.jpg,.png",
      maxSize: "1",
      uploadAPI:  {
        url: url,
        method:"POST",
        headers: {
        "Authorization" : `Bearer ${this.auth.getUserToken()}`
        },
        responseType: 'json',
      },
      theme: "attachPin",
      hideProgressBar: true,
      hideResetBtn: true,
      hideSelectBtn: true,
      fileNameIndex: false,
      replaceTexts: {
        selectFileBtn: 'Select Files',
        resetBtn: 'Reset',
        uploadBtn: 'Upload',
        dragNDropBox: 'Drag N Drop',
        attachPinBtn: 'Update profile picture...',
        afterUploadMsg_success: 'Successfully Uploaded !',
        afterUploadMsg_error: 'Upload Failed !',
        sizeLimit: 'Size Limit'
      }
    };

    
  }

  uploadProfileLogoFile($event){
    console.log('Upload response: ', $event);
    
    let code = $event.status;
    //console.log("Upload status: "+code);
    if(code == 500 || code == 400){
      //console.log('Error: ', $event.error)
      this.reqResponseObject = this.auth.mapHttpErrorResponse($event);

    }else{
      this.reqResponseObject = this.auth.mapHttpResponse($event);
    }

    let statusCode = this.reqResponseObject.statusCode;

    if(statusCode == '00'){
      this.options.text = this.reqResponseObject.statusMessage;
      this.options.title = 'Successful';

      this.alert.doToastAlert(this.options, 1);
      //console.log("Upload response data: ", this.reqResponseObject.data);

      if (this.reqResponseObject.data != null) {
        this.isUploadDetails = true;
        this.userProfile = this.reqResponseObject.data;
        this.auth.updateUserAccessDetails(this.reqResponseObject.data);
        location.reload();
        //this.router.navigate(['/admin/dashboard/myprofile'])
      }

    }else{

      this.options.text = this.reqResponseObject.errorMessage;
      this.options.title = 'Failed';

      this.alert.doToastAlert(this.options, 3);

    }

  }

  get f(){
    return this.userProfileForm.controls;
  }

}
