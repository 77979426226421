<header
  class="
    page-header page-header-dark
    bg-gradient-primary-to-secondaryadmin
    pb-10
  "
>
  <div class="container">
    <div class="page-header-content pt-4">
      <div class="row align-items-center justify-content-between">
        <div class="col-auto mt-4">
          <h1 class="page-header-title">
            <div class="page-header-icon"><i data-feather="activity"></i></div>
            Terminal management
          </h1>
          <div class="page-header-subtitle">
            Inventory management system admin terminal managements.
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<!-- Example DataTable for Dashboard Demo-->
<div class="container mt-n10">
  <div *ngIf="!isTerminalSalesDetails" class="card mb-4">
    <div class="card-header">
      POS/Terminals
      <button
        class="btn btn-sm btn-primary float-right"
        (click)="clearTerminal()"
        data-toggle="modal"
        data-target="#exampleModalScrollableR1"
        type="button"
      >
        Add terminal
      </button>
    </div>
    <div class="card-body">
      <span class="notification pull-left" *ngIf="showSearch">
        Searching...
        <img src="../../../../assets/landing/images/ring1.gif" class="smLoader"
      /></span>
      <span class="notification float-left" *ngIf="showProcessing">
        Loading terminals...
        <img src="../../../../assets/landing/images/ring1.gif" class="smLoader"
      /></span>
      <div class="input-group input-group-joined mb-4">
        <input
          class="form-control"
          #searchField
          (keyup)="searchTerminals(searchField.value)"
          type="text"
          placeholder="Search terminal..."
          aria-label="Search"
        />
        <div class="input-group-append">
          <span class="input-group-text"><i data-feather="search"></i></span>
        </div>
      </div>
      <div class="datatable">
        <table
          class="table table-bordered table-hover"
          width="100%"
          cellspacing="0"
        >
          <thead>
            <tr>
              <th>S/N</th>
              <th>Model</th>
              <th>Terminal Id</th>
              <th>Year</th>
              <th>Merchant Code</th>
              <th>Merchant name</th>
              <th>Store Assigned</th>
              <th>Date assigned</th>
              <th>Status</th>
              <th>Assign</th>
              <th>Edit</th>
              <th>View Transactions</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let pos of storeTerminals; let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ pos.modelName }}</td>
              <td>
                <i class="text-green" data-feather="credit-card"></i>
                {{ pos.terminalId }}
              </td>
              <td>{{ pos.modelYear }}</td>
              <td>{{ pos.merchantCode }}</td>
              <td style="word-break: break-word; max-width: 60px">
                {{ pos.merchantName }}
              </td>
              <td>{{ pos.storeName }}</td>
              <td>{{ pos.dateAssignedToStore | date: "short" }}</td>
              <td>
                <div
                  *ngIf="pos.status === 'ACTIVE'"
                  class="badge badge-primary badge-pill"
                >
                  Active
                </div>
                <div
                  *ngIf="pos.status == 'INACTIVE'"
                  class="badge badge-warning badge-pill"
                >
                  Inactive
                </div>
                <div
                  *ngIf="pos.status == 'DEACTIVATED'"
                  class="badge badge-danger badge-pill"
                >
                  Deactivated
                </div>
              </td>
              <td>
                <button
                  class="btn btn-datatable btn-icon btn-transparent-dark mr-2"
                >
                  <i data-feather="more-vertical"></i>
                </button>
                <button
                  class="btn btn-datatable btn-icon btn-transparent-dark"
                  (click)="initAssignPOSToStoreProfile(pos.id)"
                  data-toggle="modal"
                  data-target="#exampleModalScrollableR"
                  type="button"
                >
                  <i class="text-red" data-feather="anchor"></i>
                </button>
              </td>
              <td>
                <button
                  class="btn btn-datatable btn-icon btn-transparent-dark mr-2"
                >
                  <i data-feather="more-vertical"></i>
                </button>
                <button
                  class="btn btn-datatable btn-icon btn-transparent-dark"
                  data-toggle="modal"
                  data-target="#exampleModalScrollableR1"
                  (click)="editTerminalSales(pos)"
                >
                  <i class="text-purple" data-feather="edit"></i>
                </button>
              </td>
              <td>
                <button
                  class="btn btn-datatable btn-icon btn-transparent-dark mr-2"
                >
                  <i data-feather="more-vertical"></i>
                </button>
                <button
                  class="btn btn-datatable btn-icon btn-transparent-dark"
                  (click)="viewTerminalSales(pos.terminalId)"
                >
                  <i class="text-purple" data-feather="eye"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        *ngIf="storeTerminals?.length > 0; else noTerminals"
        class="clearfix"
      >
        <div class="hint-text">
          Showing <b>{{ totalCurrentPageCount }}</b> out of
          <b>{{ totalCount }}</b> entries @
        </div>
        <div class="pg-text">
          <mat-select
            class="pg-width"
            [(value)]="perPageCount"
            (selectionChange)="changePerPage($event)"
          >
            <mat-option [value]="10">10</mat-option>
            <mat-option [value]="15">15</mat-option>
            <mat-option [value]="25">25</mat-option>
            <mat-option [value]="50">50</mat-option>
            <mat-option [value]="75">75</mat-option>
            <mat-option [value]="100">100</mat-option>
          </mat-select>
        </div>
        <ul class="pagination">
          <li class="page-item">
            <a (click)="paginateBack()" class="page-link mover"> << </a>
          </li>
          <li
            *ngFor="let pg of totalPageList; let i = index"
            class="page-item"
            [ngClass]="{ active: currentPage == pg.value }"
          >
            <a (click)="paginate(i)" class="page-link">{{ pg.value }}</a>
          </li>
          <li class="page-item">
            <a (click)="paginateForward()" class="page-link mover"> >> </a>
          </li>
        </ul>
      </div>

      <ng-template #noTerminals>
        <p>No terminal record found!</p>
      </ng-template>
    </div>
  </div>

  <div *ngIf="isTerminalSalesDetails" class="card mb-4">
    <div class="card-header">
      Terminal transaction sales
      <button
        class="btn btn-sm btn-primary float-right"
        (click)="viewTerminalSales(terminalSalesId)"
        type="button"
      >
        &#x2190; Return to terminals
      </button>
    </div>
    <div class="card-body">
      <span class="notification float-left" *ngIf="isLoading">
        Loading sales...
        <img src="../../../../assets/landing/images/ring1.gif" class="smLoader"
      /></span>
      <span class="notification float-left" *ngIf="showSearch">
        Searching...
        <img src="../../../../assets/landing/images/ring1.gif" class="smLoader"
      /></span>
      <span class="notification float-left" *ngIf="showProcessingFile">
        Processing download...
        <img src="../../../../assets/landing/images/ring1.gif" class="smLoader"
      /></span>
      <div>
        <button
          class="btn btn-outline-teal btn-sm btn-icon float-right"
          style="margin: 2px"
          (click)="downloadSalesRecord()"
          type="button"
        >
          <i data-feather="download"></i>
        </button>
      </div>
      <div class="input-group input-group-joined mb-4">
        <input
          class="form-control"
          #searchField
          (keyup)="searchProductSales(searchField.value)"
          type="text"
          placeholder="Search sales..."
          aria-label="Search"
        />
        <div class="input-group-append">
          <span class="input-group-text"><i data-feather="search"></i></span>
        </div>
      </div>
      <div class="datatable">
        <table
          class="table table-bordered table-hover"
          width="100%"
          cellspacing="0"
        >
          <thead>
            <tr>
              <th>S/N</th>
              <th>Date</th>
              <th>Amount</th>
              <th>Channel</th>
              <th>Ref</th>
              <th>Customer Info</th>
              <th>Store name</th>
              <th>Mobile</th>
              <th>Cash</th>
              <th>Balance</th>
              <th>T. Qty</th>
              <th>Status</th>
              <th>View</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let trans of transactions; let i = index">
              <td>{{ (currentPage - 1) * perPageCount + (i + 1) }}</td>
              <td>{{ trans.createdAt | date: "short" }}</td>
              <td>
                <span [innerHTML]="currencyLoader[currency]"></span>
                {{ trans.amount | number: "1.2-2" }}
              </td>
              <td>
                <i
                  *ngIf="trans.paymentChannelType == 'Cash'"
                  class="text-green"
                  data-feather="file-plus"
                ></i>
                <i
                  *ngIf="
                    trans.paymentChannelType == 'Card' ||
                    trans.paymentChannelType == 'Cards'
                  "
                  class="text-green"
                  data-feather="credit-card"
                ></i>
                <i
                  *ngIf="trans.paymentChannelType == 'Mobile Money'"
                  class="text-pink"
                  data-feather="layout"
                ></i>
                {{ trans.paymentChannelType }}
              </td>
              <td>{{ trans.transactionRef?.substring(0, 5) }} ...</td>
              <td>
                <div class="justify-content-start align-items-start">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <span>N : </span>
                    <div
                      class="cursor-pointer btn-link"
                      style="width: 65px; margin-left: 10px; margin-right: 5px"
                    >
                      <span
                        class="d-inline-block text-truncate"
                        style="max-width: 50px"
                        *ngIf="trans.customer && trans.customer.customerName"
                        >{{ trans.customer.customerName }}</span
                      >
                      <span
                        *ngIf="!(trans.customer && trans.customer.customerName)"
                        >n/a</span
                      >
                    </div>
                    <svg
                      width="10"
                      height="10"
                      (click)="
                        auth.handleCopy($event, trans.customer.customerName)
                      "
                      viewBox="0 0 9 10"
                      fill="none"
                      color="#0DB450"
                      class="cursor-pointer"
                    >
                      <path
                        d="M5.293 10h-3.73C.7 10 0 9.3 0 8.437V3.146c0-.862.7-1.563 1.563-1.563h3.73c.862 0 1.562.7 1.562 1.563v5.293c0 .861-.7 1.562-1.562 1.562zm-3.73-7.637c-.431 0-.782.35-.782.782v5.293c0 .43.35.78.781.78h3.731c.43 0 .781-.35.781-.78V3.144c0-.431-.35-.782-.781-.782h-3.73zm6.855 5.098V1.562C8.418.702 7.718 0 6.855 0H2.52a.39.39 0 100 .781h4.335c.431 0 .782.35.782.781v5.899a.39.39 0 10.781 0z"
                        fill="#ccc"
                      ></path>
                    </svg>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <span>E : </span>
                    <div
                      class="cursor-pointer btn-link"
                      style="width: 65px; margin-left: 10px; margin-right: 5px"
                    >
                      <span
                        class="d-inline-block text-truncate"
                        style="max-width: 50px"
                        *ngIf="trans.customer && trans.customer.customerEmail"
                        >{{ trans.customer.customerEmail }}</span
                      >
                      <span
                        *ngIf="
                          !(trans.customer && trans.customer.customerEmail)
                        "
                        >n/a</span
                      >
                    </div>
                    <svg
                      width="10"
                      height="10"
                      (click)="
                        auth.handleCopy($event, trans.customer.customerEmail)
                      "
                      viewBox="0 0 9 10"
                      fill="none"
                      color="#0DB450"
                      class="cursor-pointer"
                    >
                      <path
                        d="M5.293 10h-3.73C.7 10 0 9.3 0 8.437V3.146c0-.862.7-1.563 1.563-1.563h3.73c.862 0 1.562.7 1.562 1.563v5.293c0 .861-.7 1.562-1.562 1.562zm-3.73-7.637c-.431 0-.782.35-.782.782v5.293c0 .43.35.78.781.78h3.731c.43 0 .781-.35.781-.78V3.144c0-.431-.35-.782-.781-.782h-3.73zm6.855 5.098V1.562C8.418.702 7.718 0 6.855 0H2.52a.39.39 0 100 .781h4.335c.431 0 .782.35.782.781v5.899a.39.39 0 10.781 0z"
                        fill="#ccc"
                      ></path>
                    </svg>

                    <!-- <svg width="12" height="12" viewBox="0 0 12 12" fill="none" class="cursor-pointer">
                                            <path d="M11.602 10.927L8.84 8.166A4.841 4.841 0 005.098.258a4.84 4.84 0 103.065 8.585l2.761 2.759a.478.478 0 00.678-.675zM5.098 8.974a3.882 3.882 0 01-3.88-3.876 3.884 3.884 0 013.88-3.88 3.885 3.885 0 013.879 3.88 3.883 3.883 0 01-3.88 3.876z" fill="#CCCDD9"></path>
                                        </svg> -->
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <span>P : </span>
                    <div
                      class="cursor-pointer btn-link"
                      style="width: 65px; margin-left: 10px; margin-right: 5px"
                    >
                      <span
                        class="d-inline-block text-truncate"
                        style="max-width: 50px"
                        *ngIf="trans.customer && trans.customer.customerPhone"
                        >{{ trans.customer.customerPhone }}</span
                      >
                      <span
                        *ngIf="
                          !(trans.customer && trans.customer.customerPhone)
                        "
                        >n/a</span
                      >
                    </div>
                    <svg
                      width="10"
                      height="10"
                      (click)="
                        auth.handleCopy($event, trans.customer.customerPhone)
                      "
                      viewBox="0 0 9 10"
                      fill="none"
                      color="#0DB450"
                      class="cursor-pointer"
                    >
                      <path
                        d="M5.293 10h-3.73C.7 10 0 9.3 0 8.437V3.146c0-.862.7-1.563 1.563-1.563h3.73c.862 0 1.562.7 1.562 1.563v5.293c0 .861-.7 1.562-1.562 1.562zm-3.73-7.637c-.431 0-.782.35-.782.782v5.293c0 .43.35.78.781.78h3.731c.43 0 .781-.35.781-.78V3.144c0-.431-.35-.782-.781-.782h-3.73zm6.855 5.098V1.562C8.418.702 7.718 0 6.855 0H2.52a.39.39 0 100 .781h4.335c.431 0 .782.35.782.781v5.899a.39.39 0 10.781 0z"
                        fill="#ccc"
                      ></path>
                    </svg>
                  </div>
                </div>
              </td>
              <td>{{ trans.storeName }}</td>
              <td>
                <div class="justify-content-start align-items-start">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <span>N : </span>
                    <div
                      class="cursor-pointer btn-link"
                      style="width: 35px; margin-left: 5px; margin-right: 2px"
                    >
                      <span
                        class="d-inline-block text-truncate"
                        style="max-width: 40px"
                        *ngIf="trans.mobileNetwork"
                        >{{ trans.mobileNetwork }}</span
                      >
                      <span *ngIf="!trans.mobileNetwork">n/a</span>
                    </div>
                    <svg
                      width="10"
                      height="10"
                      (click)="auth.handleCopy($event, trans.mobileNetwork)"
                      viewBox="0 0 9 10"
                      fill="none"
                      color="#0DB450"
                      class="cursor-pointer"
                    >
                      <path
                        d="M5.293 10h-3.73C.7 10 0 9.3 0 8.437V3.146c0-.862.7-1.563 1.563-1.563h3.73c.862 0 1.562.7 1.562 1.563v5.293c0 .861-.7 1.562-1.562 1.562zm-3.73-7.637c-.431 0-.782.35-.782.782v5.293c0 .43.35.78.781.78h3.731c.43 0 .781-.35.781-.78V3.144c0-.431-.35-.782-.781-.782h-3.73zm6.855 5.098V1.562C8.418.702 7.718 0 6.855 0H2.52a.39.39 0 100 .781h4.335c.431 0 .782.35.782.781v5.899a.39.39 0 10.781 0z"
                        fill="#ccc"
                      ></path>
                    </svg>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <span>M : </span>
                    <div
                      class="cursor-pointer btn-link"
                      style="width: 35px; margin-left: 5px; margin-right: 2px"
                    >
                      <span
                        class="d-inline-block text-truncate"
                        style="max-width: 40px"
                        *ngIf="trans.mobile"
                        >{{ trans.mobile }}</span
                      >
                      <span *ngIf="!trans.mobile">n/a</span>
                    </div>
                    <svg
                      width="10"
                      height="10"
                      (click)="auth.handleCopy($event, trans.mobile)"
                      viewBox="0 0 9 10"
                      fill="none"
                      color="#0DB450"
                      class="cursor-pointer"
                    >
                      <path
                        d="M5.293 10h-3.73C.7 10 0 9.3 0 8.437V3.146c0-.862.7-1.563 1.563-1.563h3.73c.862 0 1.562.7 1.562 1.563v5.293c0 .861-.7 1.562-1.562 1.562zm-3.73-7.637c-.431 0-.782.35-.782.782v5.293c0 .43.35.78.781.78h3.731c.43 0 .781-.35.781-.78V3.144c0-.431-.35-.782-.781-.782h-3.73zm6.855 5.098V1.562C8.418.702 7.718 0 6.855 0H2.52a.39.39 0 100 .781h4.335c.431 0 .782.35.782.781v5.899a.39.39 0 10.781 0z"
                        fill="#ccc"
                      ></path>
                    </svg>
                  </div>
                </div>
              </td>
              <td>
                <span *ngIf="trans.amountTendered; else noCash"
                  ><span [innerHTML]="currencyLoader[currency]"></span>
                  {{ trans.amountTendered | number: "1.2-2" }}</span
                >
                <ng-template #noCash>
                  <span
                    ><span [innerHTML]="currencyLoader[currency]"></span>
                    {{ trans.amount | number: "1.2-2" }}</span
                  >
                </ng-template>
              </td>
              <td>
                <span *ngIf="trans.customerBalance; else noBalance"
                  ><span [innerHTML]="currencyLoader[currency]"></span
                  >{{ trans.customerBalance | number: "1.2-2" }}</span
                >
                <ng-template #noBalance>
                  <span
                    ><span [innerHTML]="currencyLoader[currency]"></span>
                    0.00</span
                  >
                </ng-template>
              </td>
              <td>{{ trans.productQuantity }}</td>

              <td>
                <div
                  *ngIf="trans.channelStatusCode === '00'"
                  class="badge badge-secondary badge-pill"
                >
                  Successful
                </div>
                <div
                  *ngIf="trans.channelStatusCode !== '00'"
                  class="badge badge-warning badge-pill"
                >
                  Failed
                </div>
              </td>
              <td>
                <!-- <button class="btn btn-datatable btn-icon btn-transparent-dark mr-2"><i data-feather="more-vertical"></i></button> -->
                <!-- <button class="btn btn-datatable btn-icon btn-transparent-dark" (click)="viewSalesDetails(trans)"  data-toggle="modal" data-target="#exampleModalScrollable">
                                    <i class="text-purple" data-feather="eye"></i>
                                </button> -->
                <button
                  class="btn btn-outline-purple btn-icon btn-sm"
                  (click)="viewSalesDetails(trans)"
                  data-toggle="modal"
                  data-target="#exampleModalScrollable"
                  type="button"
                >
                  <i data-feather="eye"></i>
                </button>

                <img
                  *ngIf="salesDetailsLoader"
                  src="../../../../assets/landing/images/ring1.gif"
                  class="smLoaderSm"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        *ngIf="transactions?.length > 0; else noProductSales"
        class="clearfix"
      >
        <div class="hint-text">
          Showing <b>{{ totalCurrentPageCount }}</b> out of
          <b>{{ totalCount }}</b> entries @
        </div>
        <div class="pg-text">
          <mat-select
            class="pg-width"
            [(value)]="perPageCount"
            (selectionChange)="changePerPageSales($event)"
          >
            <mat-option [value]="10">10</mat-option>
            <mat-option [value]="15">15</mat-option>
            <mat-option [value]="25">25</mat-option>
            <mat-option [value]="50">50</mat-option>
            <mat-option [value]="75">75</mat-option>
            <mat-option [value]="100">100</mat-option>
          </mat-select>
        </div>
        <ul class="pagination">
          <li class="page-item">
            <a (click)="paginateSalesBack()" class="page-link mover"> << </a>
          </li>
          <li
            *ngFor="let pg of totalPageList; let i = index"
            class="page-item"
            [ngClass]="{ active: currentPage == pg.value }"
          >
            <a (click)="paginateSales(i)" class="page-link">{{ pg.value }}</a>
          </li>
          <li class="page-item">
            <a (click)="paginateSalesForward()" class="page-link mover"> >> </a>
          </li>
        </ul>
      </div>
      <div style="margin-top: 5px">
        <span class="notification float-left" *ngIf="isLoading">
          Loading sales...
          <img
            src="../../../../assets/landing/images/ring1.gif"
            class="smLoader"
        /></span>
      </div>
      <ng-template #noProductSales>
        <p>No sales record found!</p>
      </ng-template>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="exampleModalScrollableR1"
  data-backdrop="static"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalScrollableTitleR1"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalScrollableTitleR1">
          Add terminal
        </h5>
        <button
          class="close"
          type="button"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <div class="modal-body">
        <form
          [formGroup]="addTerminalPosForm"
          (ngSubmit)="addTerminalRecord(addTerminalPosForm.value)"
        >
          <!-- Form Group (username)-->
          <div class="form-group">
            <label class="small mb-1" for="terminalId">Terminal ID</label>
            <input
              type="text"
              class="form-control"
              id="terminalId"
              formControlName="terminalId"
              placeholder="POS terminal"
            />
            <div *ngIf="a.terminalId.invalid && a.terminalId.touched">
              <small
                *ngIf="a.terminalId.errors?.required"
                class="small-warning text-danger"
                >*Terminal id must be provided!</small
              >
            </div>
          </div>
          <div class="form-group">
            <label class="small mb-1" for="terminalSN"
              >Terminal Serial No</label
            >
            <input
              type="text"
              class="form-control"
              id="terminalSN"
              formControlName="terminalSN"
              placeholder="POS terminal serial no"
            />
            <div *ngIf="a.terminalSN.invalid && a.terminalSN.touched">
              <small
                *ngIf="a.terminalSN.errors?.required"
                class="small-warning text-danger"
                >*Terminal serial no must be provided!</small
              >
            </div>
          </div>
          <div class="form-group">
            <label class="small mb-1" for="modelName"
              >Model name (optional)</label
            >
            <input
              type="text"
              class="form-control"
              id="modelName"
              formControlName="modelName"
              placeholder="Pos model name"
            />
          </div>
          <div class="form-group">
            <label class="small mb-1" for="modelVersion"
              >Model version (optional)</label
            >
            <input
              type="text"
              class="form-control"
              id="modelVersion"
              formControlName="modelVersion"
              placeholder="POS Model version"
            />
          </div>
          <div class="form-group">
            <label class="small mb-1" for="modelYear"
              >Model Year (optional)</label
            >
            <input
              type="text"
              class="form-control"
              id="modelYear"
              formControlName="modelYear"
              placeholder="POS Model Year"
            />
          </div>
          <div class="form-group">
            <label class="small mb-1" for="modelSerialNo"
              >Serial No (optional)</label
            >
            <input
              type="text"
              class="form-control"
              id="modelSerialNo"
              formControlName="modelSerialNo"
              placeholder="Serial No"
            />
          </div>
          <!-- Save changes button-->
          <span class="notification" *ngIf="showProcessing">
            <img
              src="../../../../assets/landing/images/ring1.gif"
              class="imgNot float-right"
          /></span>
          <button
            class="btn btn-primary"
            type="submit"
            [disabled]="!addTerminalPosForm.valid || showProcessing"
          >
            {{ terminalAction }} terminal
          </button>
        </form>
      </div>
      <div class="modal-footer">
        <button
          class="btn btn-sm btn-danger closeAddTerminal"
          type="button"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Assign terminal To Store Modal -->
<div
  class="modal fade"
  id="exampleModalScrollableR"
  data-backdrop="static"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalScrollableTitleR"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalScrollableTitleR">
          Assign Sale point to user
        </h5>
        <button
          class="close"
          type="button"
          data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <div class="modal-body">
        <form
          [formGroup]="salesPointAssignForm"
          (ngSubmit)="assignPOSToStoreProfile(salesPointAssignForm.value)">

          <!-- Form Group (username)-->
          <!-- <div class="form-group">
                        <label class="small mb-1" for="storeProfileId">Choose Store</label>
                        <select class="form-control" id="storeProfileId" formControlName="storeProfileId" >
                            <option *ngFor="let store of stores; let i=index;" value="{{store.storeNumber}}">{{store.storeName}}</option>
                        </select>
                        <div *ngIf="b.storeProfileId.invalid && b.storeProfileId.touched">
                            <small *ngIf="b.storeProfileId.errors?.required" class="small-warning text-danger">*Store must be selected</small>
                        </div>
                    </div> -->
          <!-- <mat-form-field class="tp-full-width" appearance="fill">
            <mat-label>Stores</mat-label>
            <input
              type="text"
              placeholder="Select store"
              aria-label="Store name"
              matInput
              formControlName="storeProfileId"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option
                *ngFor="let store of filteredOptions | async"
                [value]="store.storeNumber"
              >
                {{ store.storeName }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field> -->

          <!-- <div class="form-group">
            <label class="small mb-1" for="storeId">Choose Store</label>
            <select
              class="form-control selectpicker select2"
              formControlName="storeProfileId">
              <option
                *ngFor="let store of stores; let i = index"
                data-tokens="{{ store.storeName }}"
                value="{{ store.storeNumber }}">
                {{ store.storeName }}
              </option>
            </select>
            <div *ngIf="b.storeProfileId.invalid && b.storeProfileId.touched">
              <small
                *ngIf="b.storeProfileId.errors?.required"
                class="small-warning text-danger"
                >*Store must be selected</small>
            </div>
          </div> -->

          <div class="form-group">
            <label class="small mb-1" for="storeId">Choose Store</label>

            <ng-select [items]="stores" bindLabel="storeName" bindValue="storeNumber" 
            [clearable]="true" (change)="selected($event)" [multiple]="false"
            class="storesList" appearance="underline" dropdownPosition="bottom"
            [virtualScroll]="true"
            formControlName="storeProfileId">
            </ng-select>

            <!-- <ng-select class="form-control storesList" formControlName="storeProfileId"  (change)="selected($event)">
              <ng-option *ngFor="let store of stores" [value]="store?.storeNumber">
                {{store.storeName}}</ng-option>
            </ng-select> -->

            <div *ngIf="b.storeProfileId.invalid && b.storeProfileId.touched">
              <small
                *ngIf="b.storeProfileId.errors?.required"
                class="small-warning text-danger"
                >*Store must be selected (enter store name to search)</small>
            </div>

          </div>

          <!-- Save changes button-->
          <span class="notification" *ngIf="showProcessing">
            <img
              src="../../../../assets/landing/images/ring1.gif"
              class="imgNot float-right"
          /></span>
          <button
            class="btn btn-primary"
            type="submit"
            [disabled]="!salesPointAssignForm.valid || showProcessing"
          >
            Assign to store
          </button>
        </form>
      </div>
      <div class="modal-footer">
        <button
          class="btn btn-sm btn-danger closeAssign"
          type="button"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModalScrollable"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalScrollableTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalScrollableTitle">
          Sales Details
        </h5>
        <button
          class="close"
          type="button"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="card-body">
          <h5>
            Total amount:
            <b
              ><span [innerHTML]="currencyLoader[currency]"></span>
              {{ totalAmount | number: "1.2-2" }}</b
            >
          </h5>
          <h5>
            Total Product quantity: <b>{{ totalQty }}</b>
          </h5>
          <p class="small mb-1">
            Reference: <b>{{ transactionRef }}</b>
          </p>
          <div class="list-group list-group-flush">
            <div
              *ngFor="let sale of salesProducts; let i = index"
              class="
                list-group-item
                d-flex
                align-items-center
                justify-content-between
                small
                px-0
                py-2
              "
            >
              <div class="mr-3">
                <i
                  class="fas fa-circle fa-sm mr-1"
                  [class.text-green]="(i + 1) % 2 === 0"
                  [class.text-purple]="(i + 1) % 2 === 1"
                ></i>
                {{ sale.product.productName }}
              </div>
              <div class="font-weight-500 text-dark">
                <span [innerHTML]="currencyLoader[currency]"></span>
                {{ sale.totalAmount | number: "1.2-2" }} | x{{ sale.quantity }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          class="btn btn-secondary btn-sm btn-danger"
          type="button"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<script src="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-select/1.13.1/js/bootstrap-select.min.js"></script>
