import { Component, OnInit } from '@angular/core';
import { Configuration } from '../config/configuration';

import { DomSanitizer } from '@angular/platform-browser';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';
import { ClientAppService } from '../shared/services/clientapp.service';
import { AuthService } from '../shared/services/auth.service';
import { AlertService } from '../shared/services/alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admindashboard',
  templateUrl: './admindashboard.component.html',
  styleUrls: ['./admindashboard.component.css']
})
export class AdmindashboardComponent implements OnInit {

  private termPaths = new Configuration;

  public cYear: any;
  public cDate: any;
  public loggedInUser: String;
  public userProfileUrl: String;
  public user: any;

  public userStores: any[] = [];
  public storeNumber;

  public switchProcessing: boolean;
  public primaryColour = "#ffffff";
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;

  constructor(private _clientApp: ClientAppService, 
    public auth: AuthService, 
    private alert: AlertService, private router: Router) { }

  ngOnInit(): void {

    this._clientApp.updateStoreUrlScripts();
    this.cYear = this._clientApp.setCopyDate(); 
    this.cDate = this._clientApp.setCurrentFullDate(); 
    this.loggedInUser = this.auth.getLoggedUserName();
    this.userProfileUrl = this.auth.getUser().profileUrl;

    this.user = this.auth.getUser();
    
  }

}
