<header class="page-header page-header-dark bg-gradient-primary-to-secondaryadmin pb-10">
    <div class="container">
        <div class="page-header-content pt-4">
            <div class="row align-items-center justify-content-between">
                <div class="col-auto mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Salesboard
                    </h1>
                    <div class="page-header-subtitle">Inventory management system admin settlement records</div>
                </div>
                
            </div>
        </div>
    </div>
</header>

<div class="container mt-n10">

    <div class="row">
        <div class="col-xl-3 col-md-6 mb-4">
            <!-- Dashboard info widget 1-->
            <div class="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-primary h-100">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <div class="small font-weight-bold text-primary mb-1">Today Sales ({{productSalesAnalytics.totalDaySalesCount}})</div>
                            <div class="h5"><span [innerHTML]="currencyLoader[currency]"></span>{{productSalesAnalytics.totalDaySalesAmount | number : '1.2-2'}}</div>
                            <div class="text-xs font-weight-bold text-success d-inline-flex align-items-center">
                                <i class="mr-1" data-feather="trending-up"></i>
                                {{(productSalesAnalytics.averageDaySalesAmount/productSalesAnalytics.averageDaySalesCount)/100 | number : '1.2-2'  }}%
                            </div>
                        </div>
                        <div class="ml-2"><i class="fas fa-tag fa-2x fa-2x text-gray-200"></i></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
            <!-- Dashboard info widget 3-->
            <div class="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-success h-100">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <div class="small font-weight-bold text-success mb-1">Current Week ({{productSalesAnalytics.totalWeekSalesCount}})</div>
                            <div class="h5"><span [innerHTML]="currencyLoader[currency]"></span>{{productSalesAnalytics.totalWeekSalesAmount | number : '1.2-2'}}</div>
                            <div class="text-xs font-weight-bold text-success d-inline-flex align-items-center">
                                <i *ngIf="productSalesAnalytics.weekGrowthType == 'NEGATIVE'" class="mr-1" data-feather="trending-down"></i>
                                <i *ngIf="productSalesAnalytics.weekGrowthType == 'POSITIVE'" class="mr-1" data-feather="trending-up"></i>
                                {{productSalesAnalytics.totalWeekSalesAmount/productSalesAnalytics.prevWeekSalesAmount | number : '1.2-2' }}%
                            </div>
                        </div>
                        <div class="ml-2"><i class="fas fa-mouse-pointer fa-2x text-gray-200"></i></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
            <!-- Dashboard info widget 2-->
            <div class="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-secondary h-100">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <div class="small font-weight-bold text-secondary mb-1">Current Month ({{productSalesAnalytics.totalMonthSalesCount}})</div>
                            <div class="h5"><span [innerHTML]="currencyLoader[currency]"></span>{{productSalesAnalytics.totalMonthSalesAmount | number : '1.2-2'}}</div>
                            <div class="text-xs font-weight-bold text-danger d-inline-flex align-items-center">
                                <i *ngIf="productSalesAnalytics.monthGrowthType == 'NEGATIVE'" class="mr-1" data-feather="trending-down"></i>
                                <i *ngIf="productSalesAnalytics.monthGrowthType == 'POSITIVE'" class="mr-1" data-feather="trending-up"></i>
                                {{productSalesAnalytics.monthGrowthPercentage}}%
                            </div>
                        </div>
                        <div class="ml-2"><i class="fas fa-tag fa-2x text-gray-200"></i></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
            <!-- Dashboard info widget 4-->
            <div class="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-info h-100">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <div class="small font-weight-bold text-info mb-1">Last Month ({{productSalesAnalytics.prevMonthAverageSalesCount}})</div>
                            <div class="h5"><span [innerHTML]="currencyLoader[currency]"></span>{{productSalesAnalytics.prevMonthAverageSalesAmount | number : '1.2-2'}}</div>
                            <div class="text-xs font-weight-bold text-danger d-inline-flex align-items-center">
                                <i *ngIf="productSalesAnalytics.averageMonthSalesAmount > productSalesAnalytics.prevMonthAverageSalesAmount" class="mr-1" data-feather="trending-down"></i>
                                <i *ngIf="productSalesAnalytics.averageMonthSalesAmount < productSalesAnalytics.prevMonthAverageSalesAmount" class="mr-1" data-feather="trending-up"></i>
                                <span *ngIf="productSalesAnalytics.averageMonthSalesAmount < productSalesAnalytics.prevMonthAverageSalesAmount">{{(productSalesAnalytics.prevMonthAverageSalesAmount/productSalesAnalytics.totalMonthSalesAmount)/100 | number : '1.2-2' }}%</span>
                                <span *ngIf="productSalesAnalytics.averageMonthSalesAmount > productSalesAnalytics.prevMonthAverageSalesAmount">{{(productSalesAnalytics.totalMonthSalesAmount/productSalesAnalytics.prevMonthAverageSalesAmount)*100 | number : '1.2-2' }}%</span>

                            </div>
                        </div>
                        <div class="ml-2"><i class="fas fa-percentage fa-2x text-gray-200"></i></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    
    <div *ngIf="!isStoreDetails" class="card mb-4">
        <div class="card-header">Store settlements</div>
        <div class="card-body">
            <span class="notification float-left" *ngIf="isLoading"> Loading settlements... <img src="../../../../assets/landing/images/ring1.gif"class="smLoader"></span>
            <span class="notification float-left" *ngIf="showSearch"> Searching settlements... <img src="../../../../assets/landing/images/ring1.gif" class="smLoader"></span>
            <span class="notification float-left" *ngIf="showProcessingFile"> Processing download... <img src="../../../../assets/landing/images/ring1.gif" class="smLoader"></span>
            <div>
                <!-- <button class="btn btn-outline-teal btn-sm btn-icon float-right" style="margin: 2px;" type="button">
                    <i data-feather="download"></i>
                </button> -->
            </div>
            <div class="form-group" class="tp-full-width">
                
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <select class="form-control select2bs4" (change)="updateSettlementSearchType($event)" style="width: 100%">
                      <option value="ALL">ALL</option>
                      <option value="IN_PROCESS">ACTIVE</option>
                      <option value="PROCESSING">COMPLETED (Pending payment)</option>
                      <option value="COMPLETED">COMPLETED (paid)</option>
                      <option value="PAID">PAID</option>
                      <option value="UNPAID">FAILED PAYMENT</option>
                    </select>
                   </div>
                    <input class="form-control" #searchField (keyup)="searchStoreSettlements(searchField.value)" type="text" placeholder="Search settlements..." aria-label="Search">
                    
                </div>
            </div>
            
            <div class="datatable">
                <table class="table table-bordered table-hover" width="100%" cellspacing="0">
                    <thead>
                        <tr>
                            <th>S/N</th>
                            <th>Date</th>
                            <th>Store Name</th>
                            <th>Ref</th>
                            <th>Amount</th>
                            <th>Settlement</th>
                            <th>Fee</th>
                            <th>Vat Fee</th>
                            <th>Status</th>
                            <th>Payment</th>
                            <th>Due date</th>
                            <th>Update</th>
                            <th>View</th>
                        </tr>
                    </thead>
                    
                    <tbody>
                        <tr *ngFor='let settlement of settlements; let i=index;'>
                            <td>{{((currentPage-1)*perPageCount)+(i+1)}}</td>
                            <td>{{settlement.createdAt | date :'short'}}</td>
                            <td>{{settlement.storeProfile.storeName}} </td>
                            <td>
                                {{settlement.cycleRef}}
                            </td>
                            
                            <td>
                                <span [innerHTML]="currencyLoader[currency]"></span> {{settlement.originalAmount}}
                            </td>
                            <td>
                                <span [innerHTML]="currencyLoader[currency]"></span> {{settlement.settlementAmount}}
                            </td>
                            <td>
                                <span [innerHTML]="currencyLoader[currency]"></span> {{settlement.fee}}
                            </td>
                            <td>
                                <span [innerHTML]="currencyLoader[currency]"></span> {{settlement.vatFee}}
                            </td>
                            <td>
                                <div *ngIf='settlement.status === "IN_PROCESS"' class="badge badge-secondary badge-pill" style="font-size: 0.85em; padding: 0.75em;">Active</div>
                                <div *ngIf='settlement.status === "PROCESSING"' class="badge badge-primary badge-pill" style="font-size: 0.85em; padding: 0.75em;">Payment</div>
                                <div *ngIf='settlement.status == "COMPLETED"' class="badge badge-success badge-pill" style="font-size: 0.85em; padding: 0.75em;">Completed</div>
                            </td>
                            <td>
                                <div *ngIf='settlement.paymentStatus === "PAID"' class="badge badge-secondary badge-pill" style="font-size: 0.85em; padding: 0.75em;">Paid</div>
                                <div *ngIf='settlement.paymentStatus == "UNPAID"' class="badge badge-warning badge-pill" style="font-size: 0.85em; padding: 0.75em;">Unpaid</div>
                            </td>
                            <td>{{settlement.paymentProcessingTime | date :'short'}}</td>
                            <td>
                                <button class="btn btn-outline-green btn-icon btn-sm" data-toggle="modal" data-target="#exampleModalStatus" type="button" (click)="assignLocalSettlement(settlement)">
                                    <i class="text-green" data-feather="arrow-right-circle"></i>
                                </button>
                            </td>
                            <td>
                                <a class="btn btn-outline-purple btn-icon btn-sm" [routerLink]="['/admin/dashboard/adminssettlementtransactions', settlement.cycleRef]"> <i data-feather="eye"></i></a>
                                <!-- <button class="btn btn-outline-purple btn-icon btn-sm" (click)="viewSettlementDetails(settlement)" >
                                    <i data-feather="eye"></i>
                                </button>  -->
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="settlements?.length > 0; else noSettlements" class="clearfix">
                <div class="hint-text">Showing <b>{{totalCurrentPageCount}}</b> out of <b>{{totalCount}}</b> entries</div>
                <ul class="pagination">
                    <li class="page-item"><a (click)='paginateBack()' class="page-link mover"> << </a></li>
                    <li *ngFor='let pg of totalPageList; let i=index;' class="page-item" [ngClass]="{'active': currentPage == pg.value}">
                        <a (click)='paginate(i)' class="page-link">{{pg.value}}</a>
                    </li>
                    <li class="page-item"><a (click)='paginateForward()' class="page-link mover"> >> </a></li>
                </ul>
            </div>
            <div style="margin-top: 5px;">
                <span class="notification float-left" *ngIf="isLoading"> Loading sales... <img src="../../../../assets/landing/images/ring1.gif"class="smLoader"></span>
            </div>
            <ng-template #noSettlements>
                <p>No store record found!</p>
            </ng-template>
        </div>
    </div>

    <div *ngIf="isStoreDetails" class="card mb-4">
        <div class="card-header">Product transaction sales details <button class="btn btn-sm btn-primary float-right" (click)="viewSalesDetails(salesTransaction)">Return to sales</button></div>
        <div class="card-body">
            
        </div>
    </div>

</div>

<!-- Modal -->
<div  class="modal fade" id="exampleModalScrollable" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalScrollableTitle">Sales Details</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
                <div class="card-body">
                    <h5>Total amount: <b><span [innerHTML]="currencyLoader[currency]"></span></b></h5>
                    <h5>Total Product quantity: <b></b></h5>
                    <p class="small mb-1">Reference: <b></b></p>
                    <div class="list-group list-group-flush">
                        
                    </div>
                </div>
            </div>
            <div class="modal-footer"><button class="btn btn-secondary btn-sm btn-danger" type="button" data-dismiss="modal">Close</button></div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade"  id="exampleModalStatus" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Settlement status update</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
               
                <form [formGroup]="settlementProfileStatusForm" (ngSubmit)="switchSettlementStatus(settlementProfileStatusForm.value)">
                    
                    <!-- Form Group (location)-->
                    <div class="form-group">
                        <label class="small mb-1" for="settlementStatus">Status</label>
                        <select class="form-control" id="settlementStatus" formControlName="status" required>
                            <option value="PAID">SET AS PAID</option>
                            <option value="UNPAID">SET AS UNPAID</option>
                            <option value="COMPLETED">MARK AS COMPLETED</option>
                        </select>
                        <div *ngIf="s.status.invalid && s.status.touched">
                            <small *ngIf="s.status.errors?.required" class="small-warning text-danger">*Status must selected</small>
                        </div>
                    </div>
                    
                    
                    <!-- Save changes button-->
                    <span class="notification" *ngIf="showProcessing"> <img src="../../../../assets/landing/images/ring1.gif"  class="imgNot float-right"></span>
                    <button class="btn btn-primary" type="submit" [disabled]="!settlementProfileStatusForm.valid || showProcessing">Change status</button>
                </form>

            </div>
            <div class="modal-footer"><button class="btn btn-secondary closeStatus" type="button" data-dismiss="modal">Cancel</button></div>
        </div>
    </div>
</div>


<ngx-loading [show]="processingSalesAnalytics" 
[config]="{animationType: ngxLoadingAnimationTypes.rectangleBounce, 
    primaryColour: primaryColour, backdropBorderRadius: '3px'}"></ngx-loading>




