<header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
    <div class="container">
        <div class="page-header-content pt-4">
            <div class="row align-items-center justify-content-between">
                <div class="col-auto mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Salesboard
                    </h1>
                    <div class="page-header-subtitle">Welcome to <b>{{auth.getUserStoreName()}}</b> inventory management system sales board</div>
                </div>
                <div class="col-12 col-xl-auto mt-4">
                    <button class="btn btn-white p-3" id="reportrange">
                        <i class="mr-2 text-primary" data-feather="calendar"></i>
                        <span></span>
                        <i class="ml-1" data-feather="chevron-down"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</header>

<!-- Main page content-->
<div class="container mt-n10">
    
    <div class="row">
        <div class="col-xl-3 col-md-6 mb-4">
            <!-- Dashboard info widget 1-->
            <div class="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-primary h-100">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <div class="small font-weight-bold text-primary mb-1">Today Sales ({{productSalesAnalytics.totalDaySalesCount}})</div>
                            <div class="h5">{{productSalesAnalytics.totalDaySalesAmount}}</div>
                            <div class="text-xs font-weight-bold text-success d-inline-flex align-items-center">
                                <i class="mr-1" data-feather="trending-up"></i>
                                {{(productSalesAnalytics.averageDaySalesAmount/productSalesAnalytics.averageDaySalesCount)/100 | number : '1.2-2'  }}%
                            </div>
                        </div>
                        <div class="ml-2"><i class="fas fa-tag fa-2x fa-2x text-gray-200"></i></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
            <!-- Dashboard info widget 2-->
            <div class="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-secondary h-100">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <div class="small font-weight-bold text-secondary mb-1">Current Month ({{productSalesAnalytics.totalMonthSalesCount}})</div>
                            <div class="h5">{{productSalesAnalytics.totalMonthSalesAmount}}</div>
                            <div class="text-xs font-weight-bold text-danger d-inline-flex align-items-center">
                                <i *ngIf="productSalesAnalytics.monthGrowthType == 'NEGATIVE'" class="mr-1" data-feather="trending-down"></i>
                                <i *ngIf="productSalesAnalytics.monthGrowthType == 'POSITIVE'" class="mr-1" data-feather="trending-up"></i>
                                {{productSalesAnalytics.monthGrowthPercentage}}%
                            </div>
                        </div>
                        <div class="ml-2"><i class="fas fa-tag fa-2x text-gray-200"></i></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
            <!-- Dashboard info widget 3-->
            <div class="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-success h-100">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <div class="small font-weight-bold text-success mb-1">Current Week ({{productSalesAnalytics.totalWeekSalesCount}})</div>
                            <div class="h5">{{productSalesAnalytics.totalWeekSalesAmount}}</div>
                            <div class="text-xs font-weight-bold text-success d-inline-flex align-items-center">
                                <i *ngIf="productSalesAnalytics.weekGrowthType == 'NEGATIVE'" class="mr-1" data-feather="trending-down"></i>
                                <i *ngIf="productSalesAnalytics.weekGrowthType == 'POSITIVE'" class="mr-1" data-feather="trending-up"></i>
                                {{productSalesAnalytics.totalWeekSalesAmount/productSalesAnalytics.prevWeekSalesAmount | number : '1.2-2' }}%
                            </div>
                        </div>
                        <div class="ml-2"><i class="fas fa-mouse-pointer fa-2x text-gray-200"></i></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
            <!-- Dashboard info widget 4-->
            <div class="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-info h-100">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <div class="small font-weight-bold text-info mb-1">Last Month ({{productSalesAnalytics.prevMonthAverageSalesCount}})</div>
                            <div class="h5">{{productSalesAnalytics.prevMonthAverageSalesAmount/productSalesAnalytics.prevMonthAverageSalesCount | number : '1.2-2'}}%</div>
                            <div class="text-xs font-weight-bold text-danger d-inline-flex align-items-center">
                                <i *ngIf="productSalesAnalytics.averageMonthSalesAmount > productSalesAnalytics.prevMonthAverageSalesAmount" class="mr-1" data-feather="trending-down"></i>
                                <i *ngIf="productSalesAnalytics.averageMonthSalesAmount < productSalesAnalytics.prevMonthAverageSalesAmount" class="mr-1" data-feather="trending-up"></i>
                                {{productSalesAnalytics.totalMonthSalesAmount/productSalesAnalytics.prevMonthAverageSalesAmount | number : '1.2-2' }}%
                            </div>
                        </div>
                        <div class="ml-2"><i class="fas fa-percentage fa-2x text-gray-200"></i></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-xxl-8">
            <!-- Tabbed dashboard card example-->
            <div class="card mb-4">
                <div class="card-header border-bottom">
                    <!-- Dashboard card navigation-->
                    <ul class="nav nav-tabs card-header-tabs" id="dashboardNav" role="tablist">
                        <li class="nav-item mr-1"><a class="nav-link active" id="activities-pill" href="#activities" data-toggle="tab" role="tab" aria-controls="activities" aria-selected="false">Products</a></li>
                        <!-- <li class="nav-item"><a class="nav-link" id="overview-pill" href="#overview" data-toggle="tab" role="tab" aria-controls="overview" aria-selected="true">Sales Overview</a></li> -->
                    </ul>
                </div>
                <div class="card-body">
                    <div class="tab-content" id="dashboardNavContent">
                        <!-- Dashboard Tab Pane 1-->
                        <!-- <div class="tab-pane fade " id="overview" role="tabpanel" aria-labelledby="overview-pill">
                            <div class="chart-area mb-4 mb-lg-0" style="height: 20rem"><canvas id="myAreaChart" width="100%" height="30"></canvas></div>
                        </div> -->
                        <!-- Dashboard Tab Pane 2-->
                        <span class="notification float-left" *ngIf="isLoading"> Loading products... <img src="../../../../assets/landing/images/ring1.gif"class="smLoader"></span>
                        <span class="notification float-left" *ngIf="showSearch"> Searching... <img src="../../../../assets/landing/images/ring1.gif"class="smLoader"></span>
                        <div class="input-group input-group-joined mb-4">
                            <input class="form-control" #searchField (keyup)="searchProduct(searchField.value)" type="text" placeholder="Search for product..." aria-label="Search" />
                            <div class="input-group-append">
                                <span class="input-group-text"><i data-feather="search"></i></span>
                            </div>
                        </div>
                        <div class="tab-pane fade show active" id="activities" role="tabpanel" aria-labelledby="activities-pill">
                            <div class="datatable table-responsive">
                                <table class="table table-bordered table-hover" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>S/N</th>
                                            <th>Product Name</th>
                                            <th>Amount</th>
                                            <th>Stock</th>
                                            <th>Qty</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor='let product of products; let i=index;'>
                                            <td>{{i+1}}</td>
                                            <td>
                                                <i class="mr-2 text-green" data-feather="zap"></i>
                                                {{product.productName}}
                                            </td>
                                            <td><span [innerHTML]="currencyLoader[currency]"></span> {{product.amount | number : '1.2-2'}}</td>
                                            <td>
                                                <div *ngIf='product.enableStock === true'>
                                                    <i class="mr-2 text-purple" data-feather="shopping-cart"></i>Stock   
                                                </div>  
                                                <div *ngIf='product.enableStock === false'>
                                                    <i class="mr-2 text-green" data-feather="airplay"></i>Non-Stock   
                                                </div>   
                                            </td>
                                            <td>
                                                <span *ngIf="product.enableStock === true else nonStockQty">{{product.quantity}}</span>
                                                <ng-template #nonStockQty>
                                                    <span>0</span>
                                                </ng-template>
                                            </td>
                                            
                                            <td (click)=addProductToCart(product) class="cartIcon animate__animated">
                                                <i class="mr-2 text-purple" data-feather="shopping-cart"></i>
                                                +
                                            </td>
                                            <td (click)=removeProductFromCart(product) class="cartIcon animate__animated">
                                                <i class="mr-2 text-red" data-feather="shopping-cart"></i>
                                                -
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div *ngIf="products?.length > 0; else noProducts" class="clearfix">
                                <div class="hint-text">Showing <b>{{totalCurrentPageCount}}</b> out of <b>{{totalCount}}</b> entries</div>
                                <ul class="pagination">
                                    <li class="page-item"><a (click)='paginateBack()' class="page-link mover"> << </a></li>
                                    <li *ngFor='let pg of totalPageList; let i=index;' class="page-item" [ngClass]="{'active': currentPage == pg.value}">
                                        <a (click)='paginate(i)' class="page-link">{{pg.value}}</a>
                                    </li>
                                    <li class="page-item"><a (click)='paginateForward()' class="page-link mover"> >> </a></li>
                                </ul>
                            </div>
                            <ng-template #noProducts>
                                <p>No product record found!</p>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xxl-4">
            <div class="row">
                <div class="col-xl-6 col-xxl-12">
                    
                    <div class="card mb-4">
                        <div class="card-header">Sales Cart <button class="btn btn-red btn-icon float-right" (click)="clearCart()" type="button">
                            <i data-feather="refresh-cw"></i>
                        </button> <button class="btn btn-outline-green btn-icon float-right" (click)="checkCart()" data-toggle="modal" data-target="#staticBackdrop" style="margin: 3px;" type="button">
                            Pay
                        </button></div>
                        <div class="card-body">
                            <!-- Item 1-->
                            <div *ngFor='let cart of productSalesList; let i=index;' class="d-flex align-items-center justify-content-between mb-4">
                                <div class="d-flex align-items-center flex-shrink-0 mr-3">
                                    <div class="avatar avatar-xl mr-3 bg-gray-200"><img class="avatar-img img-fluid" src="{{cart.productImageUrl}}" alt="" /></div>
                                    <div class="d-flex flex-column font-weight-bold">
                                        <a class="text-dark line-height-normal mb-1">{{cart.productName}}</a>
                                        <div id="cartAction{{i}}" class="small text-muted line-height-normal"> Qty - {{cart.quantity}} <button (click)="editProductQuantity(cart, i)" class="btn btn-sm btn-icon text-purple float-right" type="button"><i class="fas fa-edit"></i></button></div>
                                        <div style="display: none;" id="cartEdit{{i}}"> 
                                            <input matInput type="number" min="1" class="tp-full-widthsales" (keyup)="getQuantityFieldUpdate($event)" placeholder="New Qty">
                                            <button (click)="closeProductQuantity(cart, i)" class="btn btn-icon text-green float-right" type="button"><i class="far fa-check-square"></i></button>
                                        </div>
                                    </div>
                                </div>
                                 
                            </div>
                            <p>Total Qty: <b>{{totalQty}}</b></p>
                            <p>Total amount: <b><span [innerHTML]="currencyLoader[currency]"></span> {{totalAmount | number : '1.2-2'}} </b></p>
                        </div>
                    </div>
                </div>
                
            </div>
            <!-- Illustration dashboard card example-->
            <div class="card">
                <div class="card-body text-center p-5">
                    <img class="img-fluid mb-4" src="../../../assets/stores/assets/img/illustrations/team-spirit.svg" alt="" style="max-width: 16.25rem" />
                    <h5>Activity Brief</h5>
                    <p class="mb-4">Select product, review selected products total summary and pay with our available options. </p>
                </div>
            </div>
        </div>
    </div>

    <div class="card mb-4">
        <div class="card-header">Recent sales</div>
        <div class="card-body">
            <div *ngIf="transactions?.length > 0; else noProductSales " class="datatable">
                <table class="table table-bordered table-hover" width="100%" cellspacing="0">
                    <thead>
                        <tr>
                            <th>S/N</th>
                            <th>Amount</th>
                            <th>Channel</th>
                            <th>Ref</th>
                            <th>Customer name</th>
                            <th>Email</th>
                            <th>Phone no</th>
                            <th>Cash</th>
                            <th>Balance</th>
                            <th>Total Qty</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Receipt</th>
                            <th>View</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor='let trans of transactions; let i=index;'>
                            <td>{{i+1}}</td>
                            <td><span [innerHTML]="currencyLoader[currency]"></span> {{trans.amount | number : '1.2-2'}}</td>
                            <td>
                                <i *ngIf="trans.paymentChannelType == 'Cash'" class="text-green" data-feather="file-plus"></i>
                                <i *ngIf="trans.paymentChannelType == 'Card'" class="text-green" data-feather="credit-card"></i>
                                <i *ngIf="trans.paymentChannelType == 'Mobile Money'" class="text-pink" data-feather="layout"></i>
                                {{trans.paymentChannelType}}
                            </td>
                            <td>{{trans.transactionRef?.substring(0, 5)}} ...</td>
                            <td>
                                <span *ngIf="(trans.customer && trans.customer.customerName)">{{trans.customer.customerName}}</span>
                                <span *ngIf="!(trans.customer && trans.customer.customerName)">n/a</span>
                            </td>
                            <td>
                                <span *ngIf="(trans.customer && trans.customer.customerEmail)">{{trans.customer.customerEmail}}</span>
                                <span *ngIf="!(trans.customer && trans.customer.customerEmail)">n/a</span>
                            </td>
                            <td>
                                <span *ngIf="(trans.customer && trans.customer.customerPhone)">{{trans.customer.customerPhone}}</span>
                                <span *ngIf="!(trans.customer && trans.customer.customerPhone)">n/a</span>
                            </td>
                            <td>
                                <span *ngIf="trans.amountTendered else noCash"> <span [innerHTML]="currencyLoader[currency]"></span> {{trans.amountTendered | number : '1.2-2'}} </span>
                                <ng-template #noCash>
                                    <span><span [innerHTML]="currencyLoader[currency]"></span> {{trans.amount | number : '1.2-2'}}</span>
                                </ng-template>
                            </td>
                            <td>
                                <span *ngIf="trans.customerBalance else noBalance"><span [innerHTML]="currencyLoader[currency]"></span> {{trans.customerBalance | number : '1.2-2'}}</span>
                                <ng-template #noBalance>
                                    <span><span [innerHTML]="currencyLoader[currency]"></span> 0.00 </span>
                                </ng-template>
                            </td>
                            <td>{{trans.productQuantity}}</td>
                            <td>{{trans.createdAt | date :'short'}}</td>
                            <td>
                                <div *ngIf='trans.channelStatusCode === "00"' class="badge badge-secondary badge-pill">Successful</div>
                                <div *ngIf='trans.channelStatusCode !== "00"' class="badge badge-warning badge-pill">Failed</div>
                            </td>
                            <td>
                                <!-- <a target="blank" href="{{trans.receiptUrl}}"> <i data-feather="printer"></i></a> -->
                                <button class="btn" (click)="viewReceipt(trans.receiptUrl)" data-toggle="modal" data-target="#exampleModalScrollableR" type="button" > <i data-feather="printer"></i></button>
                            </td>
                            <td>
                                
                                <button class="btn btn-outline-purple btn-icon btn-sm" (click)="viewSalesDetails(trans)" data-toggle="modal" data-target="#exampleModalScrollable" type="button">
                                    <i data-feather="eye"></i>
                                </button>
                             
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div  class="clearfix">
                <ng-template #noProductSales>
                    <p>No sales record found!</p>
                </ng-template>
            </div>
            
        </div>
    </div>

</div>

<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Payment details</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
            </div>
            <div *ngIf="!printReceipt" class="modal-body">

                <div class="card-body">
                    <h5>Total amount: <b><span [innerHTML]="currencyLoader[currency]"></span> {{totalAmount | number : '1.2-2' }}</b></h5>
                    <h5>Total Product quantity: <b>{{totalQty}}</b></h5>
                    <h5>Balance: <b><span [innerHTML]="currencyLoader[currency]"></span> {{transBalance | number : '1.2-2' }}</b></h5>

                    <ul *ngIf="!enableMomoPayment" class="list-group list-group-flush">
                        
                        <form [formGroup]="customerForm"  >
                            <li *ngIf="takeCustomerDetails" class="list-group-item">
                                <mat-form-field class = "tp-full-width">
                                    <input matInput placeholder = "Customer Name" formControlName="customerName">
                                 </mat-form-field>
                                 <mat-form-field class = "tp-full-width">
                                    <input matInput placeholder = "Custormer Email" formControlName="customerEmail">
                                    <mat-error *ngIf = "c.customerEmail.hasError('email') 
                                       && !c.customerEmail.hasError('required')">
                                       Please enter a valid email address
                                    </mat-error>
                                    <!-- <mat-error *ngIf = "emailFormControl.hasError('required')">
                                       Email is <strong>required</strong>
                                    </mat-error> -->
                                 </mat-form-field>
                                 <mat-form-field class = "tp-full-width">
                                    <input matInput placeholder="Customer Phone no" maxlength="15" formControlName="customerPhone">
                                 </mat-form-field>
                            </li >
                        </form>
                        <li  *ngFor='let payOption of paymentOptions;' class="list-group-item">
                            <mat-form-field *ngIf="payOption.allowTenderedAmount" class="tp-full-width">
                                <input id="transBalance" matInput type="number" min="{{totalAmount}}" (keyup)="calculateTransactionBalance($event)" placeholder="Paying with Cash? Update tendered amount" value="{{amountTendered}}" >
                            </mat-form-field>
                            <button class="btn btn-teal btn-sm lift" (click)='processProductPayment(payOption)' [disabled]="!customerForm.valid || salesProcessing || isWrongTenderedAmount" type="button">Pay with {{payOption.name}}</button>
                        </li >
                        <span class="notification float-right" *ngIf="salesProcessing"> Processing payment .... <img src="../../../assets/landing/images/Infinity.gif" class="imgNot"></span>
                    </ul>
                    <ul *ngIf="enableMomoPayment" class="list-group list-group-flush">
                        
                        <form *ngIf="!confirmMomoPayment" [formGroup]="momoForm" (ngSubmit)="processProductMomoPayment(momoForm.value)" >
                            <li class="list-group-item">
                                <!-- <mat-form-field class="tp-full-width">
                                    <mat-select placeholder = "Choose Mobile Network" formControlName="mobileNetwork">
                                       <mat-option *ngFor = "let mobile of mobileNetworks" [value]= "mobile.value">
                                          {{mobile.display}}
                                       </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf = "m.mobileNetwork.hasError('required')">
                                        Please choose a mobile network provider
                                     </mat-error>
                                </mat-form-field> -->
                                <div class="form-group" class="tp-full-width">
                                    <label class="small mb-1" for="mobileNetwork">Choose mobile network</label>
                                    <select class="form-control" id="mobileNetwork" formControlName="mobileNetwork" >
                                        <option *ngFor="let mobile of mobileNetworks; let i=index;" value="{{mobile.value}}">{{mobile.display}}</option>
                                    </select>
                                    <div *ngIf="m.mobileNetwork.invalid && m.mobileNetwork.touched">
                                        <small *ngIf="m.mobileNetwork.errors?.required" class="small-warning text-danger">*Please choose a mobile network!</small>
                                    </div>
                                </div>

                                <!-- <mat-form-field class="tp-full-width">
                                    <input matInput placeholder = "Mobile" formControlName="mobile">
                                    <mat-error *ngIf = "m.mobile.hasError('required')">
                                       Please enter a mobile number
                                    </mat-error>
                                 </mat-form-field> -->

                                 <div class="form-group" class="tp-full-width">
                                    <label class="small mb-1" for="mobilenumber">Mobile number</label>
                                    <input class="form-control" id="mobilenumber" type="text" placeholder="Mobile network number" formControlName="mobile" />
                                    <div *ngIf="m.mobileNetwork.invalid && m.mobileNetwork.touched">
                                        <small *ngIf="m.mobile.errors?.required" class="small-warning text-danger">*Please enter a mobile number!</small>
                                    </div>
                                </div>

                                <div style="margin-top: 10px;">
                                    <button class="btn btn-primary btn-sm lift" type="submit" [disabled]="!momoForm.valid || salesPaymentProcessing">Process payment</button>
                                </div>
                                
                            </li >
                        </form>
                        <li *ngIf="confirmMomoPayment" class="list-group-item">
                            <p>Please click on the confirmation button to confirm payment by customer</p>
                            <button class="btn btn-primary btn-sm lift" (click)="confirmProductMomoPayment()" type="button">Confirm payment</button>
                            <button class="btn btn-danger btn-sm lift float-right" (click)="cancelMomoPaymentConfirmation()" type="button">Cancel payment</button>
                        </li>

                        <span class="notification float-right" *ngIf="salesPaymentProcessing"> Processing payment .... <img src="../../../assets/landing/images/Infinity.gif" class="imgNot"></span>
                    </ul>

                    <div class="list-group list-group-flush">
                        <div *ngFor='let cart of productSalesList; let i=index;' class="list-group-item d-flex align-items-center justify-content-between small px-0 py-2">
                            <div class="mr-3">
                                <i class="fas fa-circle fa-sm mr-1" [class.text-green]="(i+1)%2 == 0" [class.text-purple]="(i+1)%2 == 1"></i>
                                {{cart.productName}}
                            </div>
                            <div class="font-weight-500 text-dark"><span [innerHTML]="currencyLoader[currency]"></span> {{cart.amount}} | x{{cart.quantity}}</div>
                        </div>
                        
                    </div>
                </div>

            </div>
            <div *ngIf="!printReceipt" class="modal-footer">
                <button class="btn btn-sm float-left" [class.btn-success]="!takeCustomerDetails" [class.btn-primary]="takeCustomerDetails" type="button" (click)="activateCustomerDetails()"><span *ngIf="!takeCustomerDetails" style="margin-right: 2px;">Enter </span> <span *ngIf="takeCustomerDetails" style="margin-right: 2px;">Close </span> customer details</button>
                <button class="btn btn-sm btn-danger closePay" type="button" data-dismiss="modal">Close</button>
            </div>
            <div *ngIf="printReceipt" class="modal-body">
                <div id="pdfViewer" class="pdf-viewer">
                    <pdf-viewer
                        [src]="pdfSrc"
                        [render-text]="false"
                        [original-size]="false"
                        [fit-to-page]="true"
                        style="display: block;">
                    </pdf-viewer>
                </div>
            </div>
            <div *ngIf="printReceipt" class="modal-footer">
                <button class="btn btn-sm btn-success float-left" type="button" (click)="printSales()">Print</button>
                <button class="btn btn-sm btn-danger closePay" type="button" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<!-- Receipt Modal -->
<div class="modal fade" id="exampleModalScrollableR" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitleR" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalScrollableTitleR">Payment receipt</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
            </div>
            
            <div class="modal-body">
                
                <div id="pdfViewer2" class="pdf-viewer">
                    <pdf-viewer
                        [src]="pdfSrc"
                        [render-text]="false"
                        [page]="1"
                        [original-size]="false"
                        [fit-to-page]="true"
                        style="display: block;">
                    </pdf-viewer>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-sm btn-success float-left" type="button" (click)="printSales()">Print</button>
                <button class="btn btn-sm btn-danger" type="button" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<!-- View sales Modal -->
<div  class="modal fade" id="exampleModalScrollable" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalScrollableTitle">Transaction Details</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
                <div class="card-body">
                    <h5>Total amount: <b><span [innerHTML]="currencyLoader[currency]"></span> {{sTotalAmount | number : '1.2-2'}}</b></h5>
                    <h5>Total Product quantity: <b>{{sTotalQty}}</b></h5>
                    <p class="small mb-1">Reference: <b>{{sTransactionRef}}</b></p>
                    <div class="list-group list-group-flush">
                        <div *ngFor='let sale of sSalesProducts; let i=index;' class="list-group-item d-flex align-items-center justify-content-between small px-0 py-2">
                            <div class="mr-3">
                                <i class="fas fa-circle fa-sm mr-1" [class.text-green]="(i+1)%2 === 0" [class.text-purple]="(i+1)%2 === 1"></i>
                                {{sale.product.productName}}
                            </div>
                            <div class="font-weight-500 text-dark"><span [innerHTML]="currencyLoader[currency]"></span>{{sale.totalAmount | number : '1.2-2'}} | x{{sale.quantity}}</div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="modal-footer"><button class="btn btn-secondary btn-sm btn-danger" type="button" data-dismiss="modal">Close</button></div>
        </div>
    </div>
</div>

<div id="scriptDiv">
</div>






