<header
  class="
    page-header page-header-dark
    bg-gradient-primary-to-secondaryadmin
    pb-10
  "
>
  <div class="container">
    <div class="page-header-content pt-4">
      <div class="row align-items-center justify-content-between">
        <div class="col-auto mt-4">
          <h1 class="page-header-title">
            <div class="page-header-icon"><i data-feather="activity"></i></div>
            Salesboard
          </h1>
          <div class="page-header-subtitle">
            Inventory management system admin settlement transaction sales.
            <a
              style="margin-left: 10px"
              class="btn btn-sm btn-warning float-right"
              [routerLink]="['/admin/dashboard/settlements']"
            >
              &#x2190; Return to settlements
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<div class="container mt-n10">
  <div class="row">
    <div class="col-xl-3 col-md-6 mb-4">
      <!-- Dashboard info widget 1-->
      <div
        class="
          card
          border-top-0
          border-bottom-0
          border-right-0
          border-left-lg
          border-primary
          h-100
        "
      >
        <div class="card-body">
          <div class="d-flex align-items-center">
            <div class="flex-grow-1">
              <div class="small font-weight-bold text-primary mb-1">
                Today Sales ({{ productSalesAnalytics.totalDaySalesCount }})
              </div>
              <div class="h5">
                <span [innerHTML]="currencyLoader[currency]"></span
                >{{
                  productSalesAnalytics.totalDaySalesAmount | number: "1.2-2"
                }}
              </div>
              <div
                class="
                  text-xs
                  font-weight-bold
                  text-success
                  d-inline-flex
                  align-items-center
                "
              >
                <i class="mr-1" data-feather="trending-up"></i>
                {{
                  productSalesAnalytics.averageDaySalesAmount /
                    productSalesAnalytics.averageDaySalesCount /
                    100 | number: "1.2-2"
                }}%
              </div>
            </div>
            <div class="ml-2">
              <i class="fas fa-tag fa-2x fa-2x text-gray-200"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-md-6 mb-4">
      <!-- Dashboard info widget 3-->
      <div
        class="
          card
          border-top-0
          border-bottom-0
          border-right-0
          border-left-lg
          border-success
          h-100
        "
      >
        <div class="card-body">
          <div class="d-flex align-items-center">
            <div class="flex-grow-1">
              <div class="small font-weight-bold text-success mb-1">
                Current Week ({{ productSalesAnalytics.totalWeekSalesCount }})
              </div>
              <div class="h5">
                <span [innerHTML]="currencyLoader[currency]"></span
                >{{
                  productSalesAnalytics.totalWeekSalesAmount | number: "1.2-2"
                }}
              </div>
              <div
                class="
                  text-xs
                  font-weight-bold
                  text-success
                  d-inline-flex
                  align-items-center
                "
              >
                <i
                  *ngIf="productSalesAnalytics.weekGrowthType == 'NEGATIVE'"
                  class="mr-1"
                  data-feather="trending-down"
                ></i>
                <i
                  *ngIf="productSalesAnalytics.weekGrowthType == 'POSITIVE'"
                  class="mr-1"
                  data-feather="trending-up"
                ></i>
                {{
                  productSalesAnalytics.totalWeekSalesAmount /
                    productSalesAnalytics.prevWeekSalesAmount | number: "1.2-2"
                }}%
              </div>
            </div>
            <div class="ml-2">
              <i class="fas fa-mouse-pointer fa-2x text-gray-200"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-md-6 mb-4">
      <!-- Dashboard info widget 2-->
      <div
        class="
          card
          border-top-0
          border-bottom-0
          border-right-0
          border-left-lg
          border-secondary
          h-100
        "
      >
        <div class="card-body">
          <div class="d-flex align-items-center">
            <div class="flex-grow-1">
              <div class="small font-weight-bold text-secondary mb-1">
                Current Month ({{ productSalesAnalytics.totalMonthSalesCount }})
              </div>
              <div class="h5">
                <span [innerHTML]="currencyLoader[currency]"></span
                >{{
                  productSalesAnalytics.totalMonthSalesAmount | number: "1.2-2"
                }}
              </div>
              <div
                class="
                  text-xs
                  font-weight-bold
                  text-danger
                  d-inline-flex
                  align-items-center
                "
              >
                <i
                  *ngIf="productSalesAnalytics.monthGrowthType == 'NEGATIVE'"
                  class="mr-1"
                  data-feather="trending-down"
                ></i>
                <i
                  *ngIf="productSalesAnalytics.monthGrowthType == 'POSITIVE'"
                  class="mr-1"
                  data-feather="trending-up"
                ></i>
                {{ productSalesAnalytics.monthGrowthPercentage }}%
              </div>
            </div>
            <div class="ml-2">
              <i class="fas fa-tag fa-2x text-gray-200"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-md-6 mb-4">
      <!-- Dashboard info widget 4-->
      <div
        class="
          card
          border-top-0 border-bottom-0 border-right-0 border-left-lg border-info
          h-100
        "
      >
        <div class="card-body">
          <div class="d-flex align-items-center">
            <div class="flex-grow-1">
              <div class="small font-weight-bold text-info mb-1">
                Last Month ({{
                  productSalesAnalytics.prevMonthAverageSalesCount
                }})
              </div>
              <div class="h5">
                <span [innerHTML]="currencyLoader[currency]"></span
                >{{
                  productSalesAnalytics.prevMonthAverageSalesAmount
                    | number: "1.2-2"
                }}
              </div>
              <div
                class="
                  text-xs
                  font-weight-bold
                  text-danger
                  d-inline-flex
                  align-items-center
                "
              >
                <i
                  *ngIf="
                    productSalesAnalytics.averageMonthSalesAmount >
                    productSalesAnalytics.prevMonthAverageSalesAmount
                  "
                  class="mr-1"
                  data-feather="trending-down"
                ></i>
                <i
                  *ngIf="
                    productSalesAnalytics.averageMonthSalesAmount <
                    productSalesAnalytics.prevMonthAverageSalesAmount
                  "
                  class="mr-1"
                  data-feather="trending-up"
                ></i>
                <span
                  *ngIf="
                    productSalesAnalytics.averageMonthSalesAmount <
                    productSalesAnalytics.prevMonthAverageSalesAmount
                  "
                  >{{
                    productSalesAnalytics.prevMonthAverageSalesAmount /
                      productSalesAnalytics.totalMonthSalesAmount /
                      100 | number: "1.2-2"
                  }}%</span
                >
                <span
                  *ngIf="
                    productSalesAnalytics.averageMonthSalesAmount >
                    productSalesAnalytics.prevMonthAverageSalesAmount
                  "
                  >{{
                    (productSalesAnalytics.totalMonthSalesAmount /
                      productSalesAnalytics.prevMonthAverageSalesAmount) *
                      100 | number: "1.2-2"
                  }}%</span
                >
              </div>
            </div>
            <div class="ml-2">
              <i class="fas fa-percentage fa-2x text-gray-200"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!isSalesDetails" class="card mb-4">
    <div class="card-header">Product transaction sales</div>
    <div class="card-body">
      <span class="notification float-left" *ngIf="isLoading">
        Loading sales...
        <img src="../../../../assets/landing/images/ring1.gif" class="smLoader"
      /></span>
      <span class="notification float-left" *ngIf="showSearch">
        Searching...
        <img src="../../../../assets/landing/images/ring1.gif" class="smLoader"
      /></span>
      <span class="notification float-left" *ngIf="showProcessingFile">
        Processing download...
        <img src="../../../../assets/landing/images/ring1.gif" class="smLoader"
      /></span>
      <div>
        <button
          class="btn btn-outline-teal btn-sm btn-icon float-right"
          style="margin: 2px"
          (click)="downloadSalesRecord()"
          type="button"
        >
          <i data-feather="download"></i>
        </button>
      </div>
      <div class="form-group" class="tp-full-width">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <select
              class="form-control select2bs4"
              (change)="updateSettlemetSearchType($event)"
              style="width: 100%"
            >
              <option value="">ALL</option>
              <option
                *ngFor="let payOption of paymentOptions"
                value="{{ payOption.id }}"
              >
                {{ payOption.name }}
              </option>
            </select>
          </div>
          <input
            class="form-control"
            #searchField
            (keyup)="searchSettlementTransactions(searchField.value)"
            type="text"
            placeholder="Search transactions..."
            aria-label="Search"
          />
        </div>
      </div>
      <!-- <div class="input-group input-group-joined mb-4">
                <input class="form-control" #searchField (keyup)="searchProductSales(searchField.value)" type="text" placeholder="Search sales..." aria-label="Search" />
                <div class="input-group-append">
                    <span class="input-group-text"><i data-feather="search"></i></span>
                </div>
            </div> -->
      <div class="datatable">
        <table
          class="table table-bordered table-hover"
          width="100%"
          cellspacing="0"
        >
          <thead>
            <tr style="font-size: 12px">
              <th>S/N</th>
              <th>Date</th>
              <th>Amount</th>
              <th>Channel</th>
              <th>Ref</th>
              <th>Store name</th>
              <th>Cash</th>
              <th>Balance</th>
              <th>Mobile</th>
              <th>T. Qty</th>
              <th>Vat</th>
              <!-- <th>Levy</th>
                            <th>GHIF</th> -->
              <th>Cashier</th>
              <th>Status</th>
              <th>View</th>
            </tr>
          </thead>

          <tbody>
            <tr
              style="font-size: 12px"
              *ngFor="let trans of transactions; let i = index"
            >
              <td>{{ (currentPage - 1) * perPageCount + (i + 1) }}</td>
              <td>
                <span style="width: 25px">{{
                  trans.createdAt | date: "short"
                }}</span>
              </td>
              <td>
                <span [innerHTML]="currencyLoader[currency]"></span>
                {{ trans.amount | number: "1.2-2" }}
              </td>
              <td>
                <span>
                  <i
                    *ngIf="trans.paymentChannelType == 'Cash'"
                    class="text-green"
                    data-feather="file-plus"
                  ></i>
                  <i
                    *ngIf="
                      trans.paymentChannelType == 'Card' ||
                      trans.paymentChannelType == 'Cards'
                    "
                    class="text-green"
                    data-feather="credit-card"
                  ></i>
                  <i
                    *ngIf="trans.paymentChannelType == 'Mobile Money'"
                    class="text-pink"
                    data-feather="layout"
                  ></i>
                  <i
                    *ngIf="trans.paymentChannelType == 'Pos'"
                    class="text-orange"
                    data-feather="archive"
                  ></i>
                  {{ trans.paymentChannelType }}
                </span>
              </td>
              <td>
                <div class="d-flex justify-content-between align-items-center">
                  <span
                    class="d-inline-block text-truncate"
                    style="max-width: 40px"
                    *ngIf="trans.transactionRef"
                    >{{ trans.transactionRef }}</span
                  >
                  <span *ngIf="!trans.transactionRef">n/a</span>
                  <svg
                    width="10"
                    height="10"
                    (click)="auth.handleCopy($event, trans.transactionRef)"
                    viewBox="0 0 9 10"
                    fill="none"
                    color="#0DB450"
                    class="cursor-pointer"
                  >
                    <path
                      d="M5.293 10h-3.73C.7 10 0 9.3 0 8.437V3.146c0-.862.7-1.563 1.563-1.563h3.73c.862 0 1.562.7 1.562 1.563v5.293c0 .861-.7 1.562-1.562 1.562zm-3.73-7.637c-.431 0-.782.35-.782.782v5.293c0 .43.35.78.781.78h3.731c.43 0 .781-.35.781-.78V3.144c0-.431-.35-.782-.781-.782h-3.73zm6.855 5.098V1.562C8.418.702 7.718 0 6.855 0H2.52a.39.39 0 100 .781h4.335c.431 0 .782.35.782.781v5.899a.39.39 0 10.781 0z"
                      fill="#ccc"
                    ></path>
                  </svg>
                </div>
              </td>
              <td style="word-break: break-word; max-width: 60px">
                {{ trans.storeName }}
              </td>
              <td>
                <span *ngIf="trans.amountTendered; else noCash"
                  ><span [innerHTML]="currencyLoader[currency]"></span>
                  {{ trans.amountTendered | number: "1.2-2" }}</span
                >
                <ng-template #noCash>
                  <span
                    ><span [innerHTML]="currencyLoader[currency]"></span>
                    {{ trans.amount | number: "1.2-2" }}</span
                  >
                </ng-template>
              </td>
              <td>
                <span *ngIf="trans.customerBalance; else noBalance"
                  ><span [innerHTML]="currencyLoader[currency]"></span
                  >{{ trans.customerBalance | number: "1.2-2" }}</span
                >
                <ng-template #noBalance>
                  <span
                    ><span [innerHTML]="currencyLoader[currency]"></span>
                    0.00</span
                  >
                </ng-template>
              </td>
              <td>
                <div class="justify-content-start align-items-start">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <span>N : </span>
                    <div
                      class="cursor-pointer btn-link"
                      style="width: 35px; margin-left: 5px; margin-right: 2px"
                    >
                      <span
                        class="d-inline-block text-truncate"
                        style="max-width: 40px"
                        *ngIf="trans.mobileNetwork"
                        >{{ trans.mobileNetwork }}</span
                      >
                      <span *ngIf="!trans.mobileNetwork">n/a</span>
                    </div>
                    <svg
                      width="10"
                      height="10"
                      (click)="auth.handleCopy($event, trans.mobileNetwork)"
                      viewBox="0 0 9 10"
                      fill="none"
                      color="#0DB450"
                      class="cursor-pointer"
                    >
                      <path
                        d="M5.293 10h-3.73C.7 10 0 9.3 0 8.437V3.146c0-.862.7-1.563 1.563-1.563h3.73c.862 0 1.562.7 1.562 1.563v5.293c0 .861-.7 1.562-1.562 1.562zm-3.73-7.637c-.431 0-.782.35-.782.782v5.293c0 .43.35.78.781.78h3.731c.43 0 .781-.35.781-.78V3.144c0-.431-.35-.782-.781-.782h-3.73zm6.855 5.098V1.562C8.418.702 7.718 0 6.855 0H2.52a.39.39 0 100 .781h4.335c.431 0 .782.35.782.781v5.899a.39.39 0 10.781 0z"
                        fill="#ccc"
                      ></path>
                    </svg>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <span>M : </span>
                    <div
                      class="cursor-pointer btn-link"
                      style="width: 35px; margin-left: 5px; margin-right: 2px"
                    >
                      <span
                        class="d-inline-block text-truncate"
                        style="max-width: 40px"
                        *ngIf="trans.mobile"
                        >{{ trans.mobile }}</span
                      >
                      <span *ngIf="!trans.mobile">n/a</span>
                    </div>
                    <svg
                      width="10"
                      height="10"
                      (click)="auth.handleCopy($event, trans.mobile)"
                      viewBox="0 0 9 10"
                      fill="none"
                      color="#0DB450"
                      class="cursor-pointer"
                    >
                      <path
                        d="M5.293 10h-3.73C.7 10 0 9.3 0 8.437V3.146c0-.862.7-1.563 1.563-1.563h3.73c.862 0 1.562.7 1.562 1.563v5.293c0 .861-.7 1.562-1.562 1.562zm-3.73-7.637c-.431 0-.782.35-.782.782v5.293c0 .43.35.78.781.78h3.731c.43 0 .781-.35.781-.78V3.144c0-.431-.35-.782-.781-.782h-3.73zm6.855 5.098V1.562C8.418.702 7.718 0 6.855 0H2.52a.39.39 0 100 .781h4.335c.431 0 .782.35.782.781v5.899a.39.39 0 10.781 0z"
                        fill="#ccc"
                      ></path>
                    </svg>
                  </div>
                </div>
              </td>
              <td>{{ trans.productQuantity }}</td>
              <td>
                <span *ngIf="trans.vat"
                  ><span [innerHTML]="currencyLoader[currency]"></span>
                  {{ trans.vat | number: "1.2-2" }}</span
                >
                <span *ngIf="!trans.vat">n/a</span>
              </td>
              <!-- <td>
                                <span *ngIf="(trans.levyFee)"><span [innerHTML]="currencyLoader[currency]"></span>{{trans.levyFee | number : '1.2-2'}}</span>
                                <span *ngIf="!(trans.levyFee)">n/a</span>
                            </td>
                            <td>
                                <span *ngIf="(trans.gtfFee)"><span [innerHTML]="currencyLoader[currency]"></span>{{trans.gtfFee | number : '1.2-2'}}</span>
                                <span *ngIf="!(trans.gtfFee)">n/a</span>
                            </td> -->
              <td>
                <div class="d-flex justify-content-between align-items-center">
                  <span
                    class="d-inline-block text-truncate"
                    style="max-width: 40px"
                    *ngIf="trans.soldByUser"
                    >{{ trans.soldByUser }}</span
                  >
                  <span *ngIf="!trans.soldByUser">n/a</span>
                  <svg
                    width="10"
                    height="10"
                    (click)="auth.handleCopy($event, trans.soldByUser)"
                    viewBox="0 0 9 10"
                    fill="none"
                    color="#0DB450"
                    class="cursor-pointer"
                  >
                    <path
                      d="M5.293 10h-3.73C.7 10 0 9.3 0 8.437V3.146c0-.862.7-1.563 1.563-1.563h3.73c.862 0 1.562.7 1.562 1.563v5.293c0 .861-.7 1.562-1.562 1.562zm-3.73-7.637c-.431 0-.782.35-.782.782v5.293c0 .43.35.78.781.78h3.731c.43 0 .781-.35.781-.78V3.144c0-.431-.35-.782-.781-.782h-3.73zm6.855 5.098V1.562C8.418.702 7.718 0 6.855 0H2.52a.39.39 0 100 .781h4.335c.431 0 .782.35.782.781v5.899a.39.39 0 10.781 0z"
                      fill="#ccc"
                    ></path>
                  </svg>
                </div>
              </td>
              <td>
                <div
                  *ngIf="trans.channelStatusCode === '00'"
                  class="badge badge-success badge-pill"
                  style="font-size: 0.85em; padding: 0.75em"
                >
                  Success
                </div>
                <div
                  *ngIf="trans.channelStatusCode !== '00'"
                  class="badge badge-danger badge-pill"
                  style="font-size: 0.85em; padding: 0.75em"
                >
                  Failed
                </div>
              </td>
              <td>
                <!-- <button class="btn btn-datatable btn-icon btn-transparent-dark mr-2"><i data-feather="more-vertical"></i></button> -->
                <!-- <button class="btn btn-datatable btn-icon btn-transparent-dark" (click)="viewSalesDetails(trans)"  data-toggle="modal" data-target="#exampleModalScrollable">
                                    <i class="text-purple" data-feather="eye"></i>
                                </button> -->
                <button
                  class="btn btn-outline-purple btn-icon btn-sm"
                  (click)="viewSalesDetails(trans)"
                  data-toggle="modal"
                  data-target="#exampleModalScrollable"
                  type="button"
                >
                  <i data-feather="eye"></i>
                </button>
                <!-- <button class="btn btn-icon btn-transparent-dark" (click)="viewSalesDetails(trans)" >
                                    <i class="text-purple" data-feather="eye"></i>
                                </button>
                                <button class="btn btn-outline-purple btn-icon btn-sm float-right" (click)="viewSalesDetails(trans)"  type="button">
                                    <i class="text-purple" data-feather="eye"></i>
                                </button> -->
                <img
                  *ngIf="salesDetailsLoader"
                  src="../../../../assets/landing/images/ring1.gif"
                  class="smLoaderSm"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        *ngIf="transactions?.length > 0; else noProductSales"
        class="clearfix"
      >
        <div class="hint-text">
          Showing <b>{{ totalCurrentPageCount }}</b> out of
          <b>{{ totalCount }}</b> entries
        </div>
        <div class="pg-text">
          <mat-select
            class="pg-width"
            [(value)]="perPageCount"
            (selectionChange)="changePerPage($event)"
          >
            <mat-option [value]="10">10</mat-option>
            <mat-option [value]="15">15</mat-option>
            <mat-option [value]="25">25</mat-option>
            <mat-option [value]="50">50</mat-option>
            <mat-option [value]="75">75</mat-option>
            <mat-option [value]="100">100</mat-option>
          </mat-select>
        </div>
        <ul class="pagination">
          <li class="page-item">
            <a (click)="paginateBack()" class="page-link mover"> << </a>
          </li>
          <li
            *ngFor="let pg of totalPageList; let i = index"
            class="page-item"
            [ngClass]="{ active: currentPage == pg.value }"
          >
            <a (click)="paginate(pg.key)" class="page-link">{{ pg.value }}</a>
          </li>
          <li class="page-item">
            <a (click)="paginateForward()" class="page-link mover"> >> </a>
          </li>
        </ul>
      </div>
      <div style="margin-top: 5px">
        <span class="notification float-left" *ngIf="isLoading">
          Loading sales...
          <img
            src="../../../../assets/landing/images/ring1.gif"
            class="smLoader"
        /></span>
      </div>
      <ng-template #noProductSales>
        <p>No sales record found!</p>
      </ng-template>
    </div>
  </div>

  <div *ngIf="isSalesDetails" class="card mb-4">
    <div class="card-header">
      Product transaction sales details
      <button
        class="btn btn-sm btn-primary float-right"
        (click)="viewSalesDetails(salesTransaction)"
      >
        Return to sales
      </button>
    </div>
    <div class="card-body">
      <h5>
        Total amount: <b>{{ totalAmount | number: "1.2-2" }}</b>
      </h5>
      <h5>
        Total Product quantity: <b>{{ totalQty }}</b>
      </h5>
      <p class="small mb-1">
        Reference: <b>{{ transactionRef }}</b>
      </p>
      <div class="list-group list-group-flush">
        <div
          *ngFor="let cart of salesProducts; let i = index"
          class="
            list-group-item
            d-flex
            align-items-center
            justify-content-between
            small
            px-0
            py-2
          "
        >
          <div class="mr-3">
            <i
              class="fas fa-circle fa-sm mr-1"
              [class.text-green]="(i + 1) % 2 == 0"
              [class.text-purple]="(i + 1) % 2 == 1"
            ></i>
            {{ cart.product.productName }}
          </div>
          <div class="font-weight-500 text-dark">
            {{ cart.totalAmount | number: "1.2-2" }} | x{{ cart.quantity }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModalScrollable"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalScrollableTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalScrollableTitle">
          Sales Details
        </h5>
        <button
          class="close"
          type="button"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="card-body">
          <h5>
            Total amount:
            <b
              ><span [innerHTML]="currencyLoader[currency]"></span>
              {{ totalAmount | number: "1.2-2" }}</b
            >
          </h5>
          <h5>
            Total Product quantity: <b>{{ totalQty }}</b>
          </h5>
          <p class="small mb-1">
            Reference: <b>{{ transactionRef }}</b>
          </p>
          <div class="list-group list-group-flush">
            <div
              *ngFor="let sale of salesProducts; let i = index"
              class="
                list-group-item
                d-flex
                align-items-center
                justify-content-between
                small
                px-0
                py-2
              "
            >
              <div class="mr-3">
                <i
                  class="fas fa-circle fa-sm mr-1"
                  [class.text-green]="(i + 1) % 2 === 0"
                  [class.text-purple]="(i + 1) % 2 === 1"
                ></i>
                {{ sale.product.productName }}
              </div>
              <div class="font-weight-500 text-dark">
                <span [innerHTML]="currencyLoader[currency]"></span>
                {{ sale.totalAmount | number: "1.2-2" }} | x{{ sale.quantity }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          class="btn btn-secondary btn-sm btn-danger"
          type="button"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<ngx-loading
  [show]="processingSalesAnalytics"
  [config]="{
    animationType: ngxLoadingAnimationTypes.rectangleBounce,
    primaryColour: primaryColour,
    backdropBorderRadius: '3px'
  }"
></ngx-loading>
