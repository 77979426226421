import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
 import { of } from 'rxjs';

import { AuthService } from '../../shared/services/auth.service';

//import * as jwt_decode from 'jwt-decode';

import {throwError as ObservableThrowError, Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import { Configuration } from 'src/app/config/configuration';
import { ApiRequestService } from './api-request.service';
import { ApiResponseObj } from '../models/loginApiResponse';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})

export class ClientAppService {

  private options = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
  };

  private termPaths = new Configuration;
  // private apiResponse = new ApiResMessage;

  private allData: any[];

  private months = {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December'
  }

  constructor(private http: HttpClient, private auth: AuthService,
     private _api: ApiRequestService, private alert: AlertService) { }


  setCopyDate(){
    var cDate = new Date();
    return cDate.getFullYear(); 
  }

  setCurrentFullDate(){
    var cDate = new Date();
    return this.months[cDate.getMonth()]+" "+cDate.getDate()+" "+cDate.getFullYear(); 
  }

  loggedInUser(){
    return this.auth.getUserData['name']; 
  }

  // public getStateLocals()  {
  //   // const option =  {'Content-Type': 'application/json'};
  //   return this.http.get(this.termPaths._stateLocalsUrlUser);
  // }

  // public getBanks() {
  //   return this.http.get(this.termPaths._bankListUrlUser);
  // }

  // public getBanks() {
  //   return this.http.get(this.termPaths._bankListUrl);
  // }



  public removeBackendUrlScript() {
    const rpScript = document.getElementById('pScript');
    if (rpScript) {
      document.getElementById('pScript').remove();
    }
    const rsScript = document.getElementById('sScript');
    if (rsScript) {
      document.getElementById('sScript').remove();
    }
    const rcScript = document.getElementById('cScript');
    if (rcScript) {
      document.getElementById('cScript').remove();
    }
    const rtScript = document.getElementById('tScript');
    if (rtScript) {
      document.getElementById('tScript').remove();
    }


    const rbnScript = document.getElementById('bnScript');
    if (rbnScript) {
      document.getElementById('bnScript').remove();
    }
    const rpaperScript = document.getElementById('paperScript');
    if (rpaperScript) {
      document.getElementById('paperScript').remove();
    }
    const racScript = document.getElementById('acScript');
    if (racScript) {
      document.getElementById('acScript').remove();
    }
    const rjqScript = document.getElementById('jqScript');
    if (rjqScript) {
      document.getElementById('jqScript').remove();
    }
    const rbotScript = document.getElementById('botScript');
    if (rbotScript) {
      document.getElementById('botScript').remove();
    }
  }

  public updateStoreUrlScripts() {
    //console.log('Updating store scripts..');
    // Remove front end Scripts if exist..
    // const r_fwScript = document.getElementById('_fwScript');
    // if (r_fwScript) {
    //   document.getElementById('_fwScript').remove();
    // }

    // const r_featherScript = document.getElementById('_featherScript');
    // if (r_featherScript) {
    //   document.getElementById('_featherScript').remove();
    // }

    // const r_jqScript = document.getElementById('_jqScript');
    // if (r_jqScript) {
    //   document.getElementById('_jqScript').remove();
    // }

    // const r_bstScript = document.getElementById('_bstScript');
    // if (r_bstScript) {
    //   document.getElementById('_bstScript').remove();
    // }

    const r_tsScript = document.getElementById('_tsScript');
    if (r_tsScript) {
      document.getElementById('_tsScript').remove();
    }
    const r_chartScript = document.getElementById('_chartScript');
    if (r_chartScript) {
      document.getElementById('_chartScript').remove();
    }
    // const r_chartDemoScript = document.getElementById('_chartDemoScript');
    // if (r_chartDemoScript) {
    //   document.getElementById('_chartDemoScript').remove();
    // }
  
    // const r_chartBarScript = document.getElementById('_chartBarScript');
    // if (r_chartBarScript) {
    //   document.getElementById('_chartBarScript').remove();
    // }

    const r_dataTableScript = document.getElementById('_dataTableScript');
    if (r_dataTableScript) {
      document.getElementById('_dataTableScript').remove();
    }

    // const r_bDataTableScript = document.getElementById('_bDataTableScript');
    // if (r_bDataTableScript) {
    //   document.getElementById('_bDataTableScript').remove();
    // }

    // const r_dataTableDemoScript = document.getElementById('_dataTableDemoScript');
    // if (r_dataTableDemoScript) {
    //   document.getElementById('_dataTableDemoScript').remove();
    // }

    // const r_momentScript = document.getElementById('_momentScript');
    // if (r_momentScript) {
    //   document.getElementById('_momentScript').remove();
    // }
    // const r_datePickerScript = document.getElementById('_datePickerScript');
    // if (r_datePickerScript) {
    //   document.getElementById('_datePickerScript').remove();
    // }
    // const r_datePickerDemoScript = document.getElementById('_datePickerDemoScript');
    // if (r_datePickerDemoScript) {
    //   document.getElementById('_datePickerDemoScript').remove();
    // }

    // const rjqScript = document.getElementById('jqScript');
    // if (rjqScript) {
    //   document.getElementById('jqScript').remove();
    // }
    // const rbotScript = document.getElementById('botScript');
    // if (rbotScript) {
    //   document.getElementById('botScript').remove();
    // }

    const _fwScript = document.createElement('script');
    const _featherScript = document.createElement('script');
    const _jqScript = document.createElement('script');
    const _bstScript = document.createElement('script');
    const _tsScript = document.createElement('script');
    const _chartScript = document.createElement('script');
    const _chartDemoScript = document.createElement('script');
    const _chartBarScript = document.createElement('script');
    const _dataTableScript = document.createElement('script');
    const _bDataTableScript = document.createElement('script');
    const _dataTableDemoScript = document.createElement('script');
    const _momentScript = document.createElement('script');
    const _datePickerScript = document.createElement('script');
    const _datePickerDemoScript = document.createElement('script');

    // _fwScript.setAttribute('id', '_fwScript');
    // _fwScript.setAttribute('src', this.termPaths._fwScript);
    // document.getElementById('scriptDiv').appendChild(_fwScript);

    // _featherScript.setAttribute('id', '_featherScript');
    // _featherScript.setAttribute('src', this.termPaths._featherScript);
    // document.getElementById('scriptDiv').appendChild(_featherScript);

    // _jqScript.setAttribute('id', '_jqScript');
    // _jqScript.setAttribute('src', this.termPaths._jqScript);
    // document.getElementById('scriptDiv').appendChild(_jqScript);

    // _bstScript.setAttribute('id', '_bstScript');
    // _bstScript.setAttribute('src', this.termPaths._bstScript);
    // document.getElementById('scriptDiv').appendChild(_bstScript);

    _tsScript.setAttribute('id', '_tsScript');
    _tsScript.setAttribute('src', this.termPaths._tsScript);
    document.getElementById('scriptDiv').appendChild(_tsScript);

    _chartScript.setAttribute('id', '_chartScript');
    _chartScript.setAttribute('src', this.termPaths._chartScript);
    document.getElementById('scriptDiv').appendChild(_chartScript);

    // _chartDemoScript.setAttribute('id', '_chartDemoScript');
    // _chartDemoScript.setAttribute('src', this.termPaths._chartDemoScript);
    // document.getElementById('scriptDiv').appendChild(_chartDemoScript);

    // _chartBarScript.setAttribute('id', '_chartBarScript');
    // _chartBarScript.setAttribute('src', this.termPaths._chartBarScript);
    // document.getElementById('scriptDiv').appendChild(_chartBarScript);

    // _dataTableScript.setAttribute('id', '_dataTableScript');
    // _dataTableScript.setAttribute('src', this.termPaths._dataTableScript);
    // document.getElementById('scriptDiv').appendChild(_dataTableScript);

    // _bDataTableScript.setAttribute('id', '_bDataTableScript');
    // _bDataTableScript.setAttribute('src', this.termPaths._bDataTableScript);
    // document.getElementById('scriptDiv').appendChild(_bDataTableScript);

    _dataTableDemoScript.setAttribute('id', '_dataTableDemoScript');
    _dataTableDemoScript.setAttribute('src', this.termPaths._dataTableDemoScript);
    document.getElementById('scriptDiv').appendChild(_dataTableDemoScript);

    // _momentScript.setAttribute('id', '_momentScript');
    // _momentScript.setAttribute('src', this.termPaths._momentScript);
    // document.getElementById('scriptDiv').appendChild(_momentScript);

    // _datePickerScript.setAttribute('id', '_datePickerScript');
    // _datePickerScript.setAttribute('src', this.termPaths._datePickerScript);
    // document.getElementById('scriptDiv').appendChild(_datePickerScript);

    _datePickerDemoScript.setAttribute('id', '_datePickerDemoScript');
    _datePickerDemoScript.setAttribute('src', this.termPaths._datePickerDemoScript);
    document.getElementById('scriptDiv').appendChild(_datePickerDemoScript);


  }

  public confirmRegistration() {
    return this.auth.isRegistered();
  }

 

  // public loadRegisterationForm() {
  //   let headers = new HttpHeaders();
  //   headers = headers.set('Accept', 'application/pdf');
  //   return this.http.get(this.termPaths._regFormUrl, {headers: headers, responseType: 'blob'});
  // }

  public uploadForm(formObject: FormData) {
    const options = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*'
    };

    const option = {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*'
    };

    const formData = JSON.stringify(formObject);
    //console.log(formObject);

    return this.http.post(this.termPaths._productUploadUrl, formObject, {
      observe: 'response'
    });
  }

  public processRequest(url: String, reqType?: String, reqBody?: any,): Observable<ApiResponseObj>  {
  
    let response = new ApiResponseObj;
    let reqData = '';
    if(reqBody){
      reqData = JSON.stringify(reqBody);
    }

    switch (reqType) {
      case 'get':
        this._api.get(url).subscribe(res => {
          console.log(res);


          response.status = res.body['status'];
          response.statusCode = res.body['statusCode'];
          response.statusMessage = res.body['statusMessage'];
          response.data = res.body['data'];
          response.message =  res.body['message'];
          response.errorMessage = res.body['errorMessage'];
          
        });
        break;
      case 'post':
        this._api.post(url, reqData).subscribe(res => {
          // console.log(res);
          console.log(res);
          response.status =  res.body['status'];
          response.statusCode = res.body['statusCode'];
          response.statusMessage = res.body['statusMessage'];
          response.data = res.body['data'];
          response.message =  res.body['message'];
          response.errorMessage = res.body['errorMessage'];
          
        });

      break;
      case 'update':
      
        this._api.put(url, reqData).subscribe(res => {

        response.status =  res.body['status'];
        response.statusCode = res.body['statusCode'];
        response.statusMessage = res.body['statusMessage'];
        response.data = res.body['data'];
        response.message =  res.body['message'];
        response.errorMessage = res.body['errorMessage'];
        
        });
      break;
      case 'delete':
      
        this._api.delete(url, reqData).subscribe(res => {

        response.status =  res.body['status'];
        response.statusCode = res.body['statusCode'];
        response.statusMessage = res.body['statusMessage'];
        response.data = res.body['data'];
        response.message =  res.body['message'];
        response.errorMessage = res.body['errorMessage'];

        });

      break;
      default:
        this._api.get(url).subscribe(res => {
          console.log(res);
        response.status =  res.body['status'];
        response.statusCode = res.body['statusCode'];
        response.statusMessage = res.body['statusMessage'];
        response.data = res.body['data'];
        response.message =  res.body['message'];
        response.errorMessage = res.body['errorMessage'];
          
        });
        break;
    }
    return of(response);
  }

  public processRequestMapped(url: String, reqType?: String, reqBody?: any, jsonString?: boolean)  {
  
    let reqData = '';
    if(!jsonString){
      reqData = JSON.stringify(reqBody);
    }else{
      reqData = reqBody;
    }

    switch (reqType) {
      case 'get':
        return this.get(url);

        break;
      case 'post':
        return this.post(url, reqData);

      break;
      case 'put':
        return this.put(url, reqData);
      break;
      case 'delete':
        return this.delete(url, reqData);
        
      break;
      default:
        return this.get(url);
        
        break;
    }
    
  }

  // getDecodedToken(token: string) {
  //   try {
  //     return jwt_decode(token);
  //   } catch (error) {
  //     return null;
  //   }
  // }

  get(url) {
      return this.http.get(url, {observe: 'response',  headers: new HttpHeaders(this.options)})
      .pipe(catchError(this.handleError));
  }

  post(url, body) {
      return this.http.post(url, body, {observe: 'response', headers: new HttpHeaders(this.options)}).pipe(catchError(this.handleError));
  }

  put(url, body) {
      return this.http.put(url, body, {observe: 'response',  headers: new HttpHeaders(this.options) }).pipe(catchError(this.handleError));
  }

  delete(url, authorized) {

      return this.http.delete(url, {observe: 'response',  headers: new HttpHeaders(this.options)}).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    
    let options = {text: '', title: ''};
    let errMsg = error.error['errorMessage'];
    options.text = errMsg;
    options.title = 'Failed';
    if (error.status === 0 && error.error instanceof ProgressEvent) {
      let conError = "Check your internet connection!!";
      console.log('Client side network error:', error.error)
      error.error['errorMessage'] = conError;
    }
    return ObservableThrowError(error || {});
  }


}
