import { Component, OnInit } from '@angular/core';
import { Configuration } from 'src/app/config/configuration';
import { ApiResponseObj } from 'src/app/shared/models/loginApiResponse';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClientAppService } from 'src/app/shared/services/clientapp.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-adminsecurity',
  templateUrl: './adminsecurity.component.html',
  styleUrls: ['./adminsecurity.component.css']
})
export class AdminsecurityComponent implements OnInit {

  private termPaths = new Configuration;
  private reqResponseObject = new ApiResponseObj;
  public showProcessing: boolean;

  public options = {type: '', title: '', text: ''};

  public afuConfig = {};
  public userPasswordForm: FormGroup;

  public uploadingFile = false;
  public isUploadDetails = false;
  public isUpdating = false;
  public userProfile;

  constructor(private _clientApp: ClientAppService, public auth: AuthService, 
    private alert: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder) { }

  ngOnInit(): void {

    this.userProfile = this.auth.getUser();

    this.userPasswordForm = this.fb.group({
      oldPassword: ["", Validators.required],
      newPassword: ["", [Validators.required, Validators.minLength(8)]],
      cPassword: ["", Validators.required],
      passwordHint: [""] });

    //this._clientApp.updateStoreUrlScripts();

  }

  updateProfilePassword(submittedValue: any) {
    this.showProcessing = true;
    this.isUpdating = !this.isUpdating;
    
    submittedValue.id = this.userProfile.id;

    if(submittedValue.newPassword !== submittedValue.cPassword){
      this.showProcessing = !this.showProcessing;
      this.isUpdating = !this.isUpdating;
      this.options.type = 'Warning';
      this.options.text = "New Password does not match your confirm password!";
      this.alert.doToastAlert(this.options, 3);
      return;
    }

    let reqBody = JSON.stringify(submittedValue);
    //console.log(reqBody);
    let url = this.termPaths._adminBaseUrl+ "system/userprofile/"+this.userProfile.id;

    this._clientApp.put(url, reqBody)
    .subscribe((res) => {

      this.reqResponseObject = this.auth.mapHttpResponse(res);
      const statusCode = this.reqResponseObject.statusCode;
      const errorMsg = this.reqResponseObject.errorMessage;

        const statusMsg = this.reqResponseObject.statusMessage

        this.showProcessing = !this.showProcessing;
        this.isUpdating = !this.isUpdating;

        if(statusCode == '00'){

          this.options.text = statusMsg;
          this.options.title = 'Successful';

          this.alert.doToastAlert(this.options, 1);
          this.auth.logout();

          
        }else{

          this.options.type = 'error';
          this.options.text = errorMsg;
          this.alert.doToastAlert(this.options, 4);
        }

    },  err => {
      
      this.options.type = 'error';
      this.options.text = err.error.errorMessage;
      //console.log(this.options.text);
      this.alert.doToastAlert(this.options, 4);
      this.showProcessing = !this.showProcessing;
      this.isUpdating = !this.isUpdating;
      
    });

  }

  get f(){
    return this.userPasswordForm.controls;
  }

}
