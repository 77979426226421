import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Configuration } from 'src/app/config/configuration';
import { ApiResponseObj } from 'src/app/shared/models/loginApiResponse';
import { ClientAppService } from 'src/app/shared/services/clientapp.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AlertService } from 'src/app/shared/services/alert.service';

import swal from 'sweetalert2';
import { FileExportService } from 'src/app/shared/services/file-export.service';

import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { SalesHistory } from 'src/app/shared/storeentities/SalesHistory';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-adminterminals',
  templateUrl: './adminterminals.component.html',
  styleUrls: ['./adminterminals.component.css'],
})
export class AdminterminalsComponent implements OnInit {
  private termPaths = new Configuration();
  private reqResponseObject = new ApiResponseObj();
  public showProcessing: boolean;
  public countryCode;

  public options = { type: '', title: '', text: '' };

  public perPageCount = 25;
  public activePage = 1;
  public prevPage = 1;
  public totalPages;
  public totalPageList: any[] = [];
  public currentPage = 1;
  public totalCount;

  public totalCurrentPageCount = 20;

  private terminalSearch = '';
  public showSearch = false;

  public storeTerminals;
  private storeTerminalId;
  private terminalSalesId;

  public currency = 'GHS';
  public currencyLoader = {
    NGN: '&#8358;',
    GHS: '&#8373;',
  };

  public totalAmount: number = 0;
  public totalQty = 0;

  public storeUsers;
  public deassignLoader = false;
  public terminalAction = 'Add ';

  public isTerminalSalesDetails: boolean;
  private productSearch = '';
  public isLoading = false;
  public salesDetailsLoader = false;
  public isSalesDetails = false;
  public transactionRef = '';
  public salesTransaction;
  public salesProducts;
  public pdfSrc = '';
  public salesDownloadList: SalesHistory[] = [];
  public showProcessingFile: boolean;
  public transactions: any[];

  public stores: any[] = [];

  public addTerminalPosForm: FormGroup;
  public salesPointAssignForm: FormGroup;

  filteredOptions: Observable<any[]>;

  constructor(
    private _clientApp: ClientAppService,
    public auth: AuthService,
    private fb: FormBuilder,
    private fileExport: FileExportService,
    private alert: AlertService,
    private changeDetector: ChangeDetectorRef ) {}

  ngOnInit(): void {
    this.addTerminalPosForm = this.fb.group({
      terminalId: ['', Validators.required],
      terminalSN: [''],
      modelName: [''],
      modelVersion: [''],
      modelYear: [''],
      modelSerialNo: [''],
      status: ['ACTIVE'],
    });

    this.salesPointAssignForm = this.fb.group({
      storeProfileId: ['', Validators.required],
    });

    this.loadPOSTerminals();
    this.loadRegisteredStores();
  }

  // private _filter(value: any): any[] {
  //   const filterValue = value.toLowerCase();
  //   //console.log('Filter value: ' + filterValue + ' stores: ' + value);
  //   return this.stores.filter((store) =>
  //     store.storeName.toLowerCase().includes(filterValue)
  //   );
  // }

  loadPOSTerminals() {
    this.showProcessing = true;
    let url =
      this.termPaths._adminBaseUrl +
      'loadposterminals?search=' +
      this.terminalSearch +
      '&page=' +
      this.currentPage +
      '&size=' +
      this.perPageCount;

    this._clientApp.get(url).subscribe((res) => {
      //console.log(res);
      this.showProcessing = false;
      this.storeTerminals = res.body['data']['payload'];

      this.reqResponseObject = this.auth.mapHttpResponse(res);

      // console.log(res.body);
      // console.log(res.body['data']['payload'])

      if (this.reqResponseObject) {
        this.totalCount = this.reqResponseObject.data.totalCount;
        this.totalPages = this.reqResponseObject.data.totalPages;
        this.setPaginationList(this.totalPages);
        this.currentPage = this.reqResponseObject.data.page;
        //this.storeTerminals = this.reqResponseObject.data.payload;
        
        //this.storeTerminals = res['data']['payload'];

        if (this.currentPage >= 1) {
          if (this.totalCurrentPageCount > this.totalCount) {
            this.totalCurrentPageCount = this.totalCount;
          } else {
            this.totalCurrentPageCount = this.perPageCount * this.currentPage;
            if (this.totalCurrentPageCount >= this.totalCount) {
              this.totalCurrentPageCount = this.totalCount;
            }
          }
        }

        this._clientApp.updateStoreUrlScripts();
        this.changeDetector.detectChanges();
      }
    });
  }

  searchTerminals(txt) {
    if (txt !== this.terminalSearch) {
      this.terminalSearch = txt;
      this.showSearch = true;

      this.currentPage = 1;

      let url =
        this.termPaths._adminBaseUrl +
        'loadposterminals?search=' +
        this.terminalSearch +
        '&page=' +
        this.currentPage +
        '&size=' +
        this.perPageCount;

      console.log(url);

      this._clientApp.get(url).subscribe((res) => {
        this.reqResponseObject = this.auth.mapHttpResponse(res);
        this.showSearch = false;
        if (this.reqResponseObject) {
          this.totalCount = this.reqResponseObject.data.totalCount;
          this.totalPages = this.reqResponseObject.data.totalPages;
          this.setPaginationList(this.totalPages);
          this.currentPage = this.reqResponseObject.data.page;
          this.storeTerminals = this.reqResponseObject.data.payload;

          if (this.currentPage >= 1) {
            if (this.totalCurrentPageCount > this.totalCount) {
              this.totalCurrentPageCount = this.totalCount;
            } else {
              this.totalCurrentPageCount = this.perPageCount * this.currentPage;
              if (this.totalCurrentPageCount >= this.totalCount) {
                this.totalCurrentPageCount = this.totalCount;
              }
            }
          }

          this._clientApp.updateStoreUrlScripts();
          this.changeDetector.detectChanges();
        }
      });
    }
  }

  viewTerminalSales(terminalId) {
    //console.log(pos);
    this.currentPage = 1;
    this.productSearch = '';
    this.isTerminalSalesDetails = !this.isTerminalSalesDetails;
    if (this.isTerminalSalesDetails) {
      this.terminalSalesId = terminalId;
      this.loadSales();
      this._clientApp.updateStoreUrlScripts();
    } else {
      this.loadPOSTerminals();
      this._clientApp.updateStoreUrlScripts();
    }
  }

  addTerminalRecord(submittedValue: any) {
    this.showProcessing = !this.showProcessing;

    let reqBody = JSON.stringify(submittedValue);

    let url = this.termPaths._adminBaseUrl + 'addterminal';

    let type = 'post';

    if (this.storeTerminalId) {
      url =
        this.termPaths._adminBaseUrl + 'updateterminal/' + this.storeTerminalId;
      type = 'put';
    }

    this._clientApp.processRequestMapped(url, type, reqBody, true).subscribe(
      (res) => {
        this.reqResponseObject = this.auth.mapHttpResponse(res);
        const statusCode = this.reqResponseObject.statusCode;
        const errorMsg = this.reqResponseObject.errorMessage;

        const statusMsg = this.reqResponseObject.statusMessage;
        this.showProcessing = !this.showProcessing;

        if (statusCode == '00') {
          this.options.text = statusMsg;
          this.options.title = 'Successful';
          this.alert.doToastAlert(this.options, 1);

          // setInterval(()=>{
          //   $(".closeAddTerminal").click();
          // }, 500);

          this.addTerminalPosForm.reset();
          $('.closeAddTerminal').click();
          this.loadPOSTerminals();
          this._clientApp.updateStoreUrlScripts();
        } else {
          this.options.type = 'error';
          this.options.text = errorMsg;
          this.alert.doToastAlert(this.options, 4);
        }
      },
      (err) => {
        this.options.type = 'error';
        this.options.text = err.error.errorMessage;

        this.alert.doToastAlert(this.options, 4);
        this.showProcessing = !this.showProcessing;
      }
    );
  }

  editTerminalSales(pos: any) {
    this.addTerminalPosForm.patchValue(pos);
    this.storeTerminalId = pos.id;
    this.terminalAction = 'Update ';
  }

  clearTerminal() {
    this.storeTerminalId = null;
    this.terminalAction = 'Add ';
  }

  initAssignPOSToStoreProfile(terminalId) {
    this.storeTerminalId = terminalId;
    //console.log(this.storeTerminalId);
  }

  loadRegisteredStores() {
    let url = this.termPaths._adminBaseUrl + 'allstores-basic';

    this._clientApp.get(url).subscribe((res) => {
      //console.log(res);
      this.stores = res.body['data'];
      // this.reqResponseObject = this.auth.mapHttpResponse(res);

      //if (this.reqResponseObject) {
        // this.stores = this.reqResponseObject.data;
        // this.filteredOptions = this.salesPointAssignForm
        //   .get('storeProfileId')
        //   .valueChanges.pipe(
        //     startWith(''),
        //     map((value) => this._filter(value))
        //   );
        //console.log(this.stores);
      //}
      this.changeDetector.detectChanges();
    });
  }

  assignPOSToStoreProfile(submittedValue: any) {
    let storeId = this.b.storeProfileId.value;

    let url =
      this.termPaths._adminBaseUrl +
      'assignterminal/' +
      this.storeTerminalId +
      '/store/' +
      storeId;
    let type = 'put';

    let reqBody = '';

    return swal({
      title: 'Are you sure?',
      text: 'Are sure you want assign this POS profile to the store.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.value) {
        this.deassignLoader = !this.deassignLoader;

        this._clientApp
          .processRequestMapped(url, type, reqBody, true)
          .subscribe(
            (res) => {
              this.reqResponseObject = this.auth.mapHttpResponse(res);
              const statusCode = this.reqResponseObject.statusCode;
              const errorMsg = this.reqResponseObject.errorMessage;

              const statusMsg = this.reqResponseObject.statusMessage;
              this.deassignLoader = !this.deassignLoader;

              if (statusCode == '00') {
                this.options.text = statusMsg;
                this.options.title = 'Successful';
                this.alert.doToastAlert(this.options, 1);
                $('.closeAssign').click();
                this.loadPOSTerminals();
                this._clientApp.updateStoreUrlScripts();
              } else {
                this.options.type = 'error';
                this.options.text = errorMsg;
                this.alert.doToastAlert(this.options, 4);
              }
            },
            (err) => {
              this.options.type = 'error';
              this.options.text = err.error.errorMessage;

              this.alert.doToastAlert(this.options, 4);
              this.deassignLoader = !this.deassignLoader;
            }
          );
      }
    });
  }

  selected(event: any) {
    //console.log(event);
    // const selected = this.stores.filter(
    //   (data) => data.storeNumber === event
    // )[0];

    // this.salesPointAssignForm.patchValue({
    //   storeProfileId: selected?.storeNumber
    // });
  }

  loadSales() {
    this.isLoading = !this.isLoading;
    let url =
      this.termPaths._adminBaseUrl +
      'stores/loadterminalsales/' +
      this.terminalSalesId +
      '?search=' +
      this.productSearch +
      '&page=' +
      this.currentPage +
      '&size=' +
      this.perPageCount;

    this._clientApp.get(url).subscribe((res) => {
      //console.log(res);
      this.isLoading = !this.isLoading;
      this._clientApp.updateStoreUrlScripts();
      this.reqResponseObject = this.auth.mapHttpResponse(res);

      if (this.reqResponseObject) {
        this.totalCount = this.reqResponseObject.data.totalCount;
        this.totalPages = this.reqResponseObject.data.totalPages;
        this.setPaginationList(this.totalPages);
        this.currentPage = this.reqResponseObject.data.page;
        this.transactions = this.reqResponseObject.data.payload;

        if (this.currentPage >= 1) {
          if (this.totalCurrentPageCount > this.totalCount) {
            this.totalCurrentPageCount = this.totalCount;
          } else {
            this.totalCurrentPageCount = this.perPageCount * this.currentPage;
            if (this.totalCurrentPageCount >= this.totalCount) {
              this.totalCurrentPageCount = this.totalCount;
            }
          }
        }
      }
    });
  }

  viewSalesDetails(trans) {
    this.salesDetailsLoader = true;
    this.currency = trans.currency;
    this.salesTransaction = trans;
    this.totalAmount = trans.amount;
    this.totalQty = trans.productQuantity;
    this.transactionRef = trans.transactionRef;
    this.salesProducts = trans.products;
    this.salesDetailsLoader = false;
    this.changeDetector.detectChanges();

    //this._clientApp.updateStoreUrlScripts();
  }

  searchProductSales(txt) {
    if (txt !== this.productSearch) {
      this.productSearch = txt;
      this.showSearch = true;
      this.currentPage = 1;

      let url =
        this.termPaths._adminBaseUrl +
        'stores/loadterminalsales/' +
        this.terminalSalesId +
        '?search=' +
        this.productSearch +
        '&page=' +
        this.currentPage +
        '&size=' +
        this.perPageCount;

      this._clientApp.get(url).subscribe((res) => {
        this.reqResponseObject = this.auth.mapHttpResponse(res);
        this.showSearch = false;
        if (this.reqResponseObject) {
          //console.log("products search paginated: ",this.reqResponseObject.data);
          this.totalCount = this.reqResponseObject.data.totalCount;
          this.totalPages = this.reqResponseObject.data.totalPages;
          this.setPaginationList(this.totalPages);
          this.currentPage = this.reqResponseObject.data.page;
          this.transactions = this.reqResponseObject.data.payload;

          if (this.currentPage >= 1) {
            if (this.totalCurrentPageCount > this.totalCount) {
              this.totalCurrentPageCount = this.totalCount;
            } else {
              this.totalCurrentPageCount = this.perPageCount * this.currentPage;
              if (this.totalCurrentPageCount >= this.totalCount) {
                this.totalCurrentPageCount = this.totalCount;
              }
            }
          }

          this._clientApp.updateStoreUrlScripts();
          this.changeDetector.detectChanges();
        }
      });
    }
  }

  downloadData(element) {
    var htmlElement = document.getElementById(element);
    var canvas = html2canvas(htmlElement).then((canvas) => {
      var imgData = canvas.toDataURL('image/png');
      var doc = new jspdf();
      doc.addImage(imgData, 0, 0, 200, 500);
      doc.save('doc.pdf');
    });
  }

  downloadSalesRecord() {
    this.showProcessingFile = true;

    if (!this.transactions || this.transactions.length === 0) {
      let options = {
        text: 'No product sales record found!!',
        title: 'Warning',
      };
      this.alert.doToastAlert(options, 3);
      this.showProcessingFile = false;
      return;
    }

    const countTransaction = this.transactions.length;
    this.salesDownloadList = new Array(countTransaction).fill(
      new SalesHistory()
    );

    for (let i = 0; i < this.transactions.length; i++) {
      const dProductSalesList = new SalesHistory();
      dProductSalesList.Amount = this.transactions[i].amount;
      dProductSalesList.AmountTendered = this.transactions[i].amountTendered;
      dProductSalesList.ProductQuantity = this.transactions[i].productQuantity;
      dProductSalesList.TransactionRef = this.transactions[i].transactionRef;
      dProductSalesList.CardName = this.transactions[i].cardName;
      dProductSalesList.CardType = this.transactions[i].cardType;
      dProductSalesList.ChannelStatusCode =
        this.transactions[i].channelStatusCode;
      dProductSalesList.ChannelStatusMessage =
        this.transactions[i].channelStatusMessage;
      dProductSalesList.Currency = this.transactions[i].currency;
      dProductSalesList.CustomerBalance = this.transactions[i].customerBalance;
      dProductSalesList.Fee = this.transactions[i].fee;
      dProductSalesList.PaymentChannelType =
        this.transactions[i].paymentChannelType;

      dProductSalesList.SettlementAmount =
        this.transactions[i].settlementAmount;
      dProductSalesList.SoldByUser = this.transactions[i].soldByUser;
      dProductSalesList.Status = this.transactions[i].status;
      dProductSalesList.CreatedAt = this.transactions[i].createdAt;

      if (this.transactions[i].products) {
        let productDetails = '';
        let products = this.transactions[i].products;

        Object.entries(products).forEach(([p, j]) => {
          let name = j['productName'];
          let amount = j['amount'];
          let quantity = j['quantity'];

          let prodDet =
            'Name: ' + name + ' | Qty: ' + quantity + ' | Amount: ' + amount;

          productDetails = productDetails + prodDet + ' \n';
        });

        dProductSalesList.ProductsDetails = productDetails;
      }

      if (this.transactions[i].customer) {
        dProductSalesList.CustomerEmail =
          this.transactions[i].customer.customerEmail;
        dProductSalesList.CustomerPhone =
          this.transactions[i].customer.customerPhone;
        dProductSalesList.CustomerName =
          this.transactions[i].customer.customerName;
      } else {
        dProductSalesList.CustomerEmail = '';
        dProductSalesList.CustomerPhone = '';
        dProductSalesList.CustomerName = '';
      }

      this.salesDownloadList[i] = dProductSalesList;
    }

    //console.log(this.salesDownloadList);

    this.fileExport.exportAsExcelFile(this.salesDownloadList, 'Sales_records');
    const options = {
      text: 'File downloaded successfully..',
      title: 'Warning',
    };

    this.alert.doToastAlert(options, 1);

    this.showProcessingFile = false;
    this.salesDownloadList = [];
  }

  setPaginationList(totalPages) {
    //console.log('Total transactions: ', totalPages);
    this.totalPageList = [];
    if (totalPages < 10) {
      for (let i = 0; i < totalPages; i++) {
        this.totalPageList[i] = { key: i, value: (i + 1).toString() };
      }
    } else {
      let firstCount = 5;
      let lastCount = totalPages - firstCount;
      let elipsCountDown = firstCount + 2;
      let elipsCountUp = lastCount - 2;

      // if ((lastCount-firstCount) < 4) {
      //   elipsCountDown = firstCount;
      //   elipsCountUp = elipsCountDown+1
      // }

      let index = 0;

      for (let i = 0; i < firstCount; i++) {
        this.totalPageList[index] = { key: i, value: (i + 1).toString() };
        index++;
      }

      for (let k = firstCount; k < elipsCountDown; k++) {
        this.totalPageList[index] = { key: k + 'd', value: '.' };
        //lastCount++;
        index++;
      }
      for (let k = elipsCountUp; k < lastCount; k++) {
        this.totalPageList[index] = { key: k + 'u', value: '.' };
        //lastCount ++;
        index++;
      }
      for (let k = lastCount; k < totalPages; k++) {
        this.totalPageList[index] = { key: k, value: k };
        //lastCount ++;
        index++;
      }
    }
    //console.log(this.totalPageList);
  }

  paginate(page) {
    page = String(page);
    if (page.indexOf('d') !== -1) {
      page = page.substr(0, page.indexOf('d'));
    }

    if (page.indexOf('u') !== -1) {
      page = page.substr(0, page.indexOf('u'));
    }
    this.currentPage = parseInt(page) + 1;

    this.loadPOSTerminals();
  }

  changePerPage($event) {
    let pageCount = $event.value;
    //console.log(pageCount);
    this.perPageCount = parseInt(pageCount);
    this.loadPOSTerminals();
  }

  paginateBack() {
    if (this.currentPage > 1) {
      --this.currentPage;
      this.loadPOSTerminals();
    }
  }

  paginateForward() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.loadPOSTerminals();
    }
  }

  changePerPageSales($event) {
    let pageCount = $event.value;
    //console.log(pageCount);
    this.perPageCount = parseInt(pageCount);
    this.loadPOSTerminals();
  }

  paginateSalesBack() {
    if (this.currentPage > 1) {
      --this.currentPage;
      this.loadSales();
    }
  }

  paginateSalesForward() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.loadSales();
    }
  }

  paginateSales(page) {
    page = String(page);
    if (page.indexOf('d') !== -1) {
      page = page.substr(0, page.indexOf('d'));
    }

    if (page.indexOf('u') !== -1) {
      page = page.substr(0, page.indexOf('u'));
    }
    this.currentPage = parseInt(page) + 1;

    this.loadSales();
  }

  get a() {
    return this.addTerminalPosForm.controls;
  }

  get b() {
    return this.salesPointAssignForm.controls;
  }
}
