import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';
import { ClientAppService } from 'src/app/shared/services/clientapp.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/shared/services/alert.service';
import { FileExportService } from 'src/app/shared/services/file-export.service';
import { Configuration } from 'src/app/config/configuration';
import { ApiResponseObj } from 'src/app/shared/models/loginApiResponse';
import { Country } from 'src/app/shared/models/loginModels/country';

@Component({
  selector: 'app-admincountries',
  templateUrl: './admincountries.component.html',
  styleUrls: ['./admincountries.component.css']
})
export class AdmincountriesComponent implements OnInit {

  public options = {type: '', title: '', text: ''};

  private termPaths = new Configuration;
  private reqResponseObject = new ApiResponseObj;

  public productSalesAnalytics: any  = {};

  private storeSearch = '';
  private storeSearchType = 'ALL';
  public showSearch = false;
  public isLoading = false;
  public showLoadData = false;
  public showProcessingFile: boolean;
  public isStoreDetails: boolean;

  public totalAmount: number = 0;
  public totalQty = 0;
  public salesProducts;

  public activePage = 1;
  public prevPage = 1;
  public totalPages;
  public totalPageList: any[] = [];
  public currentPage = 1;
  public totalCount;

  public countries: Country[];

  public perPageCount = 25;
  public totalCurrentPageCount = 25;

  public currency = 'GHS'
  public currencyLoader = {
    "NGN": "&#8358;",
    "GHS": "&#8373;" 
   } 

  public viewCountryConfig: boolean;
  public paymentOptions: any[];

  public countryCode = 'GHS'

  public paymentOptionProfileForm: FormGroup;

  public processingSalesAnalytics: boolean;
  public primaryColour = "#ffffff";
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;

  public applyFixCharge = true;
  public processSettlement = true;
  public enableTenderedAmount = true;
  public enableOption = true;
  public enableWebOption = true;
  public enableAppOption = true;

  public disablePayFields = false;

  public showProcessing: boolean;
  public isUpdating = false;
  

  constructor(private _clientApp: ClientAppService, 
    public auth: AuthService,
    private fb: FormBuilder,
    private alert: AlertService,
    private fileExport: FileExportService,
    private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
    
    this.disablePayFields = true;
    this.paymentOptionProfileForm = this.fb.group({
      name: [{ value: '', disabled: this.disablePayFields}, Validators.required],
      viewName: [{ value: '', disabled: this.disablePayFields}, Validators.required],
      countryCode: [this.countryCode],
      status: ['ACTIVE', Validators.required],
      description: [''],
      applyFixCharge: [this.applyFixCharge],
      fixCharge: ['0.00'],
      processSettlement: [this.processSettlement],
      allowOption: ['true'],
      allowOnWeb: ['true'],
      allowOnApp: ['false'],
      allowTenderedAmount: ['true'],
      paymentOptionId: [''],
      paymentOptionNumber: [''],
      paymentOptionFeeMode: ['PERCENTAGE', Validators.required],
      paymentOptionFee: [''],
      internationalPaymentOptionMode: ['PERCENTAGE'],
      internationalPaymentOptionFee: [''],
      paymentOptionCapStatus: this.fb.group({
        cappedAmount: [''],
        cappedType: ['UNCAPPED']
      })
    });

    this.loadCountries();
    this.loadProductSalesAnalytics();
    
  }

  loadProductSalesAnalytics(){
    this.options = {type: '', title: '', text: ''};

    let url = this.termPaths._adminBaseUrl+"productsales/analytics";
    
    this.processingSalesAnalytics = true;

    this._clientApp.get(url).subscribe(res =>{
      //console.log(res);
      this.processingSalesAnalytics = false;
      this.reqResponseObject = this.auth.mapHttpResponse(res);
      
      if(this.reqResponseObject.statusCode == "00"){
        this.productSalesAnalytics = this.reqResponseObject.data;
      }
    },  err => {
      
      this.options.type = 'error';
      this.options.text = err.error.errorMessage;
      
      this.processingSalesAnalytics = false;
      this.alert.doToastAlert(this.options, 4);
    });

  }

  loadCountries(){
    let url = this.termPaths._adminBaseUrl+"system/loadcountry"
    this._clientApp.get(url)
    .subscribe((res) => {
         //console.log("loaded countries: "+res);
         const statusCode = res.body['statusCode'];
         if(statusCode == "00"){
           this.countries = res.body["data"];
           //console.log(this.countries);
           this._clientApp.updateStoreUrlScripts();
         }
    });
  }

  viewCountryPaymentOptions(payOption){
    this.paymentOptions = payOption;
    this.viewCountryConfig = true;
    //this.countryCode = this.storeProfile.country.countryCode;
    this.disablePayFields = true;
    this.paymentOptionProfileForm.patchValue(payOption);
    this.applyFixCharge = payOption.applyFixCharge;
    this.processSettlement = payOption.processSettlement;
    this.enableTenderedAmount = payOption.allowTenderedAmount;
    this.enableOption = payOption.allowOption;
    this.enableWebOption = payOption.allowOnWeb;
    this.enableAppOption = payOption.allowOnApp;
    this.paymentOptionProfileForm.patchValue({paymentOptionId: payOption.channelOptionId});
    this.paymentOptionProfileForm.patchValue({paymentOptionNumber: payOption.channelOptionNumber});
    this.paymentOptionProfileForm.patchValue({countryCode: this.countryCode});

    this._clientApp.updateStoreUrlScripts();
  }

  closePayoption(){
    this.viewCountryConfig = false;
    this._clientApp.updateStoreUrlScripts();
  }

  setEnableOptionOnApp($event){
    let target = $event.target.value;
    //console.log('Apply fix charge value: ', target);
    this.enableAppOption = !this.enableAppOption;
  }

  setApplyFixCharge($event){
    let target = $event.target.value;
    this.applyFixCharge = !this.applyFixCharge;
  }

  setProcessSettlemet($event){
    let target = $event.target.value;
    this.processSettlement = !this.processSettlement;
  }

  setTenderedAmount($event){
    let target = $event.target.value;
    this.enableTenderedAmount = !this.enableTenderedAmount;
    
  }

  setEnableOption($event){
    let target = $event.target.value;
    //console.log('Apply fix charge value: ', target);
    this.enableOption = !this.enableOption;
    
  }

  setEnableOptionOnWeb($event){
    let target = $event.target.value;
    //console.log('Apply fix charge value: ', target);
    this.enableWebOption = !this.enableWebOption;
  }

  editCountryPayOption(payOption){

    this.disablePayFields = true;
    this.paymentOptionProfileForm.patchValue(payOption);
    this.applyFixCharge = payOption.applyFixCharge;
    this.processSettlement = payOption.processSettlement;
    this.enableTenderedAmount = payOption.allowTenderedAmount;
    this.enableOption = payOption.allowOption;
    this.enableWebOption = payOption.allowOnWeb;
    this.enableAppOption = payOption.allowOnApp;
    this.paymentOptionProfileForm.patchValue({paymentOptionId: payOption.id});
    this.paymentOptionProfileForm.patchValue({paymentOptionNumber: payOption.number});
    this.paymentOptionProfileForm.patchValue({countryCode: this.countryCode});
  }

  updatePaymentOptionProfile(submittedValue: any){
    //console.log(submittedValue);
    submittedValue.applyFixCharge = this.applyFixCharge;
    submittedValue.processSettlement = this.processSettlement;
    submittedValue.allowOption = this.enableOption;
    submittedValue.allowOnWeb = this.enableWebOption;
    submittedValue.allowOnApp = this.enableAppOption;

    if (((submittedValue.paymentOptionFeeMode == 'FIXED' || submittedValue.paymentOptionFeeMode == 'PERCENTAGE') && 
      (!submittedValue.paymentOptionFee || submittedValue.paymentOptionFee < 0 || submittedValue.paymentOptionFee == 0) )) {
        this.options.text = "Payment option fee amount must be supplied!";
        this.options.title = 'Warning';
        this.alert.doToastAlert(this.options, 3);
        return;
    }

    if (this.applyFixCharge && (!submittedValue.fixCharge || 
      (submittedValue.fixCharge < 0 ||submittedValue.fixCharge == 0) )) {
        this.options.text = "Fix charge amount must be supplied";
        this.options.title = 'Warning';
        this.alert.doToastAlert(this.options, 3);
        return;
    }

    if ((submittedValue.paymentOptionCapStatus.cappedType == 'CAPPED') && 
      (!submittedValue.paymentOptionCapStatus.cappedAmount || 
        submittedValue.paymentOptionCapStatus.cappedAmount < 0 || 
        submittedValue.paymentOptionCapStatus.cappedAmount == 0) ) {

        this.options.text = "Capped amount must be provided when set fee charge per transaction as capped";
        this.options.title = 'Warning';
        this.alert.doToastAlert(this.options, 3);
        return;
    }

    //console.log(submittedValue);
    let reqBody = JSON.stringify(submittedValue);

    let url = this.termPaths._adminBaseUrl+"addcountry/paymentoption";

    let type = "post";

    if (this.disablePayFields) {
      url = this.termPaths._adminBaseUrl+"addcountry/paymentoption";
      type = "put";
    }

    this.showProcessing = !this.showProcessing;

    this._clientApp.processRequestMapped(url, type, reqBody, true)
    .subscribe((res) => {

    this.reqResponseObject = this.auth.mapHttpResponse(res);
    const statusCode = this.reqResponseObject.statusCode;
    const errorMsg = this.reqResponseObject.errorMessage;

    const statusMsg = this.reqResponseObject.statusMessage;
    this.showProcessing = !this.showProcessing;

      if(statusCode == '00'){

        this.options.text = statusMsg;
        this.options.title = 'Successful';
        this.alert.doToastAlert(this.options, 1);
        
        this.paymentOptionProfileForm.reset();

        this.applyFixCharge = true;
        this.processSettlement = true;
        this.enableTenderedAmount = true;
        this.enableOption = true;
        this.enableWebOption = true;
        this.enableAppOption = true;
        this.disablePayFields = false;

        this.viewCountryConfig = false;

        this.loadCountries();
        $(".closePayOption").click();

        this._clientApp.updateStoreUrlScripts();
        
      }else{

        this.options.type = 'error';
        this.options.text = errorMsg;
        this.alert.doToastAlert(this.options, 4);
      }

    },  err => {
      
      this.options.type = 'error';
      this.options.text = err.error.errorMessage;
      
      this.alert.doToastAlert(this.options, 4);
      this.showProcessing = !this.showProcessing;
      
    });
    
  }


  setPaginationList(totalPages){
    if(totalPages < 10){
    
      for (let i = 0; i < totalPages; i++) {
        this.totalPageList[i] = {key: i, value: (i+1).toString()};
      }

    }else{
      let firstCount = 5;
      let lastCount = totalPages-firstCount;
      let elipsCount = firstCount+3;

      for (let i = 0; i < firstCount; i++) {
        this.totalPageList[i] = {key: i, value: (i+1).toString()};
      }

      for (let k = firstCount; k < elipsCount; k++) {
        this.totalPageList[k] = {key: k, value: "."};
      }
      for (let k = elipsCount; k < elipsCount+5; k++) {
        this.totalPageList[k] = {key: k, value: lastCount};
        lastCount ++;
      }
    }
    //console.log(this.totalPageList);
  }

  paginateBack(){
    //console.log(this.currentPage);
    if(this.currentPage > 1){
      --this.currentPage;
      //this.loadRegisteredStores();
    }
  }

  paginate(page){
    this.currentPage = parseInt(page) + 1;
    //this.loadRegisteredStores();
  }

  paginateForward(){
    //console.log(this.currentPage);
    if(this.currentPage < this.totalPages){
      this.currentPage++;
      //this.loadRegisteredStores();
    }
  }

  get f(){
    return this.paymentOptionProfileForm.controls;
  }

}
