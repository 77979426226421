<header class="page-header page-header-dark bg-gradient-primary-to-secondaryadmin pb-10">
    <div class="container">
        <div class="page-header-content pt-4">
            <div class="row align-items-center justify-content-between">
                <div class="col-auto mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Salesboard
                    </h1>
                    <div class="page-header-subtitle">Inventory management system admin users</div>
                </div>
                
            </div>
        </div>
    </div>
</header>

<div class="container mt-n10">
    
    <div class="row">
        <div class="col-xl-3 col-md-6 mb-4">
            <!-- Dashboard info widget 1-->
            <div class="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-primary h-100">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <div class="small font-weight-bold text-primary mb-1">Today Sales ({{productSalesAnalytics.totalDaySalesCount}})</div>
                            <div class="h5"><span [innerHTML]="currencyLoader[currency]"></span>{{productSalesAnalytics.totalDaySalesAmount | number : '1.2-2'}}</div>
                            <div class="text-xs font-weight-bold text-success d-inline-flex align-items-center">
                                <i class="mr-1" data-feather="trending-up"></i>
                                {{(productSalesAnalytics.averageDaySalesAmount/productSalesAnalytics.averageDaySalesCount)/100 | number : '1.2-2'  }}%
                            </div>
                        </div>
                        <div class="ml-2"><i class="fas fa-tag fa-2x fa-2x text-gray-200"></i></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
            <!-- Dashboard info widget 3-->
            <div class="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-success h-100">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <div class="small font-weight-bold text-success mb-1">Current Week ({{productSalesAnalytics.totalWeekSalesCount}})</div>
                            <div class="h5"><span [innerHTML]="currencyLoader[currency]"></span>{{productSalesAnalytics.totalWeekSalesAmount | number : '1.2-2'}}</div>
                            <div class="text-xs font-weight-bold text-success d-inline-flex align-items-center">
                                <i *ngIf="productSalesAnalytics.weekGrowthType == 'NEGATIVE'" class="mr-1" data-feather="trending-down"></i>
                                <i *ngIf="productSalesAnalytics.weekGrowthType == 'POSITIVE'" class="mr-1" data-feather="trending-up"></i>
                                {{productSalesAnalytics.totalWeekSalesAmount/productSalesAnalytics.prevWeekSalesAmount | number : '1.2-2' }}%
                            </div>
                        </div>
                        <div class="ml-2"><i class="fas fa-mouse-pointer fa-2x text-gray-200"></i></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
            <!-- Dashboard info widget 2-->
            <div class="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-secondary h-100">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <div class="small font-weight-bold text-secondary mb-1">Current Month ({{productSalesAnalytics.totalMonthSalesCount}})</div>
                            <div class="h5"><span [innerHTML]="currencyLoader[currency]"></span>{{productSalesAnalytics.totalMonthSalesAmount | number : '1.2-2'}}</div>
                            <div class="text-xs font-weight-bold text-danger d-inline-flex align-items-center">
                                <i *ngIf="productSalesAnalytics.monthGrowthType == 'NEGATIVE'" class="mr-1" data-feather="trending-down"></i>
                                <i *ngIf="productSalesAnalytics.monthGrowthType == 'POSITIVE'" class="mr-1" data-feather="trending-up"></i>
                                {{productSalesAnalytics.monthGrowthPercentage}}%
                            </div>
                        </div>
                        <div class="ml-2"><i class="fas fa-tag fa-2x text-gray-200"></i></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
            <!-- Dashboard info widget 4-->
            <div class="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-info h-100">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <div class="small font-weight-bold text-info mb-1">Last Month ({{productSalesAnalytics.prevMonthAverageSalesCount}})</div>
                            <div class="h5"><span [innerHTML]="currencyLoader[currency]"></span>{{productSalesAnalytics.prevMonthAverageSalesAmount | number : '1.2-2'}}</div>
                            <div class="text-xs font-weight-bold text-danger d-inline-flex align-items-center">
                                <i *ngIf="productSalesAnalytics.averageMonthSalesAmount > productSalesAnalytics.prevMonthAverageSalesAmount" class="mr-1" data-feather="trending-down"></i>
                                <i *ngIf="productSalesAnalytics.averageMonthSalesAmount < productSalesAnalytics.prevMonthAverageSalesAmount" class="mr-1" data-feather="trending-up"></i>
                                <span *ngIf="productSalesAnalytics.averageMonthSalesAmount < productSalesAnalytics.prevMonthAverageSalesAmount">{{(productSalesAnalytics.prevMonthAverageSalesAmount/productSalesAnalytics.totalMonthSalesAmount)/100 | number : '1.2-2' }}%</span>
                                <span *ngIf="productSalesAnalytics.averageMonthSalesAmount > productSalesAnalytics.prevMonthAverageSalesAmount">{{(productSalesAnalytics.totalMonthSalesAmount/productSalesAnalytics.prevMonthAverageSalesAmount)*100 | number : '1.2-2' }}%</span>

                            </div>
                        </div>
                        <div class="ml-2"><i class="fas fa-percentage fa-2x text-gray-200"></i></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card mb-4">
        <div class="card-header">Users</div>
        <div class="card-body">
            <button class="btn btn-info float-right" data-toggle="modal" data-target="#exampleModal" style="margin: 10px;"  type="button">Add user</button>
            <div class="datatable">
                <table class="table table-bordered table-hover" width="100%" cellspacing="0">
                    <thead>
                        <tr>
                            <th>S/N</th>
                            <th>Username</th>
                            <th>Firstname</th>
                            <th>Lastname</th>
                            <th>EmailAddress </th>
                            <th>Role</th>
                            <th>Added Date</th>
                            <th>Status</th>
                            <!-- <th>View</th> -->
                        </tr>
                    </thead>
                    
                    <tbody *ngIf="adminUsers">
                        <tr *ngFor='let adminuser of adminUsers; let i=index;'>
                            <td>{{i+1}}</td>
                            <td>
                                <i class="text-green" data-feather="user-check"></i>
                                 {{adminuser.user.username}}
                            </td>
                            <td>{{adminuser.user.firstName}}</td>
                            <td>
                                {{adminuser.user.lastName}}
                            </td>
                            <td>{{adminuser.user.emailAddress}}</td>
                            <td>{{adminuser.role.roleName?.replace("_", " ")}}</td>
                            <td>{{adminuser.createdAt | date :'short'}}</td>
                            <td>
                                <div *ngIf='adminuser.userStatus === "ACTIVE"' class="badge badge-secondary badge-pill">Active</div>
                                <div *ngIf='adminuser.userStatus === "INACTIVE"' class="badge badge-warning badge-pill">Inactive</div>
                            </td>
                            <!-- <td>
                                <button class="btn btn-datatable btn-icon btn-transparent-dark mr-2"><i data-feather="more-vertical"></i></button>
                                <button class="btn btn-datatable btn-icon btn-transparent-dark" (click)="viewSalesDetails(trans)"  data-toggle="modal" data-target="#paymentModalScrollable">
                                    <i class="text-purple" data-feather="eye"></i>
                                </button>
                                <img *ngIf="salesDetailsLoader" src="../../../../assets/landing/images/ring1.gif" class="smLoaderSm">
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
            
        </div>
    </div>

</div>

<!-- Modal -->
<div class="modal fade"  id="exampleModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Add user</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
               
                <form [formGroup]="userProfileForm" (ngSubmit)="inviteUserProfile(userProfileForm.value)">
                    <!-- Form Group (username)-->
                    <div class="form-group">
                        <label class="small mb-1" for="inputUsername">Username</label>
                        <input class="form-control" id="inputUsername" type="text" placeholder="Enter your username" formControlName="username" />
                        <div *ngIf="f.username.invalid && f.username.touched">
                            <small *ngIf="f.username.errors?.required" class="small-warning text-danger">*Username is required</small>
                            <!-- <small *ngIf="f.username.errors?.email" class="small-warning text-danger">*Username requires valid email address</small> -->
                            <small *ngIf="f.username.errors?.cannotContainSpace" class="small-warning text-danger">*Username cannot contain spaces</small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="small mb-1" for="inputPassword">Default password</label>
                        <input class="form-control" id="inputPassword" type="password" formControlName="password" />
                        <div *ngIf="f.username.invalid && f.username.touched">
                            <small *ngIf="f.password.errors?.required" class="small-warning text-danger">*Kindly set default password</small>
                            <!-- <small *ngIf="f.username.errors?.email" class="small-warning text-danger">*Username requires valid email address</small> -->
                        </div>
                    </div>
                    <!-- Form Row-->
                    <div class="form-row">
                        <!-- Form Group (first name)-->
                        <div class="form-group col-md-6">
                            <label class="small mb-1" for="inputFirstName">First name</label>
                            <input class="form-control" id="inputFirstName" type="text" placeholder="Enter your first name" formControlName="firstName" />
                            <div *ngIf="f.firstName.invalid && f.firstName.touched">
                                <small *ngIf="f.firstName.errors?.required" class="small-warning text-danger">*First name is required</small>
                            </div>
                        </div>
                        <!-- Form Group (last name)-->
                        <div class="form-group col-md-6">
                            <label class="small mb-1" for="inputLastName">Last name</label>
                            <input class="form-control" id="inputLastName" type="text" placeholder="Enter your last name" formControlName="lastName" />
                            <div *ngIf="f.lastName.invalid && f.lastName.touched">
                                <small *ngIf="f.lastName.errors?.required" class="small-warning text-danger">*Last name is required</small>
                            </div>
                        </div>
                    </div>
                    <!-- Form Row        -->
                    <div class="form-row">
                        <!-- Form Group (organization name)-->
                        <div class="form-group col-md-6">
                            <label class="small mb-1" for="inputEmailAddress">Email address</label>
                            <input class="form-control" id="inputEmailAddress" type="email" placeholder="Enter your other personal email" formControlName="emailAddress"  />
                            <div *ngIf="f.emailAddress.invalid && f.emailAddress.touched">
                                <small *ngIf="f.emailAddress.errors?.required" class="small-warning text-danger">*Personal email address is required</small>
                                <small *ngIf="f.emailAddress.errors?.email" class="small-warning text-danger">*Only valid email allowed!</small>
                            </div>
                        </div>
                        <!-- Form Group (location)-->
                        <div class="form-group col-md-6">
                            <label class="small mb-1" for="input">Phone number</label>
                            <input class="form-control" id="inputLocation" type="text" placeholder="Enter your location" formControlName="phoneNumber" />
                        </div>
                    </div>
                    <!-- Form Group (location)-->
                    <div class="form-group">
                        <label class="small mb-1" for="role">Assign/Choose Role</label>
                        <select class="form-control" id="role" formControlName="roleName" required >
                            <option *ngFor="let role of adminRolesList; let i=index;" value="{{role.roleName}}" >{{role.name}}</option>
                        </select>
                        <div *ngIf="f.roleName.invalid && f.roleName.touched">
                            <small *ngIf="f.roleName.errors?.required" class="small-warning text-danger">*Role must selected or assign</small>
                        </div>
                    </div>
                    
                    <!-- Save changes button-->
                    <span class="notification" *ngIf="showProcessing"> <img src="../../../../assets/landing/images/ring1.gif"  class="imgNot float-right"></span>
                    <button class="btn btn-primary" type="submit" [disabled]="!userProfileForm.valid || showProcessing">Invite user</button>
                </form>

            </div>
            <div class="modal-footer"><button class="btn btn-secondary closePay" type="button" data-dismiss="modal">Cancel</button></div>
        </div>
    </div>
</div>
