import {StoreProfilesList} from './loginModels/storeProfileList';
import {User} from './loginModels/userProfile';

    export interface userRole {
        id: number;
        roleName: string;
        roleCode: string;
        description: string;
        isCustom: boolean;
        isStoreOwned?: any;
        storeNumber?: any;
        permissions: string[];
    }

    export interface UserStoreMappedRole {
        StoreId: userRole;
    }

    export interface UserStorePermissions {
        StoreId: string[];
    }

    export interface Data {
        accessType: string;
        user: User;
        userType: string;
        userNumber: string;
        username: string;
        name: string;
        emailAddress: string;
        status: string;
        adminUserPermissions?: any;
        userStoreMappedRole: UserStoreMappedRole;
        userStorePermissions: UserStorePermissions;
        storeList: string[];
        storeProfilesList: StoreProfilesList[];
    }

    export interface userLoginObject {
        headers: any;
        status: string;
        statusCode: number;
        token: string;
        expires: Date;
        userData: Data;
    }

    export class ApiResponseObj{
        status: string;
        statusCode: string;
        statusMessage: string
        data: any;
        error : string;
        message: string;
        errorMessage: string

    }

