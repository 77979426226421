import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Configuration } from 'src/app/config/configuration';
import { ApiResponseObj } from 'src/app/shared/models/loginApiResponse';
import { ClientAppService } from 'src/app/shared/services/clientapp.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/shared/services/alert.service';
import { FileExportService } from 'src/app/shared/services/file-export.service';

import { DomSanitizer } from '@angular/platform-browser';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';

@Component({
  selector: 'app-adminstoresettlements',
  templateUrl: './adminstoresettlements.component.html',
  styleUrls: ['./adminstoresettlements.component.css']
})
export class AdminstoresettlementsComponent implements OnInit {

  public options = {type: '', title: '', text: ''};

  private termPaths = new Configuration;
  private reqResponseObject = new ApiResponseObj;

  public productSalesAnalytics: any  = {};
  public showProcessing: boolean = false;

  private settlementSearch = '';
  private settlementSearchType = 'ALL';
  public showSearch = false;
  public isLoading = false;
  public showLoadData = false;
  public showProcessingFile: boolean;
  public isStoreDetails: boolean;

  public totalAmount: number = 0;
  public totalQty = 0;

  public activePage = 1;
  public prevPage = 1;
  public totalPages;
  public totalPageList: any[] = [];
  public currentPage = 1;
  public totalCount;

  public settlements: any[];

  public perPageCount = 25;
  public totalCurrentPageCount = 25;

  public currency = 'GHS'
  public currencyLoader = {
    "NGN": "&#8358;",
    "GHS": "&#8373;" 
  } 

  public cycleRef = '';

  public processingSalesAnalytics: boolean;
  public primaryColour = "#ffffff";
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;

  public settlementProfileStatusForm: FormGroup;

  constructor(private _clientApp: ClientAppService, 
    public auth: AuthService,
    private fb: FormBuilder,
    private alert: AlertService,
    private fileExport: FileExportService,
    private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {

    this.settlementProfileStatusForm = this.fb.group({
      status: ['', Validators.required]
    });
    this.loadProductSalesAnalytics();
    this.loadSettlementRecords();

    //this._clientApp.updateStoreUrlScripts();

  }

  loadProductSalesAnalytics(){
    this.options = {type: '', title: '', text: ''};

    let url = this.termPaths._adminBaseUrl+"productsales/analytics";
    
    this.processingSalesAnalytics = true;

    this._clientApp.get(url).subscribe(res =>{
      //console.log(res);
      this.processingSalesAnalytics = false;
      this.reqResponseObject = this.auth.mapHttpResponse(res);
      
      if(this.reqResponseObject.statusCode == "00"){
        this.productSalesAnalytics = this.reqResponseObject.data;
      }
    },  err => {
      
      this.options.type = 'error';
      this.options.text = err.error.errorMessage;
      
      this.processingSalesAnalytics = false;
      this.alert.doToastAlert(this.options, 4);
    });

  }

  loadSettlementRecords(){
    this.isLoading = !this.isLoading;
    let url = this.termPaths._adminBaseUrl+"settlementcycles?type="+this.settlementSearchType+"&search="
    +this.settlementSearch+"&page="+this.currentPage+"&size="+this.perPageCount;

    this._clientApp.get(url).subscribe(res =>{
      //console.log(res);
      this.isLoading = !this.isLoading;
      this._clientApp.updateStoreUrlScripts();
      this.reqResponseObject = this.auth.mapHttpResponse(res);
      
      if(this.reqResponseObject){
        
        this.totalCount = this.reqResponseObject.data.totalCount;
        this.totalPages = this.reqResponseObject.data.totalPages;
        this.setPaginationList(this.totalPages);
        this.currentPage = this.reqResponseObject.data.page;
        this.settlements = this.reqResponseObject.data.payload;
        //console.log(this.stores);

        if (this.currentPage >= 1) {
          if (this.totalCurrentPageCount > this.totalCount) {
            this.totalCurrentPageCount = this.totalCount;
          }else{
            this.totalCurrentPageCount = this.perPageCount * this.currentPage;
            if (this.totalCurrentPageCount >= this.totalCount) {
              this.totalCurrentPageCount = this.totalCount;
            }
          }
        }
        
      }
    })
  }

  searchStoreSettlements(txt){

    if(txt !== this.settlementSearch){

      this.settlementSearch = txt;
      this.showSearch = true;
      this.currentPage = 1;

      let url = this.termPaths._adminBaseUrl+"settlementcycles?type="+this.settlementSearchType+"&search="
      +this.settlementSearch+"&page="+this.currentPage+"&size="+this.perPageCount;

      this._clientApp.get(url).subscribe(res=>{
      this.reqResponseObject = this.auth.mapHttpResponse(res);
      this.showSearch = false;
      if(this.reqResponseObject){
          //console.log("products search paginated: ",this.reqResponseObject.data);
          this.totalCount = this.reqResponseObject.data.totalCount;
          this.totalPages = this.reqResponseObject.data.totalPages;
          this.setPaginationList(this.totalPages);
          this.currentPage = this.reqResponseObject.data.page;
          this.settlements = this.reqResponseObject.data.payload;

          if (this.currentPage > 1) {
            this.totalCurrentPageCount = this.perPageCount * this.currentPage;
            if (this.totalCurrentPageCount >= this.totalCount) {
              this.totalCurrentPageCount = this.totalCount;
            }
          }

          this._clientApp.updateStoreUrlScripts();
          this.changeDetector.detectChanges();

        }
      });

    }

  }

  viewSettlementDetails(cycle){
    //console.log(cycle);
  }

  assignLocalSettlement(settlement: any){
    this.cycleRef = settlement.cycleRef;
  }

  switchSettlementStatus(submittedValue: any){
    console.log(submittedValue);

    let reqBody = {};
    let type = "put";

    let url = this.termPaths._adminBaseUrl+"settlementcycles/"+this.cycleRef+"/status/"+submittedValue.status;

    this.showProcessing = !this.showProcessing;

    this._clientApp.processRequestMapped(url, type, reqBody, true)
    .subscribe((res) => {

    this.reqResponseObject = this.auth.mapHttpResponse(res);
    const statusCode = this.reqResponseObject.statusCode;
    const errorMsg = this.reqResponseObject.errorMessage;

    const statusMsg = this.reqResponseObject.statusMessage;
    this.showProcessing = !this.showProcessing;

      if(statusCode == '00'){

        this.options.text = statusMsg;
        this.options.title = 'Successful';
        this.alert.doToastAlert(this.options, 1);
        
        $(".closeStatus").click();
        

        this.loadSettlementRecords();
        
        this._clientApp.updateStoreUrlScripts();
        
      }else{

        this.options.type = 'error';
        this.options.text = errorMsg;
        this.alert.doToastAlert(this.options, 4);
      }

    },  err => {
      
      this.showProcessing = !this.showProcessing;
      this.options.type = 'error';
      this.options.text = err.error.errorMessage;
      
      this.alert.doToastAlert(this.options, 4);
      
      
    });
  }

  updateSettlementSearchType($event){
    //console.log($event);
    this.settlementSearchType = $event.target.value;
    this.loadSettlementRecords();
  }


  setPaginationList(totalPages){
    if(totalPages < 10){
    
      for (let i = 0; i < totalPages; i++) {
        this.totalPageList[i] = {key: i, value: (i+1).toString()};
      }

    }else{
      let firstCount = 5;
      let lastCount = totalPages-firstCount;
      let elipsCount = firstCount+3;

      for (let i = 0; i < firstCount; i++) {
        this.totalPageList[i] = {key: i, value: (i+1).toString()};
      }

      for (let k = firstCount; k < elipsCount; k++) {
        this.totalPageList[k] = {key: k, value: "."};
      }
      for (let k = elipsCount; k < elipsCount+5; k++) {
        this.totalPageList[k] = {key: k, value: lastCount};
        lastCount ++;
      }
    }
    //console.log(this.totalPageList);
  }

  paginateBack(){
    //console.log(this.currentPage);
    if(this.currentPage > 1){
      --this.currentPage;
      this.loadSettlementRecords();
    }
  }

  paginate(page){
    this.currentPage = parseInt(page) + 1;
    this.loadSettlementRecords();
  }

  paginateForward(){
    //console.log(this.currentPage);
    if(this.currentPage < this.totalPages){
      this.currentPage++;
      this.loadSettlementRecords();
    }
  }

  get s(){
    return this.settlementProfileStatusForm.controls;
  }




}
