import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ClientAppService } from 'src/app/shared/services/clientapp.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Configuration } from 'src/app/config/configuration';
import { ApiResponseObj } from 'src/app/shared/models/loginApiResponse';

import * as $ from 'jquery';

@Component({
  selector: 'app-storekycconfig',
  templateUrl: './storekycconfig.component.html',
  styleUrls: ['./storekycconfig.component.css']
})
export class StorekycconfigComponent implements OnInit {

  private termPaths = new Configuration();
  private reqResponseObject = new ApiResponseObj();
  public showProcessing: boolean;
  public isLoading = false;
  public showSearch = false;
  public isUpdating = false;

  public afuConfig = {};
  public storeProfile;

  public countryCode;

  public activePage = 1;
  public prevPage = 1;
  public totalPages;
  public totalPageList: any[] = [];
  public currentPage = 1;
  public totalCount;
  public perPageCount = 20;
  public totalCurrentPageCount = 25;
  public activateKYC = false;

  public kycForm: FormGroup;
  public storeKycs = [];

  public fields: any[] = [];
  public fieldTypes: any[] = [];
  public fieldDetails: any[] = [];
  public model = {};
  public kycNumber = null;
  public kycTextDoc = null;
  public kycAction;
  public kycTimer:any;
  private kycResponseObject = new ApiResponseObj;

  public options = { type: '', title: '', text: '' };

  constructor(private changeDetector: ChangeDetectorRef,
    private _clientApp: ClientAppService,
    public auth: AuthService,
    private fb: FormBuilder,
    private alert: AlertService) { }

  ngOnInit(): void {
    this.kycForm = this.fb.group({});
    this.loadStoreKYC();
    this.enableProfileImageUpload();

  }

  loadStoreKYC() {
    this.isLoading = !this.isLoading;
    let url =
      this.termPaths._storeBaseUrl +
      'kyc/' +
      this.auth.getUserStoreNumber();
    //console.log(url);
    this._clientApp.get(url).subscribe((res) => {
      this.isLoading = !this.isLoading;

      this.reqResponseObject = this.auth.mapHttpResponse(res);

      if (this.reqResponseObject) {
        
        this.model =  this.reqResponseObject.data;
        //console.log(this.model);
        this.buildForm();
        this._clientApp.updateStoreUrlScripts();
        this.changeDetector.detectChanges();
      }
    });
  }

  initiateStoreKYC()
  {
    this.isLoading = !this.isLoading;
    let url =
      this.termPaths._storeBaseUrl +
      'kyc/' +
      this.auth.getUserStoreNumber()+"/activate";
    //console.log(url);
    this._clientApp.get(url).subscribe((res) => {
      this.isLoading = !this.isLoading;

      this.reqResponseObject = this.auth.mapHttpResponse(res);
      let statusCode = this.reqResponseObject.statusCode;

      if (statusCode == '00') {
        this.options.text = this.reqResponseObject.statusMessage;
        this.options.title = 'Successful';

        this.alert.doToastAlert(this.options, 1);

        if (this.reqResponseObject.data != null) {
          this.loadStoreKYC();
          this.changeDetector.detectChanges();
        }
      } else {

        this.options.text = this.reqResponseObject.errorMessage;
        this.options.title = 'Failed';

        this.alert.doToastAlert(this.options, 2);
      }

    });

  }

  submitKycForm(submittedValue: any) {
    this.showProcessing = true;
    this.isUpdating = !this.isUpdating;

    let reqBody = JSON.stringify(submittedValue);

    let url =
      this.termPaths._storeBaseUrl +
      'branch/' +
      this.auth.getUserStoreNumber() +
      '/addbranch';
    let type = 'post';


    this._clientApp.processRequestMapped(url, type, reqBody, true).subscribe(
      (res) => {
        this.reqResponseObject = this.auth.mapHttpResponse(res);
        const statusCode = this.reqResponseObject.statusCode;
        const errorMsg = this.reqResponseObject.errorMessage;

        const statusMsg = this.reqResponseObject.statusMessage;

        this.showProcessing = !this.showProcessing;
        this.isUpdating = !this.isUpdating;

        if (statusCode == '00') {
          this.options.text = statusMsg;
          this.options.title = 'Successful';
          
          this.alert.doToastAlert(this.options, 1);
        } else {
          this.options.type = 'error';
          this.options.text = errorMsg;
          this.alert.doToastAlert(this.options, 4);
        }
      },
      (err) => {
        this.options.type = 'error';
        this.options.text = err.error.errorMessage;
        
        this.alert.doToastAlert(this.options, 4);
        this.showProcessing = !this.showProcessing;
        this.isUpdating = !this.isUpdating;
      }
    );
  }


  fileSelected(kyc: any){
    
    this.kycNumber = kyc.number;
    //console.log(this.kycNumber);
    let url = this.termPaths._storeBaseUrl +
    'kyc/' +
      this.auth.getUserStoreNumber() +
      '/submit/kyc/'+this.kycNumber;

    this.afuConfig = {
      multiple: false,
      formatsAllowed: '.jpeg,.jpg,.png,.pdf,.doc,.docx',
      maxSize: '5',
      uploadAPI: {
        url: url,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.auth.getUserToken()}`,
        },
        responseType: 'json',
      },
      theme: 'attachPin',
      hideProgressBar: true,
      hideResetBtn: true,
      hideSelectBtn: true,
      fileNameIndex: false,
      replaceTexts: {
        selectFileBtn: 'Choose File',
        resetBtn: 'Reset',
        uploadBtn: 'Upload',
        dragNDropBox: 'Drag N Drop',
        attachPinBtn: 'Select file',
        afterUploadMsg_success: 'Successfully Uploaded!',
        afterUploadMsg_error: 'Upload Failed !',
        sizeLimit: 'Size Limit',
      },
    };
  }

  fileActionSelected($event: any, kyc: any){
    //console.log($event);
    //console.log(kyc);
    this.kycNumber = kyc.number;
  }

  textActionSelected($event: any, kyc: any){
    
    let id = '#n'+kyc.number;
    let fieldId = '#'+kyc.number;
    this.kycNumber = kyc.number;
    this.kycTextDoc = $(fieldId).val();
    this.kycAction = false;
    clearTimeout(this.kycTimer);
    this.kycTimer = setTimeout(() =>{
      this.kycAction = true;
      this.submitKycText(kyc, this.kycTextDoc);
      //$(id).show();

    }, 3000);
  }

  submitKycText(kyc:any, val: any){
  
    if (this.kycAction == true) {

      let id = '#n'+kyc.number;
      let kycVal = {
          "storeProfileId": this.auth.getUserStoreNumber(),
          "kycNumber": kyc.number,
          "fieldName": kyc.kycDocumentName,
          "entryText": val  
      }

      $(id).show();
      
      let url =
      this.termPaths._storeBaseUrl +
      'kyc/' +
      this.auth.getUserStoreNumber()+"/submitKyc";
    
      let options = {text: '', title: ''};

      let reqBody = JSON.stringify(kycVal);
      
      this._clientApp.post(url, reqBody)
          .subscribe((res) => {
        this.kycResponseObject = this.auth.mapHttpResponse(res);
        //console.log('kyc Submission response: ',  JSON.stringify(this.kycResponseObject.data));
        const statusCode = this.kycResponseObject.statusCode
        const errorMsg = this.kycResponseObject.errorMessage
        const statusMsg = this.kycResponseObject.statusMessage
        const status = res.body['status'];

        let data = this.kycResponseObject.data;
        this.kycAction = false;

        if(statusCode == '00'){

          options.text = statusMsg;
          options.title = status;

          $(id).hide();

          this.loadStoreKYC();
          this.kycNumber = null;

          this.alert.doToastAlert(options, 1);
          
        }else{

          options.text = errorMsg;
          options.title = status;
          this.alert.doToastAlert(options, 3);
        }

      },  err => {
        //console.log(err);
        options.text = err.error.errorMessage;
        options.title = status;
        this.alert.doToastAlert(options, 3);
        
      });

    }


  }

  enableProfileImageUpload() {
    
    let url = this.termPaths._storeBaseUrl +
    'kyc/' +
      this.auth.getUserStoreNumber() +
      '/submit/kyc/'+this.kycNumber;

    this.afuConfig = {
      multiple: false,
      formatsAllowed: '.jpeg,.jpg,.png,.pdf,.doc,.docx',
      maxSize: '5',
      uploadAPI: {
        url: url,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.auth.getUserToken()}`,
        },
        responseType: 'json',
      },
      theme: 'attachPin',
      hideProgressBar: true,
      hideResetBtn: true,
      hideSelectBtn: true,
      fileNameIndex: false,
      replaceTexts: {
        selectFileBtn: 'Choose File',
        resetBtn: 'Reset',
        uploadBtn: 'Upload',
        dragNDropBox: 'Drag N Drop',
        attachPinBtn: 'Select file',
        afterUploadMsg_success: 'Successfully Uploaded!',
        afterUploadMsg_error: 'Upload Failed !',
        sizeLimit: 'Size Limit',
      },
    };
  }

  uploadKYCFile($event) {
    //console.log($event)
    let code = $event.status;
    //console.log("Upload status: "+code);
    if (code == 500 || code == 400) {
      //console.log('Error: ', $event.error)
      this.reqResponseObject = this.auth.mapHttpErrorResponse($event);
    } else {
      this.reqResponseObject = this.auth.mapHttpResponse($event);
    }

    let statusCode = this.reqResponseObject.statusCode;

    if (statusCode == '00') {
      this.options.text = this.reqResponseObject.statusMessage;
      this.options.title = 'Successful';

      this.alert.doToastAlert(this.options, 1);

      if (this.reqResponseObject.data != null) {
        
        this.loadStoreKYC();
      }
    } else {

      this.options.text = this.reqResponseObject.errorMessage;
      this.options.title = 'Failed';

      this.alert.doToastAlert(this.options, 2);
    }
  }

  buildForm(){
    const formGroupFields = {};
    this.storeKycs = [];
    for (let fi of Object.keys(this.model)) {
        let valField = []
        if (this.model[fi]['mustSubmit']) {
          valField.push(Validators.required)
        }
        formGroupFields[this.model[fi]['nameCode']] = new FormControl("").setValidators(valField);
        this.fields.push(this.model[fi]['nameCode']);
        this.fieldTypes.push(this.model[fi]['documentType']);
        this.fieldDetails.push(this.model[fi]);
        this.storeKycs.push(this.model[fi]);
    }
    //this.kycForm = new FormGroup(formGroupFields);
    this.kycForm = this.fb.group(formGroupFields);
    //console.log(this.kycForm);

  }


}
