import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SalesHistory } from 'src/app/shared/storeentities/SalesHistory';
import { Configuration } from 'src/app/config/configuration';
import { ApiResponseObj } from 'src/app/shared/models/loginApiResponse';
import { ClientAppService } from 'src/app/shared/services/clientapp.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { FileExportService } from 'src/app/shared/services/file-export.service';

import { DomSanitizer } from '@angular/platform-browser';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';

import {Chart} from 'node_modules/chart.js';

// import * as $ from 'jquery';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';


@Component({
  selector: 'app-adminstoresales',
  templateUrl: './adminstoresales.component.html',
  styleUrls: ['./adminstoresales.component.css']
})
export class AdminstoresalesComponent implements OnInit {

  public options = {type: '', title: '', text: ''};

  private termPaths = new Configuration;
  private reqResponseObject = new ApiResponseObj;
  public showProcessing: boolean;
  public showProcessingFile: boolean;
  public transactions: any[];
  
  public salesTransaction;
  public totalAmount: number = 0;
  public totalQty = 0;
  public salesProducts;

  public activePage = 1;
  public prevPage = 1;
  public totalPages;
  public totalPageList: any[] = [];
  public currentPage = 1;
  public totalCount;

  public perPageCount = 25;
  public totalCurrentPageCount = 25;

  private productSearch = '';
  public showSearch = false;
  public isLoading = false;
  public showLoadData = false;

  public salesDetailsLoader = false;
  public isSalesDetails = false;
  public transactionRef = '';
  public sStatus;
  public sStatusMsg;
  public sPaymentMode;

  public currency = 'GHS'
  private chartData;
  private todaysDate;

  private xLabels = [];
  private xPayLabels = [];
  private ySuccessDataValues = [];
  private yFailureDataValues = [];

  private yPayOptionDataValues = [];

  private ySuccessAmountDataValues = [];
  private yFailureAmountDataValues = [];
  private polarChartBgColors = ["#8e5ea2", "#3cba9f", "#e8c3b9","#c45850", "#3e95cd"];

  private chartScheduler;
  private defaultChart = {
      "startDate": "",
      "type": "DAY",
      "duration": 7
  }

  public productTopSaleAnalytics: any;
  public topSaleProducts: any = [];
  public productAnalytics: any;
  public productSalesAnalytics: any  = {};
  public productAnalyticsChart =  {
    outOfStock: 0,
    low: 0,
    active: 0,
    outOfStockCount: 0,
    lowCount: 0,
    activeCount: 0,
    total: 100,
    totalCount: 0
  };

  public searchForm: FormGroup;

  public chartType = [
    {value: 'DAY', display: 'Day'},
    {value: 'WEEK', display: 'Week'}
    // ,{value: 'MONTH', display: 'Month'}
 ];

 public pdfSrc = "";
 public salesDownloadList: SalesHistory[] = [];

 public currencyLoader = {
  "NGN": "&#8358;",
  "GHS": "&#8373;" 
 } 

 public processingSalesAnalytics: boolean;
  public primaryColour = "#ffffff";
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;

  constructor(private _clientApp: ClientAppService, 
    public auth: AuthService,
    private fb: FormBuilder,
    private alert: AlertService,
    private fileExport: FileExportService,
    private changeDetector: ChangeDetectorRef ) { }

  ngOnInit(): void {

    var today = new Date();
    this.todaysDate  = this.formatDateObject(today);
    this.searchForm = this.fb.group({
      startDate: ['', Validators.required],
      endDate: [''],
      type: ['DAY'],
      duration: ['7']
    });

    this.defaultChart.startDate = this.todaysDate;
    
    this.loadProductSalesAnalytics();
    this.loadSales();
    this.loadPaymentOption();
    this.loadChart();


    //this._clientApp.updateStoreUrlScripts();
    
    this.chartScheduler = setInterval(()=>{
      this.loadChart();
    }, 500000);

  }

  updateDatePicker($event){
    //console.log('Date selected: ', $event);
  }
  
  loadChart(){

    this.showLoadData = !this.showLoadData;
    this.xLabels = [];
    this.ySuccessDataValues = [];
    this.yFailureDataValues = [];

    this.ySuccessAmountDataValues = [];
    this.yFailureAmountDataValues = [];
    this.yPayOptionDataValues = [];

    let url = this.termPaths._adminBaseUrl+"sales/analytics";

    let reqBody = this.defaultChart;
    //console.log(reqBody);

    this._clientApp.post(url, reqBody).subscribe(res=>{
      //console.log('seaerch', res)
      this.showLoadData = !this.showLoadData;
      this.reqResponseObject = this.auth.mapHttpResponse(res);

      if(this.reqResponseObject){
        this.chartData = this.reqResponseObject.data;
        let count = this.defaultChart.duration - 1;
        let type = this.defaultChart.type;
        Object.entries(this.chartData).forEach(([p,j]) =>{
          let date = j['date'];
          let ySuccess = j['success'];
          let yFailure = j['failure'];
          let yAmountSuccess = j['successAmount'];
          let yAmountFailure = j['failureAmount'];

          //console.log('chartData', j);

          date = date.toLowerCase();

          if (type === 'DAY') {
            date = date.charAt(0).toUpperCase() + date.substring(1, 3);
          }
          
          if (type === 'WEEK') {
            date = date.substring(0, 6) + '-'+ date.substring(23, 28);
          }

          let channelDetailsProvided = [];
          
          if (null != j['channelDetails'] && j['channelDetails'].length > 0 ) {
            
            let channelDetails = j['channelDetails'];
            
            Object.entries(channelDetails).forEach(([pc,pj]) => {
              let channelName = pj['name'];
              //console.log('cDetails: ', channelName);
              channelDetailsProvided.push(channelName);
              let checkChannel = this.yPayOptionDataValues[channelName];
              if (!checkChannel) {

                this.yPayOptionDataValues[channelName] = [];

                let pcSuccessAmount = parseFloat(pj['successAmount'])
                let pcSuccessCount = parseInt(pj['success']);
                let pcSucPen = pcSuccessAmount/pcSuccessCount;

                let pcFailureAmount = parseFloat(pj['failureAmount'])
                let pcFailureCount = parseInt(pj['failure']);
                let pcFailPen = pcFailureAmount/pcFailureCount;

                this.yPayOptionDataValues[channelName].push({pcSucs: pcSucPen, pcFail: pcFailPen });

              } else{

                let pcSuccessAmount = parseFloat(pj['successAmount'])
                let pcSuccessCount = parseInt(pj['success']);
                let pcSucPen = pcSuccessAmount/pcSuccessCount;

                let pcFailureAmount = parseFloat(pj['failureAmount'])
                let pcFailureCount = parseInt(pj['failure']);
                let pcFailPen = pcFailureAmount/pcFailureCount;

                this.yPayOptionDataValues[channelName].push({pcSucs: pcSucPen, pcFail: pcFailPen });

              }

            });
            
            // Check and update other payment options..
            Object.entries(this.xPayLabels).forEach(([pc,pj]) =>{
              
              if (!channelDetailsProvided.includes(pj)) {
                if (!this.yPayOptionDataValues[pj]) {
                  this.yPayOptionDataValues[pj] = [];
                }
                this.yPayOptionDataValues[pj].push({pcSucs: 0.00, pcFail: 0.00 });
              }
             
            });            

          }else{
            
            Object.entries(this.xPayLabels).forEach(([pc,pj]) =>{
              
              if (!this.yPayOptionDataValues[pj]) {
                this.yPayOptionDataValues[pj] = [];
              }
              this.yPayOptionDataValues[pj].push({pcSucs: 0.00, pcFail: 0.00 });
            });

          }

          //console.log('PaymentOption data: ', this.yPayOptionDataValues );

          this.xLabels[count] = date;
          this.ySuccessDataValues[count] = ySuccess;
          this.yFailureDataValues[count] = yFailure;
          this.ySuccessAmountDataValues[count] = yAmountSuccess;
          this.yFailureAmountDataValues[count] = yAmountFailure;
          count--;
          
        });

        let radarChartDataSet = [];
        let radarChartDataSetSum = [];
        let radarBgColors = [];
        let colorIndex = 0;

        Object.entries(this.yPayOptionDataValues).forEach(([pc,pj]) =>{
          let dataRec = pj;
          let data = [];
          
          radarBgColors.push(this.polarChartBgColors[colorIndex]);
          colorIndex++;

          Object.entries(dataRec).forEach(([p,j]) =>{
            data.push(j['pcSucs']);
          });
          let sData = 0.00;
          Object.entries(dataRec).forEach(([p,j]) =>{
            sData = sData + parseFloat(j['pcSucs']);
          });

          radarChartDataSetSum.push(sData);

          let dataSet = {
            label: pc,
            fill: true,
            backgroundColor: "rgba(179,181,198,0.2)",
            borderColor: "rgba(179,181,198,1)",
            pointBorderColor: "#fff",
            pointBackgroundColor: "rgba(179,181,198,1)",
            data: data
          }
          radarChartDataSet.push(dataSet);

        });

        var myLineChart = new Chart("myProductCountChart", {
          type: "line",
          data: {
              labels: this.xLabels,
              datasets: [
                  {
                    label: "Success",
                    lineTension: 0.3,
                    backgroundColor: "rgba(0, 97, 242, 0.05)",
                    borderColor: "rgba(0, 97, 242, 1)",
                    pointRadius: 3,
                    pointBackgroundColor: "rgba(0, 97, 242, 1)",
                    pointBorderColor: "rgba(0, 97, 242, 1)",
                    pointHoverRadius: 3,
                    pointHoverBackgroundColor: "rgba(0, 97, 242, 1)",
                    pointHoverBorderColor: "rgba(0, 97, 242, 1)",
                    pointHitRadius: 10,
                    pointBorderWidth: 2,
                    data: this.ySuccessDataValues
                },
                {
                  label: "Failed",
                  lineTension: 0.3,
                  backgroundColor: "rgba(211, 84, 0, 0.05)",
                  borderColor: "rgba(211, 84, 0, 1)",
                  pointRadius: 3,
                  pointBackgroundColor: "rgba(211, 84, 0, 1)",
                  pointBorderColor: "rgba(211, 84, 0, 1)",
                  pointHoverRadius: 2,
                  pointHoverBackgroundColor: "rgba(211, 84, 0, 1)",
                  pointHoverBorderColor: "rgba(211, 84, 0, 1)",
                  pointHitRadius: 10,
                  pointBorderWidth: 2,
                  data: this.yFailureDataValues
                }
            ]

          },
          options: {
              maintainAspectRatio: false,
              layout: {
                  padding: {
                      left: 10,
                      right: 25,
                      top: 25,
                      bottom: 0
                  }
              },
              scales: {
                  xAxes: [{
                      time: {
                          unit: "date"
                      },
                      gridLines: {
                          display: false,
                          drawBorder: false
                      },
                      ticks: {
                          maxTicksLimit: 7
                      }
                  }],
                  yAxes: [{
                      ticks: {
                          maxTicksLimit: 7,
                          padding: 10,
                          beginAtZero: true,
                          // Include a dollar sign in the ticks
                          callback: function(value, index, values) {
                              return value;
                          }
                      },
                      gridLines: {
                          color: "rgb(234, 236, 244)",
                          zeroLineColor: "rgb(234, 236, 244)",
                          drawBorder: false,
                          borderDash: [2],
                          zeroLineBorderDash: [2]
                      }
                  }]
              },
              legend: {
                  display: false
              },
              tooltips: {
                  backgroundColor: "rgb(255,255,255)",
                  bodyFontColor: "#858796",
                  titleMarginBottom: 10,
                  titleFontColor: "#6e707e",
                  titleFontSize: 14,
                  borderColor: "#dddfeb",
                  borderWidth: 1,
                  xPadding: 15,
                  yPadding: 15,
                  displayColors: false,
                  intersect: false,
                  mode: "index",
                  caretPadding: 10,
                  callbacks: {
                      // label: function(tooltipItem, chart) {
                      //     var datasetLabel =
                      //         chart.datasets[tooltipItem.datasetIndex].label || "";
                      //     return datasetLabel + ": $" + number_format(tooltipItem.yLabel);
                      // }
                  }
              }
          }
        });

        var myAmountChart = new Chart("myProductAmountChart", {
          type: "line",
          data: {
              labels: this.xLabels,
              datasets: [
                {
                  label: "Success",
                  lineTension: 0.3,
                  backgroundColor: "rgba(0, 97, 242, 0.05)",
                  borderColor: "rgba(0, 97, 242, 1)",
                  pointRadius: 3,
                  pointBackgroundColor: "rgba(0, 97, 242, 1)",
                  pointBorderColor: "rgba(0, 97, 242, 1)",
                  pointHoverRadius: 3,
                  pointHoverBackgroundColor: "rgba(0, 97, 242, 1)",
                  pointHoverBorderColor: "rgba(0, 97, 242, 1)",
                  pointHitRadius: 10,
                  pointBorderWidth: 2,
                  data: this.ySuccessAmountDataValues
              },
              {
                label: "Failed",
                lineTension: 0.3,
                backgroundColor: "rgba(211, 84, 0, 0.05)",
                borderColor: "rgba(211, 84, 0, 1)",
                pointRadius: 3,
                pointBackgroundColor: "rgba(211, 84, 0, 1)",
                pointBorderColor: "rgba(211, 84, 0, 1)",
                pointHoverRadius: 2,
                pointHoverBackgroundColor: "rgba(211, 84, 0, 1)",
                pointHoverBorderColor: "rgba(211, 84, 0, 1)",
                pointHitRadius: 10,
                pointBorderWidth: 2,
                data: this.yFailureAmountDataValues
            }
            
            ]
          },
          options: {
              maintainAspectRatio: false,
              layout: {
                  padding: {
                      left: 10,
                      right: 25,
                      top: 25,
                      bottom: 0
                  }
              },
              scales: {
                  xAxes: [{
                      time: {
                          unit: "date"
                      },
                      gridLines: {
                          display: false,
                          drawBorder: false
                      },
                      ticks: {
                          maxTicksLimit: 7
                      }
                  }],
                  yAxes: [{
                      ticks: {
                          maxTicksLimit: 5,
                          padding: 10,
                          beginAtZero: true,
                          // Include a dollar sign in the ticks
                          callback: function(value, index, values) {
                              return "GHS " + value;
                              // return this.currencyLoader[this.country]+" " + value;
                          }
                      },
                      gridLines: {
                          color: "rgb(234, 236, 244)",
                          zeroLineColor: "rgb(234, 236, 244)",
                          drawBorder: false,
                          borderDash: [2],
                          zeroLineBorderDash: [2]
                      }
                  }]
              },
              legend: {
                  display: false
              },
              tooltips: {
                  backgroundColor: "rgb(255,255,255)",
                  bodyFontColor: "#858796",
                  titleMarginBottom: 10,
                  titleFontColor: "#6e707e",
                  titleFontSize: 14,
                  borderColor: "#dddfeb",
                  borderWidth: 1,
                  xPadding: 15,
                  yPadding: 15,
                  displayColors: false,
                  intersect: false,
                  mode: "index",
                  caretPadding: 10,
                  callbacks: {
                      // label: function(tooltipItem, chart) {
                      //     var datasetLabel =
                      //         chart.datasets[tooltipItem.datasetIndex].label || "";
                      //     return datasetLabel + ": $" + number_format(tooltipItem.yLabel);
                      // }
                  }
              }
          }
        });

        var myRadarChart = new Chart("myProductPayOptionChart", {
          type: 'polarArea',
          data: {
            labels: this.xPayLabels,
            datasets: [
              {
                label: "Payment options",
                backgroundColor: radarBgColors,
                data: radarChartDataSetSum
              }
            ]
          },
          options: {
            title: {
              display: true,
              text: 'Payment options'
            }
          }
        });



        this.defaultChart = {
          "startDate": this.todaysDate,
          "type": "DAY",
          "duration": 7
        }

          this.options.text = "Data graph updated successfully!";
          this.options.title = "Success";
          this.alert.doToastAlert(this.options, 1);
        
      }

    });
    
  }

  loadPaymentOption(){
    // provision switch country option
     let countryCode = "GHS";

    let url = this.termPaths._systemAdminBaseUrl+"loadcountry/paymentoptions?countryCode="+countryCode;
    
    this._clientApp.get(url).subscribe(res=>{
      //console.log('payment options: ',res);
      this.reqResponseObject = this.auth.mapHttpResponse(res);
      
      if(this.reqResponseObject){
        
        const payOptions = this.reqResponseObject.data;
        for(var i=0; i<payOptions.length; i++){
          this.xPayLabels.push(payOptions[i].name);
        }
        //console.log("Pay labels: "+this.xPayLabels);


      }
    })

  }

  formatDateObject(today){
    let day = today.getDate();
    let actualDay = String(day);
    let month = today.getMonth()+1;
    let actualMonth = String(month);
    if (day < 10) {
      actualDay = '0'+actualDay;
    }
    if (month < 10) {
      actualMonth = '0'+actualMonth;
    }
    return actualDay+'-'+actualMonth+'-'+today.getFullYear();
  }

  percentage(num, per)
  {
    const value = ((num/100)*per) * 100
    return Math.round(value);
  }

  updateChart(submittedValue: any){
    //console.log(submittedValue);
    let sDate = this.formatDateObject(submittedValue.startDate);
    let type = submittedValue.type;
    let duration = submittedValue.duration;
    //console.log(sDate, type, duration);

    this.defaultChart.startDate = sDate;
    this.defaultChart.type = type;
    this.defaultChart.duration = duration;

    this.loadChart();

  }

  loadProductSalesAnalytics(){
    this.options = {type: '', title: '', text: ''};

    let url = this.termPaths._adminBaseUrl+"productsales/analytics";
    
    this.processingSalesAnalytics = true;

    this._clientApp.get(url).subscribe(res =>{
      //console.log(res);
      this.processingSalesAnalytics = false;
      this.reqResponseObject = this.auth.mapHttpResponse(res);
      
      if(this.reqResponseObject.statusCode == "00"){
        this.productSalesAnalytics = this.reqResponseObject.data;
      }
    },  err => {
      
      this.options.type = 'error';
      this.options.text = err.error.errorMessage;
      
      this.processingSalesAnalytics = false;
      this.alert.doToastAlert(this.options, 4);
    });

  }


  loadStoreTopSalesAnalytics(){
    this.options = {type: '', title: '', text: ''};
    let url = this.termPaths._storeBaseUrl+ 'configs/'
           +this.auth.getUserStoreNumber()+"/topproducts/analytics";

    this._clientApp.get(url).subscribe(res =>{
      //console.log(res);
      this.reqResponseObject = this.auth.mapHttpResponse(res);
      
      if(this.reqResponseObject.statusCode == "00"){
        
        this.productTopSaleAnalytics = this.reqResponseObject.data;
        this.topSaleProducts = this.productTopSaleAnalytics.topSellingProducts;
        //console.log(this.topSaleProducts);
        
      }
    })

  }

  loadSales(){
    this.isLoading = !this.isLoading;

    let url = this.termPaths._adminBaseUrl+"stores/allsales?search="
      +this.productSearch+"&page="+this.currentPage+"&size="+this.perPageCount;

    this._clientApp.get(url).subscribe(res =>{
      //console.log(res);
      this.isLoading = !this.isLoading;
      this._clientApp.updateStoreUrlScripts();
      this.transactions = res.body['data']['payload'];

      this.reqResponseObject = this.auth.mapHttpResponse(res);
      
      if(this.reqResponseObject){
        
        this.totalCount = this.reqResponseObject.data.totalCount;
        this.totalPages = this.reqResponseObject.data.totalPages;
        this.setPaginationList(this.totalPages);
        this.currentPage = this.reqResponseObject.data.page;
        //this.transactions = this.reqResponseObject.data.payload;

        if (this.currentPage >= 1) {
          if (this.totalCurrentPageCount > this.totalCount) {
            this.totalCurrentPageCount = this.totalCount;
          }else{
            this.totalCurrentPageCount = this.perPageCount * this.currentPage;
            if (this.totalCurrentPageCount >= this.totalCount) {
              this.totalCurrentPageCount = this.totalCount;
            }
          }
        }
        
      }
      this.changeDetector.detectChanges();
    });

  }

  viewSalesDetails(trans){
    this.salesDetailsLoader = true;
    //this.isSalesDetails = !this.isSalesDetails;
    this.salesTransaction = trans;
    this.totalAmount = trans.amount;
    this.totalQty = trans.productQuantity
    this.transactionRef = trans.transactionRef;
    this.transactionRef = trans.transactionRef;
    this.sStatus = trans.status;
    this.sStatusMsg = trans.channelStatusMessage;
    this.sPaymentMode = trans.paymentChannelType;
    this.salesProducts = trans.products;
    this.salesDetailsLoader = false;
    this.changeDetector.detectChanges();

    //this._clientApp.updateStoreUrlScripts();

  }

  searchProductSales(txt){

    if(txt !== this.productSearch){

      this.productSearch = txt;
      this.showSearch = true;
      this.currentPage = 1;

      let url = this.termPaths._adminBaseUrl+"stores/allsales?search="
      +this.productSearch+"&page="+this.currentPage+"&size="+this.perPageCount;

    this._clientApp.get(url).subscribe(res=>{

      this.transactions = res.body['data']['payload'];
      this.reqResponseObject = this.auth.mapHttpResponse(res);
      this.showSearch = false;
      if(this.reqResponseObject){
          //console.log("products search paginated: ",this.reqResponseObject.data);
          this.totalCount = this.reqResponseObject.data.totalCount;
          this.totalPages = this.reqResponseObject.data.totalPages;
          this.setPaginationList(this.totalPages);
          this.currentPage = this.reqResponseObject.data.page;
          //this.transactions = this.reqResponseObject.data.payload;

          if (this.currentPage > 1) {
            this.totalCurrentPageCount = this.perPageCount * this.currentPage;
            if (this.totalCurrentPageCount >= this.totalCount) {
              this.totalCurrentPageCount = this.totalCount;
            }
          }

          this._clientApp.updateStoreUrlScripts();
          this.changeDetector.detectChanges();

        }
      })

    }

  }

  downloadData(element){
    var htmlElement = document.getElementById(element);
    var canvas = html2canvas(htmlElement).then((canvas) => {
      var imgData = canvas.toDataURL("image/png");
      var doc = new jspdf();
      doc.addImage(imgData, 0, 0,200, 500);
      doc.save("doc.pdf");
    });

  }

  downloadSalesRecord() {

    this.showProcessingFile = true;
    
    if (!this.transactions || this.transactions.length === 0) {
      let options = {text: 'No product sales record found!!', title: 'Warning'};
      this.alert.doToastAlert(options, 3);
      this.showProcessingFile = false;
      return;
    }

    const countTransaction = this.transactions.length;
    this.salesDownloadList = new Array(countTransaction).fill(new SalesHistory);

    for (let i = 0; i < this.transactions.length; i++) {
      
      const dProductSalesList = new SalesHistory();
      dProductSalesList.Amount = this.transactions[i].amount;
      dProductSalesList.AmountTendered = this.transactions[i].amountTendered;
      dProductSalesList.ProductQuantity = this.transactions[i].productQuantity;
      dProductSalesList.TransactionRef = this.transactions[i].transactionRef;
      dProductSalesList.CardName = this.transactions[i].cardName;
      dProductSalesList.CardType = this.transactions[i].cardType;
      dProductSalesList.ChannelStatusCode = this.transactions[i].channelStatusCode;
      dProductSalesList.ChannelStatusMessage = this.transactions[i].channelStatusMessage;
      dProductSalesList.Currency = this.transactions[i].currency;
      dProductSalesList.CustomerBalance = this.transactions[i].customerBalance;
      dProductSalesList.Fee = this.transactions[i].fee;
      dProductSalesList.PaymentChannelType = this.transactions[i].paymentChannelType;
      
      dProductSalesList.SettlementAmount = this.transactions[i].settlementAmount;
      dProductSalesList.SoldByUser = this.transactions[i].soldByUser;
      dProductSalesList.Status = this.transactions[i].status;
      dProductSalesList.CreatedAt = this.transactions[i].createdAt;

      if (this.transactions[i].products) {
        
        let productDetails = "";
        let products = this.transactions[i].products;

        Object.entries(products).forEach(([p,j]) =>{
          let name = j['productName'];
          let amount = j['amount'];
          let quantity = j['quantity'];

          let prodDet = "Name: "+name +" | Qty: "+quantity+" | Amount: "+amount;

          productDetails = productDetails + prodDet +" \n";
        });

        dProductSalesList.ProductsDetails = productDetails;

      }

      
      if (this.transactions[i].customer) {

        dProductSalesList.CustomerEmail = this.transactions[i].customer.customerEmail;
        dProductSalesList.CustomerPhone = this.transactions[i].customer.customerPhone;
        dProductSalesList.CustomerName = this.transactions[i].customer.customerName;

      }else{

        dProductSalesList.CustomerEmail = "";
        dProductSalesList.CustomerPhone = "";
        dProductSalesList.CustomerName = "";
      }
      
      this.salesDownloadList[i] = dProductSalesList;
      
    }

    //console.log(this.salesDownloadList);

    this.fileExport.exportAsExcelFile(this.salesDownloadList, 'Sales_records');
    const options = {
      text: 'File downloaded successfully..',
      title: 'Warning'
    };

    this.alert.doToastAlert(options, 1);
   
    this.showProcessingFile = false;
    this.salesDownloadList = [];
  }

  setPaginationList(totalPages){
    //console.log('Total transactions: ', totalPages);
    this.totalPageList =  [];
    if(totalPages < 10){
    
      for (let i = 0; i < totalPages; i++) {
        this.totalPageList[i] = {key: i, value: (i+1).toString()};
      }

    }else{

      let firstCount = 5;
      let lastCount = totalPages-firstCount;
      let elipsCountDown = firstCount+2;
      let elipsCountUp = lastCount-2;

      // if ((lastCount-firstCount) < 4) {
      //   elipsCountDown = firstCount;
      //   elipsCountUp = elipsCountDown+1
      // }

      let index = 0;

      for (let i = 0; i < firstCount; i++) {
        this.totalPageList[index] = {key: i, value: (i+1).toString()};
        index++;
      }

      for (let k = firstCount; k < elipsCountDown; k++) {
        this.totalPageList[index] = {key: k+"d", value: "."};
        //lastCount++;
        index++;
      }
      for (let k = elipsCountUp; k < lastCount; k++) {
        this.totalPageList[index] = {key: k+"u", value: "."};
        //lastCount ++;
        index++;
      }
      for (let k = lastCount; k < totalPages; k++) {
        this.totalPageList[index] = {key: k, value: k};
        //lastCount ++;
        index++;
      }
    }
    //console.log(this.totalPageList);
  }

  paginate(page){
    page = String(page);
    if (page.indexOf('d') !== -1) {
      page = page.substr(0, page.indexOf('d'));
    }

    if (page.indexOf('u') !== -1) {
      page = page.substr(0, page.indexOf('u'));
    }
    this.currentPage = parseInt(page) + 1;

    this.loadSales();
  }

  changePerPage($event){
    let pageCount = $event.value;
    //console.log(pageCount);
    this.perPageCount = parseInt(pageCount);
    this.loadSales();
  }

  paginateBack(){
    //console.log(this.currentPage);
    if(this.currentPage > 1){
      --this.currentPage;
      this.loadSales();
    }
  }

  paginateForward(){
    //console.log(this.currentPage);
    if(this.currentPage < this.totalPages){
      this.currentPage++;
      this.loadSales();
    }
  }

  ngOnDestroy(){
    if (this.chartScheduler) {
      clearInterval(this.chartScheduler);
    }
  }

  

}
