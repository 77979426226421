import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { CommonModule } from '@angular/common';

import { StorekeeperloginComponent } from './storekeeper/storekeeperlogin/storekeeperlogin.component';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { PagenotfoundComponent } from './main/pagenotfound/pagenotfound.component';

// const routes: Routes = [
//   {path: 'home', component: StoredashoardComponent}
// ];

const routes: Routes = [
  {path: '', redirectTo: 'inventory/login', pathMatch: 'full'},
  {path: 'inventory', loadChildren: () => import('./app-routing/home/home.module').then(m => m.HomeModule)},
  {
    path: 'storekeeper', redirectTo: 'storekeeper/login', pathMatch: 'full'
  },
  
  { path: 'storekeeper',  
    children: [
      {path: '', redirectTo: 'login', pathMatch: 'full'},
      {path: 'login', component: StorekeeperloginComponent},
      // {path: 'salesboard', component: StorekeepersaleboardComponent},
    ]
  },
  
  {path: 'storeapp', loadChildren: () => import('./app-routing/stores/storekeeper/storekeeper.module').then(m => m.StorekeeperModule)},

  {path: 'mystore', loadChildren: () => import('./app-routing/stores/stores.module').then(m => m.StoresModule)},
  {path: 'admin', loadChildren: () => import('./app-routing/admin/admin.module').then(m => m.AdminModule)},
  { path: 'pdf', component: PdfViewerComponent},
  { path: '**', component: PagenotfoundComponent }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
