import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClientAppService } from 'src/app/shared/services/clientapp.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UsernameValidator } from 'src/app/shared/customvalidators/username-validator';
import { Configuration } from 'src/app/config/configuration';
import { ApiResponseObj } from 'src/app/shared/models/loginApiResponse';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-adminusers',
  templateUrl: './adminusers.component.html',
  styleUrls: ['./adminusers.component.css']
})
export class AdminusersComponent implements OnInit {

  private termPaths = new Configuration;
  private reqResponseObject = new ApiResponseObj;
  public showProcessing: boolean;

  public options = {type: '', title: '', text: ''};

  public countryCode;

  public activePage = 1;
  public prevPage = 1;
  public totalPages;
  public totalPageList: any[] = [];
  public currentPage = 1;
  public totalCount;
  public perPageCount = 20;

  public processingSalesAnalytics: boolean;
  public productSalesAnalytics: any  = {};
  

  public adminUsers;

  public role: any;
  public newUserRole: any;
  private rolePermissions: any[];
  private adminRoles;
  public adminRolesList: any[] = [];
  private adminPermisionList;

  public userProfileForm: FormGroup;

  public currency = 'GHS'
  public currencyLoader = {
    "NGN": "&#8358;",
    "GHS": "&#8373;" 
   }

  constructor(private changeDetector: ChangeDetectorRef, 
    private _clientApp: ClientAppService, public auth: AuthService, 
    private alert: AlertService, private fb: FormBuilder) { }

  ngOnInit(): void {

    //this._clientApp.updateStoreUrlScripts();

    this.userProfileForm = this.fb.group({
      roleName: ["", Validators.required],
      username: ["", [Validators.required, UsernameValidator.cannotContainSpace]],
      password: ["", [Validators.required, UsernameValidator.cannotContainSpace]],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      emailAddress: ["", [Validators.email]],
      isAdminUser: ["true"],
      phoneNumber: [""]

    });

    this.loadProductSalesAnalytics();
    this.loadAdminUsers();
    this.loadAdminRoles();

  }


  loadAdminUsers(){
    let url = this.termPaths._adminBaseUrl+"loadadminusers?page="+this.currentPage+"&size="+this.perPageCount;

    this._clientApp.get(url).subscribe(res=>{
      //console.log(res);
      this.reqResponseObject = this.auth.mapHttpResponse(res);
      
      if(this.reqResponseObject){
       
        this.adminUsers = this.reqResponseObject.data;

        this._clientApp.updateStoreUrlScripts();
        this.changeDetector.detectChanges();
      }
    })

  }

  loadProductSalesAnalytics(){
    this.options = {type: '', title: '', text: ''};

    let url = this.termPaths._adminBaseUrl+"productsales/analytics";
    
    this.processingSalesAnalytics = true;

    this._clientApp.get(url).subscribe(res =>{
      //console.log(res);
      this.processingSalesAnalytics = false;
      this.reqResponseObject = this.auth.mapHttpResponse(res);
      
      if(this.reqResponseObject.statusCode == "00"){
        this.productSalesAnalytics = this.reqResponseObject.data;
      }
    },  err => {
      
      this.options.type = 'error';
      this.options.text = err.error.errorMessage;
      
      this.processingSalesAnalytics = false;
      this.alert.doToastAlert(this.options, 4);
    });

  }

  inviteUserProfile(submittedValue: any) {
    
    this.showProcessing = !this.showProcessing;

    if (submittedValue.roleName == this.adminPermisionList.roleName) {
      
      this.options.text = "Only one user can be assigned the "+submittedValue.roleName+" role."
      +"Kindly visit the account and setting section to create custom role or select another role."
      this.options.title = 'Warning';
      
      this.showProcessing = !this.showProcessing;

      this.alert.doToastAlert(this.options, 3);

      return;
    }
    
    let reqBody = JSON.stringify(submittedValue);
    
    let url = this.termPaths._adminBaseUrl+"system/adduser";

    this._clientApp.post(url, reqBody)
    
    .subscribe((res) => {
      //console.log(res);
      this.reqResponseObject = this.auth.mapHttpResponse(res);
      const statusCode = this.reqResponseObject.statusCode;
      const errorMsg = this.reqResponseObject.errorMessage;

        const statusMsg = this.reqResponseObject.statusMessage

        this.showProcessing = !this.showProcessing;

        if(statusCode == '00'){

          this.options.text = statusMsg;
          this.options.title = 'Successful';

          this.alert.doToastAlert(this.options, 1);
          
          this.loadAdminUsers();
          this._clientApp.updateStoreUrlScripts();
          $('.closePay').click();
          //location.reload();

          
        }else{

          this.options.type = 'error';
          this.options.text = errorMsg;

          this.alert.doToastAlert(this.options, 4);
        }

    },  err => {
      
      this.options.type = 'error';
      this.options.text = err.error.errorMessage;
      //console.log(this.options.text);
      this.alert.doToastAlert(this.options, 4);
      this.showProcessing = !this.showProcessing;
      
    });

  }

  loadAdminRoles(){
    let url = this.termPaths._adminBaseUrl+"system/adminroles";

    this._clientApp.get(url).subscribe(res=>{
      //console.log(res);
      this.reqResponseObject = this.auth.mapHttpResponse(res);
      
      if(this.reqResponseObject){
        this.adminRoles = this.reqResponseObject.data;

        if (this.adminRoles) {
          
          Object.entries(this.adminRoles).forEach(([k,v]) =>{
            let role = v['roleName'];
            if (role === 'SYS_ADMINISTRATOR') {
              this.adminPermisionList = { roleName : role, permissions: v['permissions']};
            }
            let roleView = role.replace("_", " ");
            
            this.adminRolesList[k] = { 'roleName': role,  'name': roleView};
          });

        }
        //console.log(this.adminRolesList)
        this._clientApp.updateStoreUrlScripts();
        this.changeDetector.detectChanges();
      }
    })

  }

  get f(){
    return this.userProfileForm.controls;
  }

}
