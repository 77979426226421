<header class="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
    <div class="container-fluid">
        <div class="page-header-content">
            <div class="row align-items-center justify-content-between pt-3">
                <div class="col-auto mb-3">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="user"></i></div>
                        <b>{{auth.getUserStoreName()}}: </b> Account Settings - Security
                    </h1>
                </div>
            </div>
        </div>
    </div>
</header>

<div class="container mt-4">
    <!-- Account page navigation-->
    <nav class="nav nav-borders">
        <a class="nav-link" routerLink="/mystore/dashboard/settings">Profile</a>
        <a *ngIf="auth.hasProfilePermission('CAN_ACCESS_STORE_BILLING_PROFILE')" class="nav-link" routerLink="/mystore/dashboard/billings">Billing</a>
        <a class="nav-link" routerLink="/mystore/dashboard/security">Security</a>
        <a *ngIf="auth.hasProfilePermission('CAN_ACCESS_STORE_PROFILE')" class="nav-link" routerLink="/mystore/dashboard/store-profile">Store Profile</a>
        <a *ngIf="auth.hasProfilePermission('CAN_ACCESS_STORE_BRANCHES')" class="nav-link ml-0" routerLink="/mystore/dashboard/store-branches">Store Branches</a>
        <a *ngIf="auth.hasProfilePermission('CAN_SUBMIT_KYC_DOCUMENT')" class="nav-link active ml-0" routerLink="/mystore/dashboard/store-kyc">Store KYC</a>
    </nav>
    <hr class="mt-0 mb-4" />
    <div class="row">
        <div class="col-xl-12">
            <!-- Account details card-->
            <div class="card mb-4">
                <div class="card-header">Store KYC For Digital Services <button *ngIf="storeKycs?.length == 0" class="btn btn-sm btn-primary float-right" (click)="initiateStoreKYC()">Initiate Digital Service Request</button></div>
                <div class="card-body">
                    <div class="tab-content" id="dashboardNavContent">
                        
                        <div class="tab-pane fade show active" id="activities" role="tabpanel" aria-labelledby="activities-pill">
                            <div *ngIf="storeKycs?.length > 0; else noStoreKycs" class="datatable table-responsive">
                                <table class="table table-bordered table-hover" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>S/N</th>
                                            <th>Document name</th>
                                            <th>Document type</th>
                                            <th>Description</th>
                                            <th>Status</th>
                                            <th>Approval Status</th>
                                            <th>Submission</th>
                                            <th>Review</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- <form [formGroup]="kycForm" (ngSubmit)="submitKycForm(kycForm.value)">
                                        </form> -->
                                        <tr *ngFor='let kyc of storeKycs; let i=index;'>
                                            <td>{{(i+1)}}</td>
                                            <td>
                                                {{kyc.name}}
                                            </td>
                                            <td>
                                                {{kyc.documentType}}
                                            </td>
                                            <td>
                                                {{kyc.description}}
                                            </td> 
                                            <td>
                                                <span *ngIf="kyc.status != 'PROFILED'"><i class="text-green" data-feather="bell-off"></i></span>
                                                <span *ngIf="kyc.status === 'PROFILED'"><i class="text-red" data-feather="bell"></i></span>
                                                {{kyc.status}}
                                            </td>
                                            <td>
                                                <span *ngIf="kyc.approvalStatus === 'APPROVED'"><i class="text-green" data-feather="bell-off"></i></span>
                                                <span *ngIf="kyc.approvalStatus === 'PROFILED'"><i class="text-blue" data-feather="bell"></i></span>
                                                <span *ngIf="kyc.approvalStatus === 'REJECTED'"><i class="text-red" data-feather="bell"></i></span>
                                                <span *ngIf="kyc.approvalStatus === 'REVIEWED'"><i class="text-yellow" data-feather="bell"></i></span>
                                                {{kyc.approvalStatus}}
                                            </td>
                                            <td *ngIf="kyc.documentType == 'TEXT'; else subLink" style="word-wrap: break-word;">
                                                {{kyc.kycText}}
                                            </td>
                                            <ng-template #subLink>
                                                <td>
                                                    <i *ngIf="kyc.kycDocumentUrl" class="mr-2 text-green" data-feather="zap"></i>
                                                    <a *ngIf="kyc.kycDocumentUrl" href="{{kyc.kycDocumentUrl}}" target="_blank" rel="noopener noreferrer">View</a>
                                                </td>
                                            </ng-template>
                                            <td style="word-wrap: break-word;">
                                                {{kyc.reviewInfo}}
                                            </td>
                                            <td>
                                                <ng-container *ngIf="kyc.documentType == 'TEXT'; else fileUploadTemplate">
                                                    <!-- <input class="form-control" id="{{kyc.nameCode}}" type="text" formControlName="{{kyc.nameCode}}" maxlength="30"> -->
                                                    <input class="form-control" id="{{kyc.number}}" (keyup)="textActionSelected($event, kyc)" type="text" maxlength="200">
                                                    <span class="notification float-right" id="n{{kyc.number}}" style="display: none;"> Processing submission... <img src="../../../../assets/landing/images/ring1.gif" class="smLoader1"></span>
                                                </ng-container>
                                                <ng-template #fileUploadTemplate>
                                                    <span (click)="fileSelected(kyc)">
                                                        <angular-file-uploader 
                                                            [config]="afuConfig"
                                                            [resetUpload]='true'
                                                            (fileSelected)="fileSelected($event)"
                                                            (ApiResponse)="uploadKYCFile($event)">
                                                        </angular-file-uploader>
                                                    </span>
                                                    
                                                </ng-template>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            
                            <ng-template #noStoreKycs>
                                <p>Store Digital services KYC not provisioned!</p>
                            </ng-template>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>


