<header class="page-header page-header-dark bg-gradient-primary-to-secondaryadmin pb-10">
    <div class="container">
        <div class="page-header-content pt-4">
            <div class="row align-items-center justify-content-between">
                <div class="col-auto mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Salesboard
                    </h1>
                    <div class="page-header-subtitle">Inventory management system admin roles and permission</div>
                </div>
                
            </div>
        </div>
    </div>
</header>

<div *ngIf="isListRoles" class="container mt-n10">
    
    <div class="row">
        <div class="col-xl-3 col-md-6 mb-4">
            <!-- Dashboard info widget 1-->
            <div class="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-primary h-100">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <div class="small font-weight-bold text-primary mb-1">Today Sales ({{productSalesAnalytics.totalDaySalesCount}})</div>
                            <div class="h5"><span [innerHTML]="currencyLoader[currency]"></span>{{productSalesAnalytics.totalDaySalesAmount | number : '1.2-2'}}</div>
                            <div class="text-xs font-weight-bold text-success d-inline-flex align-items-center">
                                <i class="mr-1" data-feather="trending-up"></i>
                                {{(productSalesAnalytics.averageDaySalesAmount/productSalesAnalytics.averageDaySalesCount)/100 | number : '1.2-2'  }}%
                            </div>
                        </div>
                        <div class="ml-2"><i class="fas fa-tag fa-2x fa-2x text-gray-200"></i></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
            <!-- Dashboard info widget 3-->
            <div class="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-success h-100">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <div class="small font-weight-bold text-success mb-1">Current Week ({{productSalesAnalytics.totalWeekSalesCount}})</div>
                            <div class="h5"><span [innerHTML]="currencyLoader[currency]"></span>{{productSalesAnalytics.totalWeekSalesAmount | number : '1.2-2'}}</div>
                            <div class="text-xs font-weight-bold text-success d-inline-flex align-items-center">
                                <i *ngIf="productSalesAnalytics.weekGrowthType == 'NEGATIVE'" class="mr-1" data-feather="trending-down"></i>
                                <i *ngIf="productSalesAnalytics.weekGrowthType == 'POSITIVE'" class="mr-1" data-feather="trending-up"></i>
                                {{productSalesAnalytics.totalWeekSalesAmount/productSalesAnalytics.prevWeekSalesAmount | number : '1.2-2' }}%
                            </div>
                        </div>
                        <div class="ml-2"><i class="fas fa-mouse-pointer fa-2x text-gray-200"></i></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
            <!-- Dashboard info widget 2-->
            <div class="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-secondary h-100">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <div class="small font-weight-bold text-secondary mb-1">Current Month ({{productSalesAnalytics.totalMonthSalesCount}})</div>
                            <div class="h5"><span [innerHTML]="currencyLoader[currency]"></span>{{productSalesAnalytics.totalMonthSalesAmount | number : '1.2-2'}}</div>
                            <div class="text-xs font-weight-bold text-danger d-inline-flex align-items-center">
                                <i *ngIf="productSalesAnalytics.monthGrowthType == 'NEGATIVE'" class="mr-1" data-feather="trending-down"></i>
                                <i *ngIf="productSalesAnalytics.monthGrowthType == 'POSITIVE'" class="mr-1" data-feather="trending-up"></i>
                                {{productSalesAnalytics.monthGrowthPercentage}}%
                            </div>
                        </div>
                        <div class="ml-2"><i class="fas fa-tag fa-2x text-gray-200"></i></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
            <!-- Dashboard info widget 4-->
            <div class="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-info h-100">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <div class="small font-weight-bold text-info mb-1">Last Month ({{productSalesAnalytics.prevMonthAverageSalesCount}})</div>
                            <div class="h5"><span [innerHTML]="currencyLoader[currency]"></span>{{productSalesAnalytics.prevMonthAverageSalesAmount | number : '1.2-2'}}</div>
                            <div class="text-xs font-weight-bold text-danger d-inline-flex align-items-center">
                                <i *ngIf="productSalesAnalytics.averageMonthSalesAmount > productSalesAnalytics.prevMonthAverageSalesAmount" class="mr-1" data-feather="trending-down"></i>
                                <i *ngIf="productSalesAnalytics.averageMonthSalesAmount < productSalesAnalytics.prevMonthAverageSalesAmount" class="mr-1" data-feather="trending-up"></i>
                                <span *ngIf="productSalesAnalytics.averageMonthSalesAmount < productSalesAnalytics.prevMonthAverageSalesAmount">{{(productSalesAnalytics.prevMonthAverageSalesAmount/productSalesAnalytics.totalMonthSalesAmount)/100 | number : '1.2-2' }}%</span>
                                <span *ngIf="productSalesAnalytics.averageMonthSalesAmount > productSalesAnalytics.prevMonthAverageSalesAmount">{{(productSalesAnalytics.totalMonthSalesAmount/productSalesAnalytics.prevMonthAverageSalesAmount)*100 | number : '1.2-2' }}%</span>

                            </div>
                        </div>
                        <div class="ml-2"><i class="fas fa-percentage fa-2x text-gray-200"></i></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card mb-4">
        <div class="card-header">Admin Roles</div>
        <div class="card-body">
            <span class="notification pull-left" *ngIf="isLoading"> Loading roles... <img src="../../../../assets/landing/images/ring1.gif" class="smLoader"></span>
            <button class="btn btn-info float-right" style="margin: 10px;" (click)="switchTabAdd()" type="button">Add role</button>
            <div class="datatable">
                <table class="table table-bordered table-hover" width="100%" cellspacing="0">
                    <thead>
                        <tr>
                            <th>S/N</th>
                            <th>Role Name</th>
                            <th>Permission Count</th>
                            <th>View Permissions</th>
                            <th>Update</th>
                        </tr>
                    </thead>
                    
                    <tbody>
                        <tr *ngFor='let roleStore of adminFullPermisionList; let i=index;'>
                            <td>{{i+1}}</td>
                            <td>
                                <i class="text-green" data-feather="user-check"></i>
                                {{roleStore.name}}
                            </td>
                            <td>{{roleStore.roleDetails.permissions?.length}}</td>
                            
                            <td>
                                <button class="btn btn-datatable btn-icon btn-transparent-dark mr-2"><i data-feather="more-vertical"></i></button>
                                <button class="btn btn-datatable btn-icon btn-transparent-dark" (click)="viewRolePermissions(roleStore)"  data-toggle="modal" data-target="#exampleModalXl">
                                    <i class="text-purple" data-feather="eye"></i>
                                </button>
                                
                            </td>
                            <td>
                                <button class="btn btn-datatable btn-icon btn-transparent-dark mr-2" *ngIf="roleStore.roleName !== 'SYS_ADMINISTRATOR' && roleStore.roleDetails.isCustom == true"><i data-feather="more-vertical"></i></button>
                                <button class="btn btn-datatable btn-icon btn-transparent-dark" *ngIf="roleStore.roleName !== 'SYS_ADMINISTRATOR' && roleStore.roleDetails.isCustom == true"  (click)="editRolePermissions(roleStore)" >
                                    <i class="text-purple" data-feather="edit"></i>
                                </button>
                                
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
        </div>
    </div>

</div>

<div *ngIf="!isListRoles" class="container mt-n10">
    
    <div class="row">
        <div class="col-xl-12">
            <button class="btn btn-info btn-sm float-right" style="margin: 10px;" (click)="switchTab()" type="button">Return to roles</button>
        </div>
        <div class="col-xl-4">
            <!-- Account details card-->
            <div class="card">
                <div class="card-header">New Role</div>
                <div class="card-body">
                    <form [formGroup]="addRoleForm" (ngSubmit)="addRoleToProfile(addRoleForm.value)">
                        <!-- Form Group (username)-->
                        <div class="form-group">
                            <label class="small mb-1" for="inputRole">Role Name</label>
                            <input class="form-control" id="inputRole" type="text" placeholder="Enter new role name" formControlName="roleName" />
                            <div *ngIf="f.roleName.invalid && f.roleName.touched">
                                <small *ngIf="f.roleName.errors?.required" class="small-warning text-danger">*Role name must be provided!!</small>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="small mb-1" for="inputRoleDesc">Role description</label>
                            <input class="form-control" id="inputRoleDesc" type="text" placeholder="Provide role description" formControlName="description" />
                            
                        </div>
                        
                        <!-- Save changes button-->
                        <span class="notification" *ngIf="showProcessing"> <img src="../../../../assets/landing/images/ring1.gif"  class="imgNot float-right"></span>
                        <button class="btn btn-primary" type="submit" *ngIf="!isEditRoleId" [disabled]="!addRoleForm.valid || showProcessing">Add role</button>
                        <div *ngIf="isEditRoleId" class="col-lg-12">
                            <input type="button" value="Update role" class="btn text-white btn-primary pull-left lift" (click)="updateRoleToProfile(addRoleForm.value)" >
                            <input type="button" value="Cancel" class="btn text-white btn-danger float-right lift" (click)="switchTabAdd()" >
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-xl-8">
            <!-- Account details card-->
            <div class="card mb-4">
                <div class="card-header"> Select Permissions</div>
                <div class="card-body">
                    <div class="row">
                        <!-- Form Group (first name)-->
                        <!-- <div *ngFor='let perm of storeAdminPermisionList.permissions; let i=index;' class="form-group col-md-3">
                            <label class="small mb-1" for="perm">{{perm?.replace('_', ' ')}}</label>
                            <input class="form-control" id="customCheck1" (change)="updateRolePermList(perm)" id="perm" type="checkbox" />
                        </div> -->
                        <div *ngFor='let perm of permissionsList; let i=index;' class="custom-control custom-checkbox col-4">
                            <input class="custom-control-input" id="{{perm.perm}}" type="checkbox" [checked]="isEditRoleId && permissions?.includes(perm.perm)" (change)="updateRolePermList(perm.perm)">
                            <label class="custom-control-label" for="{{perm.perm}}">{{perm.name}}</label>
                        </div>
                        
                    </div>
                    
                </div>
            </div>
        </div>
    </div>

</div>

<div class="modal fade" id="exampleModalXl" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" style="display: none;" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Role Permissions</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
                
                <div class="row">
                    <div *ngFor='let perm of permissionsList; let i=index;' class="col-4">
                        <!-- <label class="small mb-1" for="{{perm.perm}}">{{perm.perm}}</label>
                        <input class="form-control" id="{{perm.perm}}" id="perm" type="checkbox" /> -->
                        <p><b>{{perm.name}}</b></p>
                    </div>
                </div>

            </div>
            <div class="modal-footer"><button class="btn btn-secondary btn-sm btn-danger" type="button" data-dismiss="modal">Close</button></div>
        </div>
    </div>
</div>