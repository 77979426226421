<header class="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
    <div class="container-fluid">
        <div class="page-header-content">
            <div class="row align-items-center justify-content-between pt-3">
                <div class="col-auto mb-3">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="user"></i></div>
                        Account Settings - Security
                    </h1>
                </div>
            </div>
        </div>
    </div>
</header>

<!-- Main page content-->
<div class="container mt-4">
    <!-- Account page navigation-->
    <nav class="nav nav-borders">
        <a class="nav-link ml-0" routerLink="/admin/dashboard/myprofile">Profile</a>
        <a class="nav-link active" routerLink="/admin/dashboard/security">Security</a>
        
    </nav>
    <hr class="mt-0 mb-4" />
    <div class="row">
        <div class="col-lg-8">
            <!-- Change password card-->
            <div class="card mb-4">
                <div class="card-header">Change Password</div>
                <div class="card-body">
                    <form [formGroup]="userPasswordForm" (ngSubmit)="updateProfilePassword(userPasswordForm.value)">
                        <!-- Form Group (current password)-->
                        <div class="form-group">
                            <label class="small mb-1" for="currentPassword">Current Password</label>
                            <input class="form-control" id="currentPassword" type="password" placeholder="Enter current password" formControlName="oldPassword" />
                            <div *ngIf="f.oldPassword.invalid && f.oldPassword.touched">
                                <small *ngIf="f.oldPassword.errors?.required" class="small-warning text-danger">*Current password is required</small>
                            </div>
                        </div>
                        <!-- Form Group (new password)-->
                        <div class="form-group">
                            <label class="small mb-1" for="newPassword">New Password</label>
                            <input class="form-control" id="newPassword" type="password" placeholder="Enter new password" formControlName="newPassword" />
                            <div *ngIf="f.newPassword.invalid && f.newPassword.touched">
                                <small *ngIf="f.newPassword.errors?.required" class="small-warning text-danger">*New password is required</small>
                                <small *ngIf="f.newPassword.errors?.minlength" class="small-warning text-danger">*New password minimum length is 8</small>
                            </div>
                        </div>
                        <!-- Form Group (confirm password)-->
                        <div class="form-group">
                            <label class="small mb-1" for="confirmPassword">Confirm Password</label>
                            <input class="form-control" id="confirmPassword" type="password" placeholder="Confirm new password" formControlName="cPassword" />
                            <div *ngIf="f.cPassword.invalid && f.cPassword.touched">
                                <small *ngIf="f.cPassword.errors?.required" class="small-warning text-danger">*Confirm password is required</small>
                                <!-- <small *ngIf="userPasswordForm.controls['cpassword'].errors?.mustMatch" class="small-warning text-danger">Password should be same as Confirm password</small> -->
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="small mb-1" for="hint">Password Hint</label>
                            <input class="form-control" id="hint" type="text" placeholder="Set hint on password" formControlName="passwordHint" />
                        </div>
                        <span class="notification" *ngIf="showProcessing"> <img src="../../../../assets/landing/images/ring1.gif"  class="imgNot float-right"></span>
                        <button class="btn btn-primary" type="submit" [disabled]="!userPasswordForm.valid || isUpdating">Update password</button>
                    </form>
                </div>
            </div>
            
        </div>
        <div class="col-lg-4">
            <!-- Two factor authentication card-->
            <div class="card mb-4">
                <div class="card-header">Two-Factor Authentication</div>
                <div class="card-body">
                    <p>Add another level of security to your account by enabling two-factor authentication. We will send you a text message to verify your login attempts on unrecognized devices and browsers.</p>
                    <form>
                        <div class="form-group">
                            <div class="custom-control custom-radio">
                                <input class="custom-control-input" id="twoFactorOn" type="radio" name="radioUsage" checked />
                                <label class="custom-control-label" for="twoFactorOn">On</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input class="custom-control-input" id="twoFactorOff" type="radio" name="radioUsage" />
                                <label class="custom-control-label" for="twoFactorOff">Off</label>
                            </div>
                        </div>
                        <div class="form-group mb-0">
                            <label class="small mb-1" for="twoFactorSMS">Secret key</label>
                            <input class="form-control" id="twoFactorSMS" type="tel" placeholder="Secret key" />
                        </div>
                    </form>
                </div>
            </div>
            
        </div>
    </div>
</div>