
<div id="layoutAuthentication">
    <div id="layoutAuthentication_content">
        <main>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-5">
                        <!-- Basic login form-->
                        <div class="card shadow-lg border-0 rounded-lg mt-5">
                            <div class="card-header justify-content-center"><h3 class="font-weight-light my-4">Login</h3></div>
                            <div class="card-body">
                                <!-- Login form-->
                                <form [formGroup]="loginForm" (ngSubmit)="login(loginForm.value)" action="#" method="post">
                                    <!-- Form Group (email address)-->
                                    <div class="form-group">
                                        <label class="small mb-1" for="username">Username</label>
                                        <input class="form-control" id="username" type="email" placeholder="Username" formControlName="username" required />
                                        <div *ngIf="loginForm.controls['username'].invalid && loginForm.controls['username'].touched">
                                            <small *ngIf="username.errors?.required" class="small-warning text-danger">*Username is required</small>
                                            <small *ngIf="username.errors?.email" class="small-warning text-danger">*Only valid email is allowed as username</small>
                                          </div>
                                    </div>
                                    <!-- Form Group (password)-->
                                    <div class="form-group">
                                        <label class="small mb-1" for="inputPassword">Password</label>
                                        <input class="form-control" id="inputPassword" type="password" placeholder="Enter password" formControlName="password" required />
                                        <small *ngIf="loginForm.controls['password'].invalid && loginForm.controls['password'].touched" class="small-warning text-danger">*Password is required</small>
                                    </div>
                                    <!-- Form Group (remember password checkbox)-->
                                    <div class="form-group">
                                        <div class="custom-control custom-checkbox">
                                            <input class="custom-control-input" id="rememberPasswordCheck" type="checkbox" />
                                            <label class="custom-control-label" for="rememberPasswordCheck">Remember password</label>
                                        </div>
                                    </div>
                                    <!-- Form Group (login box)-->
                                    <div>
                                        <span class="mb-8" *ngIf="statusMessage.type === 'error'" style="margin-top: 2px; align-content: center" [ngClass]="{ 'alert': statusMessage, 'showError small-warning text-danger': statusMessage.type === 'error' }">** {{statusMessage.text}} **</span>
                                        <span class="notification" *ngIf="showProcessing"> Validating your profile... <img style="width: 10%; height: 10%;" src="../../../../assets/landing/images/Infinity.gif"class="imgNot pull-right"></span>
                                    </div>
                                    <div class="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
                                        <!-- <a class="small" href="auth-password-basic.html">Forgot Password?</a> -->
                                        <button type="submit" class="btn btn-primary">Login</button>
                                    </div>
                                </form>
                            </div>
                            <div class="card-footer text-center">
                                <!-- <div class="small"><a href="auth-register-basic.html">Need an account? Sign up!</a></div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
    <div id="layoutAuthentication_footer">
        <footer class="footer mt-auto footer-dark">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-6 small">Copyright &copy; Your Website 2021</div>
                    <div class="col-md-6 text-md-right small">
                        <a href="#!">Privacy Policy</a>
                        &middot;
                        <a href="#!">Terms &amp; Conditions</a>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</div>
<!-- <script src="https://code.jquery.com/jquery-3.5.1.min.js" crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/js/bootstrap.bundle.min.js" crossorigin="anonymous"></script>
<script src="js/scripts.js"></script> -->