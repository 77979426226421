import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Configuration } from 'src/app/config/configuration';
import { ApiResponseObj } from 'src/app/shared/models/loginApiResponse';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClientAppService } from 'src/app/shared/services/clientapp.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-adminroleandpermissions',
  templateUrl: './adminroleandpermissions.component.html',
  styleUrls: ['./adminroleandpermissions.component.css']
})
export class AdminroleandpermissionsComponent implements OnInit {

  private termPaths = new Configuration;
  private reqResponseObject = new ApiResponseObj;
  public showProcessing: boolean;

  public options = {type: '', title: '', text: ''};

  public countryCode;

  public activePage = 1;
  public prevPage = 1;
  public totalPages;
  public totalPageList: any[] = [];
  public currentPage = 1;
  public totalCount;

  public adminUsers;

  public role: any;
  public newUserRole: any;
  private rolePermissions: any[];
  private adminRoles;
  public adminRolesList: any[] = [];
  public adminPermisionList;
  public adminFullPermisionList = [];

  private permissions = [];
  public permissionsList = [];

  public addRoleForm: FormGroup;
  private editRoleId;
  private isEditRoleId = false;

  public isListRoles = true;
  public isLoading = false;


  public processingSalesAnalytics: boolean;
  public productSalesAnalytics: any  = {};

  public currency = 'GHS';
  public currencyLoader = {
    "NGN": "&#8358;",
    "GHS": "&#8373;" 
   } 

  constructor(private changeDetector: ChangeDetectorRef, 
    private _clientApp: ClientAppService, public auth: AuthService, 
    private alert: AlertService, private fb: FormBuilder) { }

  ngOnInit(): void {

    //this._clientApp.updateStoreUrlScripts();

    this.addRoleForm = this.fb.group({
      roleName: ["", Validators.required],
      description: [""],
      isCustom: ["true"],
      isSystemRole: ["false"],
      type: ["ADMIN"],
      permission: [""]
    });

    this.loadProductSalesAnalytics();
    this.loadAdminRoles();

  }

  loadProductSalesAnalytics(){
    this.options = {type: '', title: '', text: ''};

    let url = this.termPaths._adminBaseUrl+"productsales/analytics";
    
    this.processingSalesAnalytics = true;

    this._clientApp.get(url).subscribe(res =>{
      //console.log(res);
      this.processingSalesAnalytics = false;
      this.reqResponseObject = this.auth.mapHttpResponse(res);
      
      if(this.reqResponseObject.statusCode == "00"){
        this.productSalesAnalytics = this.reqResponseObject.data;
      }
    },  err => {
      
      this.options.type = 'error';
      this.options.text = err.error.errorMessage;
      
      this.processingSalesAnalytics = false;
      this.alert.doToastAlert(this.options, 4);
    });

  }


  loadAdminRoles(){
    let url = this.termPaths._adminBaseUrl+"system/adminroles";

    this._clientApp.get(url).subscribe(res=>{
      //console.log(res);
      this.reqResponseObject = this.auth.mapHttpResponse(res);
      
      if(this.reqResponseObject){
        this.adminRoles = this.reqResponseObject.data;

        if (this.adminRoles) {
          
          Object.entries(this.adminRoles).forEach(([k,v]) =>{
            let role = v['roleName'];
            if (role === 'SYS_ADMINISTRATOR') {
              this.adminPermisionList = { roleName : role, permissions: v['permissions']};
              let permsList = v['permissions'];
              Object.entries(permsList).forEach(([p,j]) =>{
                let pName = String(j);
                this.permissionsList[p] = {perm: j, name: pName.replace( /_/g, " ")}
              });
            }
            let roleView = role.replace("_", " ");
            
            this.adminRolesList[k] = { 'roleName': role,  'name': roleView};
            this.adminFullPermisionList[k] = {roleName : role, name: roleView, roleDetails: v, permissions: v['permissions']};
          });

        }
        //console.log(this.adminRolesList)
        this._clientApp.updateStoreUrlScripts();
        this.changeDetector.detectChanges();
      }
    })

  }

  viewRolePermissions(role){
    this.permissionsList = []
    let permsList = role.permissions;
    Object.entries(permsList).forEach(([p,j]) =>{
      let pName = String(j);
      this.permissionsList[p] = {perm: j, name: pName.replace( /_/g, " ")}
    });
  }

  editRolePermissions(role){
    //console.log(role);
    this.isEditRoleId = !this.isEditRoleId;
    this.permissions = [];
    let permsList = role.permissions;
    Object.entries(permsList).forEach(([p,j]) =>{
      let pName = String(j);
      //this.permissionsList[p] = {perm: j, name: pName.replace( /_/g, " ")}
      this.permissions.push(j);
    });
    this.editRoleId = role.roleDetails.id;
    this.addRoleForm.patchValue({roleName: role.name, description: role.roleDetails.description});
    this.isListRoles = !this.isListRoles;

  }

  updateRolePermList(perm){
    
    if (this.permissions.includes(perm)) {
      let indexOf = this.permissions.indexOf(perm);
      this.permissions.splice(indexOf, 1)
    }else{
      this.permissions.push(perm);
    }
  }

  switchTab(){
    this.isListRoles = !this.isListRoles;
    this.editRoleId = null;
    //this.isEditRoleId = !this.isEditRoleId;
    this.loadAdminRoles();
    this._clientApp.updateStoreUrlScripts();
  }

  switchTabAdd(){
    this.isListRoles = !this.isListRoles;
    this.editRoleId = null;
    this.isEditRoleId = false;
    this.loadAdminRoles();
    this._clientApp.updateStoreUrlScripts();
  }


  addRoleToProfile(submittedValue: any) {
    
    this.showProcessing = true;

    if (submittedValue.roleName == this.adminPermisionList.roleName) {
      
      this.options.text = "Only one user can be assigned the "+submittedValue.roleName+" role."
      +"Kindly visit the account and setting section to create custom role or select another role."
      this.options.title = 'Warning';

      this.alert.doToastAlert(this.options, 3);
      this.showProcessing = !this.showProcessing;

      return;
    }

    if (this.permissions.length == 0) {
      
      this.options.text = "You must select at least one permissions or two before creating a role "
      this.options.title = 'Warning';

      this.alert.doToastAlert(this.options, 2);
      this.showProcessing = !this.showProcessing;

      return;

    }

    submittedValue.permission = this.permissions;

    let reqBody = JSON.stringify(submittedValue);
    //console.log(reqBody);
    
    let url = this.termPaths._adminBaseUrl+ "system/addrole";
    //console.log(url);
    this._clientApp.post(url, reqBody)
    
    .subscribe((res) => {

      this.reqResponseObject = this.auth.mapHttpResponse(res);
      const statusCode = this.reqResponseObject.statusCode;
      const errorMsg = this.reqResponseObject.errorMessage;

        const statusMsg = this.reqResponseObject.statusMessage

        this.showProcessing = !this.showProcessing;

        if(statusCode == '00'){

          this.options.text = statusMsg;
          this.options.title = 'Successful';

          this.alert.doToastAlert(this.options, 1);
          
          this.loadAdminRoles();
          //this._clientApp.updateStoreUrlScripts();
          this.switchTab();

          
        }else{

          this.options.type = 'error';
          this.options.text = errorMsg;

          this.alert.doToastAlert(this.options, 4);
        }

    },  err => {
      
      this.options.type = 'error';
      this.options.text = err.error.errorMessage;
      //console.log(this.options.text);
      this.alert.doToastAlert(this.options, 4);
      this.showProcessing = !this.showProcessing;
      
    });

  }

  updateRoleToProfile(submittedValue: any) {
    
    this.showProcessing = true;

    if (submittedValue.roleName == this.adminPermisionList.roleName) {
      
      this.options.text = "Only one user can be assigned the "+submittedValue.roleName+" role."
      +"Kindly visit the account and setting section to create custom role or select another role."
      this.options.title = 'Warning';

      this.alert.doToastAlert(this.options, 3);
      this.showProcessing = !this.showProcessing;

      return;
    }

    if (this.permissions.length == 0) {
      
      this.options.text = "You must select at least one permissions or two before creating a role "
      this.options.title = 'Warning';

      this.alert.doToastAlert(this.options, 2);
      this.showProcessing = !this.showProcessing;

      return;

    }

    submittedValue.permission = this.permissions;

    let reqBody = JSON.stringify(submittedValue);
    //console.log(reqBody);
    
    let url = this.termPaths._adminBaseUrl+ "system/adminrole/"+this.editRoleId+"/update";
    //console.log(url);
  
    this._clientApp.put(url, reqBody)
    
    .subscribe((res) => {

      this.reqResponseObject = this.auth.mapHttpResponse(res);
      const statusCode = this.reqResponseObject.statusCode;
      const errorMsg = this.reqResponseObject.errorMessage;

        const statusMsg = this.reqResponseObject.statusMessage

        this.showProcessing = !this.showProcessing;

        if(statusCode == '00'){

          this.options.text = statusMsg;
          this.options.title = 'Successful';
          this.permissions = [];

          this.alert.doToastAlert(this.options, 1);
          
          this.loadAdminRoles();
          this.addRoleForm.reset();
          //this._clientApp.updateStoreUrlScripts();
          this.switchTab();

          
        }else{

          this.options.type = 'error';
          this.options.text = errorMsg;

          this.alert.doToastAlert(this.options, 4);
        }

    },  err => {
      
      this.options.type = 'error';
      this.options.text = err.error.errorMessage;
      
      this.alert.doToastAlert(this.options, 4);
      this.showProcessing = !this.showProcessing;
      
    });

  }

 
  setPaginationList(totalPages){
    if(totalPages < 10){
    
      for (let i = 0; i < totalPages; i++) {
        this.totalPageList[i] = {key: i, value: (i+1).toString()};
      }

    }else{
      let firstCount = 5;
      let lastCount = totalPages-firstCount;
      let elipsCount = firstCount+3;

      for (let i = 0; i < firstCount; i++) {
        this.totalPageList[i] = {key: i, value: (i+1).toString()};
      }

      for (let k = firstCount; k < elipsCount; k++) {
        this.totalPageList[k] = {key: k, value: "."};
      }
      for (let k = elipsCount; k < elipsCount+5; k++) {
        this.totalPageList[k] = {key: k, value: lastCount};
        lastCount ++;
      }
    }
    //console.log(this.totalPageList);
  }

  paginateBack(){
    //console.log(this.currentPage);
    if(this.currentPage > 1){
      --this.currentPage;
      //this.loadAdminUsers();
    }
  }

  paginateForward(){
    //console.log(this.currentPage);
    if(this.currentPage < this.totalPages){
      this.currentPage++;
      //this.loadAdminUsers();
    }
  }

  get f(){
    return this.addRoleForm.controls;
  }


}
