import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Configuration } from 'src/app/config/configuration';
import { ApiResponseObj } from 'src/app/shared/models/loginApiResponse';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClientAppService } from 'src/app/shared/services/clientapp.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AlertService } from 'src/app/shared/services/alert.service';

import * as $ from 'jquery';
import printJS from 'print-js'

import swal from 'sweetalert2';

import { UUID } from 'angular2-uuid';

@Component({
  selector: 'app-storekeepersaleboard',
  templateUrl: './storekeepersaleboard.component.html',
  styleUrls: ['./storekeepersaleboard.component.css']
})
export class StorekeepersaleboardComponent implements OnInit {

  private termPaths = new Configuration;
  private reqResponseObject = new ApiResponseObj;
  private salesResponseObject = new ApiResponseObj;
  public showProcessing: boolean;
  public salesProcessing: boolean;
  public salesPaymentProcessing: boolean;
  public isWrongTenderedAmount: boolean = false;

  public productSales = [];

  public productSalesList = [];

  public paymentOptions = [];

  public countryCode;

  public activePage = 1;
  public prevPage = 1;
  public totalPages;
  public totalPageList: any[] = [];
  public currentPage = 1;
  public totalCount;
  public perPageCount = 25;
  public totalCurrentPageCount = 25;

  private productSearch = '';
  public showSearch = false;
  public isLoading = false;
  public takeCustomerDetails = false;

  public totalAmount: number = 0;
  public totalQty = 0;
  public transBalance: number = 0.00;
  public amountTendered: number;

  public products: any[];
  public currency = 'GHS';
  public options = {type: '', title: '', text: ''};
  public productSalesAnalytics: any  = {};

  public customerForm: FormGroup;
  public momoForm: FormGroup;

  public pdfSrc = "";
  public printReceipt = false;

  private editCart: any;
  public transactions;

  public sSalesTransaction;
  public sTotalAmount;
  public sTotalQty;
  public sTransactionRef;
  public sSalesProducts;

  public currencyLoader = {
    "NGN": "&#8358;",
    "GHS": "&#8373;"
  }

  private salesRequest = {};

  private transRef;
  public enableMomoPayment = false;
  public confirmMomoPayment = false;
  public mobileNetworks = [
    {value: 'MTN', display: 'MTN'},
    {value: 'AIRTEL', display: 'AIRTEL'},
    {value: 'TIGO', display: 'TIGO'},
    {value: 'VODAFONE', display: 'VODAFONE'}
 ];
  
  constructor(private changeDetector: ChangeDetectorRef, 
    private _clientApp: ClientAppService, 
    public auth: AuthService, 
    private fb: FormBuilder,
    private alert: AlertService) { }

  ngOnInit(): void {

    this.customerForm = this.fb.group({
      customerName: [''],
      customerEmail: ['', Validators.email],
      customerPhone: ['']
    });

    let userTerminal = this.auth.getUserStoreTerminalByNumber(this.auth.getUserStoreNumber())[0];
    if(!userTerminal){
      userTerminal = {};
    }
    //console.log('user terminal: '+ userTerminal);

    this.momoForm = this.fb.group({
      transactionRef: [this.transRef],
      mobileNetwork: ['', Validators.required],
      mobile: ['', Validators.required],
      isConfirmation: ['false'],
      orderDesc: ['MTN Mobile Money Powered by Blu Penguin'],
      merchantCode: [userTerminal.merchantCode],
      feeTypeCode: [userTerminal.feeTypeCode],
      terminalId: [userTerminal.terminalId],
      terminalSN: [userTerminal.terminalSN],
      appId: [userTerminal.appId],
      appKey: [userTerminal.appKey],
      name: [userTerminal.ownerName],

    });
    
    this._clientApp.updateStoreUrlScripts();

    this.loadStoreProducts();
    this.loadProductSalesAnalytics();
    this.loadPaymentOption();
    this.loadRecentStoreSales();

    this.currency = this.auth.getUserStoreDefaultCurrency(this.auth.getUserStoreNumber());

    $(".currency").text(this.currencyLoader[this.currency]);

  }

  addProductToCart(prod){
    this.printReceipt = false;
    let dProd = null;
    Object.entries(this.productSales).forEach(([k,v]) =>{
      if(prod.id == v.prodId){
        dProd = v;
      }
    });

    if( dProd == null){
        let prodEntry = {
          "productId": prod.id,
          "productName": prod.productName,
          "quantity": 1,
          "amount": prod.amount,
          "productImageUrl": prod.productImageUrl
      }

      let prodEntryObj = {'prodId': prod.id, 'prodObj': prodEntry};

      this.productSales.push(prodEntryObj);

    }else{

      let prodEntry = null;
      let countIndex = null;

      for( var i = 0; i < this.productSales.length; i++){ 
    
          if ( this.productSales[i].prodId === prod.id) { 
              //console.log('Updating quantity element: '+prod.id)
              prodEntry = this.productSales[i]
              countIndex = i;
          }
      }

      if((prodEntry === undefined || prodEntry === null)){
        
        //console.log('No product found...')

      }else{

        prodEntry.prodObj.quantity = prodEntry.prodObj.quantity+1;
        //console.log('Updating existing product quantity: '+prodEntry)
        this.productSales[countIndex] = prodEntry;

      }

    }

    if ($('.cartIcon').hasClass('animate__headShake')) {
      
      $('.cartIcon').removeClass('animate__headShake');
      $('.cartIcon').addClass('animate__tada');

    }else if($('.cartIcon').addClass('animate__tada')){

      $('.cartIcon').removeClass('animate__tada');
      $('.cartIcon').addClass('animate__headShake');

    }
    else{
      $('.cartIcon').addClass('animate__headShake');
    }
    

    this.updateCartList();
    
  }

  removeProductFromCart(prod){
    //console.log(this.auth.getUserStorePermissions());
    let dProd =  null;
    let countIndex = null;
    Object.entries(this.productSales).forEach(([k,v]) =>{
      
      if(prod.id == v.prodId){
        dProd = v;
        countIndex = k;
      }
    });

    if( dProd == null){
      console.log('No existing product for reduction'); 
    }else{
      console.log('Updating existing product for reduction: '+countIndex); 
      let prodEntry = this.productSales[countIndex];

      if(prodEntry.prodObj.quantity !== 0){
        prodEntry.prodObj.quantity = prodEntry.prodObj.quantity-1;

      }else{

        for( var i = 0; i < this.productSales.length; i++){ 
            if ( this.productSales[i].prodId === prod.id) { 
                this.productSales.splice(i, 1); 
            }
        }
      }

    }
    if ($('.cartIcon').hasClass('animate__headShake')) {
      
      $('.cartIcon').removeClass('animate__headShake');
      $('.cartIcon').addClass('animate__tada');

    }else if($('.cartIcon').addClass('animate__tada')){

      $('.cartIcon').removeClass('animate__tada');
      $('.cartIcon').addClass('animate__headShake');
      
    }
    else{
      $('.cartIcon').addClass('animate__headShake');
    }

    this.updateCartList();
  }

  updateCartList(){
    this.totalAmount = 0.0;
    this.amountTendered = 0.00;
    this.totalQty = 0;
    this.productSalesList = [];
    for( var i = 0; i < this.productSales.length; i++){ 
      
      if(this.productSales[i].prodObj.quantity == 0){
        
        this.productSales.splice(i, 1);
      }else{
        this.productSalesList[i] = this.productSales[i].prodObj;
        
      }
      
    }

    Object.entries(this.productSalesList).forEach(([k,v]) =>{
      
      const formatter = new Intl.NumberFormat('en');
      const totalAmountCal = this.totalAmount+(v.amount * v.quantity);
      //this.totalAmount = formatter.format(totalAmountCal);
      this.totalAmount = totalAmountCal;
      this.totalQty = this.totalQty+v.quantity;
      this.amountTendered = totalAmountCal;
    });

  }

  loadStoreProducts(){
    this.isLoading = !this.isLoading;
    let url = this.termPaths._storeBaseUrl
    +this.auth.getUserStoreNumber()+"/loadproducts?page="+this.currentPage+"&size=15";

    this._clientApp.get(url).subscribe(res=>{
      
      this.isLoading = !this.isLoading;
      
      this.reqResponseObject = this.auth.mapHttpResponse(res);
      
      if(this.reqResponseObject){
        //console.log("products paginated: ",this.reqResponseObject.data);
        this.totalCount = this.reqResponseObject.data.totalCount;
        this.totalPages = this.reqResponseObject.data.totalPages;
        this.setPaginationList(this.totalPages);
        this.currentPage = this.reqResponseObject.data.page;
        this.products = this.reqResponseObject.data.payload;

        if (this.currentPage >= 1) {
          if (this.totalCurrentPageCount > this.totalCount) {
            this.totalCurrentPageCount = this.totalCount;
          }else{

            this.totalCurrentPageCount = this.perPageCount * this.currentPage;
            if (this.totalCurrentPageCount >= this.totalCount) {
              this.totalCurrentPageCount = this.totalCount;
            }
          }
        }

        this._clientApp.updateStoreUrlScripts();
        this.changeDetector.detectChanges();
      }
    })

  }

  loadRecentStoreSales(){
    let url = this.termPaths._storeBaseUrl
    +this.auth.getUserStoreNumber()+"/recentsales";

    this._clientApp.get(url).subscribe(res =>{
      //console.log('recent sales', res);
      this.reqResponseObject = this.auth.mapHttpResponse(res);
      
      if(this.reqResponseObject){
        this.transactions = this.reqResponseObject.data;
        this._clientApp.updateStoreUrlScripts();
      }
    })

  }

  viewSalesDetails(trans){
    
    this.sSalesTransaction = trans;
    this.sTotalAmount = trans.amount;
    this.sTotalQty = trans.productQuantity
    this.sTransactionRef = trans.transactionRef;
    this.sSalesProducts = trans.products;
    
    //this._clientApp.updateStoreUrlScripts();

  }

  printSales(){

    if (!this.pdfSrc || this.pdfSrc == '') {
      let options = {text: 'This sales record does not have an active receipt', title: 'Warning'};
      this.alert.doToastAlert(options, 3);
      return;
    }

    printJS(
      {
        printable: this.pdfSrc,
        type:'pdf',
        showModal:true,
        style: 'color: black; font-weight: bold;',
        targetStyles: ['*'],
        gridStyle: 'font-weight: bold',
      });

  }


  viewReceipt(url){
    //console.log(url);
    if (!url || url == null || url == '') {
      console.log("Null receipt url: "+url);
      let options = {text: 'This sales record does not have an active receipt', title: 'Warning'};
      this.alert.doToastAlert(options, 3);
      
      $('.closePay').click();
      
      return;

    }else{

      setTimeout(() => {
        //console.log("Setting pdfSrc...")
        this.pdfSrc = url;
        this.changeDetector.detectChanges();
      }, 500);

    }
    
    
  }

  paginate(index){
    //console.log('Paginated: '+index);
  }
 
  checkCart(){
    if (this.productSalesList.length === 0){
      let options = {text: 'Please select product into cart', title: 'Warning'};
      this.alert.doToastAlert(options, 3);

      $(".closePay").click();

      return;
    }
  }

  clearCart(){
    if (this.productSalesList.length === 0){
      let options = {text: 'No product found on cart to clear', title: 'Warning'};
      this.alert.doToastAlert(options, 3);
      return;
    }else{
      let options = {text: 'Cart cleared successully', title: 'Warning'};
      this.totalAmount = 0.0;
      this.totalQty = 0;
      this.productSalesList = [];
      this.alert.doToastAlert(options, 3);
    }
    this.productSalesList = [];
  }

  loadPaymentOption(){
    
    let store = this.auth.getUserStoreNumberProfilesByNumber(this.auth.getUserStoreNumber());

     this.countryCode = store.country.countryCode;

    let url = this.termPaths._systemBaseUrl+"loadcountry/paymentoptions?countryCode="+this.countryCode;
    //console.log('Payment option url: '+url)
    this._clientApp.get(url).subscribe(res=>{
      this.reqResponseObject = this.auth.mapHttpResponse(res);
      //console.log("Country payment options", this.reqResponseObject);
      
      if(this.reqResponseObject){
        
        const payOptions = this.reqResponseObject.data;
        for(var i=0; i<payOptions.length; i++){
          //console.log(payOptions[i].allowOnWeb);
            if(payOptions[i].allowOnWeb){
              this.paymentOptions.push(payOptions[i]);
            }
        }

      }
    })

  }

  loadProductSalesAnalytics(){
    this.options = {type: '', title: '', text: ''};
    let url = this.termPaths._storeBaseUrl+"configs/"
           +this.auth.getUserStoreNumber()+"/productsales/analytics";

    this._clientApp.get(url).subscribe(res =>{
      //console.log(res);
      this.reqResponseObject = this.auth.mapHttpResponse(res);
      
      if(this.reqResponseObject.statusCode == "00"){
        
        this.productSalesAnalytics = this.reqResponseObject.data;
    
      }
    })

  }

  activateCustomerDetails(){
    this.takeCustomerDetails = !this.takeCustomerDetails;
    if (!this.takeCustomerDetails) {
      this.customerForm.reset();
    }
  }

  processProductPayment(payOption: any){
    
    this.transRef =  UUID.UUID();

    let transMessage = "Transaction successfully";

     this.salesRequest = {
          "storeProfileId": this.auth.getUserStoreNumber(),
          "externalRef": this.transRef,
          "totalAmount": this.totalAmount,
          "amountTendered": this.amountTendered,
          "totalProductQuantity": this.totalQty,
          "statusCode": "00",
          "statusMessage": "Transaction successfully",
          "paymentChannelType": payOption.name,
          "paymentChannelId": payOption.id,
          "channelStatusCode": "00",
          "channelStatusMessage": "Transaction debited successfully",
          "customer": {
              "customerName": this.c.customerName.value,
              "customerEmail": this.c.customerEmail.value,
              "customerPhone": this.c.customerPhone.value,
          },
          "products": this.productSalesList   
      }

    if(payOption.code == 'MOMO'){
      //console.log('Processing momo payment.');
      let userStoreTerminalRecord = this.auth.getUserStoreTerminalByNumber(this.auth.getUserStoreNumber());
      
      if(!userStoreTerminalRecord || userStoreTerminalRecord.length == 0){
        let options = {text: '', title: ''};
          options.text = 'You currently do not have any mobile money profile assigned to your profile by store admin';
          options.title = 'Action not allowed';

          this.alert.doToastAlert(options, 3);

          return;
      }

      // sending request to momo service...
      this.enableMomoPayment = true;
      this.momoForm.patchValue({transactionRef: this.transRef});
      return;

    
    }else if(payOption.code == 'CASH'){
      this.processCashTransaction(payOption, this.salesRequest);
    } 

  }

  processProductMomoPayment(submittedValue: any){
    //console.log(submittedValue);
    this.salesPaymentProcessing = true;
    //console.log('Momo form value', this.momoForm.value);

    let options = {text: '', title: ''};

    this.salesRequest['momoPayment'] = submittedValue;

    let reqBody = JSON.stringify(this.salesRequest);
      
    let url = this.termPaths._storeBaseUrl
    +this.auth.getUserStoreNumber()+"/sales";

    this._clientApp.post(url, reqBody)
        .subscribe((res) => {

      this.salesResponseObject = this.auth.mapHttpResponse(res);
      //console.log('sales response: ', res);
      const statusCode = this.salesResponseObject.statusCode
      const errorMsg = this.salesResponseObject.errorMessage
      const statusMsg = this.salesResponseObject.statusMessage
      const status = res.body['status'];

      let data = this.salesResponseObject.data;
      //console.log("Momo init response"+ JSON.stringify(data));

      this.salesPaymentProcessing = false;

      if(statusCode == '00'){

        options.text = statusMsg;
        options.title = status;

        this.momoForm.patchValue({transactionRef :  data.transactionRef, isConfirmation: true });
        this.confirmMomoPayment = true;
        
        this.alert.doToastAlert(options, 1);
        //console.log('Momo updated confirmation form', this.momoForm.value);
        
      }else{

        options.text = errorMsg;
        options.title = status;
        this.alert.doToastAlert(options, 3);
      }

    },  err => {
      //console.log(err);
      options.text = err.error.errorMessage;
      options.title = status;
      this.alert.doToastAlert(options, 3);

      this.salesProcessing = false;
      this.salesPaymentProcessing = false;
      
    });

  }

  confirmProductMomoPayment(){
    this.pdfSrc = '';
    this.salesPaymentProcessing = true;
    let options = {text: '', title: ''};

    this.salesRequest['momoPayment'] = this.momoForm.value;

    let reqBody = JSON.stringify(this.salesRequest);
      
    let url = this.termPaths._storeBaseUrl
    +this.auth.getUserStoreNumber()+"/sales";

    this._clientApp.post(url, reqBody)
        .subscribe((res) => {
      this.salesResponseObject = this.auth.mapHttpResponse(res);
      //console.log('sales response: ', res);
      const statusCode = this.salesResponseObject.statusCode
      const errorMsg = this.salesResponseObject.errorMessage
      const statusMsg = this.salesResponseObject.statusMessage
      const status = res.body['status'];

      let data = this.salesResponseObject.data;

      this.salesPaymentProcessing = false;

      if(statusCode == '00'){

        options.text = statusMsg;
        options.title = status;
        this.confirmMomoPayment = false;
        this.enableMomoPayment = false;

        this.loadStoreProducts();
        this.loadProductSalesAnalytics();
        this.customerForm.reset();
        this.momoForm.reset();

        this.alert.doToastAlert(options, 1);

        //$('.closePay').click();
        this.totalAmount = 0.0;
        this.totalQty = 0;
        this.transBalance = 0.0;
        this.productSalesList = [];
        this.productSales = [];
        //this._clientApp.updateStoreUrlScripts();
        this.printReceipt = true;

        setTimeout(() => {
          this.pdfSrc = data.receiptUrl;
          this.changeDetector.detectChanges();
        }, 500);

        this.loadRecentStoreSales();
        this.salesRequest = {};
        
      }else{

        options.text = errorMsg;
        options.title = status;
        this.alert.doToastAlert(options, 3);
      }

    },  err => {
      //console.log(err);
      options.text = err.error.errorMessage;
      options.title = status;
      this.alert.doToastAlert(options, 3);
      this.salesPaymentProcessing = false;
      
    });
  }

  cancelMomoPaymentConfirmation(){

    return swal({
      title: 'Are you sure?',
      text: 'Are sure you want cancel this payment/transaction?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'

    }).then((result) => {

      if(result.value){

        let options = {text: '', title: ''};

        options.text = "Request to cancel payment confirmation was successful.";
        options.title = "Successful";
        this.confirmMomoPayment = false;
        this.enableMomoPayment = false;
        this.printReceipt = false;

        this.salesProcessing = false;

        this.loadStoreProducts();
        this.loadProductSalesAnalytics();
        this.customerForm.reset();
        this.momoForm.reset();

        this.alert.doToastAlert(options, 1);

        $(".closePay").click();

        this.totalAmount = 0.0;
        this.totalQty = 0;
        this.productSalesList = [];
        this.productSales = [];

      }

    });

  }

  processCashTransaction(payOption, sales){

    this.pdfSrc = '';
    this.salesProcessing = true;
    let options = {text: '', title: ''};

    let reqBody = JSON.stringify(sales);
      
    let url = this.termPaths._storeBaseUrl
    +this.auth.getUserStoreNumber()+"/sales";

    this._clientApp.post(url, reqBody)
        .subscribe((res) => {
      this.salesResponseObject = this.auth.mapHttpResponse(res);
      //console.log('sales response: ', res);
      const statusCode = this.salesResponseObject.statusCode
      const errorMsg = this.salesResponseObject.errorMessage
      const statusMsg = this.salesResponseObject.statusMessage
      const status = res.body['status'];

      let data = this.salesResponseObject.data;

      this.salesProcessing = false;

      if(statusCode == '00'){

        options.text = statusMsg;
        options.title = status;

        this.loadStoreProducts();
        this.loadProductSalesAnalytics();
        this.customerForm.reset();

        this.alert.doToastAlert(options, 1);

        //$('.closePay').click();
        this.totalAmount = 0.0;
        this.transBalance = 0.0;
        this.totalQty = 0;
        this.productSalesList = [];
        this.productSales = [];
        //this._clientApp.updateStoreUrlScripts();
        this.printReceipt = true;

        setTimeout(() => {
          this.pdfSrc = data.receiptUrl;
          this.changeDetector.detectChanges();
        }, 500);

        this.loadRecentStoreSales();
        this.salesRequest = {};
        
      }else{

        options.text = errorMsg;
        options.title = status;
        this.alert.doToastAlert(options, 3);
      }

    },  err => {
      //console.log(err);
      options.text = err.error.errorMessage;
      options.title = status;
      this.alert.doToastAlert(options, 3);
      this.salesProcessing = false;
      
    });

  }

  editProductQuantity(cart, id){
    
    let actionCartId ='#cartAction'+id;
    let editCartId ='#cartEdit'+id;
    this.editCart = cart;
  
    $(actionCartId).hide();
    $(editCartId).show();

  }

  getQuantityFieldUpdate($event){
    let nwQty = $event.target.value;
  
    let prodEntry = null;
    let countIndex = null;

      for( var i = 0; i < this.productSales.length; i++){ 
    
          if ( this.productSales[i].prodId === this.editCart.productId) { 
              //console.log('Updating quantity element: '+this.productSales[i].prodId)
              prodEntry = this.productSales[i]
              countIndex = i;
          }
      }

      if((prodEntry === undefined || prodEntry === null)){
        
        console.log('No product found for quantity...')

      }else{

        prodEntry.prodObj.quantity = parseFloat(nwQty);
        //console.log('Updating existing product quantity: '+prodEntry)
        this.productSales[countIndex] = prodEntry;

      }

  }

  calculateTransactionBalance($event){
    //console.log('Tendered amount: ', $event);
    let nwTenderedAmount = $event.target.value;
    let tendAmount = parseFloat(nwTenderedAmount);
    if (tendAmount < this.totalAmount) {
      let options = {text: 'Tendered amount cannot be less than total product amount', title: 'Warning'};
      this.alert.doToastAlert(options, 3);
      this.isWrongTenderedAmount = true;
      return;

    }else{
      this.isWrongTenderedAmount = false;
      this.transBalance = tendAmount - this.totalAmount; 
      this.amountTendered = tendAmount;
    }
  }

  closeProductQuantity(cart, id){
    //console.log('Close Cart edit quantity: '+cart);
    let actionCartId ='#cartAction'+id;
    let editCartId ='#cartEdit'+id;
    //console.log(actionCartId, editCartId);
    this.updateCartList();
    $(actionCartId).show();
    $(editCartId).hide();

  }

  searchProduct(txt){
   
    if(txt !== this.productSearch){

      this.productSearch = txt;
      this.showSearch = true;
      this.currentPage = 1;

      let url = this.termPaths._storeBaseUrl
      +this.auth.getUserStoreNumber()+"/loadproducts?search="
      +this.productSearch+"&page="+this.currentPage+"&size=20";

    this._clientApp.get(url).subscribe(res=>{
      this.reqResponseObject = this.auth.mapHttpResponse(res);
      this.showSearch = false;
      if(this.reqResponseObject){
        //console.log("products search paginated: ",this.reqResponseObject.data);
        this.totalCount = this.reqResponseObject.data.totalCount;
        this.totalPages = this.reqResponseObject.data.totalPages;
        this.setPaginationList(this.totalPages);
        this.currentPage = this.reqResponseObject.data.page;
        this.products = this.reqResponseObject.data.payload;

        this._clientApp.updateStoreUrlScripts();
        this.changeDetector.detectChanges();

      }

    })

    }
    
  }

  globalPressBarCode(){
    let lastBarcode = "";
    //document.onkeypress = this.processPressBarCodeScan($event);
  }

  processPressBarCodeScan($event){

  }

  setPaginationList(totalPages){
    if(totalPages < 10){
    
      for (let i = 0; i < totalPages; i++) {
        this.totalPageList[i] = {key: i, value: (i+1).toString()};
      }

    }else{
      let firstCount = 5;
      let lastCount = totalPages-firstCount;
      let elipsCount = firstCount+3;

      for (let i = 0; i < firstCount; i++) {
        this.totalPageList[i] = {key: i, value: (i+1).toString()};
      }

      for (let k = firstCount; k < elipsCount; k++) {
        this.totalPageList[k] = {key: k, value: "."};
      }
      for (let k = elipsCount; k < elipsCount+5; k++) {
        this.totalPageList[k] = {key: k, value: lastCount};
        lastCount ++;
      }
    }
    //console.log(this.totalPageList);
  }

  paginateBack(){
    //console.log(this.currentPage);
    if(this.currentPage > 1){
      --this.currentPage;
      this.loadStoreProducts();
    }
  }

  paginateForward(){
    //console.log(this.currentPage);
    if(this.currentPage < this.totalPages){
      this.currentPage++;
      this.loadStoreProducts();
    }
  }

  get c(){
    return this.customerForm.controls;
  }

  get m(){
    return this.momoForm.controls;
  }

}
