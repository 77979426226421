import { Injectable } from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private toastr: ToastrService) { }

  doAlert(options: any, type?: number) {
    switch (type) {
      case 1:
        swal({
          type: 'success',
          title: 'Successful',
          text: options.text,
        });
        break;
      case 2:
      swal({
        type: 'error',
        title: 'Oops..',
        text: options.text,
      });
      break;
      default:
          swal({
            type: 'success',
            title: options.title,
            text: options.text,
          });
      break;
    }
  }

  confirmAlert(warningText?: string): Promise<any> {
    return swal({
      title: 'Are you sure?',
      text: warningText,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    });
  }

  

  

  doToastAlert(options: any, type?: number) {
    switch (type) {
      case 1:
        this.toastr.success(options.text, "Successful");
        break;
      case 2:
        this.toastr.warning(options.text, options.title);
        break;
      case 3:
          this.toastr.info(options.text, options.title);
      break;
      case 4:
          this.toastr.error(options.text, 'Oops..');
      break;
      default:
        this.toastr.success(options.text, options.title);
      break;
    }
  }
}
